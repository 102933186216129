.coned-table {
    width: 100%;

    @include bp-medium {
        margin-left: 20px;
    }

    &__caption {
        @include body-l;
        margin-bottom: 25px;
        text-align: left;
        color: $dark-gray;
        font-family: $lexend-family;
        font-weight: $lexend-extra-bold;
    }

    &__head {
        @include body-s;
        margin-bottom: 20px;
        color: $medium-gray;
        font-family: $open-sans-family;
        font-weight: $open-sans-semi-bold;

        th {
            text-align: left;
            font-weight: $open-sans-semi-bold;
        }
    }

    &__body {
        @include body-l;
        font-family: $open-sans-family;
        font-weight: $open-sans-semi-bold;
    }
}
