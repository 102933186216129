// ============== DASHBOARD HEADER COMPONENT =================

.dashboard-header {
    @extend %flexbox;
    @include flex-direction(column);
    padding: 0 30px;

    @include bp-medium {
        @include flex-direction(row);
        @include justify-content(space-between);
        padding: 0 10px;
    }

    &-wrapper {
        margin: 30px 0 0;
    }

    &__tabs {
        height: 100%;
    }

    &__tab {
        display: inline-block;
        height: 100%;

        &-item {
            background-color: $transparent;
            padding: 2px;
            height: 100%;
            color: $medium-gray;
            font: $lexend-extra-bold calculate-rem(11) / calculate-rem(18) $lexend-family;

            @include bp-dashboard-header {
                padding: 10px;
                font-size: calculate-rem(14);
            }

            @include bp-in-page-banner {
                font-size: calculate-rem(18);
            }

            &:hover {
                border-bottom: 4px solid $alto;
            }
        }

        &-item--active {
            border-bottom: 4px solid $blue;
            color: $dark-gray;

            &:hover {
                border-color: $blue;
            }
        }
    }

    &__section {
        display: none;

        &--active {
            display: block;
            min-height: 400px;
        }
    }
}

.account-dropdown {
    position: relative;
    padding-bottom: 20px;

    @include bp-medium {
        padding-bottom: 0;
    }

    &__button {
        @extend %flexbox;
        @include align-items(center);
        background-color: Transparent;
        padding-right: 10px;
        width: 100%;
        text-align: left;

        @include bp-medium {
            padding-right: 20px;
        }

        &:focus {
            outline: none;

            body:not(.mouse-user):not(.is-oru) &::before {
                @extend .coned-button-focus;
            }

            body:not(.mouse-user) &::before {
                @extend .oru-button-focus;
            }
        }

        &:not(.account-dropdown__button--no-pointer) {
            cursor: pointer;
        }
    }

    &__icon-container {
        @extend .button;
    }

    &__icon {
        display: inline-block;
        border-radius: 50px;
        background-color: $blue;
        padding: 7px;
        width: 10px;
        height: 10px;
        color: $white;

        @include bp-medium {
            padding: 10px;
            width: 13px;
            height: 13px;
        }

        &:active {
            background-color: $dark-blue;
        }

        &::before {
            position: absolute;
            top: 7px;
            left: 2px;
            font-size: calculate-rem(20);

            @include bp-medium {
                left: 4px;
                font-size: calculate-rem(25);
            }
        }

        &--up {
            @include transform(rotate(180deg));

            &::before {
                top: 1px;
                left: 2px;

                @include bp-medium {
                    top: 4px;
                    left: 4px;
                }
            }
        }

        &--link {
            position: relative;
            padding: 0;
            width: 25px;
            height: 25px;

            &::before {
                top: -3px;
                left: -4px;
                font-size: calculate-rem(30);
            }
        }

        .is-oru & {
            background-color: $teal;

            &:active {
                background-color: $black;
            }
        }
    }

    &__content {
        @extend %text-truncate;
        display: inline-block;
        margin-left: 15px;
        width: 250px;
        overflow: hidden;
        font: $lexend-extra-bold calculate-rem(13) / calculate-rem(18) $lexend-family;

        @include bp-medium {
            width: auto;
        }

        &--no-results {
            display: block;
            margin: 0 auto;
            overflow: auto;
            white-space: normal;
        }

        &--auto {
            @include bp-medium {
                width: auto;
            }
        }
    }

    &__address {
        @extend %text-truncate;
        color: $dark-gray;
    }

    &__account-number {
        display: inline;
        color: $dark-gray;
        font-family: $open-sans-family;
        font-weight: $open-sans-semi-bold;
    }

    &__account-state {
        text-transform: uppercase;
        color: $dark-gray;
        font: calculate-rem(10) / calculate-rem(12) $open-sans-family;
        font-weight: $open-sans-extra-bold;
    }

    &__list {
        @include transition(all 0.2s ease);
        @include opacity(1);
        position: absolute;
        top: 35px;
        left: -12px;
        z-index: 10;
        margin-top: 20px;
        border: 1px solid $alto;
        background-color: $white;
        width: 100%;
        height: auto;

        &--hidden {
            @include opacity(0);
            display: none;
            height: 0;
            overflow: hidden;
        }
    }

    &__list--scrollable {
        overflow-y: scroll;
    }

    // please notice that the height of the item is being use on JS module coned.componets.account-dropdown.js
    // in case any declaration that affects the item height the value should be recalculated. method name setAccountScroll.
    &__option {
        @extend %flexbox;
        @include align-items(center);
        @include transition(all 0.2s ease);
        @include justify-content(center);
        background-color: $white;
        cursor: pointer;
        padding: 10px 0;
        width: auto;

        &:hover,
        &:focus {
            .account-dropdown__address {
                color: $blue;
            }

            .account-dropdown__account-number {
                color: $blue;
            }

            .account-dropdown__account-state {
                color: $blue;
            }
        }

        &--link,
        &--link:hover {
            @include flexbox;
            @include align-items(center);
            background-color: transparent;
        }

        &--add-account {
            cursor: auto;
        }

        &--scrollable {
            border-top: 1px solid $medium-gray;
        }

        body:not(.mouse-user):not(.is-oru) &:focus {
            @extend .coned-focus-outline;
        }

        body:not(.mouse-user) &:focus {
            @extend .oru-focus-outline;
        }
    }

    &__option-check {
        @include opacity(1);
        position: relative;
        width: 10px;
        height: 10px;

        &::before {
            position: absolute;
            top: -20px;
            left: -5px;
            font-size: calculate-rem(30);
        }

        &--hidden {
            display: none;
        }
    }

    &__link {
        color: $dark-gray;

        &--add-account {
            color: $white;
        }
    }

    &__arrow {
        position: absolute;
        top: -10px;
        left: 49%;
        border-right: 10px solid $transparent;
        border-bottom: 10px solid $white;
        border-left: 10px solid $transparent;
        width: 0;
        height: 0;
    }

    .highlight-text {
        background-color: $blue;
        color: $white;
    }
}

.account-dropdown-logged-in {
    position: relative;
    margin: 60px 0 20px;
    padding-bottom: 20px;    

    @include bp-medium {
        margin: 0;
        padding-bottom: 0;
    }

    &__single-container {
        padding: 10px 0;
    }

    &__button {
        @extend %flexbox;
        @include align-items(center);
        background-color: Transparent;
        padding-right: 10px;
        width: 100%;
        text-align: left;

        @include bp-medium {
            padding-right: 20px;
        }

        &:focus {
            outline: none;

            body:not(.mouse-user):not(.is-oru) &::before {
                @extend .coned-button-focus;
            }

            body:not(.mouse-user) &::before {
                @extend .oru-button-focus;
            }
        }

        &:not(.account-dropdown__button--no-pointer) {
            cursor: pointer;
        }
    }

    &__icon-container {
        @extend .button;
    }

    &__icon {
        display: inline-block;
        border-radius: 50px;
        background-color: $blue;
        padding: 7px;
        width: 10px;
        height: 10px;
        color: $white;

        @include bp-medium {
            padding: 10px;
            width: 13px;
            height: 13px;
        }

        &:active {
            background-color: $dark-blue;
        }

        &::before {
            position: absolute;
            top: 7px;
            left: 2px;
            font-size: calculate-rem(20);

            @include bp-medium {
                left: 4px;
                font-size: calculate-rem(25);
            }
        }

        &--up {
            @include transform(rotate(180deg));

            &::before {
                top: 1px;
                left: 2px;

                @include bp-medium {
                    top: 4px;
                    left: 4px;
                }
            }
        }

        &--link {
            position: relative;
            padding: 0;
            width: 25px;
            height: 25px;

            &::before {
                top: -3px;
                left: -4px;
                font-size: calculate-rem(30);
            }
        }

        .is-oru & {
            background-color: $teal;

            &:active {
                background-color: $black;
            }
        }
    }

    &__no-accounts {
        @include body-s;
        padding: 20px 30px;
        color: $dark-gray;
        font-family: $open-sans;
    }

    &__content {
        @extend %text-truncate;
        @include body-s;
        display: inline-block;
        margin-left: 23px;
        width: auto;
        overflow: hidden;
        font-family: $lexend-family;
        font-weight: $lexend-extra-bold;

        &--no-results {
            display: block;
            margin: 0 auto;
            padding: 0 30px;
            overflow: auto;
            white-space: normal;            
        }

        &--auto {
            @include bp-medium {
                width: auto;
            }
        }
    }

    &__address {
        @extend %text-truncate;
        @include body-m;
        display: block;
        color: $dark-gray;
    }

    &__account-number {
        @include body-s;
        display: inline;
        color: $medium-gray;
        font-family: $open-sans-family;
        font-weight: $open-sans-semi-bold;
    }

    &__account-state {
        @include body-s;
        text-transform: uppercase;
        color: $medium-gray;
        font-family: $open-sans-family;
        font-weight: $open-sans-extra-bold;
    }

    &__list {
        background-color: $white;
        width: 100%;
        height: auto;

        input {
            color: $dark-gray;
        }
    }

    &__list--scrollable {
        overflow-y: scroll;
    }

    // please notice that the height of the item is being use on JS module coned.componets.account-dropdown.js
    // in case any declaration that affects the item height the value should be recalculated. method name setAccountScroll.
    &__option {
        @extend %flexbox;
        @include align-items(center);
        @include transition(all 0.2s ease);
        @include justify-content(left);
        position: relative;
        background-color: $white;
        cursor: pointer;
        padding: 10px 30px;
        width: auto;

        &:hover,
        &:focus {
            .account-dropdown__address {
                color: $blue;
            }

            .account-dropdown__account-number {
                color: $blue;
            }

            .account-dropdown__account-state {
                color: $blue;
            }
        }

        &--link,
        &--link:hover {
            @include flexbox;
            @include align-items(center);
            background-color: transparent;
        }

        &--add-account {
            @include justify-content(center);
            border-width: 1px 0;
            border-style: solid;
            border-color: $dark-gray;
            cursor: auto;
        }

        &--scrollable {
            border-top: 1px solid $medium-gray;
        }

        body:not(.mouse-user):not(.is-oru) &:focus {
            @extend .coned-focus-outline;
        }

        body:not(.mouse-user) &:focus {
            @extend .oru-focus-outline;
        }
    }

    &__option-check {
        @include opacity(1);
        position: absolute;
        width: 10px;
        height: 10px;
        color: $dark-gray;

        &::before {
            position: absolute;
            top: -18px;
            left: -7px;
            font-size: calculate-rem(30);
        }

        &--hidden {
            display: none;
        }
    }

    &__link {
        color: $dark-gray;

        &--add-account {
            color: $white;
        }
    }

    &__arrow {
        position: absolute;
        top: -10px;
        left: 49%;
        border-right: 10px solid $transparent;
        border-bottom: 10px solid $white;
        border-left: 10px solid $transparent;
        width: 0;
        height: 0;
    }

    .highlight-text {
        background-color: $blue;
        color: $white;
    }
}

.dashboard-header--oru {
    .account-dropdown__option:hover,
    .account-dropdown__option:focus {
        .account-dropdown__address {
            color: $teal;
        }

        .account-dropdown__account-number {
            color: $teal;
        }

        .account-dropdown__account-state {
            color: $teal;
        }
    }

    .coned-tabs__tab-item--single {
        border-color: $teal;
    }

    .coned-tabs__tab--dropdown {
        .coned-tabs__tab-item {
            border: transparent;
        }
    }

    .highlight-text {
        background-color: $orange;
    }
}
