// ============ Skip to Main Content ===============

.skip-main-content {
    @extend %flexbox;
    @include align-items(center);
    @include justify-content(center);
    position: absolute;
    top: -100px;
    border-bottom: 1px solid $medium-gray;
    background-color: $white;
    font-family: $lexend-family;
    font-size: calculate-rem(13);
    font-weight: $lexend-extra-bold;

    &:focus {
        position: static !important;
        height: 30px;

        body:not(.mouse-user):not(.is-oru) & {
            @extend .coned-focus-outline;
        }

        body:not(.mouse-user) & {
            @extend .oru-focus-outline;
        }

        &::before {
            content: none !important;
        }
    }

    &--blue {
        color: $blue;
    }

    &--orange {
        color: $teal;
    }

    &__icon {
        position: relative;
        margin-left: 10px;
        width: 15px;
        height: 12px;

        &::before {
            position: absolute;
            top: -12px;
            left: -13px;
            font-size: calculate-rem(40);
        }
    }
}
