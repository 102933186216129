.dropdown {
    @extend %flexbox;
    position: relative;
    box-sizing: border-box;

    @include bp-medium {
        border-right: 1px solid $light-gray;
        border-left: 1px solid $light-gray;
    }

    &__button {
        @extend .button--outline-theme;
        @include btn-focus-outline;
        @include flexbox;
        @include align-items(center);
        @include justify-content(space-between);
        @include gap(20px);
        @include transition(all 150ms ease-in-out);
        background: transparent;
        padding: 5px 15px;
        width: 100%;
        min-width: 210px;
        min-height: inherit;
        box-sizing: border-box;

        .icon-carrot {
            position: static;
            color: $dark-gray;
            font-size: 3.5rem;
        }

        &-text {
            @include body-m;
            color: $dark-gray;
            font-family: $open-sans-family;
            font-weight: $open-sans-bold;
        }

        &[aria-expanded="true"],
        &[aria-expanded="false"]:hover,
        &:hover {
            background-color: $blue;

            .dropdown__button-text {
                color: $white;
            }

            .icon-carrot {
                color: $white;
            }

            .is-oru & {
                background-color: $teal;
            }
        }
    }

    &__content {
        @include flexbox;
        @include flex-direction(column);
        @include box-shadow(0, 2px, 12px, 0, $black-quarter-transparent);
        @include gap(20px);
        position: absolute;
        bottom: 0;
        left: 0;
        transform: translateY(100%);
        visibility: hidden;
        z-index: 999;
        background: $white;
        padding: 30px;
        min-width: 100%;
        box-sizing: border-box;

        &[aria-hidden="false"] {
            visibility: visible;
        }
    }

    &__container {
        @include flexbox;
        width: 100%;
    }

    &--form-to-table {
        border: 1px solid $medium-gray;

        .dropdown {
            &__button {
                @include border-radius(0);
                border: 0 !important;
                background-color: $white;
                min-width: 295px;

                @include bp-medium {
                    min-width: 210px;
                }

                // scss-lint:disable NestingDepth
                &-text {
                    @include body-s;
                }

                &[aria-expanded="true"],
                &[aria-expanded="false"]:hover,
                &:hover {
                    background-color: $white;

                    .dropdown__button-text {
                        color: $black;
                    }

                    .icon-carrot {
                        color: $black;
                    }
                }

                // scss-lint:enable NestingDepth
            }

            &__content {
                left: -1px;
                border: 1px solid $medium-gray;
                -moz-box-shadow: none;
                -webkit-box-shadow: none;
                box-shadow: none;
                padding: 0;
                min-width: calc(100% + 2px);
            }
        }
    }
}
