// ============== RELATED RESOURCES COMPONENT =================

.related-resources {
    padding: 60px 20px 50px;

    @include bp-medium {
        padding: 45px 0 55px 21.5%;
        width: 50%;
    }

    &-wrapper {
        background-color: $white;
    }

    &__title {
        @include heading-m;
        color: $dark-gray;
        font-family: $lexend-family;
        font-weight: $lexend-extra-bold;
    }

    &__links {
        &-item {
            margin-top: 20px;
        }

        &-item:not(:last-child) {
            margin-bottom: 40px;
        }
    }

    &__anchor {
        @extend .button-link;
        display: flex;

        &-icon {
            @extend .button-icon;
            @include border-radius(50%);
            display: inline-block;
            margin-right: 10px;
            background-color: $blue;
            color: $white;

            &::before {
                position: absolute;
                top: 0;
                left: 0;
                font-size: 2.5rem;
            }
        }

        .in-page-banner__cta-icon {
            &:not(.related-resources__anchor-icon) {
                width: 0;
            }
        }
    }
}

.related-resources--oru {
    .related-resources__anchor-icon {
        background-color: $teal;
    }
}
