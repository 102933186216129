.accordion {
    margin: 0 auto;
    width: 100%;
    max-width: 930px;

    .collapsible-container-wrapper {
        margin: 0;
    }

    .collapsible-container {
        &__item:first-of-type {
            border-top: 2px solid $black;
        }

        &__item-content {
            padding: 0 23px;
            width: 100%;
            box-sizing: border-box;

            ul {
                padding: 0 0 0 20px;

                @include bp-medium {
                    padding-left: 14%;
                }
            }
        }

        &__item {
            border-bottom: 2px solid $black;
            box-sizing: border-box;
        }

        &__item--open {
            .collapsible-container__item-content {
                padding: 0 23px 46px;
            }
        }
    }

    &__heading {
        @include heading-m;
        margin-bottom: 20px;
        font-family: $lexend-family;
        font-weight: $lexend-extra-bold;
    }

    &__paragraph {
        @include body-l;
        margin-bottom: 20px;
        font-family: $open-sans-family;
        font-weight: $open-sans-regular;
    }

    &__action {
        @extend %flexbox;
        margin-top: 20px;
        margin-bottom: 35px;
        box-sizing: border-box;

        &-cta {
            @extend .button;
        }

        &-show {
            @extend .button-link, .button-link--hover-underline;
            @include btn-focus-outline;
        }
    }

    &__header {
        @extend %flexbox;
        @include align-items(center);
        @include justify-content(space-between);
        background: transparent;
        padding: 24px 23px;
        width: 100%;
        min-height: 102px;
        box-sizing: border-box;

        &-title,
        &-subtitle {
            @include body-l;
            max-width: 80%;
            text-align: left;
            font-family: $open-sans-family;

            @include bp-medium {
                max-width: 552px;
            }
        }

        &-title {
            font-weight: $open-sans-bold;
        }

        &-subtitle {
            font-weight: $open-sans-regular;

            @include bp-medium {
                @include text-truncate-wrap;
                max-height: 50px;
            }
        }

        .collapsible-container__item-button-text {
            @extend %flexbox;
            @include flex-direction(column);
        }

        .collapsible-container__item-icon {
            position: static;
        }

        &[aria-expanded='false'] {
            &:hover {
                background: $gray-porcelain;
            }
        }
    }

    &__wrapper {
        &-show {
            @extend %flexbox;
            @include justify-content(center);
            @include align-items(center);
            margin-top: 40px;
        }
    }

    .article-content & li ul {
        margin-top: 0;
    }

    .article-content & a {
        white-space: normal;
    }
}
