// ============== IN PAGE CUSTOMER MESSAGING COMPONENT =================

.in-page-customer-messaging {
    margin: 20px 10px;
    background-color: $white;
    padding: 20px 0;

    @include bp-medium {
        margin: 40px auto;
    }

    &__block {
        @extend %flexbox;
        @include align-items(center);
        @include box-sizing(border-box);
        padding-right: 7px;
        padding-left: 13px;
        width: 100%;
        max-width: 1000px;

        @include bp-medium {
            margin-right: auto;
            margin-left: auto;
            padding-right: 17px;
            padding-left: 7px;
        }
    }

    &__icon {
        position: relative;
        width: 40px;
        height: 40px;
        color: $red;

        span[class*='icon-'] {
            display: inherit;
            width: inherit;

            &::before {
                position: absolute;
                top: -6px;
                left: -6px;
                font-size: calculate-rem(52);
            }
        }        
    }

    &__close {
        position: relative;
        margin-left: auto;
        outline: none;
        background-color: transparent;        
        width: 40px;
        height: 40px;
                
        body:not(.mouse-user):not(.is-oru) &:focus::after {
            @extend .coned-button-focus;
        }
        
        body:not(.mouse-user) &:focus::after {
            @extend .oru-button-focus;
        }
    
        &::before {
            position: absolute;
            top: -10px;
            left: -10px;
            font-size: calculate-rem(60);
        }
    }

    &__description {
        @include body-m;
        margin-right: 8px;
        margin-left: 15px;
        color: $dark-gray;
        font-family: $open-sans-family;
        flex: 1 1 0;

        a {
            body:not(.mouse-user):not(.is-oru) &:focus {
                @extend .coned-focus-outline;
            }
            
            body:not(.mouse-user) &:focus {
                @extend .oru-focus-outline;
            }
        
            &::before {
                content: none !important;
            }
        }

        strong,
        b {
            font-weight: $open-sans-extra-bold;
        }
    }

    &__title {
        font-family: $lexend-family;
        font-weight: $lexend-extra-bold;
    }

}
