// ============== SEARCH RESULTS =================

.search-results {
    padding: 50px 20px;
    color: $dark-gray;

    @include bp-medium {
        padding: 90px 0 90px 21.5%;
        width: 57%;
    }

    &--faq {
        padding: 50px 0 0;

        @include bp-medium {
            padding: 90px 0 0;
            width: 100%;
        }
    }

    &-wrapper {
        margin: 30px auto 60px;
        background-color: $white;

        @include bp-small {
            margin: 10px 10px 20px;
        }
    }

    &__title {
        @include heading-l;
        margin-bottom: 15px;
        font-family: $lexend-family;
        font-weight: $lexend-extra-bold;

        @include bp-small {
            display: none;
        }
    }

    &__info {
        @include body-xs;
        text-transform: uppercase;
        color: $medium-gray;
        font-family: $open-sans-family;
        font-weight: $open-sans-semi-bold;
    }

    &__info--faq {
        padding: 0 20px;

        @include bp-medium {
            padding-left: 21.5%;
            width: 57%;
        }
    }

    &__list {
        margin-top: 30px;
        border-top: 1px solid $medium-gray;
        padding-top: 45px;
    }

    &__item {
        @include transition(all, 0.7s, ease);
        @include opacity(1);
        position: relative;
        top: 0;
        padding: 5px 4px;
        overflow: hidden;

        &:not(:last-child) {
            margin-bottom: 45px;
        }

        &--animation {
            @include opacity(0);
            top: 25px;
        }

        &-title {
            @include heading-m;
            display: table;
            margin-bottom: 15px;
            text-decoration: underline;
            font-family: $lexend-family;
            font-weight: $lexend-extra-bold;

            &-text {
                display: block;
            }
        }

        &-title:visited {
            color: $purple !important;
        }

        &-type,
        &-link {
            @include body-xs;
            text-decoration: underline;
            font-family: $open-sans-family;
            font-weight: $open-sans-semi-bold;

            &,
            .is-oru & {
                color: $medium-gray;
            }
        }

        &-type {
            display: inline-block;
            margin: 0 10px 10px 0;
            text-transform: uppercase;
            font-weight: $open-sans-regular;
        }

        &-type::before {
            padding-right: 10px;
        }

        &-icon {
            padding-right: 5px;
        }

        &-link {
            display: inline-block;
            word-break: break-word;

            @include bp-small {
                display: none;
            }
        }

        &-link:hover {
            text-decoration: none;
        }

        &-description {
            @include body-l;
            color: $dark-gray;
            font-family: $open-sans-family;
        }
    }
}
