// ============ FIELD ===============

.coned-field {
    @include flexbox();
    @include flex-direction(column);
    position: relative;
    top: auto;
    clear: both;
    width: 100%;

    &-error-wrapper {
        @include align-items(center);
        @include body-s;
        position: relative;
        margin: 10px 0;
        padding-left: 25px;
        color: $red;
        font-family: $open-sans-family;
        font-weight: $open-sans-semi-bold;
    }
}

.coned-field--disabled {
    .coned-label,
    .border-bar {
        @include opacity(0.5);
    }
}
