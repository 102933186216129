.outage-confirmation-banner {
    margin: 0 10px 20px;
    background-color: $white;

    @include bp-medium {
        margin: 40px 0;
    }

    &__wrapper {
        padding: 30px 20px;
        width: 100%;
        min-width: 0;

        @include bp-small {
            @include box-sizing(border-box);
        }

        @include bp-medium {
            margin: 0 auto;
            padding: 50px 0;
            width: 58%;
        }
    }

    &__heading {
        @include heading-l;
        margin-bottom: 30px;
        text-align: center;
        font-family: $lexend-family;
        font-weight: $lexend-extra-bold;

        &--no-margin-bottom {
            margin-bottom: 0;
        }
    }

    &__etr-wrapper {
        @include flexbox;
        @include flex-direction(column);

        margin-bottom: 30px;
        border: 1px solid $black;

        @include bp-medium {
            @include flex-direction(row);
        }

        &--full-width {
            width: 100%;
        }
    }

    &__etr {
        @include body-m;
        padding: 30px;
        font-family: $open-sans-family;

        @include bp-medium {
            padding: 40px;
            width: 50%;
        }

        + .outage-confirmation-banner__user-info {
            border-top: 1px solid $black;

            @include bp-medium {
                border-top: 0;
                border-left: 1px solid $black;
            }
        }
        
        &-text {
            @include body-l;
        }
    }

    &__etr--no-border-left {
        @include bp-medium {
            border-left: 0;
        }
    }

    &__etr--no-border-bottom-mobile {
        @include bp-small {
            border-bottom: 0;
        }
    }

    &__etr-estimate {
        @include heading-m;
        margin-bottom: 20px;
        color: $green;
        font-family: $lexend-family;
        font-weight: $lexend-extra-bold;
    }

    &__user-info,
    &__accounts,
    &__overview {
        @include body-l;
        padding: 30px;
        font-family: $open-sans-family;

        @include bp-medium {
            padding: 40px;
            width: 50%;
        }
    }

    &__user-info--border-top {
        border-top: 1px solid $black;

        @include bp-medium {
            border-top: 0;
        }
    }

    &__accounts,
    &__overview {
        @include bp-medium {
            border-right: 1px solid $black;
        }
    }

    &__covid-updates {
        @include body-l;
        text-align: center;
        font-family: $open-sans-family;
    }

    &--vertical-align-center {
        @include flexbox;
        @include flex-direction(column);
        @include justify-content(center);
        box-sizing: border-box;
    }

    &--margin-top {
        margin-top: 25px;
    }
}
