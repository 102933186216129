// ============== NOT AVAILABLE COMPONENT =================

.not-available {
    padding: 65px 20px 80px;
    text-align: center;
    color: $dark-gray;

    @include bp-medium {
        margin: 0 auto;
        padding: 80px 20px;
    }

    &-wrapper {
        margin: 20px 10px;
        background-color: $white;

        @include bp-medium {
            margin: 35px auto;
        }

        &--margin-top {
            margin-top: 10px;

            @include bp-medium {
                margin: 35px auto;
            }
        }

        &--margin-bottom {
            margin-bottom: 10px;

            @include bp-medium {
                margin: 35px auto;
            }
        }
    }

    &__picture {
        &-image {
            width: 125px;

            &--auto-width {
                width: auto;
                max-width: 100%;
            }
        }
    }

    &__title {
        padding: 25px 0 10px;
        font: $lexend-extra-bold calculate-rem(30) / calculate-rem(43) $lexend-family;
        word-wrap: break-word;

        @include bp-medium {
            padding: 40px 0 9px;
        }
    }

    &__text {
        padding-bottom: 30px;
        font: calculate-rem(20) / calculate-rem(30) $open-sans-family;
    }
    
    &__cta {
        @extend .button, .button--high-emphasis, %inline-flex;
        @include justify-content(center);
        @include align-items(center);
        padding: 17px 64px;
        width: 100%;

        @include bp-medium {
            width: auto;
        }
    }
}
