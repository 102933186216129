// ============== REGISTER BENEFITS COMPONENT =================

.register-benefits {
    @include flexbox;
    @include align-items(flex-start);
    @include justify-content(space-around);
    @include flex-wrap(nowrap);
    @include flex-direction(column);
    padding: 40px 30px;

    @include bp-medium {
        @include flex-direction(row);
    }

    &__title {
        @include body-l;
        padding: 40px 0 25px;
        text-align: center;
        text-transform: uppercase;
        color: $medium-gray;
        font-family: $open-sans-family;
        font-weight: $open-sans-extra-bold;
    }

    &__container {
        margin-bottom: 80px;
        background-color: $blue;
    }

    &__item {
        @include flexbox;
        @include flex-direction(row-reverse);
        @include justify-content(space-around);
        margin-bottom: 30px;
        width: 100%;

        @include bp-medium {
            @include flex-direction(column);
            @include justify-content(center);
            width: 25%;
        }
    }

    &__image {
        max-height: 110px;
    }

    &__image-container {
        @include flexbox;
        @include align-items(center);
        @include justify-content(center);
        width: 60%;
        min-height: 110px;

        @include bp-medium {
            width: auto;
        }
    }

    &__paragraph {
        @include heading-m;
        padding: 25px 5px;
        width: 40%;
        text-align: center;
        color: $white;
        font-family: $lexend-family;
        font-weight: $lexend-extra-bold;

        @include bp-medium {
            width: auto;
        }
    }

    &__terms-container {
        text-align: center;
    }

    &__terms {
        @include body-s;
        color: $dark-gray;
        font-family: $open-sans-family;
    }
}

.register-benefits__container--oru {
    background-color: $orange;

    .register-benefits {
        &__paragraph {
            color: $teal;
        }
    }
}
