// =========== FOUNDATIONAL GLOBAL TYPE STYLES ==============

@mixin display-l {
    line-height: calculate-rem(108);
    font-size: calculate-rem(83);

    @include bp-medium {
        line-height: calculate-rem(137);
        font-size: calculate-rem(105);
    }
}

@mixin display-m {
    line-height: calculate-rem(43);
    font-size: calculate-rem(33);

    @include bp-medium {
        line-height: calculate-rem(60);
        font-size: calculate-rem(46);
    }
}

@mixin heading-l {
    line-height: calculate-rem(38);
    font-size: calculate-rem(29);

    @include bp-medium {
        line-height: calculate-rem(43);
        font-size: calculate-rem(33);
    }
}

@mixin heading-m {
    line-height: calculate-rem(30);
    font-size: calculate-rem(23);
}

@mixin heading-s {
    line-height: calculate-rem(30);
    font-size: calculate-rem(20);
}

@mixin body-l {
    line-height: calculate-rem(27);
    font-size: calculate-rem(18);
}

@mixin body-m {
    line-height: calculate-rem(24);
    font-size: calculate-rem(16);
}

@mixin body-s {
    line-height: calculate-rem(21);
    font-size: calculate-rem(14);
}

@mixin body-xs {
    line-height: calculate-rem(18);
    font-size: calculate-rem(12);
}

@mixin body-xxs {
    line-height: calculate-rem(14);
    font-size: calculate-rem(10);
}
