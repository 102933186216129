// ============== WAMI COMPONENT =================

.wami {
    &__wrapper {
        margin-bottom: 35px;
    }

    &__notifications-description {
        @include body-s;
        color: $medium-gray;
        font-family: $open-sans-family;

        a {
            font-weight: $open-sans-semi-bold;
        }
    }

    &__description {
        @include body-s;
        border-bottom: 3px solid $medium-gray;
        padding-bottom: 20px;
        color: $medium-gray;
        font-family: $open-sans-family;

        a {
            font-weight: $open-sans-semi-bold;
        }
    }

    &__notifications {
        &-headers-container {
            margin-top: 20px;
            border-top: 3px solid $medium-gray;
            padding-bottom: 50px;
        }

        &-header-email,
        &-header-phone {
            @include flexbox;
            @include align-items(baseline);
            @include flex-direction(column);
            border-bottom: 1px solid $medium-gray;
            padding: 15px 0;
            white-space: break-spaces;

            @include bp-medium {
                @include flex-direction(row);
            }

            .wami__communication-title {
                white-space: nowrap;
            }

            .wami__communication-description {
                max-width: 100%;
            }
        }

        &-header-email .wami__communication-description {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        &-title {
            @include heading-s;
            padding-bottom: 15px;
            font-family: $lexend-family;
            font-weight: $lexend-extra-bold;
        }

        &-subtitle {
            @include body-m;
            border-top: 1px solid $medium-gray;
            padding: 15px 0;
            font-family: $lexend-family;
            font-weight: $lexend-extra-bold;
        }
    }

    &__notification {
        &-item {
            border-top: 3px solid $medium-gray;
            padding: 20px 0 50px;
        }

        &-wrapper {
            display: block;
            width: 100%;
        }

        &-description {
            &-container {
                margin-top: 10px;
                border-top: 1px solid $medium-gray;
                padding: 20px 0;
            }

            &-title {
                @include heading-s;
                font-family: $lexend-family;
                font-weight: $lexend-extra-bold;
            }
        }

        &-tooltip-container {
            border-bottom: 1px solid $medium-gray;
            padding-bottom: 15px;

            &--multiple-option {
                padding-top: 25px;
            }
        }

        &-tooltip-description {
            @include body-s;
            margin-right: 5px;
            color: $dark-gray;
            font-family: $open-sans-family;
            font-weight: $open-sans-semi-bold;
        }

        &-header {
            @include flexbox;
            @include justify-content(space-between);
            @include align-items(center);
            border-top: 1px solid $medium-gray;

            &-email,
            &-phone {
                @include flexbox;
                @include justify-content(space-between);
                @include align-items(center);
                border-bottom: 1px solid $medium-gray;
            }
        }

        &-check {
            @include flexbox;
            @include align-items(baseline);

            &-type {
                @include body-s;
                text-transform: uppercase;
                font-family: $open-sans-family;
                font-weight: $open-sans-bold;
            }

            &-description {
                @include body-s;
                margin-left: 5px;
                color: $dark-gray;
                font-family: $open-sans-family;
            }
        }

        &-description-container {
            border-bottom: 1px solid $medium-gray;
            padding-bottom: 20px;
        }
    }

    &__communication {
        &-title {
            @include body-s;
            margin-right: 10px;
            text-transform: uppercase;
            font-family: $open-sans-family;
            font-weight: $open-sans-bold;
        }

        &-description {
            @include body-s;
            padding-top: 10px;
            color: $medium-gray;
            font-family: $open-sans-family;
            font-weight: $open-sans-regular;

            @include bp-medium {
                padding-top: 0;
            }

            a {
                font-weight: $open-sans-semi-bold;
            }
        }
    }

    &__button-wrapper {
        padding-top: 30px;
        text-align: center;
    }

    &__button {
        @extend .button, .button--high-emphasis;

        &:focus {
            outline: none;

            body:not(.mouse-user):not(.is-oru) &:before {
                @extend .coned-button-focus;
            }

            body:not(.mouse-user) &:before {
                @extend .oru-button-focus;
            }
        }
    }

    &__button-back {
        @extend .button-link;
        display: inline-flex;

        &-icon::before {
            @include transform(rotate(90deg));
            position: absolute;
            top: -1px;
            left: -6px;
            font-size: calculate-rem(25);
        }

        &-icon {
            display: inline-flex;
            position: relative;
            margin-right: 5px;
            width: 12px;
            height: 12px;
        }

        &-text {
            display: inline-flex;
        }
    }

    &__search {
        &-wrapper {
            border-bottom: 3px solid $medium-gray;
        }

        &-container {
            padding: 15px 0;

            .text-search__icon {
                right: 3%;
            }
        }

        &-clear-button {
            right: 25px;
        }
    }

    &__pagination-item {
        @include flexbox;
        @include flex-direction(row);
        @include justify-content(space-between);
        @include align-items(center);
        @include flex-wrap(wrap);
        border-bottom: 2px solid $medium-gray;
        padding: 20px 5px;

        &-info {
            max-width: 70%;
        }

        &-manage {
            max-width: 25%;
        }

        &-error {
            margin-top: 10px;
            border-top: 1px solid $medium-gray;
            padding-top: 20px;
            width: 100%;
        }

        .highlight-text {
            background-color: $blue;
            color: $white;

            &--oru {
                background-color: $orange;
            }
        }

        .no-text-search .highlight-text {
            background-color: inherit;
            color: inherit;
        }

        &--no-results {
            display: inline-block;
            border: 0;
        }
    }

    &__address {
        @include body-s;
        display: block;
        color: $dark-gray;
        font-family: $lexend-family;
        font-weight: $lexend-extra-bold;
    }

    &__account-number {
        &-title {
            text-transform: uppercase;
            font-weight: $open-sans-bold;
        }

        &-content {
            @include body-s;
            display: inline;
            font-family: $open-sans-family;
        }
    }

    &__account-state {
        @include body-s;
        display: none;
        text-transform: uppercase;
        color: $dark-gray;
        font-family: $open-sans-family;

        @include bp-medium {
            display: inline;
        }
    }

    &__cta {
        @extend .button-link, .button-link--extra-low-emphasis, %flexbox;
        
        * {
            pointer-events: none;
        }
    }
}
