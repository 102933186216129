// ============== FINANCIAL ADVISOR COMPONENT =================
.financial-advisor {
    @include flexbox;
    @include justify-content(center);
    @include flex-direction(column);
    margin: 20px 10px;
    background: $white;
    
    @include bp-medium {
        margin: 30px auto 20px;
        max-width: 1400px;
    }

    &__place-holder-image {
        @include flexbox;
        @include justify-content(center);
        @include align-items(center);

        margin-bottom: 24px;
        border: dashed $medium-gray;
        background: $light-gray;
        width: 256px;
        height: 144px;

        &-text {
            @include heading-m;
            font-family: $lexend;
        }
    }
}
