// ============== HEADER COMPONENT =================

.header {
    &-wrapper {
        position: fixed;
        z-index: 9999;
        width: 100%;
    }

    &__logo {
        margin-left: 30px;
        width: 138px;
        height: 33px;

        @include bp-medium {
            width: 150px;
            height: 29px;
        }

        @include bp-large-header {
            margin-left: 35px;
            width: 190px;
            height: 37px;
        }

        &--our-business {
            @include bp-medium {
                display: none;
            }
        }

        &--no-margin-left {
            margin-left: 0;
        }
    }

    &__logo--coned {
        width: 175px;
        height: 27px;

        @include bp-medium {
            width: 200px;
            height: 31px;
        }

        @include bp-large-header {
            width: 250px;
            height: 39px;
        }
    }

    &__logo--cet {
        margin-left: 30px;
        width: auto;
        height: 33px;

        @include bp-medium {
            height: 29px;
        }

        @include bp-large-header {
            margin-left: 35px;
            height: 37px;
        }
    }

    &__logo--foundation {
        margin-left: 38px;
        width: 210px;

        @include bp-medium {
            margin-left: 36px;
            width: 190px;
        }

        @include bp-large-header {
            margin-left: 42px;
            width: 250px;
        }
    }

    &__main-content {
        display: none;
        width: 100%;

        @include bp-medium {
            display: block;
            border-top: 0;
            width: auto;
        }
    }

    &__actions {
        @extend %flexbox;
        @include flex-flow(row wrap);
        height: 60px;

        @include bp-medium {
            display: none;
        }
    }

    &__action {
        display: inline-block;
        height: 100%;

        @include bp-smaller {
            @include flex-grow(1);
        }

        &:not(:last-child) {
            border-right: solid 1px $white;
        }
    }

    &__action-btn {
        @extend %flexbox;
        @include align-items(center);
        @include body-l;
        background-color: $blue;
        padding: 0 5px;
        height: 100%;
        color: $white;
        font-family: $lexend-family;
        font-weight: $lexend-extra-bold;

        @include bp-min-smallerer {
            padding: 0 13px;
        }

        @include bp-primary-nav-small {
            padding: 0 20px;
        }

        @include bp-smaller {
            width: 100%;
        }

        @include bp-mid-small {
            padding: 0 30px;
        }

        &-icon {
            position: relative;
            width: 18px;
            height: 18px;

            &::before {
                position: absolute;
                top: -14px;
                left: -14px;
                font-size: calculate-rem(45);
            }
        }

        a {
            color: $white;
        }

        body:not(.mouse-user):not(.is-oru) &:focus {
            @extend .coned-focus-outline;
        }
    }

    &__secondary-nav {
        @include transition(all, 0.3s, ease);
        @include opacity(1);
        display: block;
        position: relative;
        top: 0;
        padding: 40px 25px 25px 30px;

        @include bp-medium {
            display: none;
        }

        &-item:not(:last-child) {
            margin-bottom: 35px;
        }

        &-link {
            @include flexbox();
            @include align-items(center);
            @include body-s;
            text-transform: uppercase;
            text-decoration: none;
            color: $dark-gray;
            font-family: $lexend-family;
            font-weight: $lexend-semi-bold;
        }

        &-link-icon {
            margin-left: -7px;
            padding-right: 5px;
            font-size: calculate-rem(30);
        }

        &-options {
            @include transition(0.6s easy-in-out);
            display: none;
            margin-top: 10px;
            background-color: $white;
            padding: 10px;
        }

        &-options--open {
            display: block;
        }

        &-option:not(:last-child) {
            margin-bottom: 10px;
        }

        &-anchor {
            margin-bottom: 10px;
            color: $medium-gray;
        }

        &-anchor--selected {
            cursor: text;
            color: $twitter-blue;
        }
    }

    &__secondary {
        display: none;
        background-color: $dark-gray;

        @include bp-medium {
            display: block;
            z-index: 99;
        }

        &-content {
            @extend %flexbox;
            @include flex-direction(row);
            @include justify-content(flex-end);
            @include align-items(center);
            height: 30px;
        }

        &-item {
            @include flexbox();
            @include align-items(center);
            padding-right: 20px;
            height: 100%;
        }

        &-link {
            @extend .not-underlined-anchor;
            @include body-xs;
            position: relative;
            padding-left: 22px;
            font-family: $lexend-family;
            font-weight: $lexend-semi-bold;

            &-language-switcher {
                padding-right: 22px;
            }

            &,
            .is-oru & {
                color: $white;
            }
        }

        &-link-icon {
            position: absolute;
            top: -4px;
            left: 0;
            font-size: calculate-rem(24);
        }

        &-options {
            @include transition(0.6s easy-in-out);
            @include opacity(0);
            position: absolute;
            top: 20px;
            z-index: 1;
            border: 1px solid $black;
            border-top: 5px solid $blue;
            background-color: $white;
            padding: 10px;
            width: 100px;
            max-height: 0;
            overflow: hidden;
        }

        &-options--open {
            @include opacity(1);
            max-height: 500px;
        }

        &-option:not(:last-child) {
            margin-bottom: 10px;
        }

        &-anchor {
            margin-bottom: 10px;
            color: $medium-gray;
        }

        &-anchor--selected {
            cursor: text;
            color: $twitter-blue;
        }
    }

    &__primary {
        border-bottom: solid 1px $medium-gray;
        height: 60px;

        @include bp-medium {
            height: auto;
        }

        &-left-bg {
            @include bp-medium {
                position: absolute;
                left: 0;
                background-color: $white;
                width: 50%;
                height: 90px;
            }
        }

        &-right-bg {
            @include bp-medium {
                position: absolute;
                right: 0;
                background-color: $blue;
                width: 50%;
                height: 90px;
            }
        }

        &-content {
            @extend %flexbox;
            @include flex-direction(row);
            @include align-items(center);
            @include justify-content(space-between);
            position: relative;
            background-color: $white;
            height: 60px;

            @include bp-medium {
                height: 90px;
            }
        }

        &-nav {
            @extend %flexbox;
            @include flex-direction(column);
            @include transition(all, 0.3s, ease);
            @include opacity(1);
            position: relative;
            top: 0;

            @include bp-small {    
                &--mobile-hidden {
                    display: none !important;
                }
            }

            @include bp-medium {
                @include flex-direction(row);
                @include align-items(center);
                height: 90px;
            }
        }

        &-nav-item {
            display: inline-block;
            position: relative;
            color: $dark-gray;
            font: $lexend-semi-bold calculate-rem(12) $lexend-family;

            @include bp-medium {
                height: 100%;
                font-size: calculate-rem(12);
            }

            @include bp-large-header {
                font-size: calculate-rem(13);
            }
        }

        &-nav-item:first-child {
            @include bp-medium {
                border-left: solid 1px $medium-gray;
            }
        }

        &-login {
            @include bp-medium {
                position: relative;
            }
        }
    }

    &__skip-content {
        position: absolute;
        top: -40px;
        left: 0;
        -webkit-transition: top 1s ease-out;
        transition: top 1s ease-out;
        z-index: 100;
        border: 1px solid $white;
        background: $green;
        padding: 6px;
        color: $white;

        &:focus {
            position: absolute;
            top: 0;
            left: 0;
            -webkit-transition: top 0.1s ease-in;
            transition: top 0.1s ease-in;
            outline-color: transparent;
        }
    }
}

.header--nav-item-animation {
    @include bp-small {
        @include opacity(0);
        top: 25px;
    }
}

.header__primary--open {
    @include bp-small {
        @include transition(all 0.6s ease);
        position: absolute;
        top: 0;
        border-bottom: 0;
        background-color: $white;
        width: 100%;
        overflow-y: scroll;

        .header__primary-content {
            @include flex-wrap(wrap);
            height: auto;
        }

        .header__action {
            border: 0;
        }

        .header__action-menu {
            background-color: $white;
        }

        .header__action-btn-icon {
            margin-right: -20px;
            color: $blue;
            font-size: calculate-rem(60);

            &::before {
                position: initial;
                font-size: inherit;
            }
        }

        .header__action-btn {
            display: none;
            padding: 0 25px;
        }

        .header__main-content,
        .header__action-menu {
            display: block;
        }

        .primary-nav-item__links {
            padding: 10px 40px 10px 30px;
        }
    }

    @include bp-medium {
        height: auto;
    }

    @include bp-smaller {
        .header__primary-content {
            @include flex-direction(row);
        }

        .header__actions {
            width: auto;
        }
    }
}

.primary-nav-item {
    &:hover,
    &.hover {
        @include bp-medium {
            .primary-nav-item__btn::before {
                left: 0;
                width: 100%;
            }

            .primary-nav-item__arrow {
                display: block;
            }

            .primary-nav-item__links {
                @include opacity(1);
                display: block;
                top: 90px;
                border-top: solid 1px $medium-gray;
                border-bottom: solid 3px $blue-brand;
                padding: 42px 42px 7px;
                height: auto;

                &--dropdown {
                    padding: 0;
                }
            }
        }

        .primary-nav-item__links {	
            @include opacity(1);	
            display: block;	
            top: 90px;	
            border-top: solid 1px $medium-gray;	
            border-bottom: solid 3px $blue-brand;	
            padding: 42px 42px 7px;	
            height: auto;	

            &--a11y-200-zoom {
                max-height: calc(100vh - 130px);
                overflow-y: auto;
            }
            
            &--dropdown {	
                padding: 0;	
            }	
        }	
    }	

    &__bottom-section {	
        position: fixed;	
        bottom: 0;	
        width: 100%;	

        @include bp-medium {	
            position: relative;	
        }	
    }

    &__bottom-links {	
        bottom: 0; 
        background-color: $white;
        padding: 10px 30px; 

        @include bp-medium {
            padding: 10px 30px;

            .primary-nav-item__anchor-icon {	
                position: relative;	
            }	
    	
            .primary-nav-item__anchor {	
                margin-bottom: 28px;	
            }	
        }	

        .primary-nav-item__anchor-icon {	
            position: relative;	
        }

        .primary-nav-item__anchor {	
            display: inline-flex;
            margin-bottom: 0;	
        }	
    }

    &__btn {
        @include heading-m;
        position: relative;
        background-color: transparent;
        padding: 25px 62px 25px 30px;
        width: 100%;
        text-align: left;
        font-family: $lexend-family;
        font-weight: $lexend-extra-bold;

        @include bp-medium {
            @include flexbox();
            @include align-items(center);
            @include body-xs;
            border-right: solid 1px $medium-gray;
            padding: 0 5px;
            width: 100px;
            height: 100%;
            text-align: center;
            font-family: $lexend-family;
            font-weight: $lexend-semi-bold;
            justify-content: center;
        }

        @include bp-large-header {
            width: 110px;
        }

        @include bp-hero {
            padding: 0 10px;
            width: 125px;
        }

        @include bp-extra-large-header {
            padding: 0 25px;
            width: 150px;
        }

        &::before {
            @include transition(all 0.3s ease);
            display: block;
            position: absolute;
            top: 0;
            left: 50%;
            border-top: solid 3px $blue-brand;
            width: 0%;
            content: '';
        }

        &:focus {
            outline: none;

            body:not(.mouse-user):not(.is-oru) &::after {
                @extend .coned-button-focus;
            }

            body:not(.mouse-user) &::after {
                @extend .oru-button-focus;
            }
        }

        p {	
            padding-right: 25px;	
            text-align: left;	
            
            span {	
                font-family: $lexend-family;
                font-weight: $lexend-extra-bold;
                
            }         
            
            .primary-nav-item__text--light {
                font-family: $lexend-family;
                font-weight: $lexend-semi-bold;
            }
        }
    }

    &__btn-search {
        @include bp-large {
            width: 130px !important;
        }
    }

    &__icon {
        @include bp-small {
            position: absolute;
            top: 30%;
            right: 17px;
            font-size: calculate-rem(35);

            &--mobile-hidden {
                display: none !important;
            }

            &--left {
                position: absolute;
                top: 30%;
                left: 17px;
                font-size: calculate-rem(35);
            }
        }

        @include bp-medium {
            display: none;
            padding-left: 15px;

            &--desktop-hidden {
                display: none !important;
            }

            &--mobile-hidden {
                display: inline-block !important;
            }
        }

        &--open {
            @include transform(rotate(180deg));
            padding: 0 15px 0 0;
        }

        &--carrot-right {
            @include transform(rotate(270deg));
            padding: 0;
        }

        &--carrot-left {
            @include transform(rotate(90deg));
            padding: 0;
        }

        &--globe-left {
            padding-left: 20px;
        }
    }

    &__icon-left {
        position: relative;
        width: 24px;
        height: 24px;

        @include bp-medium {
            display: inline-block;
        }

        @include bp-in-page-banner {
            display: none;
        }

        &::before {
            position: absolute;
            top: -9px;
            left: -9px;
            font-size: calculate-rem(45);
        }
    }

    &__icon-logged-in {
        @include bp-in-page-banner {
            display: none !important;
        }
    }

    &__text {
        display: none;

        @include bp-in-page-banner {
            display: inline-block;
        }
    }

    &__arrow {
        @include transition(all, 0.2s, ease);
        display: none;
        position: relative;
        background: $white;

        &:hover {
            display: block;
        }

        &::after,
        &::before {
            position: absolute;
            bottom: -1px;
            left: 50%;
            z-index: 10;
            border: solid transparent;
            width: 0;
            height: 0;
            content: '';
            pointer-events: none;
        }

        &::after {
            margin-left: -15px;
            border-width: 15px;
            border-color: $white-transparent;
            border-bottom-color: $white;
        }

        &::before {
            margin-left: -16px;
            border-width: 16px;
            border-color: $alto-transparent;
            border-bottom-color: $dark-gray;
        }

        &--active {
            display: block;
        }
    }

    &__links {
        @include transition(opacity 0.6s ease, top 0.3s ease);
        @include opacity(0);
        display: none;
        height: 0;
        overflow: hidden;

        @include bp-medium {
            position: absolute;
            top: 110px;
            left: -95%;
            z-index: 1;
            border-right: solid 1px $medium-gray;
            border-left: solid 1px $medium-gray;
            background-color: $white;
            width: 200px;

            &--right {
                right: 0;
                left: auto;
            }

            &:hover {
                @include opacity(1);
                border-top: solid 1px $alto;
                padding: 42px 42px 7px;
                height: auto;
            }
        }

        @include bp-large-header {
            left: -65%;

            &--right {
                left: auto;
            }
        }

        @include bp-extra-large-header {
            left: -43%;

            &--right {
                left: auto;
            }
        }

        &--open {
            @include bp-small {
                @include opacity(1);
                display: block;
                height: auto;
            }
        }

        &--dropdown {
            @include bp-medium {
                left: -122px;
            }
        }
    }

    &__anchor {
        @extend %flexbox, .not-underlined-anchor;
        @include align-items(center);
        @include body-xs;
        position: relative;
        margin-bottom: 28px;
        font-family: $lexend-family;
        font-weight: $lexend-semi-bold;

        &:hover {
            color: $blue;
        }

        &,
        .is-oru & {
            color: $dark-gray;
        }

        &-icon {
            position: absolute;
            left: -7px;
            font-size: calculate-rem(30);

            &::before {
                display: inline-block;
                text-decoration: none;
            }
        }

        &--lock {
            position: absolute;
            top: -2px;
            right: -2px;
            font-size: calculate-rem(25);
        }
    }
}

.primary-nav-drawer {
    position: relative;
    width: 200%;

    &--desktop-hidden {
        @include bp-medium {
            display: none !important;
        }
    }

    &__primary-wrapper {
        @include transition(left, 1s);
        position: absolute;
        top: 0;
        left: 0;
        width: 50%;

        &--hide {
            left: -100%;
        }
        
    }

    &__secondary-wrapper {
        @include transition(left, 1s);
        position: absolute;
        top: 0;
        left: 100%;
        z-index: 10;
        background: $white;
        width: 50%;

        &--show {
            left: 0;
        }
    }

    .primary-nav-item__btn {
        width: 100%;
    }

    .primary-nav-item__btn-text {
        padding-left: 25px;
    }

    .primary-nav-item__btn-language-switcher-text {
        padding-left: 40px;
    }

    .primary-nav-item__link {

        @include bp-small {
            padding: 0 30px;
        }
    }
}

.primary-nav-item--blue {
    display: none;

    @include bp-medium {
        display: inline-block;
        background-color: $blue;
        color: $white;
    }

    a {
        color: $white;
    }

    &-links {
        display: inline-block;

        @include bp-medium {
            .primary-nav-item__anchor-icon {
                margin-left: -28px;
            }
        }

        .primary-nav-item__text {
            display: none;

            @include bp-small {
                display: inline-block;
            }

            @include bp-in-page-banner {
                display: inline-block;
            }
        }
    }

    &:hover,
    &.hover {
        @include bp-medium {
            .primary-nav-item__btn::before {
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                border-top: 3px solid $white;
                width: 100%;
                content: '';
            }
        }
    }

    .primary-nav-item__btn {
        @extend %flexbox;

        @include bp-medium {
            border-right: solid 1px $white;
            padding: 0 25px;
            width: auto;
        }

        @include bp-in-page-banner {
            padding: 0 10px;
        }

        @include bp-large-header {
            padding: 0 15px;
        }

        @include bp-extra-large-header {
            padding: 0 30px;
        }
    }

    .primary-nav-item__icon::before {
        position: absolute;
        top: -7px;
        left: -9px;
        font-size: calculate-rem(30);
    }

    .primary-nav-item__icon {
        display: inline-block;
        position: relative;
        margin-right: 5px;
        padding-left: 0;
        width: 12px;
        height: 15px;
        color: $white;

        @include bp-nav-icons {
            width: 24px;
            height: 24px;

            &::before {
                top: -9px;
                left: -9px;
                font-size: calculate-rem(45);
            }
        }
    }

    .primary-nav-item__anchor {
        color: $dark-gray;

        &:hover {
            text-decoration: underline;
            color: $blue;
        }
    }

    .primary-nav-item__link--title {
        padding-left: 0;
    }
}

.primary-nav-item--logged-in {
    .primary-nav-item__icon--baby-blue {
        @include border-radius(50%);
        margin-right: 0;
        margin-left: 5px;
        background-color: $white;
        padding: 0;
        width: 15px;
        min-width: 15px;
        height: 15px;
        color: $blue;
        box-sizing: border-box;

        @include bp-medium {
            display: none;
        }

        @include bp-in-page-banner {
            display: inline-block;
        }

        &::before {
            @include transform(translate(-50%, -50%));
            position: absolute;
            top: 54%;
            left: 50%;
            font-size: calculate-rem(22);
        }
    }

    &:hover,
    &.hover {
        .primary-nav-item__links {
            @include bp-medium {
                right: 0;
                left: inherit;
                padding: 30px 0 7px;
                width: 300px;
            }
        }

        .primary-nav-item__link {
            padding: 0 42px;

            &--title {
                @include body-m;
                font-family: $lexend-family;
                font-weight: $lexend-extra-bold;
            }

            &--profile {
                display: inline-block;
                padding-right: 0;

                @include bp-medium {
                    width: 40%;
                }
            }

            &--logout {
                display: inline-block;
                padding-right: 0;

                @include bp-medium {
                    width: 30%;
                }
            }

            &--profile .primary-nav-item__anchor,
            &--preferences .primary-nav-item__anchor,
            &--logout .primary-nav-item__anchor {
                padding-left: 0;

                @include bp-medium {
                    padding-left: 30px;
                }
            }
        }
    }
}

.primary-nav-item--accounts-logged-in {

    .primary-nav-item__title {	
        @include heading-m;
        padding: 30px 30px 5px;	
        color: $dark-gray;	
        font-family: $lexend-family;
        font-weight: $lexend-extra-bold;
    }
    
    .primary-nav-item__btn {
        @include bp-large {
            width: 230px;
        }

        @include bp-extra-large {
            width: 250px;
        }
    }

    .primary-nav-item__icon--baby-blue {
        @include border-radius(50%);
        margin-right: 0;
        margin-left: 5px;
        background-color: $white;
        padding: 0;
        width: 15px;
        min-width: 15px;
        height: 15px;
        color: $blue;
        box-sizing: border-box;

        @include bp-medium {
            display: none;
        }

        @include bp-in-page-banner {
            display: inline-block;
        }

        &::before {
            @include transform(translate(-50%, -50%));
            position: absolute;
            top: 54%;
            left: 50%;
            font-size: calculate-rem(22);
        }
    }

    .primary-nav-item__links {
        @include bp-large {
            width: 360px !important;
        }

        @include bp-extra-large {
            width: 380px !important;
        }
    }

    &:hover,
    &.hover {
        .primary-nav-item__links {
            @include bp-medium {
                right: 0;
                left: inherit;
                padding: 0 0 7px;
                width: 345px;
            }
        }

        .primary-nav-item__link {
            padding: 5px 0;

            &--title {
                @include body-l;
                font-family: $lexend-family;
                font-weight: $lexend-extra-bold;
            }

            &--profile {
                padding-right: 0;
            }

            &--logout {
                padding-right: 0;
            }
        }

        .primary-nav-item__anchor-icon {
            font-size: calculate-rem(22);
        }
    }
}

.header--oru {
    @include bp-medium {
        .primary-nav-item:hover {
            .primary-nav-item__links {
                border-bottom: solid 3px $orange;
            }
        }
    }

    .header__logo {
        width: 138px;
        height: 20px;

        @include bp-primary-nav-small {
            width: 160px;
            height: 26px;
        }

        @include bp-medium {
            width: 180px;
            height: 26px;
        }

        @include bp-large-header {
            width: 250px;
            height: 36px;
        }
    }

    .search-box__logo {
        @include bp-medium {
            margin-top: 10px;
        }
    }

    .header__primary--open .header__action-menu {
        background-color: $white;
    }

    .primary-nav-item--blue {
        background-color: $orange;
        color: $dark-gray;

        .primary-nav-item__btn {
            border-right: solid 1px $teal;
        }

        .primary-nav-item__anchor:hover {
            text-decoration: underline;
        }

        &:hover,
        &.hover {
            @include bp-medium {
                .primary-nav-item__btn::before {
                    border-color: $teal;
                }
            }
        }
    }

    .header__action:not(:last-child) {
        border-color: $teal;
    }

    .primary-nav-item__btn::before,
    .search-box__input:focus {
        border-color: $orange-brand;
    }

    .header__primary-right-bg,
    .modal-selector__link--active::after,
    .header__action-btn {
        background-color: $orange;

        &:focus {
            @extend .oru-focus-outline;
        }
    }

    .search-box__animation::after {
        background-color: $teal;
    }

    .primary-nav-item__anchor:hover,
    .search-box__close-button,
    .search-box__search-button,
    .modal-selector-close,
    .modal-selector__link--active,
    .modal-selector__link:hover,
    .header__primary--open,
    .header__action-btn-icon {
        color: $teal;
    }

    .primary-nav-item--logged-in {
        .primary-nav-item__btn {
            color: $dark-gray;
        }

        .primary-nav-item__icon--baby-blue {
            background-color: $dark-gray;
            color: $white;
        }
    }

    .primary-nav-item--accounts-logged-in {

        .primary-nav-item__icon--baby-blue {
            background-color: $dark-gray;
            color: $white;
        }
    }

    .primary-nav-item__anchor-bill {
        border-bottom-color: $orange;
        background-color: $orange;

        &-cta {
            background-color: $orange;
        }
    }

    .primary-nav-item {
        &:hover,
        &.hover {
            @include bp-medium {
                .primary-nav-item__links {
                    border-bottom: solid 3px $orange;
                }
            }
        }

        &__icon {
            color: $dark-gray;
        }
    }

    .login__form--dropdown {
        @include bp-medium {
            border-bottom: solid 3px $orange;
        }
    }

    .primary-nav-item__btn {
        &:focus {
            outline: none;

            body:not(.mouse-user) &::after {
                @extend .oru-button-focus;
            }
        }
    }
}
