// ============== FORM ACTIONS COMPONENT =================

.form__actions {
    @extend %flexbox;
    @include flex-direction(column);
    @include box-sizing(border-box);
    display: flex;
    margin: 25px 0;
    width: 100%;

    @include bp-medium {
        display: block;
    }

    &--regular-column {
        @include flex-direction(column);

        .form__actions-submit-button {
            margin: 20px 0 0 30px;
        }
    }

    &-submit-button {
        @extend .button;

        @include bp-small {
            width: 100%;
        }

        @include bp-medium {
            margin-bottom: 0;
        }

        .manage-enroll__gutter &,
        .manage-cards__gutter & {
            @extend .button;
        }

        &--margin-top {
            margin-top: 15px;

            @include bp-medium {
                margin-top: 20px;
            }
        }
    }

    &-reset-button,
    &-decline-button {
        @extend .button-link;

        @include bp-smaller {
            margin-right: 0;
        }
    }

    &-decline-button {
        @extend .button-link--gray;
    }

    &-link-button {
        @extend .button-link, .button-link--extra-low-emphasis;

        @include bp-smaller {
            margin-right: 0;
        }
    }

    &--card-form {
        padding: 10px 0;
    }

    &--login-modal {
        @include bp-medium {
            float: right;
            margin: 0;
            width: 195px;
        }

        @include bp-login-modal {
            float: right;
            margin: 0;
            width: 160px;
        }
    }

    &--width-auto {
        width: auto;
    }

    &--no-padding-bottom {
        padding-bottom: 0;
    }

    &--no-wrapper {
        @include bp-small {
            padding: 25px 20px;
        }
    }

    &--more-padding {
        @include bp-medium {
            padding: 55px 0;
        }
    }

    &--no-margin-top {
        margin-top: 0;
    }

    &--no-margin-bottom {
        margin-bottom: 0;
    }

    &--margin-bottom {
        margin-bottom: 10px;
    }

    &--margin-right {
        @include bp-medium {
            margin-right: 30px;
        }
    }

    &--position-relative {
        position: relative;
    }
    
    &--row-desktop {
        @include bp-medium {
            display: inline-block;
            width: auto;
        }
    }

    &-submit-animation {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }

    &--visibility-hidden {
        visibility: hidden;
    }

    &:not(.form__actions--regular-column) .button,
    &:not(.form__actions--regular-column) .button-link {
        margin-bottom: 20px;

        @include bp-medium {
            margin: 0 30px 0 0;
        }

        &:focus {
            outline: none;

            body:not(.mouse-user):not(.is-oru) &::before {
                @extend .coned-button-focus;
            }

            body:not(.mouse-user) &::before {
                @extend .oru-button-focus;
            }
        }

        &.form__actions-submit-button--margin-top-custom {
            &:first-child {
                margin-top: 25px;
            }

            &:not(:first-child) {
                @include bp-medium {
                    margin-top: 25px;
                }
            }
        }
    }
}
