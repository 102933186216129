// ============ INPUT FILE ===============

.coned-inputfile {
    @include opacity(0);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2000;
    cursor: pointer;
    width: 100%;
    height: 100%;
    overflow: hidden;

    &-wrapper {
        border: 2px dashed $medium-gray;
        background-color: $white;

        &--with-margin {
            margin: 30px 0;
        }
    }

    &__error {
        @include body-s;
        margin-top: 10px;
        color: $red;
    }

    &__drag {
        position: relative;
        padding: 3rem 0;
        width: 100%;
        height: 100%;
        overflow: hidden;

        body:not(.mouse-user):not(.is-oru) &--focus {
            @extend .coned-focus-outline;
            width: calc(100% - 4px);
        }

        body:not(.mouse-user) &--focus {
            @extend .oru-focus-outline;
            width: calc(100% - 4px);
        }

        &:hover {
            .coned-inputfile__label-upload {
                text-decoration: none;
            }
        }

        &--bigger {
            padding: 9rem 0;
        }
    }

    &__label {
        @extend %flexbox;
        @extend %text-truncate-wrap;
        @extend .icon-upload;
        @include flex-direction(column);
        @include justify-content(center);
        @include align-items(center);
        @include body-l;
        padding: 0 20px;
        text-align: center;
        color: $medium-gray;
        font-family: $open-sans-family;
        font-weight: $open-sans-semi-bold;

        &::before {
            margin: -6px 0 10px;
            color: $blue;
            font: calculate-rem(38) 'coned-icons';
        }

        &-optional {
            font-weight: $open-sans-regular;
        }

        &-upload {
            @extend .button-link, .button-link--extra-low-emphasis;
            margin-top: 6px;
        }

        &-remove {
            @extend .button-link, .button-link--extra-low-emphasis;
            z-index: 2001;
            margin: 0 auto;
            outline: none;
            width: auto;

            body:not(.mouse-user):not(.is-oru) &:focus::before {
                @extend .coned-button-focus;
            }
        
            body:not(.mouse-user) &:focus::before {
                @extend .oru-button-focus;
            }
        }
    }

    &__file-list {
        @include body-s;
        margin-top: 6px;
        text-align: center;
        color: $silver-chalice;
        font-weight: $open-sans-regular;
    }

    &__remove {
        position: relative;
        z-index: 9999;
        background-color: transparent;
        color: $red;
    }

    &__item {
        @extend %flexbox;
        @include flex-direction(column);
        position: relative;
        margin-bottom: 20px;
        color: $medium-gray;
    }

    &__item-name {
        margin-bottom: 3px;
        font-family: $open-sans-family;
    }

    &__item-title {
        font-weight: $open-sans-bold;
    }
}

.is-oru {
    .coned-inputfile__label {
        &::before {
            color: $teal;
        }

        &-upload {
            color: $teal;
        }

        &-remove {
            color: $teal;
        }
    }
}
