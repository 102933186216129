.dashboard-third-party-accounts {
    &__no-content {
        padding: 30px 15px;
        text-align: center;
    }

    &__wrapper {
        padding: 0 20px;
    }

    &__search-input-no-content {
        margin: 50px;
    }

    &__search-input-no-content-title {
        margin-bottom: 15px;
        font: $lexend-extra-bold calculate-rem(14) / calculate-rem(18) $lexend-family;
    }

    &__search-input-no-content-text {
        font: calculate-rem(14) / calculate-rem(18) $open-sans-family;
    }

    &__search-input-field-container {
        margin-bottom: 10px;
        padding: 0 15px 15px;
    }

    &__search-input-container {
        position: relative;
        padding: 0;
        max-width: 350px;
    }

    &__search-input {
        margin-top: 10px;
    }

    &__search-input-border-bar {
        max-width: 350px;

        &::after {
            border-color: $blue;
        }
    }

    &__search-input-icon {
        position: absolute;
        top: 25px;
        right: 5%;
    }

    &__header-container {
        @include flexbox;
        @include justify-content(space-between);
        @include flex-direction(column);

        @include bp-medium {
            @include flex-direction(row);
        }
    }

    &__table-container {
        width: 100%;
        text-align: left;

        &--border-mobile {
            border-top: 2px solid $medium-gray;
            padding-top: 15px;
        }
    }

    &__table-header-group {
        display: none;
    }

    &__table-body {
        &--border {
            box-shadow: 0 -1px 0 0 $medium-gray;
        }
    }

    &__table-row {
        position: relative;
        padding: 15px 0 20px;

        &--border {
            border-bottom: 1px solid $medium-gray;
        }

        &.active-row {
            .dashboard-third-party-accounts__table-item--type,
            .dashboard-third-party-accounts__table-item--email,
            .dashboard-third-party-accounts__table-item--meter {
                display: block;
            }

            .dashboard-third-party-accounts__table-item--arrow .icon-carrot {
                @include transform(rotate(180deg));
            }
        }
    }

    &__table-header,
    &__table-item {
        @include body-xs;
        color: $dark-gray;
        font-family: $open-sans-family;
    }

    &__table-header {
        font-weight: $open-sans-extra-bold;
    }

    &__table-item {
        @include transition(opacity 0.3s ease);

        font-weight: $open-sans-semi-bold;

        &--checkbox {
            padding: 0 15px;
            width: 37px;
        }

        &--type:not(.dashboard-third-party-accounts__table-header),
        &--meter:not(.dashboard-third-party-accounts__table-header),
        &--email:not(.dashboard-third-party-accounts__table-header) {
            display: none;
        }

        &--email:not(.dashboard-third-party-accounts__table-header) {
            text-transform: lowercase;
            word-break: break-all;
        }

        &--arrow {
            position: absolute;
            bottom: 0;
            left: 0;
            z-index: 1;
            background: 0;
            cursor: pointer;
            width: 100%;
            text-align: center;

            .icon-carrot {
                display: block;
                font-size: calculate-rem(30);
            }
        }

        .dashboard-third-party-accounts__table-row &--pending-account {
            @include body-m;
            font-weight: $open-sans-bold;
        }

        .dashboard-third-party-accounts__table-row &--request {
            @include body-s;
            color: $medium-gray;
            font-family: $open-sans-family;
        }

        &--export {
            text-align: center;
        }

        &--button:focus {
            outline: none;

            body:not(.mouse-user):not(.is-oru) &::before {
                @extend .coned-button-focus;
            }

            body:not(.mouse-user) &::before {
                @extend .oru-button-focus;
            }
        }
    }

    &__checkbox-container {
        @include flexbox;
        @include align-items(center);

        padding: 0 0 15px 15px;

        &--border-top {
            margin-top: 25px;
            border-top: 2px solid $medium-gray;
            padding-top: 25px;
        }
    }

    &__list-export {
        padding: 0 0 15px 10px;

        .button-link {
            @include flexbox;
            text-decoration: none;
        }

        [class*='icon-'] {
            font-size: calculate-rem(23);
        }

        &--button-text {
            text-decoration: underline;

            &:hover {
                text-decoration: none;
            }
        }
    }

    &__checkbox-label {
        color: $dark-gray;
    }

    &__form-actions {
        text-align: center;

        .form__actions {
            justify-content: center;
        }
    }

    &--pending-access {
        .dashboard-third-party-accounts__table-row {
            @include align-items(center);
        }

        .dashboard-third-party-accounts__table-row-wrapper {
            padding-left: 10px;
        }

        .dashboard-third-party-accounts__table-item--checkbox {
            @include flex(75px 0 0);

            padding-right: 0;

            .coned-checkbox {
                top: 0;
            }
        }
    }

    .dashboard-third-party-accounts__checkbox-label {
        padding-left: 10px;
    }

    .coned-input--filled ~ .dashboard-third-party-accounts__search-input-border-bar {
        border-color: $blue;
    }

    .highlight-text {
        background-color: $alto7;
        font-weight: $open-sans-extra-bold;
    }

    .invisible {
        visibility: hidden;
    }
}

@include bp-smallest {
    .dashboard-third-party-accounts {
        &__table-item,
        &__table-header {
            font-size: calculate-rem(14);
        }

        &__table-item--export .button {
            font-size: calculate-rem(12);
        }

        &__table-item--name,
        &__table-item--account {
            max-width: calc(
                100vw - 220px
            ); // 100% of the screen minus the checkbox and export widths
        }
    }
}

@include bp-medium {
    .dashboard-third-party-accounts--pending-access
        .dashboard-third-party-accounts__table-item--checkbox {
        @include flex(50px 0 0);
    }
}

@include bp-lt-extra-large {
    .dashboard-third-party-accounts {
        &__table-row {
            @include flexbox;
            @include justify-content(left);

            &-wrapper {
                @include flex-grow(1);
                @include flex-shrink(1);
                padding-bottom: 12px;
            }
        }

        &__table-item {
            &--checkbox {
                @include flex(37px 0 0);

                position: relative;

                .coned-checkbox {
                    top: 15px;
                }
            }

            &--name,
            &--account {
                max-width: calc(
                    100vw - 250px
                ); // 100% of the screen minus the checkbox and export widths
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }

            &--account {
                padding: 4px;
            }

            &--export {
                position: absolute;
                top: 35px;
                right: 0;
            }
        }
    }
}

@include bp-extra-large {
    .dashboard-third-party-accounts {
        &__no-content {
            padding: 90px 20%;
        }

        &__search-input-field-container {
            padding: 0 50px 15px;
        }

        &__search-input {
            padding-left: 25px;
        }

        &__wrapper {
            padding: 0 50px;
        }

        &__table-container {
            display: table;
            border-top: 2px solid $medium-gray;
        }

        &__table-header-group {
            display: table-header-group;
        }

        &__table-body {
            display: table-row-group;
        }

        &__table-row {
            display: table-row;
            padding: 30px 0;
            height: auto;

            &-wrapper {
                display: table-cell;
                vertical-align: middle;

                > .dashboard-third-party-accounts__table-item,
                > .dashboard-third-party-accounts__table-header {
                    display: inline-block;
                    margin-left: -3px;
                    box-sizing: border-box;
                }
            }

            &--border {
                border: 0;

                > div {
                    border-bottom: 1px solid $medium-gray;
                }
            }

            &.active-row {
                height: auto;

                .dashboard-third-party-accounts__table-item--checkbox {
                    height: auto;
                }

                .dashboard-third-party-accounts__table-item--arrow {
                    display: none;
                }

                .dashboard-third-party-accounts__table-item--type,
                .dashboard-third-party-accounts__table-item--email,
                .dashboard-third-party-accounts__table-item--meter {
                    opacity: 1;
                    height: auto;
                }
            }
        }

        &__table-header {
            @include body-m;
            display: table-cell;
            padding: 20px 2.5px;
            vertical-align: bottom;
        }

        &__table-item {
            @include body-xs;
            display: table-cell;
            float: none;
            padding: 15px 2.5px;
            vertical-align: middle;

            &--type,
            &--email,
            &--meter {
                opacity: 1 !important;
                height: auto !important;
            }

            &--checkbox {
                padding: 15px;
                width: 5%;
                height: auto;
            }

            &--name {
                width: 17.25%;
            }

            &--account {
                width: 19.54%;
            }

            &--balance {
                width: 11.49%;
            }

            &--email {
                width: 25.86%;
            }

            &--meter {
                width: 14.37%;
            }

            &--type {
                width: 11.49%;
            }

            &--export {
                width: 8%;
            }

            &--arrow {
                display: none !important;
            }

            &--pending-account {
                width: 45%;
            }

            &--request {
                width: 55%;
            }
        }

        &--pending-access {
            .dashboard-third-party-accounts__table-item {
                padding: 0;

                &--checkbox {
                    padding: 15px 0 15px 15px;
                    width: 50px;
                }
            }
        }
    }
}
