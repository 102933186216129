// ============== METER SUBMIT COMPONENT =================

.meter-submit {
    padding: 80px 20px;

    @include bp-medium {
        padding: 95px 0 125px 21.5%;
        width: 60%;
    }

    &-wrapper {
        margin-bottom: 30px;
        background-color: $white;

        @include bp-small {
            margin: 0 10px 10px;
        }
    }

    &__title {
        padding-bottom: 30px;
        color: $dark-gray;
        font: $lexend-extra-bold calculate-rem(34) / calculate-rem(54) $lexend-family;
    }

    &__description {
        color: $dark-gray;
        font: calculate-rem(24) / calculate-rem(40) $open-sans-family;

        @include bp-medium {
            line-height: calculate-rem(32);
            font-size: calculate-rem(18);
        }
    }

    &__action {
        padding-bottom: 35px;

        @include bp-medium {
            display: inline-block;
            padding-right: 50px;
        }

        &s {
            padding: 43px 0 0;

            @include bp-medium {
                padding: 50px 0 15px;
            }
        }

        &-link {
            @extend .button-link, .button-link--extra-low-emphasis;
        }

        &-icon {
            position: relative;
            padding-right: 45px;

            @include bp-medium {
                padding-right: 30px;
            }

            &::before {
                position: absolute;
                top: -10px;
                left: -10px;
                font-size: calculate-rem(50);

                @include bp-medium {
                    top: -7px;
                    left: -7px;
                    font-size: calculate-rem(30);
                }
            }
        }
    }

    &__note {
        color: $dark-gray;
        font: calculate-rem(20) / calculate-rem(27) $open-sans-family;

        @include bp-medium {
            line-height: calculate-rem(20);
            font-size: calculate-rem(14);
        }
    }
}
