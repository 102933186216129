// ============== CARDS TILE TYPE CTA COMPONENT =================

.card-tile {
    &--type-cta {
        @extend %flexbox;
        @include align-items(center);
        min-height: 300px;

        &::before {
            border-top: 0;
        }

        .card-tile__zoom,
        .card-tile__zoom:hover {
            background-color: $blue;
            height: 100%;
        }
    }

    &__info-container--type-cta {
        position: relative;
        bottom: 0;
        margin: 0 auto;
        padding: 10px 0;
        width: 90%;
        text-align: center;
        color: $white;
        word-wrap: break-word;

        @include bp-medium {
            width: 80%;
        }
    }

    &__service-title {
        @include body-l;
        margin-top: 20px;
        font-family: $open-sans-family;

        @include bp-medium {
            margin-top: 0;
        }
    }

    &__title--type-cta {
        margin-top: 10px;

        @include bp-medium {
            margin: 10px auto 30px;
        }
    }

    // Card Tile buttons.
    &__cta-link {
        @extend .button;
        margin: 10px 0;

        body:not(.is-oru) .card-tile__zoom--blue &,
        body:not(.is-oru) .card-tile--lighter-bg & {
            @extend .button, .button--secondary-color;
        }
    }

    &__cta-link-icon {
        position: relative;
        margin-right: 10px;
        width: 24px;

        &::before {
            position: absolute;
            top: -20px;
            left: -8px;
            font-size: calculate-rem(40);
        }
    }
}

.card-tile--oru {
    .card-tile {
        &__info-container--type-cta {
            color: $teal;
        }

        &--type-cta {
            .card-tile__zoom,
            .card-tile__zoom:hover {
                background-color: $orange;
            }
        }
    }
}
