// ============== MASKED FIELD COMPONENT =================

.masked-field {
    &__container {
        position: relative;
    }

    &__button {
        @extend .button-link, .button-link--extra-low-emphasis;
        position: absolute;
        top: 45px;
        right: 0;
        text-transform: uppercase;

        body:not(.mouse-user):not(.is-oru) &:focus {
            outline: 0 !important;

            &::before {
                @extend .coned-button-focus;
            }
        }

        body:not(.mouse-user) &:focus {
            outline: 0 !important;

            &::before {
                @extend .oru-button-focus;
            }
        }
    }

    &__button-right-margin {
        @extend .button-link, .button-link--extra-low-emphasis;
        position: absolute;
        top: 45px;
        right: 0;
        margin-right: 50px;
        text-transform: uppercase;

        body:not(.mouse-user):not(.is-oru) &:focus {
            outline: 0 !important;

            &::before {
                @extend .coned-button-focus;
            }
        }

        body:not(.mouse-user) &:focus {
            outline: 0 !important;

            &::before {
                @extend .oru-button-focus;
            }
        }
    }
}
