.smart-energy {
    background: $white;
    padding: 40px 30px 0;
    min-height: 100%;

    &-title {
        font: $lexend-extra-bold calculate-rem(25) / calculate-rem(35) $lexend-family;
    }

    &-paragraph {
        padding: 30px 0;
        color: $dark-gray;
        font: 1.6rem/1.8rem 'Open Sans', sans-serif;
        font-weight: $open-sans-semi-bold;
    }
}
