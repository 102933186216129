.listing-table {
    width: 100%;
    table-layout: fixed;

    &__wrapper {
        margin-bottom: 40px;
        background-color: $white;

        &--no-margin-bottom {
            margin-bottom: 0;
        }

        &--margin-top-40 {
            margin-top: 40px;
        }
    }

    &__content {
        padding: 30px 20px;

        @include bp-medium {
            padding-right: 30px;
            padding-left: 30px;
        }

        &--compact {
            @include bp-medium {
                margin-left: 21.5%;
                padding-right: 0;
                padding-left: 0;
                width: 50%;
            }
        }
    }

    &__caption {
        @include body-l;
        padding: 16px 30px 16px 0;
        text-align: left;
        color: $dark-gray;
        font-family: $lexend-family;
        font-weight: $lexend-extra-bold;

        &-container {
            border-bottom: 3px solid $orange;

            body:not(.is-oru) & {
                border-bottom: 3px solid $blue;
            }
        }
    }

    &__head {
        .listing-table--transform & {
            @include bp-small {
                display: none;
            }
        }
    }

    &__title {
        @include body-s;
        text-transform: uppercase;
        color: $dark-gray;
        font-family: $open-sans-family;
        font-weight: $open-sans-bold;
        word-wrap: break-word;

        .listing-table--transform & {
            @include bp-small {
                width: calc(50% - 15px);
            }
        }

        &--mobile {
            @include flex-basis(50%);

            @include bp-medium {
                display: none;
            }

            &:first-child {
                padding-right: 30px;
            }

            &:not(:first-child) {
                text-transform: none;
            }
        }
    }

    &__row {
        &--light-gray-bg {
            background-color: $lighter-gray;
        }
    }

    &__cell {
        margin-bottom: 0;
        border-bottom: 1px solid $medium-gray;
        padding: 20px 30px 20px 0;
        vertical-align: top;
        text-align: start;

        .listing-table--transform & {
            @include bp-small {
                @include flexbox;
            }

            &:nth-child(2) {
                display: none;

                @include bp-medium {
                    display: table-cell;
                }
            }
        }

        &--center-align {
            text-align: center;
        }

        &--header-mobile {
            @include bp-medium {
                display: none;
            }
        }

        &--gray-mobile {
            @include bp-small {
                background-color: $lighter-gray;
            }
        }

        &--no-border-bottom {
            border-bottom: 0;
        }

        a {
            @extend %focus-outline-on-element;
        }
    }

    &__text {
        @include body-s;
        font-family: $open-sans-family;
        word-wrap: break-word;

        .listing-table--transform & {
            @include bp-small {
                width: calc(50% - 15px);
            }

            &--mobile-full-width {
                @include bp-small {
                    width: 100%;
                }
            }
        }

        &--mobile {
            padding-right: 30px;
            text-transform: uppercase;
            font-weight: $open-sans-semi-bold;

            @include bp-medium {
                display: none;
            }
        }

        &--bold {
            font-weight: $open-sans-bold;
        }

        &:not(a) {
            color: $dark-gray;
        }
    }
}
