// ============ MODIFIERS TOOLTIP =============

.tooltipster-sidetip .tooltipster-box {
    @include border-radius(0);
    border: 1px solid $medium-gray;
    border-top: 2px solid $blue-brand;
    background-color: $white;
}

.tooltipster-sidetip.tooltipster-top .tooltipster-arrow {
    bottom: -1px;
}

.tooltipster-sidetip.tooltipster-top .tooltipster-arrow-border {
    border-top-color: $medium-gray;
}

.tooltipster-sidetip.tooltipster-top .tooltipster-arrow-background {
    top: -1px;
    border-top-color: $white;
}

.tooltipster-sidetip.tooltipster-bottom .tooltipster-arrow-border {
    border-bottom-color: $blue;
}

.tooltipster-sidetip.tooltipster-bottom .tooltipster-arrow-background {
    border-bottom-color: $white;
}

.tooltipster-base,
.tooltipster-box,
.tooltipster-content {
    width: 100% !important;
    min-width: 100% !important;

    @include bp-medium {
        width: auto !important;
        min-width: auto !important;
    }
}

.tooltipster-sidetip--oru {
    .tooltipster-box {
        border-top: 2px solid $orange;
    }

    .tooltipster-arrow-border {
        border-bottom-color: $orange !important;
    }
}

.tooltipster-base {
    .modal-open & {
        z-index: 999998 !important; //available on popups or modals open
    }
}
