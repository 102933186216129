// ============ LABEL ===============

.coned-label {
    @include box-sizing(border-box);
    @include transition(all, 0.15s, ease);
    @include body-l;
    position: absolute;
    top: 0;
    z-index: 1;
    cursor: text;
    padding: 37px 0 0;
    overflow: hidden;
    color: $medium-gray;
    font-family: $open-sans-family;
    font-weight: $open-sans-semi-bold;

    @include bp-medium {
        padding-left: 20px;
    }

    &--login-dropdown {
        @include body-s;

        @include bp-medium {
            padding-left: 20px;
        }
    }

    &--date-mobile {
        margin-top: -25px;
        width: 150px;

        @include bp-medium {
            margin-top: auto;
            width: auto;
        }
    }

    &--long {
        top: auto;
        bottom: 5px;
    }
}
