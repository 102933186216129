.navigation-tile {
    position: relative;
    background-color: $white;
    padding: 30px; 
    width: 100%;
    min-height: 175px;
    box-sizing: border-box;

    @include bp-medium {
        display: inline-block;
        margin-bottom: 20px;
        width: calc(33.45% - 15px);
        min-height: 198px;

        &:not(:nth-child(2n + 1)) {
            margin-left: 0;
        }

        &:not(:nth-child(2n + 2)) {
            margin-right: 0;
        }

        &:not(:nth-child(3n + 1)) {
            margin-left: 10px;
        }

        &:not(:nth-child(3n + 3)) {
            margin-right: 10px;
        }
    } 
    
    &:not(:last-child) {
        margin-bottom: 20px;
    }
    
    &::before {
        @include transition(all 0.2s ease);
        position: absolute;
        top: -3px;
        left: 50%;
        border-top: 3px solid $blue-brand;
        width: 0;
        content: '';
    }

    &:hover {
        background-color: $alabaster;

        &::before {
            left: 0;
            width: 100%;
        }
    }

    &__wrapper {
        @extend %flexbox;
        @include flex-direction(column);
        @include flex-wrap(wrap);

        margin: 20px 10px;

        @include bp-medium {
            @include flex-direction(row);

            margin: 0 auto;
        }      
    }

    &__title {
        @include heading-m;
        margin-bottom: 10px;
        color: $dark-gray;
        font-family: $lexend-family;
        font-weight: $lexend-extra-bold;
    }

    &__description {
        @include body-l;
        margin-bottom: 20px;
        color: $dark-gray;
        font-family: $open-sans-family;
    }

    &__link-wrapper {
        @include button-s;
        font-family: $open-sans-family;
        font-weight: $open-sans-extra-bold;
        
        @include bp-medium {
            display: block;
            position: absolute;        
            bottom: 30px;
        }
    }

    &__link {
        font-weight: $open-sans-extra-bold;  
    }
}

.is-oru {
    .navigation-tile {        
        &::before {
            border-top: 3px solid $orange-brand;
        }
    }
}
