// ============== FINANCIAL-ADVISOR-ACCOUNT-SELECT COMPONENT =================

.financial-advisor-account-selector {
    @include flexbox;
    @include align-items(center);
    @include justify-content(center);
    @include flex-direction(column);
    background: $white;
    padding: 50px 20px;
    text-align: center;

    @include bp-medium {
        margin: 0 auto;
        max-width: 446px;
    }

    @include bp-large {
        max-width: 1048px;
        box-sizing: border-box;
    }

    &__image {
        margin-bottom: 24px;
        height: 164px;
    }

    &__title {
        @include heading-l;
        font-family: $lexend-family;
    }

    &__description {
        @include body-l;
        padding: 12px 0 40px;
        font-family: $open-sans;
    }

    &__dropdown { 
        margin: 0;
        width: 100%;
        text-align: center;
        font-family: $lexend-family;

        &-title {
            @include heading-m;
            padding-bottom: 16px;
        }

        &--padding-0 {
            padding: 0;
        }
    }
}
