// ============== CHECK OUTAGE COMPONENT =================

.check-outage {
    background-color: transparent;

    &__container {
        @include bp-medium {
            margin-bottom: 40px;
        }
    }

    &__nav {
        @extend %flexbox;
        @include justify-content(center);
        padding: 20px 0;
        width: 100%;
    }

    &__nav-item,
    &__link {
        @extend .button-link;
    }

    &__nav-item:not(:last-child) {
        margin-right: 25px;
    }

    &__link {
        display: table;
        margin: 0 0 30px;

        &--no-padding {
            padding: 0;
        }
    }

    &__description {
        @include body-l;
        margin-top: 44px;
        color: $dark-gray;
        font-family: $open-sans-family;
        font-weight: $open-sans-bold;

        @include bp-medium {
            margin-top: 55px;
        }
    }

    &__account-details {
        @include align-items(center);
        margin: 24px 0;

        .date-box__title {
            @include body-xs;
        }

        .date-box__date {
            @include heading-m;
        }
    }

    &__alert {
        @include body-m;
        position: relative;
        padding-left: 30px;
        color: $red;
        font-family: $open-sans-family;
        font-weight: $open-sans-semi-bold;

        &-icon {
            @include border-radius(50%);
            position: absolute;
            left: 0;
            margin-right: 10px;
            background-color: $red;
            padding: 5px;
            color: $white;
            font-size: calculate-rem(15);
        }
    }

    &__report-container {
        @extend %flexbox;
        @include align-items(center);
        @include flex-direction(column);
        margin-top: 28px;
    }

    &__report {
        @include body-m;
        color: $dark-gray;
        font-family: $open-sans-family;

        span {
            font-weight: $open-sans-bold;
        }
    }

    &__continue-button-container {
        margin: 0 auto;
        padding: 40px 0 0;
        width: 100%;
        text-align: center;
    }

    &__restoration-cta {
        @extend .button, .button--high-emphasis;
        margin: 0 auto 30px;
    }

    &__report-cta {
        @extend .button;
        margin: 20px auto;
    }

    &__cta-container {
        @include flexbox;
        @include justify-content(center);
    }

    &__see-more-options {
        margin-top: 20px;
    }

    &__next-steps {
        @include flexbox;
        @include justify-content(space-between);
        @include flex-direction(column);
        margin: 20px 0;

        @include bp-medium {
            @include flex-direction(row);
            margin: 0;
        }

        &__card {
            @include flexbox;
            @include flex-direction(column);
            @include justify-content(space-between);
            margin-bottom: 20px;
            background-color: $blue;
            width: auto;
            text-align: center;
            color: $white;

            @include bp-medium {
                margin: 0;
            }

            &__wrapper {
                padding: 60px 30px;
            }

            &__title {
                @include heading-m;
                margin-bottom: 20px;
                font-family: $lexend-family;
                font-weight: $lexend-extra-bold;
            }

            &__text {
                @include body-l;
                margin-bottom: 30px;
                font-family: $open-sans-family;
                font-weight: $open-sans-regular;
            }

            &__cta {
                @extend .button, %inline-flex, .button--high-emphasis;
                @include body-m;
                margin-top: auto;
                font-family: $open-sans-family;
                font-weight: $open-sans-extra-bold;

                @include bp-medium {
                    @include flex-direction(row);
                    @include align-self(center);
                }
            }

            &--half {
                @include bp-medium {
                    width: 49%;
                }
            }
        }

        &__card__cta {
            body:not(.is-oru) & {
                @extend .button--secondary-color;
            }
        }
    }

    *[tabindex="-1"] {
        outline: 0;
    }
}

.check-outage--oru {
    .check-outage__next-steps__card {
        background-color: $orange;
    }

    .check-outage__next-steps__card__title,
    .check-outage__next-steps__card__text {
        color: $teal;
    }
}
