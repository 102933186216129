// ============== ARTICLE HEADER COMPONENT =================

.article-header {
    margin-bottom: 10px;
    color: $dark-gray;

    @include bp-medium {
        margin-bottom: 35px;
    }

    &__background {
        @extend %flexbox;
        @include align-items(center);
        @include flex-flow(row wrap);
        background: $black-semi-transparent;
        height: 100%;

        @include bp-medium {
            @include linear-gradient-two-breaks(
                left,
                top,
                right,
                $black-almost-transparent,
                0%,
                $black-almost-transparent,
                15%,
                $transparent,
                80%,
                $transparent,
                100%
            );
        }

        &--no-gradient {
            background: none;
        }

        .article-header--image-desktop & {
            @include bp-small {
                background: $white;
            }
        }
    }

    &--image {
        @include transition(all, 0.4s, ease);
        background-color: $blue;
        background-repeat: no-repeat;
        background-position: right top;
        background-size: cover;
        height: 540px;
        color: $white;

        @include bp-medium {
            height: 515px;
        }

        .is-oru & {
            background-color: $teal;
        }

        &-light {
            color: $dark-gray !important;
        }

        &-desktop {
            border-bottom: solid 3px $blue-brand;
            background-size: auto;
            height: auto;
            color: $dark-gray;

            @include bp-medium {
                border-bottom: 0;
                background-repeat: no-repeat;
                background-position: right top;
                height: 515px;
                color: $white;
            }
        }
    }

    &-wrapper {
        background-color: $white;
    }

    &-wrapper--single-line {
        .article-header {
            margin-bottom: 20px;

            @include bp-medium {
                margin-bottom: 40px;
            }

            &__content {
                padding: 20px 30px 15px;

                @include bp-medium {
                    margin: 0 auto;
                    padding-right: 0;
                    padding-left: 0;
                    width: 72%;
                    max-width: 1000px;
                }
            }

            &__title {
                @include heading-l;
            }
        }
    }

    &__picture {
        display: none;

        &-background {
            background-color: $blue;
            background-repeat: no-repeat;
            background-position: right top;
            width: 100%;
            height: 230px;

            @include bp-medium {
                display: none;
            }

            .is-oru & {
                background-color: $teal;
            }
        }

        &-hero {
            display: block;
            position: relative;
            padding-top: 56.25%;
            height: 0;
            overflow: hidden;

            @include bp-medium {
                padding: 0;
                height: 100%;
            }
        }

        &-centered-img {
            position: absolute;
            top: 0;
            width: 100%;
            height: 100%;

            @include bp-medium {
                @include transform(translateX(-50%));
                left: 50%;
                width: auto;
            }
        }
    }

    &__split {
        @include flexbox;
        @include flex-direction(column-reverse);
        border-bottom: solid 3px $blue-brand;
        background-color: $white;

        @include bp-medium {
            @include flex-direction(row);
            border-bottom: 0;
            height: 478px;
        }
    }

    &__half-box {
        @include bp-medium {
            width: 50%;
            box-sizing: border-box;
        }

        &-content {
            @include align-self(center);
            padding: 20px 4% 0;
        }
    }

    &__content {
        position: relative;
        padding: 40px 30px 60px;

        @include bp-hero-large {
            padding: 75px 0 95px 21.5%;
            width: 65%;
        }

        @include bp-hero-extra-large {
            width: 50%;
        }

        &--left-aligned {
            @include bp-hero-large {
                padding: 75px 0 95px 2.5%;
                width: 65%;
            }
        }
    }

    &__content--image {
        padding: 20px 30px 0;

        @include bp-hero-medium {
            padding: 20px 35px 0;
        }

        @include bp-hero-large {
            padding: 0 0 0 21.5%;
            min-width: 35vw;
        }
    }

    &__title {
        @include transition(all, 0.4s, ease);
        @include opacity(1);
        @include heading-l;
        position: relative;
        top: 0;
        margin-bottom: 15px;
        font-family: $lexend-family;
        font-weight: $lexend-extra-bold;
    }

    &__byline {
        display: inline-block;
        font-size: calculate-rem(13);

        &-wrapper {
            @include transition(all, 0.4s, ease);
            margin-bottom: 20px;
        }

        &-hr {
            display: inline-block;
            margin: 0 10px 2px 0;
            background-color: $blue-brand;
            width: 75px;
            height: 3px;
        }

        &-item {
            display: inline-block;
        }

        &-item:not(:last-child) {
            margin-right: 10px;
            border-right: 2px solid $alto;
            padding-right: 10px;
        }

        &-author {
            text-transform: uppercase;
            font-family: $open-sans-family;
            font-weight: $open-sans-bold;
        }

        &-date {
            font-family: $open-sans-family;
        }
    }

    &__description {
        @include transition(all, 0.4s, ease);
        @include opacity(1);
        @include heading-s;
        position: relative;
        top: 0;
        margin-bottom: 20px;
        font-family: $lexend-family;
        font-weight: $lexend-regular;

        .article-header--image & {
            a {
                color: $white;
            }
        }
    }

    &__ctas {
        @extend %flexbox;
        @include flex-flow(row wrap);
        @include transition(all, 0.4s, ease);
        @include opacity(1);
        position: relative;
        top: 0;
    }

    &__cta {
        margin-bottom: 20px;

        &:not(:last-child) {
            margin-right: 25px;
        }

        &-link {
            @extend .button;
            overflow: hidden;

            &:focus {
                overflow: visible;
            }
        }

        &-link--no-background {
            @extend .button--secondary-color;
        }

        &-text {
            z-index: 1;
        }

        &-animation-block {
            @include transition(all, 0.4s, ease);
            @include border-radius(50px);
            position: absolute;
            top: 50%;
            left: 50%;
            background-color: $blue;
            width: 0;
            height: 0;
        }

        &-description {
            @extend %flexbox;
            @include align-items(center);
            @include body-s;
            height: 50px;
            text-transform: uppercase;
            font-family: $open-sans-family;
            font-weight: $open-sans-extra-bold;

            .article-header--image-desktop & {
                color: $dark-gray;

                @include bp-medium {
                    color: $white;
                }
            }
        }

        &-icon {
            display: inline-block;
            position: relative;
            z-index: 1;
            margin-right: 5px;
            width: 20px;

            &::before {
                position: absolute;
                top: -17px;
                left: -9px;
                font-size: calculate-rem(35);
            }
        }
    }

    &__cta-link:active {
        .article-header__cta-animation-block {
            background-color: $blue;
        }
    }
}

.article-header__description {
    ul,
    ol {
        @include body-s;
        padding: 30px 0 0 20px;
        list-style: none;
        font-family: $open-sans-family;
        font-weight: $open-sans-semi-bold;

        @include bp-medium {
            padding-left: 14%;
        }
    }

    li {
        margin-bottom: 20px;

        &::before {
            padding-right: 10px;
            color: $blue;
            content: '•';
        }

        ul,
        ol {
            padding-bottom: 1px;
        }
    }

    ul li {
        text-indent: -1.1em;
    }

    ol {
        counter-reset: elementcounter;

        li {
            text-indent: -1.6em;

            &::before {
                content: counter(elementcounter) '.';
                counter-increment: elementcounter;
            }
        }
    }

    sup {
        vertical-align: super;
        font-size: 0.75em;
    }

    h2 {
        font-size: calculate-rem(20);
        font-weight: $open-sans-regular;
    }

    a {
        white-space: normal;

        &:focus {
            body:not(.mouse-user):not(.is-oru) & {
                @extend .coned-focus-outline;
            }

            body:not(.mouse-user) & {
                @extend .oru-focus-outline;
            }

            &::before {
                content: none !important;
            }
        }
    }
}

.article-header--oru {
    .article-header {
        &__byline-hr {
            background-color: $orange-brand;
        }

        &__cta-animation-block {
            background-color: $orange;
        }

        &__description li::before {
            color: $orange;
        }
    }

    &.article-header--image-desktop {
        border-bottom: solid 3px $orange-brand;

        @include bp-medium {
            border-bottom: 0;
        }
    }
}
