// ============== REGISTER COMPONENT =================

.register {
    &__title {
        @include heading-l;
        display: block;
        padding-top: 20px;
        text-align: center;
        font-family: $lexend-family;
        font-weight: $lexend-extra-bold;
    }

    &__password {
        position: relative;
        margin: 0;
    }

    &__validation {
        @include body-s;
        padding-top: 15px;
        color: $green;
        font-family: $open-sans-family;
        font-weight: $open-sans-semi-bold;

        @include bp-medium {
            padding: 15px 15px 30px 0;
        }

        &-paragraph {
            display: inline-block;
            position: relative;
            padding-left: 20px;

            @include bp-medium {
                padding-left: 40px;
            }
        }

        &-icon {
            position: absolute;
            top: -7px;
            left: -10px;
            font-size: calculate-rem(30);

            @include bp-medium {
                left: 10px;
            }
        }

        &--error {
            color: $red;

            .icon-close {
                top: -9px;
                font-size: calculate-rem(35);
            }
        }
    }

    &__success-banner {
        margin-bottom: 50px;
    }
}
