// ============== TOAST COMPONENT =================

.toast {
    @extend %flexbox;
    @include justify-content(center);
    position: fixed;
    bottom: 30px;
    left: 0;
    visibility: hidden; //Hidden by default
    opacity: 0;
    z-index: 999999;
    width: 100%;
    overflow-y: auto;

    &:focus {
        outline: none;
    }

    &--notification {
        bottom: 12px;
    }

    &-container {
        @include align-self(center);
        @include box-sizing(border-box);
        background-color: $darker-gray;
        padding: 0 20px;
        width: 75%;
        height: auto;

        &--no-border {
            border: 0;
        }

        &--notification {
            @include flexbox;
            @include align-items(center);
            @include justify-content(center);
            border-radius: 8px;
            background-color: $green;
            padding: 20px;
            width: calc(100% - 20px);
            max-width: 748px;
            font-family: $open-sans-family;
            font-weight: $open-sans-semi-bold;
        }

        &:focus {
            body:not(.mouse-user):not(.is-oru) & {
                @extend .coned-focus-outline;
            }

            body:not(.mouse-user) & {
                @extend .oru-focus-outline;
            }

            &::before {
                content: none !important;
            }
        }
    }

    &__description-headings {
        padding: 10px;
        color: $white;
        font-size: calculate-rem(18);

        &--notification {
            @include body-s;
            padding: 0;
        }
    }

    &__close-cta {
        @extend .button-link;
        @include align-self(flex-start);
        float: right;
        color: $white;

        &:focus {
            outline: none;

            body:not(.mouse-user):not(.is-oru) &::before {
                @extend .coned-button-focus;
            }

            body:not(.mouse-user) &::before {
                @extend .oru-button-focus;
            }
        }
    }

    &__icon-round-bg {
        display: inline-block;
        position: relative;
        margin-right: 12px;
        border-radius: 50%;
        border-color: $white;
        background-color: $white;
        width: 32px;
        height: 32px;
        flex: 0 0 32px;        

        &::before {
            position: absolute;
            top: 1px;
            left: 1px;            
            color: $green;
            font-size: 3rem;
        }
    }

    &-show {
        -webkit-animation: fadein 0.5s, fadeout 0.5s 4.6s;
        animation: fadein 0.5s, fadeout 0.5s 4.6s;
        visibility: visible; // Show the toast
        opacity: 1;
        
        &-bottom-12 {
            -webkit-animation: fadein-bottom-12 0.5s, fadeout-bottom-12 0.5s 4.6s;
            animation: fadein-bottom-12 0.5s, fadeout-bottom-12 0.5s 4.6s;
            visibility: visible; // Show the toast
            opacity: 1;
        }
    }


    /* Animations to fade the toast in and out */
    @-webkit-keyframes fadein {
        from {bottom: 0; opacity: 0;}
        to {bottom: 30px; opacity: 1;}
    }
    
    @keyframes fadein {
        from {bottom: 0; opacity: 0;}
        to {bottom: 30px; opacity: 1;}
    }
    
    @-webkit-keyframes fadeout {
        from {bottom: 30px; opacity: 1;}
        to {bottom: 0; opacity: 0;}
    }
    
    @keyframes fadeout {
        from {bottom: 30px; opacity: 1;}
        to {bottom: 0; opacity: 0;}
    }

    /* Animations to fade the toast in and out with a bottom positon of 12 */
    @-webkit-keyframes fadein-bottom-12 {
        from {bottom: 0; opacity: 0;}
        to {bottom: 12px; opacity: 1;}
    }
    
    @keyframes fadein-bottom-12 {
        from {bottom: 0; opacity: 0;}
        to {bottom: 12px; opacity: 1;}
    }
    
    @-webkit-keyframes fadeout-bottom-12 {
        from {bottom: 12px; opacity: 1;}
        to {bottom: 0; opacity: 0;}
    }
    
    @keyframes fadeout-bottom-12 {
        from {bottom: 12px; opacity: 1;}
        to {bottom: 0; opacity: 0;}
    }
}
