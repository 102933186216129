.loading-page-animation {
    .submit-button {
        &__top {
            background-color: $white;
        }

        &__container--bg-container {
            background-color: $alto;
        }

        &__container--bg-container-reversed,
        &__slice-right,
        &__slice-left {
            background-color: $blue;
        }

        &__slice-right,
        &__slice-left {
            &--white {
                background-color: $alto;
            }
        }
    }

    .form__actions {
        padding: 0;
    }
}

.loading-page-animation--oru {
    &.loading-page-animation .submit-button__container--bg-container-reversed,
    &.loading-page-animation .submit-button__slice-right,
    &.loading-page-animation .submit-button__slice-left {
        background-color: $teal;
    }

    &.loading-page-animation .submit-button__slice-right--white,
    &.loading-page-animation .submit-button__slice-left--white {
        background-color: $alto;
    }
}
