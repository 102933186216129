// ============== FORM MESSAGES COMPONENT =================

.coned-form-messages {
    display: none;
    background-color: $white;
    padding: 50px 20px 10px;
    overflow: hidden;

    @include bp-medium {
        padding: 100px 0 0 21.5%;
        width: 50%;
    }

    &--contact-us {
        @include bp-medium {
            padding: 0 0 20px 70px;
            width: 63%;
        }
    }

    &--card-message {
        display: block;
        padding: 0;
        width: 100%;
        text-align: center;
    }
}

.coned-form-message {
    display: none;

    &__title {
        @include heading-l;
        margin-bottom: 25px;
        font-family: $lexend-family;
        font-weight: $lexend-extra-bold;
    }

    &__description {
        @include body-l;
        font-family: $open-sans-family;
        font-weight: $open-sans-semi-bold;
    }

    &__description-wrapper {
        @include body-l;
        font-family: $open-sans-family;
        font-weight: $open-sans-semi-bold;

        p:not(:last-child) {
            margin-bottom: 30px;
        }

        a {
            body:not(.mouse-user):not(.is-oru) &:focus {
                @extend .coned-focus-outline;
            }

            body:not(.mouse-user) &:focus {
                @extend .oru-focus-outline;
            }

            &::before {
                content: none !important;
            }
        }
    }

    &__title--error {
        color: $red;
    }

    &__title--success {
        .is-oru & {
            color: $teal;
        }
    }

    &--success,
    &--error {
        padding: 10px 10px 100px;
    }

    &__button {
        @include button-l;
        margin-top: 30px;
        text-transform: none;

        @include bp-small {
            width: 100%;
        }

        ~ .coned-form-message__button {
            margin-top: 20px;
        }

        &--no-height {
            height: auto;
        }

        &--margin-right-20-desktop {
            @include bp-medium {
                margin-right: 20px;
            }
        }
    }
}

.form-message-required {
    @include body-s;
    margin: 20px 0 50px;
    border-bottom: 1px solid $medium-gray;
    padding-bottom: 10px;
    color: $medium-gray;
    font-family: $open-sans-family;
    font-weight: $open-sans-semi-bold;

    &--with-border-top {
        margin: 20px 0 0;
        border-top: 1px solid $medium-gray;
        border-bottom: 0;
        padding-top: 10px;
    }

    &--no-margin-bottom {
        margin-bottom: 0;
    }
}
