// ============== POPUP COMPONENT =================

.popup {
    @extend %flexbox;
    @include justify-content(center);
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 999999;
    background-color: $black-semi-transparent;
    width: 100%;
    overflow-y: auto;

    .coned-label {
        @include bp-medium {
            padding-left: 20px;
            line-height: calculate-rem(30);
            font-size: calculate-rem(18);
        }
    }

    &__payment-details {
        position: relative;
        margin-top: 30px;

        &--label {
            @include body-l;
            color: $dark-gray;
            font-family: $open-sans-family;
            font-weight: $open-sans-bold;
        }

        &--value {
            @include body-l;
            color: $dark-gray;
            font-family: $open-sans-family;
        }

        &--value-error {
            @include body-l;
            color: $red;
            font-family: $open-sans-family;
        }

        &--separator {
            display: block;

            @include bp-medium {
                display: none;
            }

            &::before {
                position: absolute;
                background-color: $light-gray;
                width: 100%;
                height: 2px;
                content: ' ';
            }
        }
    }

    &-scroll-section {
        height: 100%;
    }

    &-container {
        @include align-self(center);
        @include box-sizing(border-box);
        margin: 5% auto;
        border-top: 3px solid $blue;
        background-color: $white;
        padding: 20px;
        width: 95%;
        height: auto;

        @include bp-medium {
            padding: 60px;
            width: 690px;
            height: auto;
        }

        &--smaller {
            margin: 20% auto;
            max-width: 500px;
        }

        &--bigger {
            width: 100%;
        }

        &--payment-alert {
            max-width: 500px;
        }

        &:focus {
            outline: none;
        }

        &--no-border {
            border: 0;
        }

        &--zones-by-zip {
            margin: 0;
            width: 100%;

            @include bp-medium {
                margin: 5% auto;
                width: 795px;
            }
        }
    }

    &__title-container {
        @extend %flexbox;
        @include align-items(center);
        margin-bottom: 30px;
        border-bottom: 3px solid $medium-gray;
        padding-bottom: 25px;

        @include bp-popup-small {
            margin-bottom: 25px;
        }

        &--lockout {
            margin-bottom: 10px;
        }

        &--no-border {
            margin-bottom: 0;
            border: 0;
        }
    }

    &__title-headings {
        @include flex-grow(1);
    }

    &__title {
        @include heading-l;
        color: $dark-gray;
        font-family: $lexend-family;
        font-weight: $lexend-extra-bold;

        &--center {
            margin: 50px 0 35px;
            text-align: center;
        }

        &--no-margin {
            margin: 0;
        }
    }

    &__subtitle {
        @include heading-m;
        margin: 15px 0 0;
        text-transform: uppercase;
        color: $dark-gray;
        font-family: $lexend-family;
        font-weight: $lexend-extra-bold;
    }

    &__header-description {
        margin: 20px 0 0 20px;
        font-size: calculate-rem(14);
    }

    &__description {
        @include body-l;
        font-family: $open-sans-family;

        &--center {
            text-align: center;
        }
    }

    &__close-cta {
        @extend .button-link, .button-link--extra-low-emphasis;
        @include align-self(flex-start);
        float: right;

        @include bp-medium {
            margin-top: -20px;
        }

        &--low-emphasis {
            @extend .button-link;

            // scss-lint:disable NestingDepth
            body:not(.mouse-user):not(.is-oru) &:focus {
                outline: none;
            
                &::before {
                    @extend .coned-button-focus;
                }                
            }
        
            body:not(.mouse-user) &:focus {
                outline: none;
                
                &::before {
                    @extend .oru-button-focus;
                }
            }
            // scss-lint:enable NestingDepth
        }
    }

    &__content {
        @include body-l;
        position: relative;
        color: $dark-gray;
        font-family: $open-sans-family;

        &--footer {
            @include body-m;
            margin-right: 5px;
            text-transform: uppercase;
            font-family: $lexend-family;
            font-weight: $lexend-extra-bold;
        }

        &--slider {
            @include bp-small {
                width: calc(100vw - 40px);
                max-width: 624px;
            }
        }
    }

    &__error-message {
        position: absolute;
        width: 100%;
        text-align: center;
    }

    &__input--hidden {
        visibility: hidden;
        margin: 0;
        padding: 0;
        height: 0;
    }

    &__footer {
        @extend %flexbox;
        @include justify-content(flex-end);
        @include align-items(center);
        margin: 50px 2px 0;
        padding-bottom: 20px;

        .form__actions {
            width: auto;
        }

        &--center {
            @include justify-content(space-evenly);
        }

        &--left {
            @include justify-content(normal);
            @include flex-wrap(wrap);
            margin-top: 35px;

            .popup__continue-cta,
            .popup__submit-cta {
                margin-right: 20px;
                padding: 15px 30px;

                @include bp-popup-small {
                    margin: 0;
                }
            }
        }

        &--no-margin-padding {
            margin: 0;
            padding: 0;
        }

        &--vertical {
            @include flex-direction(column);

            @include bp-medium {
                @include flex-direction(row);
            }

            .popup__cancel-cta {
                margin: 20px 0;
                border: 2px solid $medium-gray;
                border-radius: 50px;
                padding: 15px 50px;

                @include bp-medium {
                    margin-right: 25px;
                }
            }
        }
    }

    &__buttons-container {
        @include bp-popup-small {
            &,
            &--reverse {
                @include flexbox();
                @include flex-direction(column);
                width: 100%;
                text-align: center;
            }
    
            &--reverse {
                @include flex-direction(column-reverse);
            }
        }

        &--right-aligned {
            @include flexbox();
            @include justify-content(flex-end);
            width: 100%;
        }
    }

    &__ctas-container {
        @include flexbox;
        @include flex-wrap(wrap);
        @include align-items(center);
        margin-bottom: 45px;
    }

    &__cta {
        @extend .button-link;

        &--eligible {
            margin-bottom: 10px;
            width: 100%;
        }
    }

    &__continue-cta,
    &__submit-cta,
    &__lockout-cta,
    &__button-cta {
        @extend .button;
    }

    &__lockout-cta {
        display: inline-block;
        margin: 20px 0;
    }

    &__cancel-cta {
        @extend .button-link, .button-link--extra-low-emphasis;
        margin-top: 15px;
        margin-right: 20px;

        @include bp-popup-small {
            margin: 20px 0;

            &--small-popup-margin {
                margin-right: 20px;
                margin-bottom: 0;
            }
        }

        &--no-top-margin {
            margin: 0 20px 0 0;
        }
    }

    &__close-cta,
    &__cancel-cta,
    &__continue-cta,
    &__submit-cta,
    &__lockout-cta,
    &__button-cta,
    &__cta {
        &:focus {
            outline: none;

            body:not(.mouse-user):not(.is-oru) &::before {
                @extend .coned-button-focus;
            }

            body:not(.mouse-user) &::before {
                @extend .oru-button-focus;
            }
        }
    }

    hr {
        margin-bottom: 15px;
        border: 1px solid $light-gray;
    }
}

.popup--oru {
    .popup-container {
        border-top: 3px solid $orange;

        &--no-border {
            border: 0;
        }

        &--no-border-bottom {
            border-bottom: none !important;
        }
    }
}
