// ============== CONTACT-US FORM COMPONENT =================

.contact-us-form {
    clear: both;
    margin-bottom: 20px;

    @include bp-medium {
        margin-bottom: 64px;
        border: 0;
    }

    &-wrapper {
        margin: 0 10px;
        background-color: $white;

        @include bp-medium {
            margin: 0;
        }
    }

    &__form-block {
        display: block;
        width: 100%;

        @include bp-smaller {
            width: 100%;
        }

        @include bp-medium {
            @include box-sizing(border-box);
            display: inline-block;
            border: 0;
            padding: 0 4% 0 0;
            width: 100%;
        }
    }

    &__form-container {
        display: inline-block;
        width: 100%;

        @include bp-medium {
            width: 100%;
        }

        &--with-text {
            display: inline-block;

            @include bp-medium {
                width: 63%;
            }
        }
    }

    &__copy-block {
        display: block;
        margin-top: 80px;
        padding: 0 20px;
        overflow: hidden;
        vertical-align: top;

        @include bp-medium {
            @include box-sizing(border-box);
            display: inline-block;
            margin-top: 150px;
            padding: 0 70px 0 0;
            width: 36%;
        }

        p {
            @include body-l;
            margin-bottom: 65px;
            color: $dark-gray;
            font-family: $open-sans-family;

            @include bp-medium {
                margin-bottom: 50px;
            }
        }
    }

    &__container {
        padding: 20px;

        @include bp-medium {
            padding: 0 0 80px;
        }

        &-blocks {
            @include flexbox;
            @include flex-flow(row wrap);
            @include flex-direction(row);
        }

        &-title {
            @extend %text-truncate-wrap;
            @include heading-m;
            display: block;
            color: $black;
            font-family: $lexend-family;
            font-weight: $lexend-extra-bold;
        }
    }

    &__title-container {
        @extend %text-truncate-wrap;
        display: block;
        clear: both;
        margin: 65px 0 20px;
        border-bottom: 3px solid $medium-gray;
        width: 100%;
        color: $dark-gray;
        font-family: $lexend-family;
        font-weight: $lexend-extra-bold;
        
        @include bp-medium {
            width: auto;
        }
    }

    &__field-container {
        @include box-sizing(border-box);
        display: block;
        margin-bottom: 45px;
        width: 100%;

        @include bp-medium {
            margin-bottom: 25px;
        }
    }
}

.coned-form--contact-us {
    display: inline-block;
    width: 90%;

    @include bp-medium {
        padding: 100px 0 125px 70px;
    }
}

.coned-form--required-message {
    width: 100%;

    @include bp-medium {
        width: 96%;
    }
}

.contact-us-form__copy-block--success {
    @include bp-medium {
        margin-top: 0;
        padding: 0 70px;
        width: 100%;
    }
}
