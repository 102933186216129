// ============ CHECKBOX ACCOUNTS ===============

.checkbox-accounts {
    @include transition(all, 0.2s, ease);
    @include box-sizing(border-box);
    display: inline-block;
    position: relative;
    margin: 0 auto;
    border: 1px solid $light-gray;
    background-color: $white;
    cursor: pointer;
    padding: 0 20px;
    width: 100%;
    color: $dark-gray;

    &__input {
        position: relative;
        z-index: -1;

        body:not(.mouse-user):not(.is-oru)
            &:focus
            + .checkbox-accounts__container
            > .checkbox-accounts__checked {
            @extend .coned-focus-outline;
        }

        body:not(.mouse-user)
            &:focus
            + .checkbox-accounts__container
            > .checkbox-accounts__checked {
            @extend .oru-focus-outline;
        }
    }

    &__title {
        @include body-s;
        padding: 0 15px;
        width: 40%;
        font-family: $lexend-family;
        font-weight: $lexend-extra-bold;
    }

    &__image {
        @include flex-shrink(0);
        width: 20%;
        max-height: 70px;
    }

    &__container {
        @include flexbox;
        @include align-items(center);
        @include justify-content(space-between);
        padding: 20px 0;
    }

    &__checked {
        @extend .coned-checkbox;
        padding: 0;
        min-width: 50px;
        height: 50px;
        font-size: calculate-rem(40);

        @include bp-medium {
            width: 15%;
        }

        &::before {
            @include opacity(0);
            vertical-align: middle;
            line-height: 34px;
        }
    }

    /* Show check mark */
    &__input:checked ~ .checkbox-accounts__container > .checkbox-accounts__checked {
        border-color: $green;
        background-color: $green;
        color: $white;

        &::before {
            @include opacity(1);
        }
    }
}
