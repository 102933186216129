.linear-stepper {
    @include flexbox;
    @include justify-content(space-between);
    position: relative;
    left: 0;
    margin: 0 auto;
    padding: 20px 0 30px;
    width: 100%;
    list-style: none;
    flex-direction: column;

    @include bp-medium {
        padding: 30px 0 45px;
        width: 100%;
        flex-direction: row;
    }

    &__step {
        @include flexbox;
        position: relative;
        width: 100%;
        height: 100px;

        @include bp-medium {
            display: block;
            width: 100%;
            height: auto;
            text-align: center;
            flex-direction: column;
        }

        &::before {
            position: absolute;
            top: 50%;
            left: 18px;
            transform: translateY(-50%);
            transition: all 0.25s ease-out;
            z-index: 1;
            background-color: $medium-gray;
            padding: 0;
            width: 2px;
            height: 100%;
            content: '';

            @include bp-medium {
                left: 0;
                width: 100%;
                height: 2px !important;
            }
        }

        &.is-complete + .is-current:after,
        &.is-complete + .is-complete:after {
            background-color: $green;
            height: 100% !important;

            @include bp-medium {
                width: 100%;
                height: 2px !important;
            }
        }

        &.is-complete + .is-error:after {
            background-color: $red;
            height: 100% !important;

            @include bp-medium {
                width: 100%;
                height: 2px !important;
            }
        }

        &:first-child {
            text-align: left;
            
            &::before {
                @include bp-medium {
                    left: 10%;
                    width: 90%;
                    height: 2px;
                }
            }
    
            .linear-stepper__icon {
                @include bp-medium {
                    left: 10%;
                }
            } 

            .linear-stepper__step-label {
                @include bp-medium {
                    left: -14px;
                    width: 50%;
                    text-align: center;
                }
            }
        }

        &:last-child {
            text-align: right;
    
            &::before {
                content: none;
    
                @include bp-medium {
                    right: 16%;
                    left: 0;
                    width: 75%;
                    height: 2px !important;
                    content: '';
                }
            }
    
            .linear-stepper__icon {
                @include bp-medium {
                    left: 75%;
                }
            }

            .linear-stepper__step-label {
                @include bp-medium {
                    left: 59%;
                    width: 46%;
                    text-align: center;
                }
            }
        }
    }

    &__step-label {
        @include body-m;
        transition: all 0.25s ease-out;
        padding: 0.7em 0.5em 0;
        width: auto;
        color: $medium-gray;
        font-family: $open-sans-family;
        font-weight: $open-sans-semi-bold;

        @include bp-medium {
            display: block;
            position: absolute;
            left: 25%;
            padding: 0.5em 0 0;
            width: 50%;
            text-align: center;
        }

        span {
            display: block;
        }

        .is-current > &,
        .is-complete > & {
            font-weight: $open-sans-bold;

            &.linear-stepper__step--light {
                font-weight: $open-sans-semi-bold;
            }
        }

        &--light {
            font-weight: $open-sans-regular;
        }
    }

    &__icon {
        position: relative;
        transition: all 0.25s ease-out;
        z-index: 10;
        border: 2px solid $medium-gray;
        border-radius: 50%;
        background-color: $white;
        padding: 0 !important;
        width: 36px;
        max-width: 100%;
        height: 36px;
        color: $medium-gray;

        @include bp-medium {
            left: 43%;
            text-align: center;
        }

        &::before {
            position: relative !important;
            font-size: 3.5rem !important;
            font-weight: 500;
        }

        .is-current > &,
        .is-complete > & {
            border-color: $green;
            background-color: $green;
            color: $white;
        }

        .is-pending > & {
            border-color: $green !important;
            background-color: $white;
            color: $green !important;
        }

        .is-error > & {
            border-color: $red;
            background-color: $red;
            color: $white;
        }
    }
}

.steps-content {
    padding-top: 0;

    @include bp-medium {
        padding-top: 40px;
    }

    &__step {
        display: none;

        &.is-current {
            display: block;
        }
    }

    p {
        @include body-m;
        color: $dark-gray;
        font-family: $open-sans-family;
        font-weight: $open-sans-semi-bold;
    }

    ul {
        padding: 0 0 35px 20px;
    }

    li {
        @include body-m;
        color: $dark-gray;
        font-family: $open-sans-family;
        font-weight: $open-sans-semi-bold;
        list-style-type: disc;
    }
}
