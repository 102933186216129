// ============== ACCOUNT TYPE NOT SUPPORTED COMPONENT =================
.account-type-not-supported {
    @include align-self(start);
    padding-top: 16px;
    width: auto;

    @include bp-medium {
        width: 440px;
        max-width: 100%;
    }

    @include bp-large {
        @include align-self(center);
    }

    &__title {
        @include flexbox;
        @include align-items(center);

        &-text {
            @include heading-m;
            text-align: left;
            color: $red;
            font-family: $lexend;
        }

        &-icon { 
            position: relative;
            margin-right: 8px;
            border-radius: 50%;
            background: $red;
            width: 24px;
            height: 24px;
    
            &::before {
                position: absolute;
                top: -3px;
                left: -3px;
                color: $white;
                font-size: 3rem;
            }
        }
    }

    &__description {
        @include body-m;
        padding-top: 8px;
        text-align: start;
        font-family: $open-sans;
    }
}
