// ============== FILTERS COMPONENT =================

.filters {
    @include bp-medium {
        margin: -35px auto 35px;
        background-color: $blue;
        padding-left: 30px;
    }

    &--open {
        position: fixed;
        top: 60px;
        z-index: 99;
        width: 100%;

        @include bp-medium {
            position: absolute;
        }

        .filters__main-filters {
            padding: 0;
            height: 100vh;
        }
    }

    &__wrapper {
        position: relative;
        overflow: hidden;

        @include bp-medium {
            overflow: visible;
        }
    }

    &__top {
        @extend %flexbox;
        @include justify-content(space-between);
        @include box-shadow(0, 0, 10px, 0, $light-gray);
        @include box-sizing(border-box);
        position: relative;
        top: 0;
        z-index: 15;
        background-color: $blue;
        padding: 4px 15px 4px 30px;
        width: 100%;
    }

    &__back {
        @extend .button-link, .button-link--extra-low-emphasis;
        display: inline-flex;
        text-align: left;
        align-items: center;

        body:not(.is-oru) & {
            @extend .button-link--secondary-color;
        }

        &-icon {
            @include transform(rotate(90deg));
            display: inline-block;
            position: relative;
            width: 15px;
            height: 15px;

            &::before {
                position: absolute;
                top: -6px;
                left: -6px;
                font-size: calculate-rem(30);
            }
        }

        &:focus {
            outline: none;

            body:not(.mouse-user):not(.is-oru) &::before {
                @extend .coned-button-focus;
            }

            body:not(.mouse-user) &::before {
                @extend .oru-button-focus;
            }
        }
    }

    &__show-filters,
    &__clear-button--mobile {
        @extend .button;
        @include align-self(center);

        @include bp-medium {
            display: none;
        }

        body:not(.is-oru) & {
            @extend .button--secondary-color;
        }
    }

    &__clear-button--hidden {
        visibility: hidden;
    }

    &__content {
        position: relative;
        background-color: $blue;

        @include bp-medium {
            background-color: transparent;
        }

        &--active {
            background-color: $white;
        }
    }

    &__title {
        @include body-l;
        margin: 20px 0;
        padding: 5px;
        color: $white;
        font-family: $lexend-family;
        font-weight: $lexend-extra-bold;
    }

    &__main-filters {
        @extend %flexbox;
        @include flex-direction(column);
        margin-bottom: 10px;
        padding: 20px 0;

        @include bp-medium {
            @include flex-direction(row);
            @include align-items(center);
            @include justify-content(space-between);
            margin-top: 0;
            margin-bottom: 0;
            background-color: $blue;
            padding: 0;
        }
    }

    &__category-list {
        @extend %flexbox;
        @include flex-direction(column);

        @include bp-medium {
            @include flex-direction(row);
        }
    }

    &__results-info {
        @extend %flexbox;
        @include justify-content(space-between);
        @include align-items(center);
        padding: 0 30px;
    }

    &__results-info-right {
        @extend %flexbox;
        @include flex-direction(column);
        @include align-items(flex-end);

        @include bp-medium {
            padding: 0 30px;
        }
    }

    &__clear-button {
        @extend .button-link, .button-link--extra-low-emphasis;
        display: block;
        color: $white;

        @include bp-medium {
            &:hover {
                text-decoration: underline;
            }
        }
    }

    &__results-data {
        @include body-xs;
        margin: 2px 0;
        text-align: right;
        text-transform: uppercase;
        color: $white;
        font-family: $open-sans-family;
        font-weight: $open-sans-bold;
    }

    &-results {
        &__empty {
            margin-bottom: 10px;
            color: $dark-gray;

            @include bp-medium {
                margin-bottom: 50px;
            }
        }

        &__empty-title,
        &__empty-subtitle {
            @include heading-m;
            margin: 0 auto;
            max-width: 300px;
            font-family: $open-sans-family;
            font-weight: $open-sans-bold;
        }

        &__empty-subtitle {
            @include body-m;
            padding-top: 20px;
            font-family: $open-sans-family;
            font-weight: $open-sans-regular;
        }
    }

    &__item {
        @extend %flexbox;
        @include flex-direction(column);
        @include transition(background-color 0.2s ease);
        position: relative;
        z-index: 16;
        border-bottom: 1px solid $gray;
        background: 0;
        cursor: pointer;
        padding: 26px 30px 23px 45px;
        width: 100%;

        @include bp-medium {
            margin-bottom: 0;
            border: 0;
            border-right: 1px solid $white;
            padding: 12.5px 20px;
            width: 177px;
        }

        &:first-child {
            @include bp-medium {
                border-left: 1px solid $white;
            }
        }

        &--active {
            @include bp-medium {
                background-color: $default-selection-blue;

                .filters__item-icon {
                    @include transform(rotate(180deg));
                }
            }
        }

        &[disabled] {
            opacity: 0.5;
            pointer-events: none;
        }

        &:focus {
            outline: none;

            body:not(.mouse-user):not(.is-oru) &::before {
                @extend .coned-button-focus;
            }

            body:not(.mouse-user) &::before {
                @extend .oru-button-focus;
            }
        }
    }

    &__item-icon {
        @include transform(rotate(270deg));
        @include border-radius(50%);
        display: inline-block;
        position: absolute;
        top: 45px;
        right: 30px;
        background-color: $medium-gray;
        padding: 5px;
        width: 18px;
        height: 18px;
        color: $white;

        @include bp-medium {
            @include transform(rotate(0));
            top: 20px;
            right: 10px;
            margin-left: 5px;
            background-color: $white;
            width: 11px;
            height: 11px;
            color: $blue;
        }

        &::before {
            position: absolute;
            top: -2px;
            left: -3px;
            font-size: calculate-rem(35);

            @include bp-medium {
                top: 1px;
                left: 1px;
                font-size: calculate-rem(20);
            }
        }
    }

    &__sub-category {
        @extend %text-truncate;
        @include body-l;
        display: block;
        margin-top: 5px;
        width: 100%;
        text-align: left;
        color: $dark-gray;
        font-family: $open-sans-family;

        @include bp-medium {
            margin-top: 0;
            height: 20px;
            color: $white;
        }

        &--active {
            color: $blue;

            @include bp-medium {
                color: $white;
            }
        }
    }

    &__items-list {
        position: absolute;
        top: 0;
        z-index: 17;
        border: 1px solid $medium-gray;
        background-color: $white;
        width: 100%;
        height: 100%;

        @include bp-medium {
            @include box-shadow(0, 1px, 5px, 0, $black-quarter-transparent);
            width: 270px;
            height: auto;
        }
    }

    &__list-header {
        &--text-search {
            position: absolute;
            border-bottom: 1px solid $light-gray;
            background-color: $white;
            width: 100%;

            @include bp-medium {
                width: 270px;
            }
        }
    }

    &__list-item {
        @extend %flexbox;
        @include align-items(center);
        position: relative;
        margin-bottom: 30px;

        @include bp-medium {
            margin-bottom: 10px;
        }

        .highlight-text {
            background-color: $blue;
            color: $white;
        }

        &--active {
            .filters__item-title {
                color: $blue;
                font-weight: $open-sans-bold;
            }

            .filters__check-icon {
                display: block;
            }
        }

        &--hidden {
            display: none;
        }

        &:focus {
            outline: none;
            
            body:not(.mouse-user):not(.is-oru) & {
                @extend .coned-focus-outline;
            }

            body:not(.mouse-user) & {
                @extend .oru-focus-outline;
            }
        }
    }

    &__item-title {
        @extend .not-underlined-anchor;
        @include body-s;
        margin-left: 10px;
        color: $dark-gray;
        font-family: $lexend-family;
        font-weight: $lexend-extra-bold;

        &:hover {
            color: $blue;
        }
    }

    &__item-main-title {
        @include body-s;
        text-align: left;
        text-transform: uppercase;
        color: $dark-gray;
        font-family: $open-sans-family;
        font-weight: $open-sans-bold;

        @include bp-medium {
            margin-bottom: 3px;
            color: $white;
        }
    }

    &__check-icon {
        display: none;
        position: absolute;
        top: -5px;
        left: -25px;
        color: $blue;
        font-size: calculate-rem(35);
    }

    &__search-container {
        padding: 20px 20px 10px 50px;
        max-height: calc(
            100vh - 190px
        ); // total height - (top navigation + filters + top padding + button padding)
        overflow-y: auto;

        @include bp-medium {
            padding: 20px 30px;
            max-height: 330px;
        }

        &--fixed {
            margin-top: 100px;
            max-height: calc(
                100vh - 278px
            ); // total height - (top navigation + filters + search bar + top padding + button padding)

            @include bp-medium {
                margin-top: 70px;
                max-height: 260px;
            }

            @include bp-small {
                .account-dropdown__address {
                    white-space: normal;
                }
            }

            .account-dropdown__content {
                @include body-s;
                font-family: $open-sans-family;

                @include bp-small {
                    width: auto;
                    max-width: 400px;
                }
            }
        }
    }

    &__dropdown-arrow {
        @include bp-medium {
            position: absolute;
            left: 50%;
            margin-top: 1px;
            background: $white;

            &::after,
            &::before {
                position: absolute;
                bottom: 1px;
                left: 50%;
                z-index: 10;
                border: solid transparent;
                width: 0;
                height: 0;
                content: '';
                pointer-events: none;
            }

            &::after {
                margin-left: -9px;
                border-width: 9px;
                border-color: $white-transparent;
                border-bottom-color: $white;
            }

            &::before {
                margin-left: -10px;
                border-width: 10px;
                border-color: $white-transparent;
                border-bottom-color: $white;
            }
        }
    }

    .text-search {
        @include bp-small {
            padding: 15px 48px;

            &__input {
                @include body-xs;
                padding: 20px 10px 20px 15px;
            }

            &__clear-button {
                top: 28px;
                right: 81px;
                width: 35px;
                height: 35px;
                font-size: calculate-rem(50);

                &::before {
                    top: -8px;
                    left: -8px;
                }
            }

            &__icon {
                &::before {
                    top: -20px;
                    left: -14px;
                    font-size: calculate-rem(50);
                }
            }
        }
    }
}

.filters--oru {
    @include bp-medium {
        background-color: $orange;
    }

    .filters__top {
        background-color: $orange;
    }

    .filters__content {
        position: relative;
        background-color: $orange;

        @include bp-medium {
            background-color: transparent;
        }

        &--active {
            background-color: $white;
        }
    }

    .filters__main-filters {
        @include bp-medium {
            background-color: $orange;
        }
    }

    .filters__item {
        @include bp-medium {
            border-right: 1px solid $medium-gray;

            &:hover {
                background-color: $orange;
            }
        }

        &:first-child {
            @include bp-medium {
                border-left: 1px solid $orange;
            }
        }

        &--active {
            @include bp-medium {
                background-color: $orange;
            }
        }
    }

    .filters__item-icon {
        background-color: $white;
        color: $dark-gray;
    }

    .filters__sub-category {
        color: $white;

        &--active {
            color: $orange;

            @include bp-medium {
                color: $white;
            }
        }
    }

    .filters__list-item {
        .highlight-text {
            background-color: $orange;
        }

        &--active {
            .filters__item-title {
                color: $orange;
            }
        }
    }

    .filters__item-title {
        &:hover {
            color: $teal;
        }
    }

    .filters__check-icon {
        color: $teal;
    }
}
