// ============== SEARCH FILTERS COMPONENT =================

.tips-filters {
    position: relative;
    margin-bottom: 35px;
    background-color: $blue;
    width: 100%;

    &__wrapper {
        @extend %flexbox;
        @include flex-flow(row wrap);
        @include justify-content(space-around);
        @include align-items(center);
        margin-top: -10px;
        padding: 0 30px;
        height: 115px;

        @include bp-medium {
            margin-top: -35px;
            height: 65px;
        }
    }

    &__item {
        @extend %flexbox;
        @include align-items(center);
        padding: 0 10px;

        @include bp-tips-filters {
            padding: 0;
        }

        @include bp-medium {
            position: relative;
        }

        &-text {
            @include body-s;
            margin-right: 10px;
            text-transform: uppercase;
            color: $white;
            font-family: $open-sans-family;
            font-weight: $open-sans-bold;

            .is-oru & {
                color: $teal;
            }
        }

        &-button {
            @extend .dropdown-title--mobile;
            @include body-s;
            margin-top: 0;
            padding: 5px 20px;
            width: 130px;
            height: 35px;
            font-family: $open-sans-family;
            font-weight: $open-sans-bold;

            @include bp-medium {
                display: block;
                width: 170px;
                height: 45px;
                text-align: left;
            }

            &--active {
                @extend .dropdown-title--active;
            }

            body:not(.mouse-user) &:focus {
                outline: none;
            }

            body:not(.mouse-user) &:focus::before {
                @extend .coned-button-focus;
            }
        }

        &-title {
            @extend %text-truncate;
            display: inline-block;
            max-width: 70px;
            text-transform: uppercase;

            @include bp-medium {
                max-width: 105px;
            }
        }

        &-icon {
            position: absolute;
            top: 3px;
            right: 5px;
            font-size: calculate-rem(25);

            @include bp-medium {
                top: 5px;
                right: 10px;
                font-size: calculate-rem(30);
            }
        }
    }

    &__results-info {
        @include body-xs;
        width: 100%;
        text-align: center;
        text-transform: uppercase;
        color: $white;
        font-family: $open-sans-family;
        font-weight: $open-sans-bold;

        @include bp-medium {
            width: auto;
        }

        .is-oru & {
            color: $teal;
        }
    }
}

.tips-filters--oru {
    background-color: $orange;
}
