// ============== GREEN DATA COMPONENT =================

.green-data {
    &-wrapper {
        margin-bottom: 20px;
        padding: 30px 30px 0;

        @include bp-medium {
            margin-top: 30px;
            margin-bottom: 0;
            padding: 0 15%;
        }

        @include bp-billing {
            padding: 0 20%;
        }

        &--no-padding-sides {
            padding-right: 0;
            padding-left: 0;
        }

        &--bigger-padding-top {
            padding-top: 40px;

            @include bp-medium {
                padding-top: 50px;
            }
        }

        &--no-margin-top-desktop {
            @include bp-medium {
                margin-top: 0;
            }
        }
    }

    &-column {
        @extend %flexbox;
        @include flex-direction(row);

        @include bp-medium {
            @include flex-direction(column);
            padding-top: 0;
        }

        &__image {
            @include flex-shrink(0);
            @include align-self(flex-start);
            margin-right: 20px;
            width: 120px;
            height: 120px;

            @include bp-medium {
                margin-right: 15px;
                width: 110px;
                height: 110px;
            }

            @include bp-start-service-profile {
                @include align-self(auto);
                margin-right: 35px;
                width: 120px;
                height: 120px;
            }

            img {
                width: 100%;
                height: auto;
            }
        }

        &__details {
            @extend %flexbox;
            @include flex-direction(column);
            @include align-items(flex-start);
            @include justify-content(flex-start);
            @include box-sizing(border-box);
            width: 100%;
            text-align: left;

            @include bp-medium {
                padding-top: 20px;
            }
        }

        &--row {
            @include bp-medium {
                @include flex-direction(row);
            }
        }
    }

    &-container {
        padding-top: 20px;

        .service-unavailable-error {
            padding-top: 60px;
            text-align: center;
            font: calculate-rem(14) / calculate-rem(18) $open-sans-family;
        }
    }

    &__text {
        @include body-s;
        padding: 20px 0 0;
        width: 100%;
        color: $medium-gray;
        font-family: $open-sans-family;
        font-weight: $open-sans-semi-bold;
    }

    &__title {
        @include body-l;
        color: $dark-gray;
        font-family: $lexend-family;
        font-weight: $lexend-extra-bold;
    }

    &__link {
        @extend .button-link, .button-link--extra-low-emphasis;
        margin-top: 20px;
    }
}
