.maid-tracking-banner {
    position: fixed;
    bottom: 0;
    z-index: 2002;
    border-top: 2px solid $blue-brand;
    background-color: $white;
    width: 100%;
    height: 120px;
    text-align: center;

    @include bp-medium {
        height: 72px;
    }

    &--oru {
        border-top: 2px solid $orange-brand;
    }

    &__text-container {
        @include flexbox;
        @include flex-direction(column);
        @include justify-content(center);
        @include align-items(center);
        padding: 0 40px 0 20px;
        height: 100%;

        @include bp-medium {
            @include flex-direction(row);
            @include justify-content(center);
            padding: 0;
        }
    }

    &__agreement-text {
        font: calculate-rem(16) / calculate-rem(25) $open-sans-family;

        a {
            display: inline-block;
            font-weight: $open-sans-bold;
        }
    }

    &__cta {
        position: absolute;
        right: -10px;
        outline: 0;
        background-color: transparent;
        color: $blue;
        font-size: 6rem;

        @include bp-medium {
            top: 10px;
            right: 0;
        }

        &:focus {
            body:not(.mouse-user):not(.is-oru) &::after {
                @extend .coned-button-focus;
            }

            body:not(.mouse-user) &::after {
                @extend .oru-button-focus;
            }
        }

        .is-oru & {
            color: $teal;
        }
    }
}
