// ============== GALLERY COMPONENT =================

.gallery {
    margin: 0 0 50px;
    padding: 0;
    overflow: hidden;

    @include bp-medium {
        margin: 0 0 95px;
        padding: 40px 0;
    }

    &-single {
        background-color: $white;
        padding: 0 0 50px;

        @include bp-medium {
            padding: 0 0 95px;
        }
    }

    &-single-wrapper {
        @include bp-medium {
            padding-left: 21.5%;
            width: 50%;
        }
    }

    &__wrapper {
        @extend %flexbox;
        @include flex-direction(column);
        width: 100%;

        @include bp-medium {
            @include flex-direction(row);
            padding-left: 14.5%;
        }
    }

    &__image-container {
        margin: 0;
    }

    &__picture-image {
        width: 100%;
    }

    &__counter {
        @extend %flexbox;
        @include justify-content(center);
        @include align-items(center);
        @include body-s;
        position: absolute;
        top: 0;
        right: 0;
        z-index: 2;
        background-color: $dark-gray;
        width: 35px;
        height: 35px;
        color: $white;
        font-family: $open-sans-family;
        font-weight: $open-sans-bold;
    }

    &__controls {
        @extend %flexbox;
        position: relative;

        @include bp-medium {
            padding-bottom: 25px;
        }
    }

    &__button {
        @extend .additional-button, .additional-button--secondary-color;
        @include transform(rotate(180deg));
        @include border-radius(50%);
        width: 35px;
        height: 35px;

        &::before {
            position: absolute;
            top: 5px;
            left: 3px;
            font-size: calculate-rem(25);
        }
    }

    &__button:focus {
        outline: none;

        &::after {
            position: absolute;
            top: -4px;
            left: -4px;
            padding: 2px;
            width: 100%;
            height: 100%;
            content: '';

            body:not(.mouse-user):not(.is-oru) & {
                @extend .coned-focus-outline;
            }

            body:not(.mouse-user) & {
                @extend .oru-focus-outline;
            }
        }
    }

    &__next-button {
        @include transform(rotate(-90deg));
        margin-left: 2px;
    }

    &__prev-button {
        @include transform(rotate(90deg));
    }

    &__description {
        margin-right: 20px;

        @include bp-medium {
            margin-right: 0;
        }
    }

    &__info-wrapper {
        @include flexbox;
        @include flex-direction(row-reverse);
        margin: 25px 20px;
        padding: 2px 0 0 4px;
        overflow: hidden;

        @include bp-medium {
            @include flex-direction(column);
            margin: 0 15px;
            width: 20%;
        }

        @include bp-large {
            width: 14%;
        }
    }

    &__content {
        padding: 20px 20px 0;

        @include bp-medium {
            padding: 5px 10% 0;
        }
    }

    &__icon {
        display: none;
        margin: 0 0 15px;
        color: $tundora;
        font-size: calculate-rem(20);

        @include bp-medium {
            margin-top: 20px;
        }
    }

    &__title {
        @include body-s;
        display: inline;
        color: $dark-gray;
        font-family: $open-sans-family;
        font-weight: $open-sans-bold;
    }

    &__caption {
        @include body-s;
        display: inline;
        margin-left: 2px;
        color: $medium-gray;
        font-family: $open-sans-family;
    }

    &__source {
        @include body-s;
        color: $dark-gray;
        font-family: $open-sans-family;
        font-weight: $open-sans-bold;

        @include bp-medium {
            margin-top: 25px;
        }

        &-link {
            display: inline-block;
            font-weight: $open-sans-regular;
        }
    }

    &__source--single {
        @include bp-medium {
            margin: 0;
        }
    }

    &__date {
        @include body-s;
        color: $medium-gray;
        font-family: $open-sans-family;
    }
}

.swiper-container {
    width: 100%;
    min-width: 100%;

    @include bp-medium {
        min-width: 50%;
        max-width: 50%;
    }
}

.gallery-single-wrapper {
    .gallery__content {
        @include bp-medium {
            padding-left: 0;
        }
    }
}
