// ============ DISABLED MODAL ===============

.disabled-modal {
    @include opacity(0.2);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    background-color: $medium-gray;
    width: 100%;
    height: 100%;
}
