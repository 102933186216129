// ============== SHARE ENERGY DATA COMPONENT =================

.share-energy {
    margin: 0 30px;

    @include bp-medium {
        margin: 0;
    }

    &--no-margin-mobile {
        @include bp-small {
            margin: 0;
        }
    }

    &-wrapper {
        margin-top: 25px;
        background: $white;
        padding: 72px 0;

        @include bp-medium {
            padding: 72px 15%;
        }

        @include bp-billing {
            padding: 72px 20%;
        }

        &--no-padding {
            padding: 0;
        }
    }

    &__title {
        @include heading-l;
        border-bottom: solid 3px $medium-gray;
        padding-bottom: 20px;
        width: 100%;
        color: $dark-gray;
        font-family: $lexend-family;
        font-weight: $lexend-extra-bold;

        &--20-margin-top {
            margin-top: 20px;
        }
    }

    &__text {
        @include body-m;
        padding: 25px 0;
        width: 100%;
        color: $medium-gray;
        font-family: $open-sans-family;
        font-weight: $open-sans-semi-bold;
    }

    &_buttons-container {
        @include flexbox;
        @include flex-direction(column);
        @include align-items(flex-start);
    }

    &__link {
        @extend .button-link, .button-link--extra-low-emphasis;

        &--25-margin-bottom {
            margin-bottom: 25px;

            &:last-child {
                @include bp-medium {
                    margin-bottom: 0;
                }
            }
        }
    }
}
