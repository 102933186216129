// ============== CONFIRMATION SCREEN COMPONENT =================

.confirmation-screen {
    &__wrapper {
        &--left-aligned {
            text-align: left;
        }

        &--padding-10-mobile {
            @include bp-small {
                padding: 10px;
            }
        }

        &--hidden {
            display: none;
        }
    }

    &__main-container {
        @include flexbox;
        @include flex-wrap(wrap);
        margin-bottom: 10px;

        @include bp-medium {
            margin-top: 40px;
            margin-bottom: 20px;
        }
    }

    // p selector allows styles like bold to be controlled via content on sitecore
    &__paragraphs-container p:not(.confirmation-screen__paragraph--eta) {
        @include body-m;
        font-family: $open-sans-family;

        &:not(p:last-of-type):not(.confirmation-screen__paragraph--no-margin-bottom) {
            margin-bottom: 20px;
        }
        
        a {
            &::before {
                content: none !important;
            }
    
            body:not(.mouse-user):not(.is-oru) &:focus {
                @extend .coned-focus-outline;
            }
    
            body:not(.mouse-user) &:focus {
                @extend .oru-focus-outline;
            }
        }
    }

    &__paragraphs-container--max-width-800 p {
        max-width: 800px;
    }

    &__paragraph {
        &--bold {
            font-weight: $open-sans-bold;
        }
    
        &--eta {
            @include heading-m;
            color: $green;
            font-family: $lexend-family;
            font-weight: $lexend-extra-bold;

            &:not(p:last-of-type) {
                margin-bottom: 20px;
            }
        }
    
        &--error {
            color: $red;
            font-weight: $open-sans-semi-bold;
        }

        &--no-margin-bottom {
            margin-bottom: 0;
        }
    }
        
    &__title {
        @include heading-m;
        margin-bottom: 20px;
        max-width: 800px;
        font-family: $lexend-family;
        font-weight: $lexend-extra-bold;
        word-wrap: break-word;

        &--margin-bottom-30 {
            margin-bottom: 30px;
        }
    }
 
    &__subtitle {
        @include body-s;
        margin-bottom: 20px;
        text-transform: uppercase;
        color: $dark-gray;
        font-family: $open-sans-family;
        font-weight: $open-sans-bold;
    }

    &__button {
        @extend .button, .button--high-emphasis;
        margin-bottom: 20px;
        width: 100%;
        max-width: 440px;

        @include bp-medium {
            width: auto;
        }

        &-icon {
            font-size: calculate-rem(30);
        }

        &s-container {
            @include flexbox;
            @include flex-direction(column);
            @include flex-wrap(wrap);
            @include justify-content(center);
            @include align-items(center);
            margin-top: 20px;

            @include bp-medium {
                @include flex-direction(row);
            }

            &:last-child {
                margin-bottom: -20px;
            }

            &--left-aligned {
                @include align-items(flex-start);

                @include bp-medium {
                    @include justify-content(flex-start);
                }
            }

            &--no-margin-top {
                margin-top: 0;
            }
        }

        &--medium-emphasis {
            @extend .button;
            margin-bottom: 20px;
            width: 100%;
            max-width: 440px;

            @include bp-medium {
                width: auto;
            }

            &:not(:last-child) {
                @include bp-medium {
                    margin-right: 20px;
                }
            }
        }

        &--secondary-color {
            @extend .button, .button--secondary-color;
        }

        &--no-margin-right {
            margin-right: 0;
        }
    }

    &__link {
        @extend .button-link;
        margin-bottom: 20px;

        body:not(.mouse-user):not(.is-oru) &:focus {
            outline: none;

            &::before {
                @extend .coned-button-focus;
            }
        }

        body:not(.mouse-user) &:focus {
            outline: none;

            &::before {
                @extend .oru-button-focus;
            }
        }

        &:not(:last-child) {
            @include bp-medium {
                margin-right: 20px;
            }
        }
    }

    &__text-area {
        margin-bottom: 10px;
        background-color: $white;
        padding: 30px 20px;
        overflow: hidden;
        text-align: center;
    
        @include bp-medium {
            @include flexbox;
            @include flex-direction(column);
            @include justify-content(center);
            @include align-items(center);
            margin-bottom: 20px;
            padding-right: 50px;
            padding-left: 50px;
        }
    }

    &__account-info {
        @include flexbox;
        @include flex-direction(column);
        @include justify-content(center);
        background-color: $white;
        padding: 30px 20px;
        width: 100%;

        @include bp-medium {
            @include box-sizing(border-box);
            width: 32.1%;
        }

        @include bp-cards {
            width: 32.35%;
        }
    }
}
