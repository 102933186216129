// ============== LANGUAGE SWITCHER COMPONENT =================

.language-switcher {
    
    &-modal {
        position: relative;        
    }

    &-container {
        display: block;
        position: absolute;
        bottom: auto;
        left: 0;
        transition: opacity .6s ease,top .3s ease;
        z-index: 15;
        border-bottom: 0;
        background-color: $white;
        padding: 15px 18px;
        width: 289px;
        overflow: auto;
        
        @include bp-medium {
            position: absolute;
            right: 0;
            left: unset;
            border-bottom: solid 3px $blue-brand;
            padding: 16px;
            width: 244px;
            max-height: 100vh;
            overflow: hidden;

            .is-oru & {
                border-bottom: solid 3px $orange;
            }
        }
    }

    &-languages-list {
        max-height: calc(100vh - 65px);
        overflow-x: hidden;
        overflow-y: auto;
    }

    &-option-item {
        @extend %flexbox;
        @include flex-direction(row);
        @include body-xs;
        margin-bottom: 30px;
        padding: 0 22px;
        color: $dark-gray;
        font-family: $lexend-family;
        font-weight: $lexend-semi-bold;
        justify-content: left;
        align-items: center;
        
        @include bp-medium {
            margin-bottom: 16px;
            padding: 0 20px;
            width: 200px;
        }

        &-check-icon {
            position: relative;
            font-size: calculate-rem(30);
        }

        .icon-check-status::before {
            position: absolute;
            top: -15px;
            left: -25px;
            content: '\e96e';
        }
        
        &--language {
            margin-left: 6px;
            cursor: pointer;
            text-decoration: none;
            color: $dark-gray;

            .is-oru & {
                color: $dark-gray;
            }

            &--selected {
                color: $blue;
                // scss-lint:disable NestingDepth
                .is-oru & {
                    color: $teal;
                }
                // scss-lint:enable NestingDepth
            }
        }

        &--selected {
            color: $blue;
            
            .is-oru & {
                color: $teal;
            }
        }

        body:not(.mouse-user):not(.is-oru) &:focus {
            @extend .coned-focus-outline;
        }

        body:not(.mouse-user) &:focus {
            @extend .oru-focus-outline;
        }   

    }

    &-desktop-control--open {
        position: relative;
        background-color: $white;
        
        a {
            color: $dark-gray !important;
        }
    }

    &-action-icon {
        position: absolute;
        top: -4px;
        font-size: 2.4rem;
        
        &--open {
            transform: rotate(180deg);
        }
    }
}
