// ============== FORM LOADING COMPONENT =================

.page-loading {
    @extend %flexbox;
    @include align-items(center);
    @include justify-content(center);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99999;
    background-color: $white-semi-transparent;
    width: 100%;
    height: 100%;

    &__image {
        @include transform(translate(-50%, -50%) rotate(0.001deg));
        position: fixed;
        top: 50%;
        left: 50%;
        width: 50px;
        height: 50px;

        @include bp-medium {
            width: 100px;
            height: 100px;
        }
    }

    &--hidden {
        display: none;
    }

    &--div {
        position: relative;
        height: 200px;

        .page-loading__image {
            position: absolute;
        }
    }
}
