.landing-section {
    @include flexbox;
    @include align-items(center);
    @include justify-content(center);
    @include flex-direction(column);
    background-color: $light-gray;

    &--bg-white {
        background-color: $white;
    }

    &__container {
        overflow: hidden;
    }

    &:first-of-type {
        background-color: transparent;

        .landing__module-wrapper {
            &:first-of-type {
                @include bp-medium {
                    background: -moz-linear-gradient(
                        top,
                        $white-transparent 10%,
                        $light-gray-transparent 38%,
                        $light-gray-rbga 74%
                    ); /* FF3.6-15 */
                    background: -webkit-linear-gradient(
                        top,
                        $white-transparent 10%,
                        $light-gray-transparent 38%,
                        $light-gray-rbga 74%
                    ); /* Chrome10-25,Safari5.1-6 */
                    background: linear-gradient(
                        to bottom,
                        $white-transparent 10%,
                        $light-gray-transparent 38%,
                        $light-gray-rbga 74%
                    ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
                }
            }
        }
    }

    .landing__module-wrapper {
        background-color: $light-gray;
        width: 100%;
        height: 100%;
    }
}
