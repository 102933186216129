// ============== SUCCESS BANNER COMPONENT =================
.informational-banner {
    @include body-m;
    display: flex;
    margin-bottom: 10px;
    padding: 20px 12px 15px 27px;
    width: auto;
    text-align: left;
    color: $dark-gray;
    
    @include bp-medium {
        margin-bottom: 20px;
        padding: 20px 27px;
    }

    &--bg-gray {
        background-color: $medium-gray;
        color: $white;
    }

    &--icon {
        margin: 22px 4px 0 0;
        font-size: 26px;

        @include bp-medium {
            margin: 0 17px 0 0;
        }
    }

    &--text {
        @include flex-direction(column);

        display: flex;
        font-family: $open-sans-family;  
        
        @include bp-medium {
            @include flex-direction(row);
        }
    }

    &--title {
        margin-bottom: 5px;
        font-family: $lexend-family;
        font-weight: $lexend-extra-bold;

        @include bp-medium {
            margin-bottom: 0;
            padding: 0 10px;
        }
    }
}

