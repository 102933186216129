// ============== PAGINATION COMPONENT =================

.pagination {
    @extend %flexbox;
    @include flex-flow(row wrap);
    @include justify-content(center);
    @include align-items(center);
    padding: 45px 0 90px;

    &--mobile {
        @include bp-medium {
            display: none;
        }

        &-small {
            padding: 40px 0;
        }
    }

    &--desktop {
        @include bp-medium {
            padding: 45px 0;
        }

        @include bp-small {
            display: none;
        }
    }

    &__item {
        padding: 2px;
        width: 75px;
        height: 75px;
        text-align: center;

        &--wider {
            @include bp-small {
                width: 40%;
            }
        }
    }

    &__text,
    &__link {
        @extend %flexbox;
        @include flex-flow(row wrap);
        @include justify-content(center);
        @include align-items(center);
        @include heading-s;
        width: 100%;
        height: 100%;
        font-family: $lexend-family;
        font-weight: $lexend-extra-bold;
    }

    &__text {
        border: 2px solid $blue;
        background-color: $white;
        color: $dark-gray;

        .is-oru & {
            border-color: $teal;
        }
    }

    &__link {
        @include transition(all 0.2s ease);
        display: block;
        position: relative;
        outline: none;
        background-color: $white;
        cursor: pointer;
        text-decoration: underline;
        color: $blue;

        &:hover {
            text-decoration: none;
        }

        body:not(.mouse-user):not(.is-oru) &:focus::after {
            @extend .coned-button-focus;
        }

        body:not(.mouse-user) &:focus::after {
            @extend .oru-button-focus;
        }

        &::before {
            position: absolute;
            top: 35px;
            left: 18px;
            font-size: calculate-rem(40);
        }

        &--prev {
            @include transform(rotate(90deg));
            z-index: 1;
            text-decoration: none;
        }

        &--next {
            @include transform(rotate(-90deg));
            text-decoration: none;
        }

        .is-oru & {
            color: $teal;
        }
    }

    &__reset {
        display: none;
    }

    // scss-lint:disable NestingDepth
    &-simple {
        nav {
            @include flexbox;
            @include flex-direction(row);
            @include justify-content(center);
            @include align-items(center);

            ul {
                @include flexbox;
                @include flex-direction(row);
                @include justify-content(center);
                @include align-items(center);
                position: relative;
                padding: 0;
                list-style: none;

                li {
                    &:first-of-type {
                        @include transform(translateX(-100%));
                        position: absolute;
                        top: 0;
                        left: 0;
                    }

                    &:last-of-type {
                        @include transform(translateX(100%));
                        position: absolute;
                        top: 0;
                        right: 0;
                    }
                }
            }
        }

        .pagination {
            &__cta {
                @include flexbox;
                @include justify-content(center);
                @include align-items(center);
                @include heading-s;
                @include user-select(none);
                border: 2px solid $white;
                background-color: $white;
                cursor: pointer;
                min-width: 60px;
                min-height: 60px;
                font-family: $lexend-family;
                font-weight: $lexend-extra-bold;

                @include bp-small {
                    display: none;
                }


                &--active {
                    border: 2px solid $blue;
                    cursor: default;
                    text-decoration: none;
                    color: $black;

                    @include bp-small {
                        @include flexbox;
                        padding: 0 40px;
                    }
                }

                &--prev,
                &--next {
                    text-decoration: none;

                    @include bp-small {
                        @include flexbox;
                    }

                    .icon-carrot {
                        &::before {
                            font-size: calculate-rem(40);
                        }
                    }

                }

                &--prev {
                    .icon-carrot {
                        @include transform(rotate(90deg));
                    }
                }

                &--next {
                    .icon-carrot {
                        @include transform(rotate(-90deg));
                    }
                }
            }
        }
    }

    // scss-lint:enable NestingDepth
}
