// ============ CHECKBOX RECEIPT ===============

.checkbox-receipt {
    &--overdue {
        .checkbox-receipt__tooltip {
            color: $red !important;
        }
    }

    &__error {
        padding: 3px 0;
        font-size: 10px;

        @include bp-medium {
            position: absolute;
            right: 0;
            bottom: 0;
            padding: 0;
            width: 130px;
            font-size: 10px;
        }
    }
}
