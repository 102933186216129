// ============== ACTIVE CONNECTIONS COMPONENT =================

.active-connection-item {
    background-color: $white;
    width: auto;

    &__header {
        padding: 0 30px;
        width: auto;

        @include bp-medium {
            padding: 0 55px;
        }

        @include bp-large {
            padding: 0 110px;
        }

        &-labels {
            display: block;
            margin-top: 15px;
            border-bottom: 3px solid $light-gray;
            padding-bottom: 10px;
            height: auto;

            @include bp-medium {
                @include flexbox();
                @include align-items(center);
                margin-top: 0;
                padding-bottom: 0;
                height: 65px;
            }
        }

        &-logo {
            display: block;
            float: none;
            margin-right: 15px;
            width: 65px;
            height: 65px;

            @include bp-medium {
                display: inline-block;
                float: left;
            }
        }

        &-title {
            @include heading-l;
            display: block;
            margin-bottom: 5px;
            font-family: $lexend-family;
            font-weight: $lexend-extra-bold;

            @include bp-medium {
                display: inline-block;
                margin: auto 0 10px;
            }
        }

        &-subtitle {
            @include heading-s;
            display: block;
            margin: 0;
            font-family: $open-sans-family;
            font-weight: $open-sans-semi-bold;

            @include bp-medium {
                margin: auto 0 13px 20px;
            }
        }

        &-paragraph {
            @include body-m;
            font-family: $open-sans-family;
            font-weight: $open-sans-semi-bold;
        }
    }

    &--hidden {
        display: none;
    }

    &__load-more {
        @extend .button, .button--secondary-color;
        margin-top: 30px;

        &-container {
            display: block;
            width: 100%;
            text-align: center;
        }
    }
}

.collapsible-account-item {
    @include transition(background-color 0.2s ease);
    display: block;
    padding: 0 30px;
    width: auto;
    overflow: hidden;

    @include bp-medium {
        padding: 0 55px;
    }

    @include bp-large {
        padding: 0 110px;
    }

    &__edit-button {
        position: relative;
        top: -36px;

        @include bp-medium {
            top: auto;
        }
    }

    &__submit-progress-animation {
        padding: 0 25px;
    }

    &__submit-button-top {
        background-color: $alabaster;
    }

    &__date-container {
        padding-top: 16px;
        padding-bottom: 20px;

        @include bp-medium {
            padding-bottom: 36px;
        }
    }

    &__date-picker {
        background-color: inherit;
    }

    &__header {
        @include justify-content(space-between);
        @include flexbox();
        @include align-items(center);
        margin-left: 0;
        border-bottom: 1px solid $light-gray;
        padding: 30px 0;

        @include bp-medium {
            margin-left: 80px;
        }
    }

    &__container-title {
        width: auto;

        @include bp-medium {
            @include flexbox();
            width: 100%;
        }
    }

    &__title {
        @include body-m;
        display: inline-block;
        width: auto;
        font-family: $open-sans-family;
        font-weight: $open-sans-bold;

        @include bp-medium {
            @include flexbox();
            width: 100%;
        }

        &--gray-color {
            color: $medium-gray;
        }
    }

    &__subtitle {
        @include body-m;
        display: block;
        color: $medium-gray;
        font-family: $open-sans-family;
        font-weight: $open-sans-bold;

        @include bp-medium {
            display: inline-block;
            width: 100%;
        }
    }

    &__button {
        @extend .button-link, .button-link--extra-low-emphasis;
        @include flexbox;
        @include align-items(center);
        text-align: left;
        text-decoration: none;

        &-container {
            @include transition(all 0.2s ease);
            @include flexbox;
            @include flex-direction(column);
            @include align-items(center);
            @include align-self(stretch);
            column-gap: 28px;
            float: right;
            padding: 0;
            width: 100%;
            height: 104px;

            @include bp-medium {
                @include flex-direction(row);
                @include justify-content(flex-end);
                column-gap: 36px;
                height: auto;
            } 
        }

        body:not(.mouse-user):not(.is-oru) &:focus {
            outline: none;

            &::before {
                @extend .coned-button-focus;
            }
        }

        body:not(.mouse-user) &:focus {
            outline: none;

            &::before {
                @extend .oru-button-focus;
            }
        }

        &--right-floated {
            float: right;
        }

        &--underline {
            text-decoration: underline;
        }

        &--no-margin-right {
            margin-right: 0;
        }
    }

    &__form {
        @include transition(all 0.2s ease);
        display: none;
        margin-left: 0;
        border-bottom: 1px solid $light-gray;
        background-color: inherit;
        padding-top: 30px;
        padding-bottom: 30px;
        height: auto;

        @include bp-medium {
            margin-left: 80px;
            padding-left: 85px;
        }

        &-column {
            @include flexbox;
            @include flex-direction(column);
        }

        &-title {
            @include heading-s;
            display: block;
            padding-left: 3px;
            font-family: $lexend-family;
            font-weight: $lexend-extra-bold;
        }

        &-subtitle {
            @include body-m;
            display: block;
            padding-top: 15px;
            padding-left: 3px;
            font-family: $open-sans-family;
            font-weight: $open-sans-semi-bold;
        }

        &-button {
            padding-left: 3px;

            &--link {
                @extend .button-link;
                margin-right: 20px;
            }

            &--blue-rounded { 
                @extend .button;
            }
            
            &--cancel {
                color: $blue;
            }
        }

        &-button--link {
            body:not(.mouse-user):not(.is-oru) &::before {
                @extend .coned-button-focus;
            }
    
            body:not(.mouse-user) &::before {
                @extend .oru-button-focus;
            }
        }
        
        &-bottom {
            @include flexbox;
            @include justify-content(flex-end);
            @include align-items(center);
            margin-top: 20px;
        }

        &-deauthorize {
            @include flexbox();
            @include flex-direction(row);
            @include align-items(center);
        }
    }

    &[disabled] {
        pointer-events: none;

        .collapsible-account-item__title {
            color: $medium-gray;
        }

        .collapsible-account-item__subtitle,
        .collapsible-account-item__edit-button {
            display: none;
        }
    }

    &.collapsible-container__item--open {
        .collapsible-account-item__form {
            display: block;

            @include bp-medium {
                padding-bottom: 50px;
            }
        }
    
    }

    &__accordion {
        @include flexbox;
        @include justify-content(flex-end);
        @include align-items(center);
        float: right;
        background-color: $white;
        width: 115px;
    
        @include bp-medium {
            width: 320px;
        }

        &-arrow {
            background-color: $white;
            color: $blue;
            font-size: 2.5rem;
    
            &--rotate {
                transform: rotateX(180deg);
            }
        }
    }

    &__select-container {
        padding-top: 6px;
        padding-bottom: 0;
        width: auto;

        @include bp-medium {
            padding-bottom: 16px;
            width: 100%;
        }

        &-label {
            @include body-xs;
            @include flexbox();
            padding-bottom: 36px;
            color: rgba(0, 0, 0, 0.80);
            font-family: $open-sans;
            font-weight: 600;
            font-style: normal;
        }
    }
}

.active-connections-container {
    background-color: $white;
}

.active-connections-list {
    background-color: $white;
    padding: 40px 0 20px;

    @include bp-medium {
        padding: 120px 0 0;
    }

    &__element {
        padding-bottom: 70px;

        @include bp-medium {
            padding-bottom: 100px;
        }
    }

    &--empty {
        padding: 40px 0;

        @include bp-medium {
            padding: 150px 0;
        }
    }
}
