// ============== DISCLAIMER COMPONENT =================

.disclaimer {
    margin: 0 10px 20px;
    border-top: solid 1px $medium-gray;
    background-color: $white;

    @include bp-medium {
        margin: 0 auto 64px;
    }

    &__content {
        @include body-xs;
        padding: 50px 20px;
        color: $medium-gray;
        font-family: $open-sans-family;

        @include bp-medium {
            padding: 60px 0 75px 21.5%;
            width: 50%;
        }

        a {
            word-break: break-word;

            body:not(.mouse-user):not(.is-oru) &:focus {
                @extend .coned-focus-outline;
            }

            body:not(.mouse-user) &:focus {
                @extend .oru-focus-outline;
            }
            
            &:focus::before {
                content: none !important;    
            }
        }
    }

    &__title {
        @include heading-s;
        padding-bottom: 30px;
        font-family: $lexend-family;
        font-weight: $lexend-extra-bold;
    }
}
