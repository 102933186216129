// ============== PROGRAM STATUS COMPONENT =================
.program-status {
    @include border-radius(8px);
    padding: 40px 0 24px;
    width: 100%;

    @include bp-medium {
        padding-top: 24px;
        padding-bottom: 16px;
    }

    &-top {
        @include flexbox;
        @include align-items(center);

        &-title {
            @include heading-m;
            text-align: left;
            color: $medium-gray;
            font-family: $lexend;

            &--green {
                color: $green;
            }

            &--black {
                color: $black;
            }
        }

        &__icon {
            position: relative;
            margin-right: 8px;
            border-radius: 50%;
            background-color: $white;
            width: 24px;
            height: 24px;  
            
            &--green {
                background-color: $green;
            }

            &--gray {
                background: $medium-gray;
            }

            &--red {
                background: $red;
            }

            &--rotate-180 {
                transform: rotate(180deg);
            }
    
            &::before {
                position: absolute;
                top: -3px;
                left: -3px;
                color: $white;
                font-size: 3rem;
            }
        }

        &--blue {
            background-color: $blue;
        }

        &--red {
            background-color: $red;
        }

        &--align-top {
            margin-top: 16px;
            align-self: flex-start;
        }
    }

    &-description {
        @include body-m;
        padding-top: 8px;
        text-align: start;
        font-family: $open-sans;
    }

    &--padding-vertical {
        padding: 28px 0 16px;
    }

    &--padding-top-40 {
        padding-top: 40px;
    }

    .is-oru & {
        &-top {
            &--blue {
                background-color: $teal;
            }
        }
    }
}
