// ============== CONTACT-US TEXT COMPONENT =================

.contact-us-text {
    clear: both;

    &-wrapper {
        margin: 0 10px;

        @include bp-medium {
            margin: 0;
        }
    }

    &__container {
        background-color: $white;
        padding: 20px;

        @include bp-medium {
            padding: 115px 70px 10px;
        }

        &-title {
            @include heading-m;
            display: block;
            margin: 80px 0;
            overflow: hidden;
            color: $dark-gray;
            font-family: $lexend-family;
            font-weight: $lexend-extra-bold;
            word-break: break-word;

            @include bp-medium {
                margin: 0 0 70px;
            }
        }
    }

    &__items {
        @include bp-medium {
            @include flexbox;
            @include flex-flow(row wrap);
            @include justify-content(space-between);
        }
    }

    &__item {
        display: block;
        margin-bottom: 100px;

        @include bp-medium {
            @include box-sizing(border-box);
            display: inline-block;
            margin-bottom: 70px;
            padding-right: 4%;
            width: 50%;
        }
        
        @include bp-large {
            width: 33%;
        }

        &:last-child {
            margin-right: auto;
        }

        a {
            body:not(.mouse-user):not(.is-oru) &:focus {
                @extend .coned-focus-outline--reversed;
            }

            body:not(.mouse-user) &:focus {
                @extend .oru-focus-outline--reversed;
            }

            &:focus::before {
                content: none !important;
            }
        }
    }

    &__item-icon {
        padding-left: 10px;
    }

    &__item-links {
        @include transition(all 0.2s ease);
        @include opacity(0);
        display: block;
        height: 0;
        overflow: hidden;

        @include bp-medium {
            @include opacity(1);
            padding-bottom: 0;
            height: auto;
        }
    }

    &__item-title {
        @include heading-s;
        overflow: hidden;
        color: $dark-gray;
        font-family: $lexend-family;
        font-weight: $lexend-extra-bold;
        word-break: break-word;

        @include bp-medium {
            display: block;
            margin-bottom: 20px;
        }
    }

    &-link-icon {
        @include transition(all 0.2s ease);
        @include border-radius(50%);
        display: inline-block;
        margin-right: 10px;
        background-color: $blue;
        padding: 9px 0 0 9px;
        width: 24px;
        height: 24px;
        font-size: calculate-rem(15);
    }

    &__item-anchor {
        @extend .button-link, .button-link--extra-low-emphasis;
    }

    &__item-copy {
        @include body-l;
        margin-bottom: 20px;
        color: $medium-gray;
        font-family: $open-sans-family;

        b {
            font-weight: $open-sans-bold;
        }

        span {
            @include body-l;
            text-transform: uppercase;
            color: $silver-chalice;
            font-family: $open-sans-family;
            font-weight: $open-sans-bold;
        }

        p {
            margin-bottom: 40px;

            @include bp-medium {
                margin-bottom: 35px;
            }
        }

        h3 {
            @include body-l;
        }
    }
}
