// ============ ANIMATED HERO ===============

.animated-hero {
    @include transition(all, 0.4s, ease);
    @include opacity(1);
    position: relative;
    top: 0;

    &--animation-item {
        @include transition(all, 0.4s, ease);
        @include opacity(0);
        top: 25px;
    }

    &--animation-image {
        @include transition(all, 0.4s, ease);
        @include opacity(0);
    }
}
