// ============== OVERVIEW NOTIFICATION COMPONENT =================

.overview-notification {
    @extend %flexbox;
    @include align-items(center);
    @include justify-content(space-between);
    @include flex-direction(column);
    position: relative;
    margin-bottom: 15px;
    padding: 22px 0;
    width: 100%;
    overflow: hidden;

    @include bp-medium {
        @include flex-direction(row);
        margin-bottom: 20px;
    }

    &--red {
        background-color: $red;
    }

    &__block {
        @extend %flexbox;
        @include align-items(flex-start);
        @include box-sizing(border-box);
        margin: 0 20px;
        padding: 0 10px 0 50px;
        width: 90%;

        @include bp-medium {
            @include align-items(center);
            padding-left: 60px;
        }
    }

    &__cta-container {
        @extend %flexbox;
        @include flex-grow(0);
        @include flex-shrink(0);
        @include flex-direction(column);
        width: 90%;

        @include bp-bill-card {
            @include flex-direction(row);
            padding-right: 30px;
            width: auto;
        }
    }

    &__cta {
        @extend %flexbox, .button;
        @include flex-grow(0);
        @include flex-shrink(0);
        @include justify-content(center);
        @include border-radius(50px);
        @include transition(border 0.2s ease);
        margin: 20px 7px 0;
        padding: 10px 30px;
        text-align: center;
        font-family: $lexend-family;
        font-weight: $lexend-extra-bold;

        @include bp-medium {
            margin: 5px 7px;
            padding: 12px 30px;
        }

        &,
        .is-oru & {
            border: 2px solid $red;
            background-color: $white;
            text-decoration: none;
            color: $red;

            &:active:not([disabled]) {
                background-color: $overage-red;
            }
        }
    }

    &__cta--secondary {
        &,
        .is-oru & {
            border: 2px solid $white;
            background-color: $red;
            color: $white;
        }
    }

    &__icon {
        position: relative;
        height: 15px;
        color: $white;

        @include bp-medium {
            height: 35px;
        }

        span[class*='icon-']::before {
            position: absolute;
            top: -8px;
            left: -40px;
            font-size: calculate-rem(35);

            @include bp-medium {
                top: 0;
            }
        }
    }

    &__description {
        @include body-m;
        letter-spacing: 0.1px;
        color: $white;
        font-family: $open-sans-family;

        strong,
        b {
            font-weight: $open-sans-extra-bold;
        }
    }
}

.overview-notification-card {
    @extend %flexbox;
    @include justify-content(center);
    @include align-items(center);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 50px;
    color: $white;

    &__title {
        @include body-m;
        font-family: $lexend-family;
        font-weight: $lexend-extra-bold;
    }

    &--red {
        border-bottom: 2px $red solid;
        background: repeating-linear-gradient(-60deg, $red, $red 20px, $red 20px, $red 40px);
    }

    &--yellow {
        background: repeating-linear-gradient(
            -55deg,
            $yellow,
            $yellow 20px,
            $dark-yellow 20px,
            $dark-yellow 40px
        );
        color: $dark-gray;
    }

    &--green {
        background: repeating-linear-gradient(
            -55deg,
            $green,
            $green 20px,
            $green 20px,
            $green 40px
        );
    }
}
