.filter-table {
    @include body-m;
    margin: 0 auto;
    background: $white;
    max-width: 1400px;

    table {
        width: 100%;
        table-layout: fixed;
        box-sizing: border-box;

        tbody tr {
            display: table-row;
            visibility: visible;

            &[aria-hidden="true"] {
                display: none;
                visibility: hidden;
            }
        }
    }

    &__heading {
        @include body-l;
        padding: 16px 0;
        text-align: left;
        color: $dark-gray;
        font-family: $lexend-family;
        font-weight: $lexend-extra-bold;

        @include bp-medium {
            padding: 16px 20px;
        }
    }

    &__filters {
        &-container {
            @extend %flexbox;
            @include flex-direction(column);
            position: relative;
            border-bottom: 3px solid $blue;
            padding: 5px 15px 15px 0;
            width: 100%;
            box-sizing: border-box;

            @include bp-medium {
                @include flex-direction(row);
            }

            .is-oru & {
                border-bottom: 3px solid $teal;
            }
        }
    }

    &__action {
        &-clear {
            @extend .button-link, .button-link--hover-underline;
            @include btn-focus-outline;
        }
    }

    &__row {
        border-bottom: 1px solid $medium-gray;

        @include bp-medium {
            border-bottom: 0;
        }
    }

    &__cell {
        margin-bottom: 0;
        padding: 20px 0 0;
        vertical-align: top;
        text-align: start;

        @include bp-small {
            @include flexbox;
        }

        @include bp-medium {
            border-bottom: 1px solid $medium-gray;
            padding: 20px 0 20px 20px;
        }

        &:nth-child(2) {
            display: none;

            @include bp-medium {
                display: table-cell;
            }
        }


        &:last-child {
            padding: 20px 0;

            @include bp-medium {
                padding: 20px;
            }
        }

        &--header {
            @include bp-medium {
                display: none;
            }

            .filter-table__text {
                font-weight: $open-sans-regular;
            }
        }
    }

    &__head {
        @include bp-small {
            display: none;
        }
    }

    &__text {
        @include body-s;
        font-family: $open-sans-family;
        word-wrap: break-word;

        @include bp-small {
            width: calc(50% - 15px);
        }
    }

    &__title {
        @include body-s;
        text-transform: uppercase;
        color: $dark-gray;
        font-family: $open-sans-family;
        font-weight: $open-sans-bold;
        word-wrap: break-word;

        &--mobile {
            @include flex-basis(40%);

            @include bp-medium {
                display: none;
            }

            &:first-child {
                padding-right: 30px;
            }

            &:not(:first-child) {
                text-transform: none;
            }
        }
    }

    &__wrapper {
        margin-bottom: 40px;
        background-color: $white;
        width: 100%;
        box-sizing: border-box;

        &-table {
            padding: 0;

            @include bp-medium {
                padding: 0 15px;
            }
        }
    }

    &__container {
        padding: 0 20px 40px;
        width: 100%;
        box-sizing: border-box;

        @include bp-medium {
            margin: 0 auto 0 0;
            padding: 0 0 95px 14.5%;
            max-width: 50%;
        }

        &--lg {
            @include bp-medium {
                margin: 0 auto 0 0;
                padding: 0 0 95px 14.5%;
                max-width: 80%;
            }
        }

        &--xl {
            @include bp-medium {
                margin: unset;
                padding: 0 0 95px;
                max-width: 100%;
            }
        }
    }

    &__content {
        padding: 0;
    }
}
