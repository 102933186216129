// ============== LOGIN COMPONENT =================

.unauthenticated-login {
    &--desktop {
        @include bp-lt-large {
            display: none !important;
        }
    }

    &--mobile {
        @include bp-large {
            display: none !important;
        }
    }

    &__title {
        @include heading-s;
        font-family: $lexend-family;
        font-weight: $lexend-extra-bold;
    }

    &__description {
        @include body-s;
        font-family: $open-sans-family;

        &--centered {
            text-align: center;
        }

        &--input-padding {
            @include bp-medium {
                padding-left: 20px;
            }
        }

        a {
            font-weight: $open-sans-semi-bold;
        }
    }

    &__header-description {
        @include bp-medium {
            margin-top: 30px;
        }
    }

    &__paragraph {
        a {
            font-weight: $open-sans-semi-bold;
        }

        &--no-padding {
            @include bp-small {
                padding-left: 0;
            }
        }
    }

    &__wrapper {
        @include flexbox();
        @include flex-flow();
        @include justify-content(space-between);

        margin: 65px 0 0;
        padding: 0;
        list-style: none;

        @include bp-small {
            margin-top: 40px;
        }
    }

    &__container {
        margin-top: 65px;

        @include bp-small {
            margin-top: 40px;
        }
    }

    &__sub-form {
        @include flex(1 1 50%);
        padding: 0 3%;
        box-sizing: border-box;

        &:first-child {
            padding-left: 0;
        }

        &:last-child {
            padding-right: 0;
        }
    }

    &__input-error {
        @include align-items(center);
        @include body-s;
        position: relative;
        margin: 10px 0;
        padding-left: 25px;
        color: $red;
        font-family: $open-sans-family;
        font-weight: $open-sans-semi-bold;

        @include bp-medium {
            padding-left: 40px;
        }

        &-message {
            display: inline;
        }
    }

    &__recaptcha {
        margin-top: 60px;
        margin-left: 0 !important;
    }

    .unauthenticated-login__sub-form + .unauthenticated-login__sub-form {
        border-left: 2px solid $alto;
    }

    .unauthenticated-login__collapsible-item {
        padding: 55px 20px;
    }

    .unauthenticated-login__collapsible-container-item {
        padding-left: 0;
        width: 100%;
    }
}
