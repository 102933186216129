// DO NOT REPLACE THIS PART
// Added colors for ORU & ConEd logos
.icon-oru-logo {
    @include transform(scale(0.5));
    color: $orange;

    &::before {
        @extend .icon-coned-logo::before;
    }
}

.icon-coned-logo {
    @include transform(scale(0.5));
    color: $blue;
}

.icon--centered {
    @include bp-smaller {
        display: inline-block;
    }

    &::before {
        @include transform(translateY(-25%));

        @include bp-smaller {
            @include transform(translateY(-20%));
        }
    }
}

// Replace code from here until end
// Remember to fix path to fonts files
@font-face {
    font-family: 'coned-icons';
    src: url('../fonts/coned-icons.eot?eorugz');
    src: url('../fonts/coned-icons.eot?eorugz#iefix') format('embedded-opentype'),
        url('../fonts/coned-icons.ttf?eorugz') format('truetype'),
        url('../fonts/coned-icons.woff?eorugz') format('woff'),
        url('../fonts/coned-icons.svg?eorugz#coned-icons') format('svg');
    font-weight: normal;
    font-style: normal;
}

[class^='icon-'],
[class*=' icon-'],
.icon-default-container {
    /* use !important to prevent issues with browser extensions that change fonts */
    text-transform: none;
    line-height: 1;
    font-family: 'coned-icons' !important;
    font-weight: normal;

    /* Better Font Rendering =========== */
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;

    font-style: normal;
    font-variant: normal;
    speak: none;
}

.icon-coned-logo::before {
    content: '\e900';
}

.icon-report-problem::before {
    content: '\e901';
}

.icon-arrow::before {
    content: '\e902';
}

.icon-autopay::before {
    content: '\e903';
}

.icon-award::before {
    content: '\e904';
}

.icon-battery-41::before {
    content: '\e905';
}

.icon-before-you-dig::before {
    content: '\e906';
}

.icon-billing-payment::before {
    content: '\e907';
}

.icon-brochure::before {
    content: '\e908';
}

.icon-building::before {
    content: '\e909';
}

.icon-buildings::before {
    content: '\e90a';
}

.icon-calculator::before {
    content: '\e90b';
}

.icon-calendar::before {
    content: '\e90c';
}

.icon-car::before {
    content: '\e90d';
}

.icon-carbon-sf6::before {
    content: '\e90e';
}

.icon-careers::before {
    content: '\e90f';
}

.icon-climate::before {
    content: '\e910';
}

.icon-clock::before {
    content: '\e911';
}

.icon-industrial::before {
    content: '\e912';
}

.icon-commercial::before {
    content: '\e913';
}

.icon-community::before {
    content: '\e914';
}

.icon-confirmation::before {
    content: '\e915';
}

.icon-contact-us::before {
    content: '\e916';
}

.icon-customer-service::before {
    content: '\e917';
}

.icon-earning-cost::before {
    content: '\e918';
}

.icon-economic-development::before {
    content: '\e919';
}

.icon-education::before {
    content: '\e91a';
}

.icon-energy-efficient::before {
    content: '\e91b';
}

.icon-electric-car-charger::before {
    content: '\e91c';
}

.icon-electric-emergency::before {
    content: '\e91d';
}

.icon-electricity::before {
    content: '\e91e';
}

.icon-mail::before {
    content: '\e91f';
}

.icon-employee::before {
    content: '\e920';
}

.icon-efficiency::before {
    content: '\e921';
}

.icon-energy-savings::before {
    content: '\e922';
}

.icon-energy::before {
    content: '\e923';
}

.icon-environment-health::before {
    content: '\e924';
}

.icon-environment::before {
    content: '\e925';
}

.icon-close::before {
    content: '\e926';
}

.icon-facebook::before {
    content: '\e927';
}

.icon-financial-help::before {
    content: '\e928';
}

.icon-flickr::before {
    content: '\e929';
}

.icon-forms::before {
    content: '\e92a';
}

.icon-gas-emergency::before {
    content: '\e92b';
}

.icon-get-your-bill::before {
    content: '\e92c';
}

.icon-google::before {
    content: '\e92d';
}

.icon-green::before {
    content: '\e92e';
}

.icon-menu::before {
    content: '\e92f';
}

.icon-history::before {
    content: '\e930';
}

.icon-images::before {
    content: '\e931';
}

.icon-person::before {
    content: '\e932';
}

.icon-infographic::before {
    content: '\e933';
}

.icon-innovation-tech::before {
    content: '\e934';
}

.icon-internet::before {
    content: '\e935';
}

.icon-issues-status::before {
    content: '\e936';
}

.icon-light-bulb::before {
    content: '\e937';
}

.icon-linked-in::before {
    content: '\e938';
}

.icon-live-chat::before {
    content: '\e939';
}

.icon-lock::before {
    content: '\e93a';
}

.icon-login::before {
    content: '\e93b';
}

.icon-logged-in::before {
    content: '\e93c';
}

.icon-lower-bill::before {
    content: '\e93d';
}

.icon-mailing::before {
    content: '\e93e';
}

.icon-media-center::before {
    content: '\e93f';
}

.icon-carrot::before {
    content: '\e940';
}

.icon-mobile::before {
    content: '\e941';
}

.icon-more-info::before {
    content: '\e942';
}

.icon-natural-gas::before {
    content: '\e943';
}

.icon-setup-service::before {
    content: '\e944';
}

.icon-news-highlights::before {
    content: '\e945';
}

.icon-online::before {
    content: '\e946';
}

.icon-excellence::before {
    content: '\e947';
}

.icon-outage-alert::before {
    content: '\e948';
}

.icon-outage-map::before {
    content: '\e949';
}

.icon-payment::before {
    content: '\e94a';
}

.icon-pdf::before {
    content: '\e94b';
}

.icon-plants::before {
    content: '\e94c';
}

.icon-print::before {
    content: '\e94d';
}

.icon-question::before {
    content: '\e94e';
}

.icon-rebates-incentives::before {
    content: '\e94f';
}

.icon-renewable::before {
    content: '\e950';
}

.icon-repairs::before {
    content: '\e951';
}

.icon-report-service::before {
    content: '\e952';
}

.icon-residential::before {
    content: '\e953';
}

.icon-retiree::before {
    content: '\e954';
}

.icon-safety-precaution::before {
    content: '\e955';
}

.icon-safety::before {
    content: '\e956';
}

.icon-sce-report::before {
    content: '\e957';
}

.icon-search::before {
    content: '\e958';
}

.icon-send::before {
    content: '\e959';
}

.icon-senior-services::before {
    content: '\e95a';
}

.icon-back-to-top::before {
    content: '\e95b';
}

.icon-slideshow::before {
    content: '\e95c';
}

.icon-small-businesses::before {
    content: '\e95d';
}

.icon-smart-meter::before {
    content: '\e95e';
}

.icon-share::before {
    content: '\e95f';
}

.icon-solar::before {
    content: '\e960';
}

.icon-special-accomodations::before {
    content: '\e961';
}

.icon-spreadsheets::before {
    content: '\e962';
}

.icon-steam-emergency::before {
    content: '\e963';
}

.icon-steam::before {
    content: '\e964';
}

.icon-storm-preparedness::before {
    content: '\e965';
}

.icon-thumbs-up::before {
    content: '\e966';
}

.icon-transmission-plant::before {
    content: '\e967';
}

.icon-twitter::before {
    content: '\e968';
}

.icon-upload::before {
    content: '\e969';
}

.icon-video::before {
    content: '\e96a';
}

.icon-volunteer::before {
    content: '\e96b';
}

.icon-wind::before {
    content: '\e96c';
}

.icon-youtube::before {
    content: '\e96d';
}

.icon-check-status::before {
    content: '\e96e';
}

.icon-art::before {
    content: '\e96f';
}

.icon-opportunities-v1::before {
    content: '\e970';
}

.icon-opportunities-v2::before {
    content: '\e971';
}

.icon-pipeline::before {
    content: '\e972';
}

.icon-powerline::before {
    content: '\e973';
}

.icon-recycling::before {
    content: '\e974';
}

.icon-language::before {
    content: '\e975';
}

.icon-filled-lock::before {
    content: '\e976';
}

.icon-river::before {
    content: '\e977';
}

.icon-shopping-cart::before {
    content: '\e978';
}

.icon-showers::before {
    content: '\e979';
}

.icon-snow::before {
    content: '\e97a';
}

.icon-snowflake::before {
    content: '\e97b';
}

.icon-social-share::before {
    content: '\e97c';
}

.icon-solar-energy::before {
    content: '\e97d';
}

.icon-thermostat::before {
    content: '\e97e';
}

.icon-thumbs-down::before {
    content: '\e97f';
}

.icon-alert-bell::before {
    content: '\e980';
}

.icon-announcement::before {
    content: '\e981';
}

.icon-bio-engineering::before {
    content: '\e982';
}

.icon-bridge::before {
    content: '\e983';
}

.icon-business::before {
    content: '\e984';
}

.icon-camera::before {
    content: '\e985';
}

.icon-carbon-dioxide::before {
    content: '\e986';
}

.icon-clear-night::before {
    content: '\e987';
}

.icon-cloudy::before {
    content: '\e988';
}

.icon-coastguard::before {
    content: '\e989';
}

.icon-culture::before {
    content: '\e98a';
}

.icon-cyber-security::before {
    content: '\e98b';
}

.icon-dashboard::before {
    content: '\e98c';
}

.icon-diversity::before {
    content: '\e98d';
}

.icon-download::before {
    content: '\e98e';
}

.icon-employee-internal::before {
    content: '\e98f';
}

.icon-event::before {
    content: '\e990';
}

.icon-explosion::before {
    content: '\e991';
}

.icon-external-site::before {
    content: '\e992';
}

.icon-fire::before {
    content: '\e993';
}

.icon-freezing-rain::before {
    content: '\e994';
}

.icon-government::before {
    content: '\e995';
}

.icon-hurricane::before {
    content: '\e996';
}

.icon-insights::before {
    content: '\e997';
}

.icon-map::before {
    content: '\e998';
}

.icon-microsoft-excel::before {
    content: '\e999';
}

.icon-microsoft-powerpoint::before {
    content: '\e99a';
}

.icon-microsoft-word::before {
    content: '\e99b';
}

.icon-money::before {
    content: '\e99c';
}

.icon-multifamily::before {
    content: '\e99d';
}

.icon-my-settings::before {
    content: '\e99e';
}

.icon-neighborhood::before {
    content: '\e99f';
}

.icon-truck::before {
    content: '\e9a0';
}

.icon-trust::before {
    content: '\e9a1';
}

.icon-waste::before {
    content: '\e9a2';
}

.icon-wind-energy::before {
    content: '\e9a3';
}

.icon-stray-voltage::before {
    content: '\e9a4';
}

.icon-diagnostics::before {
    content: '\e9270';
}

.icon-flurries::before {
    content: '\e93f0';
}

.icon-info::before {
    content: '\e9a5';
}

.icon-play::before {
    content: '\e9a6';
}

.icon-pause::before {
    content: '\e9a7';
}

.icon-instagram::before {
    content: '\e9a8';
}

.icon-expand::before {
    content: '\e9a9';
}

.icon-start-stop-service::before {
    content: '\e9aa';
}

.icon-globe::before {
    content: '\e9ab';
}

.icon-filter::before {
    content: '\e93f1';
}

.icon-commercial-industrial:before {
    content: '\e93f2';
}

.icon-small-medium-business:before {
    content: '\e93f3';
}

.icon-income-eligble:before {
    content: '\e93f4';  
}

.icon-home-owner-renter:before {
    content: '\e93f5';
}

.icon-ev-developers:before {
    content: '\e93f6';
}
