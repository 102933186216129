// ============== CARD MODULE COMPONENT =================

.card-module {
    @extend %flexbox;
    @include flex-direction(column);
    @include box-sizing(border-box);
    @include flex-shrink(1);
    @include align-items(center);
    position: relative;
    margin-bottom: 20px;
    background-color: $white;
    padding: 0 35px;
    width: 100%;
    height: auto;
    min-height: 330px;
    overflow: hidden;

    @include bp-medium {
        @include flex-basis(auto);
        padding: 0 50px;
        width: calc(50% - 10px);

        &:not(:nth-child(2n + 1)) {
            margin-left: 10px;
        }

        &:not(:nth-child(2n + 2)) {
            margin-right: 10px;
        }
    }

    @include bp-cards {
        @include flex-basis(auto);
        width: calc(33.3% - 13px);

        &:not(:nth-child(2n + 1)) {
            margin-left: 0;
        }

        &:not(:nth-child(2n + 2)) {
            margin-right: 0;
        }

        &:not(:nth-child(3n + 1)) {
            margin-left: 10px;
        }

        &:not(:nth-child(3n + 3)) {
            margin-right: 10px;
        }
    }

    &--alert {
        border-top: 2px solid $red;
    }

    &--full-link {
        padding: 0;
    }

    &--cross-link {
        padding: 45px 30px;

        @include bp-medium {
            padding: 45px 82px;
        }

        .card-module__title {
            margin: 0;            
        }

        .card-module__description {
            @include body-l;
            font-family: $open-sans-family;

            &--margin-bottom-20 {
                margin-bottom: 20px;
            }
        }

        .card-module__cta {
            margin: 0;
        }

        .card-module__cta-button {
            @extend .button;
        }
    }

    &--padding-30-50 {
        padding: 30px 50px;
    }

    &--margin-bottom-10-mobile {
        @include bp-small {
            margin-bottom: 10px;
        }
    }

    &--cover-horizontal-space {
        @include bp-medium {
            flex: 1 1 0;

            &:nth-child(1n + 1) {
                margin-right: 0;
            }
    
            &:not(:first-child) {
                margin-left: 20px;
            }
        }
        
        .card-module__title,
        .card-module__description {
            max-width: 800px;
        }
    }

    &--dark-theme {
        background-color: $orange;

        .card-module__title,
        .card-module__description {
            color: $teal;
        }
    
        .card-module__content-border {
            background-color: $teal;
        }

        body:not(.is-oru) & {
            background-color: $blue;

            .card-module__title,
            .card-module__description {
                color: $white;
            }
    
            .card-module__content-border {
                background-color: $white;
            }

            .card-module__cta-button {
                @extend .button--secondary-color;
            }
        }
    }

    &__wrapper-link,
    &__wrapper-multiple {
        @extend %flexbox;
        @include flex-direction(column);
        @include flex-shrink(1);
        @include align-items(center);
        max-width: calc(100% - 70px);
        height: auto;
        min-height: 330px;
        text-decoration: none;
    }

    &-wrapper {
        @extend %flexbox;
        @include flex-wrap(wrap);
        padding: 0 10px;

        @include bp-medium {
            padding: 40px 0;
        }

        &--padding-0 {
            padding: 0;
        }

        &--padding-top-30 {
            padding-top: 30px;
        }

        &--padding-top-10-mobile {
            @include bp-small {
                padding-top: 10px;
            }
        }

        &--sides-padding-0 {
            padding-right: 0;
            padding-left: 0;
        }

        &--padding-bottom-0 {
            padding-bottom: 0;
        }
    }

    &__wrapper-link {
        &:focus::before {
            content: none !important;
        }

        &--focus {
            body:not(.mouse-user):not(.is-oru) & {
                @extend .coned-focus-outline;
            }

            body:not(.mouse-user) & {
                @extend .oru-focus-outline;
            }
        }
    }

    &__title {
        @include heading-m;
        margin-top: 45px;
        max-width: 100%;
        text-align: center;
        color: $dark-gray;
        font-family: $lexend-family;
        font-weight: $lexend-extra-bold;
    }

    &__description {
        @include flex-grow(1);
        @include flex-shrink(1);
        @include body-l;
        max-width: 100%;
        text-align: center;
        color: $dark-gray;
        font-family: $open-sans-family;


        &--no-flex-grow { 
            @include flex-grow(0);
        }

        &--bold {
            font-weight: $open-sans-bold;
        }
    }

    &__content-border {
        margin: 20px auto;
        background-color: $medium-gray;
        width: 73px;
        height: 2px;
    }

    &__cta {
        margin: 35px auto 45px;
    }

    &__cta--contact {
        margin: 20px auto 10px;

        &:focus {
            overflow: visible;
        }
    }

    &__cta--form {
        margin: 20px auto;

        &:focus {
            outline: none;
            overflow: visible;

            body:not(.mouse-user):not(.is-oru) &::before {
                @extend .coned-button-focus;
            }

            body:not(.mouse-user) &::before {
                @extend .oru-button-focus;
            }
        }
    }

    &__date {
        @include body-xs;
        color: $medium-gray;
        font-family: $open-sans-family;
        font-weight: $open-sans-regular;
    }

    &-picture-image {
        @include border-radius(50%);
        margin: 15px auto 20px;
        width: 65px;
        height: 65px;
    }

    &-form-wrapper {
        width: 100%;
    }

    &__link {
        @extend .button-link, .button-link--extra-low-emphasis;
        margin: 8px 0 25px;
    }

    &__link-container {
        @extend %flexbox;
        @include flex-direction(column);
        @include align-items(center);
        padding: 15px;
        max-width: 100%;

        @include bp-contact-us-small-card-link {
            max-width: 50%;
        }
        
        @include bp-contact-us-medium-card-link {
            max-width: 70%;
        }

    }

    .coned-form-messages,
    .coned-select,
    .coned-select__arrow,
    .coned-input {
        background-color: $transparent;
    }

    .form-loading__image--center { 
        position: absolute;
        width: 25px;
        height: 25px;

        @include bp-medium {
            width: 50px;
            height: 50px;
        }
    }

    .transactional__error {
        @include body-s;
        padding: 20px 59px;
    }
}
