// ============== TRANSACTIONAL-FOOTER COMPONENT =================

.transactional-footer {
    margin: 0 auto;
    padding: 20px 30px 0;
    text-align: center;

    &--padding-top {
        padding-top: 0;
    }

    &__button {
        @include border-radius(32px);
        margin: 30px auto;
        width: 100%;
        height: 64px;
        font: $lexend-extra-bold calculate-rem(18) / calculate-rem(32) $lexend-family;

        @include bp-medium {
            width: 400px;
        }

        &:hover {
            background-color: $green;
        }

        &-text {
            padding-right: 20px;
        }
    }

    &__paragraph,
    &__title {
        @include body-xs;
        text-align: center;
        color: $dark-gray;
        font-family: $open-sans-family;
        font-weight: $open-sans-semi-bold;

        @include bp-medium {
            margin: 0 auto;
            padding-bottom: 20px;
            width: 700px;
        }

        a {
            color: $dark-gray;
        }

        p {
            padding-bottom: 20px;
        }

        b {
            font-weight: $open-sans-bold;
        }
    }

    &__title {
        padding-top: 20px;
        font: calculate-rem(18) / calculate-rem(24) $open-sans-family;

        @include bp-medium {
            padding-top: 40px;
        }
    }
}
