// ============== CURRENTLY ENROLLED UNITS =================

.currently-enrolled {
    &__description {
        @include body-l;
        color: $dark-gray;
        font-family: $open-sans-family;

        &-container {
            position: relative;
            margin: 0 30px;
            border-bottom: 1px solid $medium-gray;
            padding: 40px 0;
            max-width: 786px;
            text-align: center;

            @include bp-medium {
                margin: 0 auto;
            }
        }

        &-button {
            @extend .button;
            margin-top: 20px;
        }
    }

    &__pagination {
        background: $white;

        &--empty {
            @include bp-small {
                margin: 0 10px;
            }
        }

        ul {
            padding-bottom: 35px;
        }

        .pagination--desktop {
            padding-top: 0;
        }
    }

    &__unit-list {
        display: none;
        text-align: left;
    }

    &__item {
        padding: 0 30px;
        text-align: center;

        @include bp-medium {
            margin: 0 auto;
            max-width: 786px;
            text-align: initial;
        }

        &-container {
            @extend %flexbox;
            @include flex-wrap(wrap);
            @include align-items(center);
            @include justify-content(space-between);
            position: relative;
            border-bottom: 1px solid $medium-gray;
            padding: 39px 0;
            text-align: left;
        }

        &-address {
            @include body-l;
            display: inline-block;
            width: 60%;
            color: $dark-gray;
            font-family: $lexend-family;
            font-weight: $lexend-extra-bold;
        }

        &-box {
            @extend %flexbox;
            @include align-items(center);
        }

        &-cta {
            @extend .button-link, .button-link--extra-low-emphasis;
            margin: 0;
            white-space: nowrap;

            body:not(.mouse-user):not(.is-oru) &:focus {
                @extend .coned-focus-outline;
            }

            body:not(.mouse-user) &:focus {
                @extend .oru-focus-outline;
            }          
        }

        &-icon {
            @include border-radius(50%);
            display: inline-block;
            position: relative;
            margin-left: 15px;
            background-color: $blue;
            cursor: pointer;
            padding: 5px;
            width: 10px;
            height: 10px;
            color: $white;

            &::before {
                position: absolute;
                top: 0;
                left: 0;
                font-size: calculate-rem(20);
            }
        }

        &--open {
            @include bp-enroll-units {
                padding: 0 calc((100% - 786px) / 2);
            }

            .currently-enrolled__table {
                @include transition(all, 0.2s, ease);
                height: auto;

                @include bp-medium {
                    padding: 0 15%;
                }
            }

            .currently-enrolled__item-container {
                border-width: 1px;
            }

            .currently-enrolled__item-icon {
                @include transform(rotate(180deg));
            }

            .currently-enrolled__unit-list {
                display: block;
            }
        }

        &--unenroll {
            background: $light-gray;
            padding: 0;
            max-width: none;
        }
    }

    &__table {
        @include transition(all, 0.2s, ease);
        height: 0;
        overflow: hidden;

        &-container {
            border-bottom: 1px solid $light-gray;
        }
    }
}
