// ============ MODIFIERS CHARTS =============

.c3 {
    &__zoomed-blocked {
        cursor: pointer !important;
    }

    &-axis {
        .tick {
            line {
                display: none;
            }

            text {
                font-family: $open-sans;
                font-size: calculate-rem(10);
                font-weight: $open-sans-bold;
                fill: $dark-gray;
            }
        }

        .domain {
            display: none;
        }
    }

    &-axis-y {
        .tick {
            text {
                fill: $medium-gray;
            }
        }
    }

    &-area {
        &-NonSummerdeterminantDay {
            opacity: 0;
            fill: $medium-gray !important;
        }

        &-PeakdeterminantDay,
        &-OffPeakdeterminantDay,
        &-unbilledOverageDeterminantDayE,
        &-unbilledOverageDeterminantDayF,
        &-billedOverageDeterminantDayF,
        &-determinantDay {
            fill: none !important;
        }
    }

    &-target-offpeakDemandDelivery,
    &-target-peakDemandDelivery {
        path {
            stroke: none !important;
        }
    }

    &-target-unBilledOverage {
        path {
            outline-offset: -7px;
            outline-width: 1px;
            outline-style: solid;
            outline-color: $red;
        }
    }

    &-target-coned-line-chart {
        .c3-lines {
            display: none;
        }

        .c3-circles {
            stroke: $dark-chart-green !important;
            stroke-width: 2px !important;
            fill: $white !important;

            ._expanded_ {
                fill: $light-green !important;
                stroke: $light-green !important;
                stroke-width: 1px !important;
            }
        }
    }

    &-target-offPeakDemand,
    &-target-coned-line-offPeakChart {
        .c3-lines {
            path {
                stroke: $dark-chart-green !important;
                stroke-width: 3px;
            }
        }
    }

    &-target-OffPeakdeterminantDay {
        .c3-circles {
            ._expanded_ {
                fill: $dark-chart-green !important;
                stroke: $dark-chart-green !important;
                stroke-width: 1px !important;
            }
        }
    }

    &-circles-PeakdeterminantDay {
        circle {
            stroke: $light-green;
            stroke-width: 3px;
            fill: $white !important;
        }

        ._expanded_ {
            fill: $light-green !important;
            stroke: $light-green !important;
            stroke-width: 1px !important;
        }
    }

    &-circles-NonSummerdeterminantDay {
        circle {
            stroke: $medium-gray;
            stroke-width: 3px;
            fill: $white !important;
        }

        ._expanded_ {
            fill: $medium-gray !important;
            stroke: $medium-gray !important;
            stroke-width: 1px !important;
        }
    }

    &-circles-OffPeakdeterminantDay {
        circle {
            stroke: $dark-chart-green;
            stroke-width: 3px;
            fill: $white !important;
        }
    }

    &-circles-actualDemand {
        .c3-circle {
            display: none !important;

            &._expanded_ {
                display: block !important;
            }
        }
    }

    &-circles-fixedSubscriptionRate {
        display: none;
    }

    &-circles-determinantDay {
        circle {
            stroke: $medium-gray;
            stroke-width: 3px;
            fill: $white !important;
        }

        ._expanded_ {
            fill: $medium-gray !important;
            stroke-width: 1px;
            stroke: $medium-gray;
        }
    }

    &-circles-unbilledOverageDeterminantDayE {
        circle {
            stroke: $sepia-black;
            stroke-width: 3px;
            fill: $white !important;
        }

        ._expanded_ {
            fill: $sepia-black !important;
            stroke-width: 1px;
            stroke: $sepia-black;
        }
    }

    &-circles-unbilledOverageDeterminantDayF {
        circle {
            stroke: $sepia-black;
            stroke-width: 3px;
            fill: $white !important;
        }

        ._expanded_ {
            fill: $sepia-black !important;
            stroke-width: 1px;
            stroke: $sepia-black;
        }
    }

    &-circles-billedOverageDeterminantDayF {
        circle {
            stroke: $fire-brick;
            stroke-width: 3px;
            fill: $white !important;
        }

        ._expanded_ {
            fill: $fire-brick !important;
            stroke-width: 1px;
            stroke: $fire-brick;
        }
    }

    &-line-fixedSubscriptionRate {
        stroke-dasharray: 5.5;
        stroke-width: 1px;
    }

    &-target-coned-line-offPeakChart {
        .c3-lines {
            path {
                stroke: $dark-chart-green !important;
                stroke-width: 2.5px !important;
            }
        }

        .c3-areas {
            path {
                opacity: 0.5 !important;
                fill: $shadow-dark-green !important;
            }
        }

        .c3-circle {
            stroke: $white;
            stroke-width: 1px;
            fill: $dark-chart-green !important;
        }
    }

    &-target-coned-line-nonSummerChart {
        .c3-lines {
            path {
                stroke: $medium-gray !important;
                stroke-width: 2.5px !important;
            }
        }

        .c3-areas {
            path {
                opacity: 0.5 !important;
                fill: $medium-gray !important;
            }
        }

        .c3-circle {
            stroke: $white;
            stroke-width: 1px;
            fill: $medium-gray !important;
        }
    }

    &-target-coned-line-eventChart {
        .c3-lines {
            path {
                stroke-width: 2.5px !important;
            }
        }
    }

    &-target-coned-line-peakChart {
        .c3-lines {
            path {
                stroke: $light-green !important;
                stroke-width: 2.5px !important;
            }
        }

        .c3-areas {
            path {
                fill: $shadow-light-green !important;
            }
        }
    }

    &-target-coned-line-chart-overage {
        .c3-lines {
            display: none;
        }

        .c3-circles {
            stroke: $red !important;
            stroke-width: 2px !important;

            circle {
                fill: $white !important;
            }

            ._expanded_ {
                fill: $red !important;
            }
        }
    }

    &-target-coned-line-chart-billed {
        .c3-lines {
            display: none;
        }

        .c3-circles {
            stroke: $red !important;
            stroke-width: 2px !important;
        }
    }

    &-target-nonSummerPeakValue {
        path {
            stroke: $medium-gray !important;
            stroke-width: initial;
        }
    }

    &-bars-offPeakValue,
    &-bars-peakValue,
    &-bars-nonSummerPeakValue,
    &-bars-averageDemandValue,
    &-bars-unbilledSubscriptionOverageValue,
    &-bars-billedSubscriptionOverageValue {
        ._expanded_ {
            fill-opacity: 1 !important;
        }
    }

    &-lines-dailyUsageSupply {
        path {
            stroke: $blue !important;
            stroke-width: 2px !important;
        }
    }

    &-line-PeakdeterminantDay,
    &-line-OffPeakdeterminantDay,
    &-line-NonSummerdeterminantDay,
    &-line-unbilledOverageDeterminantDayE,
    &-line-unbilledOverageDeterminantDayF,
    &-line-billedOverageDeterminantDayF,
    &-determinantDay {
        stroke: none !important;
    }

    &-grid {
        .c3-xgrid-focus {
            stroke-dasharray: 5.5;
        }
    }

    &-event-rect {
        cursor: default !important;
        stroke: $medium-gray;
        stroke-width: 1.1px;
    }

    &-ygrid-line {
        text {
            fill: $darker-gray;
        }

        line {
            stroke: $medium-gray;
            stroke-width: 0.5px;
        }
    }

    .text-line-overage {
        rect {
            stroke: $overage-red !important;
        }

        text {
            font-weight: $open-sans-bold;
            fill: $overage-red !important;
        }
    }
}

.c3-target.c3-defocused,
.c3-target.c3-focused {
    opacity: 1 !important;
}
