.pay-bill-notification {

    &__checkboxes {
        padding: 25px 0 10px;
        text-align: left;

        @include bp-medium {
            margin-left: 60px;
            text-align: center;
        }
    }
    
    &__text {
        @include body-m;
        padding: 20px;
        font-family: $open-sans-family;
        font-weight: $open-sans-semi-bold;
    }

    &__email_text {
        @include body-m;
        text-align: center;
        color: $dark-gray;
        font-family: $open-sans-family;
        font-weight: $open-sans-semi-bold;
        
    }

    &__buttons {
        @include bp-medium {
            @include flex-direction(row);
            text-align: center;
        }
    }

    &__button_confirm {
        @extend .button, %inline-flex;
    }

    &__button_notNow {
        @extend .button-link;
    }

    &__banner {
        margin-bottom: 10px;

        @include bp-medium {
            margin-bottom: 20px;
        }
    }
}
