// ============== FULL MEDIA WITH CONTENT COMPONENT =================

.full-media-with-content {
    @include bp-medium {
        position: relative;
    }

    &--video {
        position: relative;
        height: 100%;
    }

    &--video-no-background {
        .coned-video__player {
            background-color: transparent;

            .vjs-poster {
                background-color: transparent;
            }
        }
    }

    &__text-wrapper {
        display: flex;
        margin: 0 auto;
        width: 100%;
        max-width: 1400px;
        height: 100%;
        align-items: center;

        @include bp-medium {
            position: absolute;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
        }

        &--right {
            @include bp-medium {
                justify-content: flex-end;
            }
        }
    }

    &__text-container {
        background-color: $white;

        @include bp-medium {
            width: 50%;
            max-height: 385px;
        }

        @include bp-large {
            display: flex;
            max-height: 520px;
            align-items: center;
        }

        @include bp-large-landing-hero {
            max-height: 580px;
        }

        /* Portrait */
        @include bp-portrait {
            max-height: 385px;
        }

        p {
            margin-top: 10px;

            @include bp-medium {
                margin-top: 30px;
            }

            &:first-child {
                margin-top: 0;
            }
        }
    }

    &__image {
        width: 100%;

        @include bp-medium {
            min-height: 520px;
        }
    }

    .vjs-big-play-button {
        top: 50% !important;
        left: 50% !important;
        transform: translate(-50%, -50%);
        border: 2px solid $white;
        border-radius: 50px;
        background-color: $transparent;
        width: 90px !important;
        height: 90px !important;

        &::before {
            margin: 27px auto;
            width: 40px !important;
            font-size: 50px;
        }
    }
}

.full-media-with-content__media-container {
    @include bp-medium {
        position: relative;
        height: 100%;
    }

    &--video-with-text {
        @include bp-medium {
            position: relative;
            padding-top: calc(1600 / 2840 * 100%);

            picture {
                display: none;
            }
        }

        .coned-video__player {
            display: none;

            @include bp-medium {
                display: inline-block;
                pointer-events: none;
            }

            .vjs-control-bar,
            .vjs-big-play-button,
            .vjs-loading-spinner {
                display: none !important;
            }
        }
    }

    &--only-video {
        position: relative;
        padding-top: calc(1600 / 2840 * 100%);

        .full-media-with-content__image {
            display: none;
        }
    }
}
