.comparison-table {
    width: 100%;
    table-layout: fixed;

    &__wrapper {
        margin-bottom: 40px;
        background-color: $white;

        &--no-margin-bottom {
            margin-bottom: 0;
        }

        &--margin-top-40 {
            margin-top: 40px;
        }
    }

    &__content {
        padding: 30px 20px;

        @include bp-medium {
            margin-left: 21.5%;
            padding-right: 0;
            padding-left: 0;
            width: 50%;
        }
    }

    &__caption {
        @include body-l;
        border-bottom: 3px solid $orange;
        padding: 16px 30px 16px 0;
        text-align: left;
        color: $dark-gray;
        font-family: $lexend-family;
        font-weight: $lexend-extra-bold;

        body:not(.is-oru) & {
            border-bottom: 3px solid $blue;
        }
    }

    &__description {
        @include body-m;
        padding-top: 16px;
        padding-right: 30px;
        color: $dark-gray;
        font-family: $open-sans-family;
        word-wrap: break-word;
    }

    &__title {
        @include body-m;
        text-transform: capitalize;
        color: $dark-gray;
        font-family: $open-sans-family;
        font-weight: $open-sans-bold;
        word-wrap: break-word;
    }

    &__row {
        &--sticky {
            position: sticky;
            top: 61px;
            z-index: 1;

            @include bp-medium {
                top: 121px;                
            }
        }
    }

    &__cell {
        margin-bottom: 0;
        border-bottom: 1px solid $medium-gray;
        padding-top: 20px;
        padding-bottom: 20px;
        vertical-align: top;
        text-align: center;

        @include bp-medium {
            padding-right: 20px;
            padding-left: 20px;
        }

        &:nth-child(odd) {
            @include bp-small {
                padding-right: 10px;
            }
        }

        &:nth-child(even) {
            @include bp-small {
                padding-left: 10px;
            }
        }

        &--left-align {
            text-align: start;

            @include bp-medium {
                padding-right: 40px;
                padding-left: 0;
            }
        }

        &--no-border-bottom {
            border-bottom: 0;
        }

        &--no-padding-top {
            padding-top: 0;
        }

        a {
            @extend %focus-outline-on-element;
        }
    }

    &__text {
        @include body-s;
        font-family: $open-sans-family;
        word-wrap: break-word;

        &:not(a) {
            color: $dark-gray;
        }

        &--label {
            @include body-m;
            font-weight: $open-sans-semi-bold;
        }

        &--large {
            @include heading-m;
            font-family: $lexend-family;
            font-weight: $lexend-regular;
        }
    }

    &__image {
        max-width: 100%;
        
        &-container {
            @include aspect-ratio(16, 9, 'comparison-table__image');
            overflow: hidden;
        }
    }
}
