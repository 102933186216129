// ============== BREAKPOINTS =================

@mixin bp-extra-large {
    @media only screen and (min-width: $min-width-extra-large) {
        @content;
    }
}

@mixin bp-lt-extra-large {
    @media only screen and (max-width: $max-width-extra-large) {
        @content;
    }
}

@mixin bp-large {
    @media only screen and (min-width: $min-width-large) {
        @content;
    }
}

@mixin bp-large-only {
    @media only screen and (max-width: $max-width-extra-large) and (min-width: $min-width-medium) {
        @content;
    }
}

@mixin bp-lt-large {
    @media only screen and (max-width: $max-width-medium) {
        @content;
    }
}

@mixin bp-medium {
    @media only screen and (min-width: $min-width-medium) {
        @content;
    }
}

@mixin bp-small {
    @media only screen and (max-width: $max-width-small) {
        @content;
    }
}

@mixin bp-smaller {
    @media only screen and (max-width: $max-width-smaller) {
        @content;
    }
}

@mixin bp-min-smaller {
    @media only screen and (min-width: $max-width-smaller) {
        @content;
    }
}

@mixin bp-small-devices {
    @media only screen and (max-width: $max-width-small-devices) {
        @content;
    }
}

@mixin bp-smallest {
    @media only screen and (max-width: $max-width-smallest) {
        @content;
    }
}

// COMPONENT BREAKPOINTS
@mixin bp-max-page-hero-small {
    @media only screen and (max-width: $min-width-page-hero-small) {
        @content;
    }
}

@mixin bp-billing {
    @media only screen and (min-width: $min-width-billing) {
        @content;
    }
}

@mixin bp-billing-medium {
    @media only screen and (min-width: $min-width-billing-tabs) {
        @content;
    }
}

@mixin bp-bill-card {
    @media only screen and (min-width: $min-width-bill-card) {
        @content;
    }
}

@mixin bp-bill-card-alternative {
    @media only screen and (min-width: $min-width-bill-card-alternative) {
        @content;
    }
}

@mixin bp-page-hero-small {
    @media only screen and (min-width: $min-width-page-hero-small) {
        @content;
    }
}

@mixin bp-hero-medium {
    @media only screen and (min-width: $min-width-page-hero-medium) {
        @content;
    }
}

@mixin bp-hero-large {
    @media only screen and (min-width: $min-width-page-hero-large) {
        @content;
    }
}

@mixin bp-hero-extra-large {
    @media only screen and (min-width: $min-width-page-hero-extra-large) {
        @content;
    }
}

@mixin bp-primary-nav-small {
    @media only screen and (min-width: $min-width-primary-nav) {
        @content;
    }
}

@mixin bp-tips-filters {
    @media only screen and (min-width: $min-width-tips-filters) {
        @content;
    }
}

@mixin bp-mid-small {
    @media only screen and (min-width: $min-width-mid-small) {
        @content;
    }
}

@mixin bp-coned-hero {
    @media only screen and (min-width: $min-width-coned-hero) {
        @content;
    }
}

@mixin bp-coned-images {
    @media only screen and (min-width: $min-width-coned-images) {
        @content;
    }
}

@mixin bp-green-data {
    @media only screen and (min-width: $min-width-green-data) {
        @content;
    }
}

@mixin bp-coned-title {
    @media only screen and (min-width: $min-width-coned-title) {
        @content;
    }
}

@mixin bp-large-header {
    @media only screen and (min-width: $min-width-large-header) {
        @content;
    }
}

@mixin bp-in-page-banner {
    @media only screen and (min-width: $min-width-in-page-banner) {
        @content;
    }
}

@mixin bp-dashboard-header {
    @media only screen and (min-width: $min-width-dashboard-header) {
        @content;
    }
}

@mixin bp-hero {
    @media (min-width: $min-width-hero) {
        @content;
    }
}

@mixin bp-extra-large-header {
    @media only screen and (min-width: $min-width-extra-large-header) {
        @content;
    }
}

@mixin bp-page-hero-large {
    @media only screen and (min-width: $content-max-width) {
        @content;
    }
}

@mixin bp-article-header {
    @media only screen and (min-width: $max-width-card-tiles) {
        @content;
    }
}

@mixin bp-min-smallerer {
    @media only screen and (min-width: $max-width-smallerer) {
        @content;
    }
}

@mixin bp-mob-alerts {
    @media only screen and (min-width: $min-width-mob-alerts) {
        @content;
    }
}

@mixin bp-cards {
    @media only screen and (min-width: $min-width-cards) {
        @content;
    }
}

@mixin bp-opower-widget {
    @media only screen and (min-width: $min-width-opower-widget) {
        @content;
    }
}

@mixin bp-contact-us-hero-large {
    @media only screen and (max-width: $min-width-extra-large-header) {
        @content;
    }
}

@mixin bp-smallerer {
    @media only screen and (max-width: $max-width-smallerer) {
        @content;
    }
}

@mixin bp-extra-small {
    @media only screen and (max-width: $max-width-extra-small) {
        @content;
    }
}

@mixin bp-contact-us-hero-mid {
    @media only screen and (max-width: $max-width-contact-us-hero-mid) {
        @content;
    }
}

@mixin bp-article-actions {
    @media only screen and (max-width: $max-width-article-actions) {
        @content;
    }
}

@mixin bp-contact-us {
    @media only screen and (max-width: $max-width-contact-us) and (min-width: $max-width-smaller) {
        @content;
    }
}

@mixin bp-contact-us-small-card-link {
    @media only screen and (max-width: $max-width-small) and (min-width: $max-width-smaller) {
        @content;
    }
}

@mixin bp-contact-us-medium-card-link {
    @media only screen and (max-width: $min-width-cards) and (min-width: $max-width-small) {
        @content;
    }
}

@mixin bp-page-cards-tile {
    @media only screen and (max-width: $max-width-card-tiles) and (min-width: $min-width-medium) {
        @content;
    }
}

@mixin bp-faceted-search {
    @media only screen and (max-width: $max-width-faceted-search) and (min-width: $min-width-faceted-search) {
        @content;
    }
}

@mixin bp-date-picker {
    @media only screen and (min-width: $content-max-width) {
        @content;
    }
}

@mixin bp-nav-icons {
    @media only screen and (max-width: $max-width-nav-icons) and (min-width: $min-width-nav-icons) {
        @content;
    }
}

@mixin bp-form-progres-bar {
    @media only screen and (max-width: $max-width-from-progress-bar) {
        @content;
    }
}

@mixin bp-overview-notifications {
    @media only screen and (max-width: $min-width-small) {
        @content;
    }
}

@mixin bp-payment-agreement {
    @media only screen and (max-width: $max-width-payment-agreement) {
        @content;
    }
}

@mixin bp-login-modal {
    @media only screen and (max-width: $max-width-login-modal) and (min-width: $min-width-medium) {
        @content;
    }
}

@mixin bp-popup-small {
    @media only screen and (max-width: $min-width-mob-alerts) {
        @content;
    }
}

@mixin bp-report-expand {
    @media only screen and (max-width: $max-width-report-expand) and (min-width: $min-width-report-expand) {
        @content;
    }
}

@mixin bp-table-tabs-nav-by {
    @media only screen and (min-width: $min-width-table-tabs-by-nav) {
        @content;
    }
}

@mixin bp-enroll-units {
    @media only screen and (min-width: $min-width-enroll-units) {
        @content;
    }
}

@mixin bp-start-service-profile {
    @media only screen and (min-width: $min-width-start-service-profile) {
        @content;
    }
}

@mixin bp-large-landing-hero {
    @media only screen and (min-width: $min-width-landing-hero-xl) {
        @content;
    }
}

@mixin bp-large-homepage-hero {
    @media only screen and (min-width: $min-width-homepage-hero-xl) {
        @content;
    }
}

@mixin bp-portrait {
    @media only screen and (min-device-width: $min-width-medium) /*768*/ and (max-device-width: $min-width-extra-large) /*1024*/ and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {
        @content;
    }
}

@mixin bp-portrait-ipad-pro {
    @media only screen and (min-device-width: $min-width-extra-large) /*1024*/ and (max-device-width: $max-width-large) /*1366*/ and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5) {
        @content;
    }
}

@mixin bp-progress-bar-large {
    @media only screen and (min-width: $min-width-progress-bar-large) {
        @content;
    }
}

@mixin bp-unsupported-browser-banner {
    @media only screen and (min-width: $min-width-unsupported-browser-banner) {
        @content;
    }
}

@mixin bp-graphics-mobile {
    @media only screen and (min-width: $min-width-graphics-mobile) {
        @content;
    }
}

@mixin bp-graphics-desktop {
    @media only screen and (min-width: $min-width-graphics-desktop) {
        @content;
    }
}

@mixin bp-graphics-desktop-large {
    @media only screen and (min-width: $min-width-graphics-desktop-large) {
        @content;
    }
}

@mixin bp-graphics-desktop-extra-large {
    @media only screen and (min-width: $min-width-graphics-desktop-extra-large) {
        @content;
    }
}
