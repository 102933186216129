// ============== SUCCESS BANNER COMPONENT =================

.success-banner {
    padding: 60px 20px;
    text-align: center;
    color: $dark-gray;

    @include bp-medium {
        margin: 0 auto;
        padding: 70px 0 80px;
        width: 50%;
    }

    &--extra-width {
        @include bp-medium {
            width: 70%;
        }
    }

    &-wrapper {
        margin: 0 10px 20px;
        background-color: $white;

        @include bp-medium {
            margin: 35px auto;
        }

        &--margin-top {
            margin-top: 10px;

            @include bp-medium {
                margin: 35px auto;
            }
        }

        &--margin-bottom {
            margin-bottom: 10px;

            @include bp-medium {
                margin: 35px auto;
            }
        }
    }

    &__picture {
        &-image {
            width: 125px;

            &--auto-width {
                width: auto;
                max-width: 100%;
            }
        }
    }

    &__title {
        @include heading-l;
        padding: 40px 0 10px;
        font-family: $lexend-family;
        font-weight: $lexend-extra-bold;
        word-wrap: break-word;

        &--medium {
            @include heading-m;
        }
    }

    &__text {
        @include body-m;
        font-family: $open-sans-family;

        &--margin {
            margin: 50px 0;
        }

        &--post-payment {
            @include body-l;
            padding: 15px 0 0 10px;
        }

        &--more-padding-bottom {
            padding-bottom: 15px;
        }

        &--emphasized {
            @include body-l;
            font-family: $lexend-family;
            font-weight: $lexend-regular;
        }

        a {
            body:not(.mouse-user):not(.is-oru) &:focus {
                @extend .coned-focus-outline;
            }

            body:not(.mouse-user) &:focus {
                @extend .oru-focus-outline;
            }

            &::before {
                content: none !important;
            }
        }
    }

    &__span {
        display: block;

        &--ellipsis {
            @extend %text-truncate;
            width: 100%;
        }

        &--visible {
            white-space: normal;
        }
    }

    &__alert {
        @include body-m;
        margin-top: 20px;
        color: $red;
        font-family: $open-sans-family;
    }

    &--report-outage {
        padding: 60px 20px 48px;
        width: 90%;

        @include bp-medium {
            padding: 70px 0 48px;
            width: 60%;
        }
    }

    &__link {
        @extend .button-link;
        display: block;
        margin-top: 30px;

        &--inline {
            display: inline;
            margin-top: 0;
        }
    }

    &__button {
        @extend .button, .button--high-emphasis;
        margin-top: 30px;
        width: 100%;
        max-width: 440px;

        @include bp-medium {
            width: auto;
        }

        &-icon {
            font-size: calculate-rem(30);
        }

        &--secondary-color {
            @extend .button--secondary-color;
        }
    }

    &--notification {
        margin-bottom: 40px;

        a:not(.success-banner__button) {
            @extend .button-link, .button-link--extra-low-emphasis;
        }
    }

    &--register {
        margin-top: 30px;
    }

    &-batch-process {
        padding-top: 30px;
    }
}

.success-banner-notification {
    @extend %flexbox;
    @include align-items(center);
    @include justify-content(space-between);
    @include flex-direction(column);
    position: relative;
    margin-bottom: 15px;
    padding: 16px 0;
    width: 100%;
    overflow: hidden;

    @include bp-medium {
        @include flex-direction(row);
        margin-bottom: 20px;
    }

    &--red {
        background-color: $red;
    }

    &__block {
        @extend %flexbox;
        @include align-items(flex-start);
        @include justify-content(center);
        @include box-sizing(border-box);
        margin: auto;
        padding: 0;
        width: 90%;
        text-align: center;

        @include bp-medium {
            width: 60%;
        }
    }

    &__description {
        letter-spacing: 0.1px;
        color: $white;
        font: $open-sans-bold calculate-rem(13) / calculate-rem(16) $open-sans-family;

        @include bp-medium {
            font: $open-sans-bold calculate-rem(14) / calculate-rem(22) $open-sans-family;
        }
    }
}
