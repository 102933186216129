// ============== PAGE LOADING ANIMATION COMPONENT =================

.loading-page-animation {
    padding: 52px 42px;

    @include bp-medium {
        margin: 0 auto;
        padding: 45px 0 78px;
        width: 90%;
        max-width: 786px;
    }

    &__wrapper {
        margin: 10px 10px 20px;
        background-color: $white;

        @include bp-medium {
            margin: 25px auto 50px;
        }
    }

    &__icon-container {
        text-align: center;
    }

    &__icon {
        width: 105px;
    }

    &__text {
        margin: 0 auto;
        max-width: 495px;
        text-align: center;
    }

    &__title {
        @include heading-l;
        padding: 28px 0 24px;
        letter-spacing: 0.5px;
        color: $dark-gray;
        font-family: $lexend-family;
        font-weight: $lexend-extra-bold;
    }

    &__description {
        @include body-m;
        color: $dark-gray;
        font-family: $open-sans-family;
    }

    &__button {
        visibility: hidden;
    }

    &__overlay {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 100000;
        width: 100vw;
        height: 100vh;
        pointer-events: visible;
    }
}
