// ============ GUTTER ===============
/*
    % placeholder added to page and content gutter in order to
    be able to extend this classes without any lint warnings
*/

.page-gutter,
.content-gutter,
%page-gutter,
%content-gutter {
    @include bp-medium {
        margin: 0 auto;
    }
}

.page-gutter,
%page-gutter {
    @include bp-medium {
        max-width: $page-max-width;
    }
}

.content-gutter,
%content-gutter {
    @include bp-medium {
        max-width: $content-max-width;
    }
}
