// ============== ENROLL COMMERCIAL TRUMPS =================

.enroll-commercial {
    .pagination {
        &--desktop {
            padding: 43px 0;
        }

        &--mobile {
            padding: 30px 0;
        }
    }
}
