// ============== BREADCRUMBS COMPONENT =================

.breadcrumbs {
    @extend %flexbox;
    @include flex-wrap(wrap);
    padding: 7px 30px;

    @include bp-medium {
        padding: 5px 30px;
    }

    &-wrapper {
        border-bottom: solid 1px $medium-gray;
        background-color: $white;
    }

    &__item {
        @extend %flexbox;
        @include align-items(center);
        @include body-xs;
        max-width: 100%;
        font-family: $open-sans-family;
        font-weight: $open-sans-semi-bold;

        &-link,
        &-text {
            @extend %text-truncate;
        }

        &-link {
            @include flexbox;
            @include align-items(center);

            body:not(.mouse-user):not(.is-oru) &:focus {
                @extend .coned-focus-outline;
            }

            body:not(.mouse-user) &:focus {
                @extend .oru-focus-outline;
            }
        }

        &-text {
            color: $medium-gray;
        }

        &-icon {
            display: none;

            &:first-child {
                @include transform(rotate(90deg));
                display: inline-block;
                font-size: calculate-rem(25);

                @include bp-small {
                    margin-left: -5px;
                }
            }
        }
    }

    &__item:not(:last-child) {
        &::after {
            padding: 0 5px;
            color: $medium-gray;
            content: '/';
        }
    }
}
