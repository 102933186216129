.customer-messaging {
    &__scroll-section {
        height: 100%;
    }

    &__container {
        @include align-self(center);
        @include box-sizing(border-box);
        position: relative;
        background-color: $white;
        padding: 0 32px 50px;
        width: 100vw;
        height: auto;

        @include bp-small {
            @include flexbox;
            @include flex-direction(column);
            min-height: 100vh;
        }

        @include bp-medium {
            margin: 5% auto;
            padding: 80px 18.4% 34px;
            max-width: 1108px;
            height: auto;
        }

        &--multiple-messages {
            padding-bottom: 40px;

            @include bp-medium {
                padding: 78px 40px 34px;
                height: auto;
            }
        }
    }

    &__nav-mobile {
        @include flexbox;
        @include justify-content(flex-end);
        @include box-shadow(0, 0, 12px, -2px, rgba(0, 90, 163, 0.26));
        position: sticky;
        top: 0;
        left: 0;
        z-index: 1;
        margin-right: -32px;
        margin-bottom: 35px;
        margin-left: -32px;
        background-color: $white;
        padding-left: 32px;
        width: calc(100% + 32px);
        min-height: 69px;

        @include bp-medium {
            display: none;
        }

        &--multiple-messages {
            @include justify-content(space-between);
            box-shadow: none;
        }

        &--shadow {
            @include box-shadow(0, 0, 12px, -2px, rgba(0, 90, 163, 0.26));
        }
    }

    &__close-cta-mobile {
        position: relative;
        margin-top: 4px;
        margin-right: 4px;
        margin-left: auto;
        background-color: transparent;
        width: 60px;
        height: 60px;
        color: $teal;
        font-size: 6rem;

        body:not(.is-oru) & {
            color: $blue;
        }

        &:focus {
            outline: 0;

            body:not(.mouse-user):not(.is-oru) &::after {
                @extend .coned-button-focus;
            }

            body:not(.mouse-user) &::after {
                @extend .oru-button-focus;
            }
        }
    }

    &__close-cta-desktop {
        @extend .button-link, .button-link--extra-low-emphasis;
        display: none;
        position: absolute;
        top: 40px;
        right: 50px;

        @include bp-medium {
            display: inline-block;
        }

        &:not(.no-custom-outline):focus {
            @include bp-medium {
                position: absolute;
            }
        }

        &:focus {
            outline: 0;

            body:not(.mouse-user):not(.is-oru) &::after {
                @extend .coned-button-focus;
            }

            body:not(.mouse-user) &::after {
                @extend .oru-button-focus;
            }
        }
    }

    &__title-container {
        @include flexbox;
        @include align-items(center);
        margin-bottom: 20px;
        border-bottom: 1px solid $pre-medium-gray;
        padding-bottom: 20px;

        @include bp-medium {
            padding-bottom: 16px;
        }

        &--multiple-messages {
            margin-bottom: 0;
            border-bottom: 0;

            @include bp-small {
                padding-bottom: 20px;
            }
        }
    }

    &__title-headings {
        @include flex-grow(1);
    }

    &__title {
        @include heading-s;
        color: $dark-gray;
        font-family: $lexend-family;
        font-weight: $lexend-regular;
    }

    &__content {
        @include body-l;
        position: relative;
        color: $dark-gray;
        font-family: $open-sans-family;

        &--multiple-messages {
            @include flexbox;

            @include bp-medium {
                border-bottom: 1px solid $medium-gray-93;
            }
        }
    }

    &__list {
        margin-bottom: 20px;
        border-top: 1px solid $medium-gray;
        border-bottom: 1px solid $medium-gray;

        &-container {
            @include flex-basis(100%);

            @include bp-medium {
                @include flex(1 1 0);
                border-right: 1px solid $medium-gray-93;
                padding-right: 20px;
            }
        }

        &-message {
            @include body-l;
            position: relative;
            padding: 11px 68px 11px 26px;
            color: $dark-gray;
            font-family: $lexend-family;
            font-weight: $lexend-regular;

            @include bp-medium {
                padding: 16px 26px;
            }

            &:not(:last-child) {
                border-bottom: 1px solid $medium-gray;
            }
        }

        &-message:hover {
            cursor: pointer;

            &::before {
                position: absolute;
                top: 0;
                left: 0;
                z-index: 1;
                box-shadow: 0 4px 2px 0 $black-transparent-15-percent;
                width: 100%;
                height: 100%;
                content: '';
            }
        }
        
        &-message:focus {
            outline: 0;

            body:not(.mouse-user):not(.is-oru) &::after {
                @extend .coned-button-focus;
            }

            body:not(.mouse-user) &::after {
                @extend .oru-button-focus;
            }
        }

        &-message--unread {
            position: relative;
            font-family: $lexend-family;
            font-weight: $lexend-extra-bold;
            
            p::before {
                @include border-radius(50%);
                @include transform(translate(0, -50%));
                display: block;
                position: absolute;
                top: 50%;
                left: 0;
                background-color: $teal;
                width: 14px;
                height: 14px;
                content: '';

                @include bp-medium {
                    left: 6px;
                    width: 10px;
                    height: 10px;    
                }

                // scss-lint:disable NestingDepth
                body:not(.is-oru) & {
                    background-color: $blue;
                }
                // scss-lint:enable NestingDepth
            }
        }

        &-message--active {
            background-color: $light-gray;
        }
    }

    &__caret {
        @include transform(translate(0, -50%) rotate(-90deg));
        position: absolute;
        top: 50%;
        right: 6px;
        color: $medium-gray;
        font-size: 3.5rem;

        @include bp-medium {
            display: none;
        }
    }

    &__message-container {
        @include flex(1 1 0);
        display: none;
        
        @include bp-medium {
            display: block;
            padding-left: 20px;
        }

        &--active {
            display: block;
        }
    }

    &__message-title {
        @include heading-m;
        margin-bottom: 28px;
        font-family: $lexend-family;
        font-weight: $lexend-extra-bold;

        @include bp-medium {
            margin-bottom: 16px;
        }
    }
    
    &__account-container {
        margin-bottom: 28px;

        @include bp-medium {
            margin-bottom: 16px;
        }
    }

    &__account-message {
        @include body-l;
        color: $black;
        font-family: $open-sans-family;
        font-weight: $open-sans-semi-bold;
    }

    &__account-number {
        @extend %focus-outline-on-element;
        @include body-l;
        display: inline-block;
        color: $medium-gray;
        font-family: $open-sans-family;
    }

    &__show-more,
    &__back-button {
        @extend .button-link, .button-link--extra-low-emphasis;
        display: block;

        &:focus {
            outline: 0;

            body:not(.mouse-user):not(.is-oru) &::after {
                @extend .coned-button-focus;
            }

            body:not(.mouse-user) &::after {
                @extend .oru-button-focus;
            }
        }
    }

    &__back-button {
        @include transform(translate(0, -50%));
        top: 50%;
    }

    &__paragraphs-container {
        padding-bottom: 22px;

        @include bp-medium {
            padding-bottom: 32px;
        }

        p {
            @include body-l;
            margin-bottom: 28px;
            color: $black;
            font-family: $open-sans-family;

            a {
                @extend %focus-outline-on-element;
            }
        }
    }

    &__footer {
        @include bp-small {
            margin-top: auto;
        }
        
        &--multiple-messages {
            @include flexbox;
            @include flex-direction(column);
            @include justify-content(space-between);
            @include align-items(center);
            padding-top: 20px;
    
            @include bp-medium {
                @include flex-direction(row);
            }
        }
    }
    

    &__light-paragraphs-container {
        p {
            @include body-m;
            margin-bottom: 15px;
            text-align: center;
            color: $medium-gray;
            font-family: $open-sans-family;

            a {
                @extend %focus-outline-on-element;
            }
        }

        &--multiple-messages {
            @include bp-medium {
                padding-right: 26px;
            }

            p {
                color: $dark-gray;

                @include bp-medium {
                    margin-bottom: 0;
                    text-align: left;
                }
            }
        }
    }

    &__buttons-container {
        @include flexbox;
        @include justify-content(center);
        width: 100%;

        &--multiple-messages {
            
            @include bp-medium {
                display: block;
                flex: 0 1 301px;
            }
        }
    }

    &__button {
        @extend .button;
        width: 100%;
        max-width: 301px;

        @include bp-medium {
            max-width: 348px;
        }

        &--multiple-messages {
            @include bp-medium {
                max-width: 301px;
            }
        }
    }
}
