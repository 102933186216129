// ============== OVERVIEW BILL ANALYSIS COMPONENT =================

.overview-bill-analysis {
    position: relative;
    margin: 15px 10px;
    background-color: $white;
    padding: 30px 20px 110px;
    overflow: hidden;

    @include bp-medium {
        @include box-sizing(border-box);
        margin: 40px auto;
        padding: 60px;
    }

    &__header {
        @extend %flexbox;
        @include flex-direction(column);

        @include bp-medium {
            @include flex-direction(row);
            @include justify-content(space-between);
            @include align-items(flex-end);
        }
    }

    &__title {
        @include heading-l;
        color: $dark-gray;
        font-family: $lexend-family;
        font-weight: $lexend-extra-bold;

        @include bp-medium {
            padding-top: 0;
        }
    }

    &__link {
        @extend .button-link, .button-link--extra-low-emphasis;
        margin-top: 20px;

        &--initial {
            margin-top: 0;

            @include bp-small {
                margin-top: 20px;
            }
        }

        &-icon {
            position: relative;
            padding-right: 16px;

            &::before {
                position: absolute;
                top: -9px;
                left: -12px;
                font-size: calculate-rem(35);
            }
        }
    }

    &__nav {
        @include flexbox;

        @include flex-direction(row);
        margin-top: 25px;

        @include bp-medium {
            margin-top: 15px;
            margin-left: 30px;
            width: 50%;
        }

        @include bp-cards {
            @include flex-wrap(nowrap);
        }

        &-description {
            margin-right: 5px;
            margin-bottom: 3px;
            width: 100%;
            color: $dark-gray;
            font: calculate-rem(14) $open-sans-family;

            @include bp-cards {
                width: auto;
            }
        }

        &-separator {
            margin: 0 8px;
            background-color: $dark-gray;
            width: 3px;
            color: $dark-gray;
            font: calculate-rem(14) $open-sans-family;
            font-weight: $open-sans-extra-bold;

            @include bp-medium {
                width: 2px;
            }
        }

        &-item {
            @include button-s;
            position: relative;
            background-color: $white;
            font-family: $open-sans-family;
            font-weight: $open-sans-extra-bold;

            &,
            .is-oru & {
                text-decoration: none;
                color: $medium-gray;
            }

            &:last-child {
                padding-right: 0;
            }

            &:last-child::after {
                display: none;
            }

            &:hover {
                border-bottom: 3px solid $medium-gray;
            }
        }

        &-item--active {
            border-bottom: 3px solid $blue;
            color: $dark-gray;

            &:hover {
                border-color: $blue;
            }
        }
    }

    &__initial {
        margin-top: 30px;
        border-top: 3px solid $light-gray;
        border-bottom: 1px solid $light-gray;
        background-color: $wild-sand;
        padding: 40px 20px;

        &-description {
            @include flexbox;
            @include align-items(center);
            color: $dark-gray;
            font: calculate-rem(18) / calculate-rem(24) $open-sans-family;
            font-weight: $open-sans-regular;
        }

        &-icon {
            margin-right: 20px;
            color: $silver;
            font-size: calculate-rem(40);
        }
    }

    &__information {
        @include flex-direction(column);
        @include justify-content(space-around);
        display: none;
        margin-top: 50px;

        @include bp-medium {
            @include flex-direction(row);
            margin-top: 90px;
        }

        &--active {
            @include flexbox;
        }

        &--column-direction {
            @include flex-direction(column);
        }
    }

    &__level-payment {
        &-title {
            @include heading-s;
            font-family: $lexend-family;
            font-weight: $lexend-extra-bold;
        }

        &-paragraph {
            @include body-m;
            margin-top: 25px;
            font-family: $open-sans-family;

            &--introduction {
                @include body-s;
                margin-top: 50px;
                font-family: $open-sans-family;
                font-weight: $open-sans-extra-bold;
            }
        }

        &-list-container {
            @include flexbox;
            @include flex-direction(column);
            margin-top: 5px;
            text-align: center;

            @include bp-medium {
                @include flex-direction(row);
            }
        }

        &-list-element {
            @include flex-grow(1);
            border: 1px solid $light-gray;
            border-bottom: 0;
            padding: 35px 0 22px;

            @include bp-medium {
                border-right: 0;
                border-bottom: 1px solid $light-gray;
                max-width: 30%;

                &:last-child {
                    border-right: 1px solid $light-gray;
                }
            }

            &:last-child {
                border-bottom: 1px solid $light-gray;
            }
        }

        &-list-element-amount {
            @include heading-l;
            font-family: $lexend-family;
            font-weight: $lexend-extra-bold;

            &--positive-balance {
                color: $green;
            }

            &--negative-balance {
                color: $red;
            }
        }

        &-list-element-description {
            @include body-xs;
            margin-top: 20px;
            color: $medium-gray;
            font-family: $open-sans-family;
            font-weight: $open-sans-bold;

            &-paragraph {
                display: inline;
            }

            .coned-tooltip__text {
                color: $medium-gray;
            }
        }
    }

    &__progress-plan {
        @include flexbox;
        margin-top: 20px;

        &-element {
            @include flexbox;
            @include flex-direction(column);
            @include align-items(center);
            width: 100%;

            &:first-child .overview-bill-analysis__progress-plan-bar {
                border-left: 1px solid $dark-gray;
                border-radius: 50px 0 0 50px;
            }

            &:last-child .overview-bill-analysis__progress-plan-bar {
                border-right: 1px solid $dark-gray;
                border-radius: 0 50px 50px 0;
            }
        }

        &-bar {
            @include flexbox;
            @include justify-content(center);
            @include align-items(center);
            margin-bottom: 18px;
            border: 1px $dark-gray;
            border-style: solid none;
            width: 100%;
            height: 30px;

            &--progressed {
                border-right: 1px solid $green;
                background-color: $green;
            }

            &--selected {
                @include linear-gradient-degrees(91deg, $green, 50%, $white, 50%);
            }
        }

        &-paragraph {
            @include body-xs;
            font-family: $open-sans-family;
            font-weight: $open-sans-extra-bold;
        }
    }

    &__column {
        margin-top: 10px;
        width: 100%;

        @include bp-medium {
            border-bottom: 1px solid $medium-gray;
            width: 50%;

            &:last-child {
                margin-left: 70px;
            }
        }

        &--text {
            border: 0;
        }

        &-item {
            @include flexbox;
            @include flex-direction(column);
            border-bottom: 1px solid $medium-gray;
            padding: 50px 0;

            &:last-child {
                @include bp-medium {
                    border: 0;
                }
            }

            &--changes {
                @include flex-direction(row);
                padding: 40px 0;
            }
        }
    }

    &__information-title {
        @include heading-s;
        border-bottom: 3px solid $medium-gray;
        padding-bottom: 10px;
        width: 100%;
        color: $dark-gray;
        font-family: $lexend-family;
        font-weight: $lexend-extra-bold;
    }

    &__note {
        @include body-s;
        margin-top: 40px;
        font-family: $open-sans-family;
        font-weight: $open-sans-semi-bold;
    }
}

.analysis-column {
    &__service-header {
        @include flexbox();
        @include align-items(center);
    }

    &__service-title {
        @include body-l;
        margin: 0 10px;
        color: $medium-gray;
        font-family: $lexend-family;
        font-weight: $lexend-extra-bold;
    }

    &__service-detail {
        @include body-s;
        color: $medium-gray;
        font-family: $open-sans-family;
        font-weight: $open-sans-semi-bold;
    }

    &__service-icon {
        @include flexbox();
        @include align-items(center);
        @include justify-content(center);
        @include border-radius(50%);
        width: 23px;
        height: 23px;
        color: $white;
        font-size: calculate-rem(35);

        &--up {
            @include transform(rotate(-90deg));
            background-color: $red;
        }

        &--down {
            @include transform(rotate(90deg));
            background-color: $green;
        }

        &--check {
            position: absolute;
            z-index: 10;
            border: 2px solid $green;
            background-color: $white;
            width: 31px;
            height: 31px;
            color: $green;

            @include bp-medium {
                position: relative;
            }
        }
    }

    &__service-description {
        @include body-l;
        display: block;
        margin-top: 10px;
        font-family: $open-sans-family;

        span {
            font-weight: $open-sans-bold;
        }

        &--up {
            span {
                color: $red;
                font-family: $lexend-family;
                font-weight: $lexend-extra-bold;
            }
        }

        &--down {
            span {
                color: $green;
                font-family: $lexend-family;
                font-weight: $lexend-extra-bold;
            }
        }
    }

    &__changes-icon {
        position: relative;
        margin-right: 20px;
        width: 24px;
        color: $medium-gray;

        &::before {
            position: absolute;
            top: -5px;
            left: -8px;
            font-size: calculate-rem(35);
        }
    }

    &__changes-description {
        @include flexbox();
        @include flex-grow(1);
        @include body-m;
        width: 50%;
        color: $medium-gray;
        font-family: $open-sans-family;
        font-weight: $open-sans-semi-bold;

        &--no-icon {
            margin-left: 42px;
        }
    }

    &__changes-price {
        @include body-l;
        font-family: $lexend-family;
        font-weight: $lexend-extra-bold;

        &--up {
            color: $red;
        }

        &--down {
            color: $green;
        }
    }

    &__text {
        border-bottom: 1px solid $light-gray;
        background-color: $wild-sand;
        padding: 40px 20px;

        &-description {
            color: $dark-gray;
            font: calculate-rem(18) / calculate-rem(24) $open-sans-family;
        }

        &-cta {
            @include transition(color 0.3s ease);
            display: block;
            margin: 10px 0 50px;
            color: $blue;
            font: calculate-rem(14) $open-sans-family;
            font-weight: $open-sans-extra-bold;

            &:hover {
                color: $blue;
            }
        }
    }
}

.overview-bill-analysis__disclaimer {
    @include flexbox;
    @include flex-direction(column);
    width: 100%;

    @include bp-medium {
        float: right;
        width: 45%;
    }

    @include bp-extra-large {
        width: 47%;
    }
}

.overview-bill-analysis--oru {
    .analysis-column__text-cta {
        color: $orange;

        &:hover {
            color: $orange;
        }
    }

    .overview-bill-analysis__nav {
        &-item--active {
            border-bottom: 3px solid $teal;
            color: $dark-gray;

            &:hover {
                border-color: $teal;
            }
        }
    }
}

.overview-bill-analysis__nav-item {
    body:not(.mouse-user):not(.is-oru) &:focus {
        position: relative;
        outline: 0 !important;

        &::before {
            @extend .coned-button-focus;
        }
    }

    body:not(.mouse-user) &:focus {
        position: relative;
        outline: 0 !important;

        &::before {
            @extend .oru-button-focus;
        }
    }
}
