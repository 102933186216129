// ============== IN PAGE BANNER COMPONENT =================

.in-page-banner {
    padding: 40px;
    text-align: center;
    color: $white;

    @include bp-medium {
        text-align: left;
    }

    &-wrapper {
        background-color: $blue;
    }

    &__title {
        @include body-s;
        margin-bottom: 20px;
        font-family: $open-sans-family;
        font-weight: $open-sans-extra-bold;

        @include bp-medium {
            margin-bottom: 0;
        }
    }

    &__text {
        @include heading-l;
        font-family: $lexend-family;
        font-weight: $lexend-light;

        @include bp-smaller {
            line-height: calculate-rem(38);
            font-size: calculate-rem(28);
        }

        @include bp-medium {
            text-align: center;
            line-height: calculate-rem(42);
            font-size: calculate-rem(30);
        }
    }

    &__ctas {
        display: inline-block;
        padding-top: 30px;

        @include bp-medium {
            padding-top: 0;
            padding-left: 30px;
        }
    }

    &__cta {
        display: inline-block;
        margin-right: 20px;
        margin-bottom: 15px;

        @include bp-smaller {
            margin-right: 0;
        }

        &:last-child {
            margin-right: 0;
        }
    }

    &__cta-link {
        @extend .button;

        body:not(.is-oru) & {
            @extend .button--secondary-color;
        }
    }

    &__cta-icon {
        @extend .button-icon;

        &::before {
            position: absolute;
            top: -1px;
            left: -5px;
            font-size: calculate-rem(30);
        }
    }

    &--ctas {
        .in-page-banner__content {
            @extend %flexbox;
            @include flex-direction(column);

            @include bp-medium {
                @include flex-direction(row);
                @include justify-content(space-between);
                @include align-items(center);
            }
        }

        .in-page-banner__text {
            @include bp-medium {
                max-width: 40%;
                text-align: left;
            }
        }
    }
}

.in-page-banner--oru {
    background-color: $orange;

    .in-page-banner__title {
        color: $teal;
    }

    .in-page-banner__content {
        color: $teal;
    }
}
