// ============== FINANCIAL-ADVISOR-STEPS COMPONENT =================

.financial-advisor-steps {
    @include flexbox;
    @include align-items(start);
    @include justify-content(start);
    @include flex-direction(column);
    background: $white;
    padding: 50px 16px 20px;
    text-align: start;

    @include bp-medium {
        margin: 0 auto;
        padding: 50px 20px;
        width: 100%;
        max-width: 446px;
    }

    @include bp-large {
        max-width: 812px;
    }

    &__title {
        @include heading-m;
        padding-bottom: 16px;
        font-family: $lexend-family;
        font-weight: $lexend-regular;
    }

    &__description {
        @include body-l;
        padding: 0 0 16px;
        color: $dark-gray;
        font-family: $open-sans;

        &--padding {
            padding: 0;
        }
    }

    &__subtitle {
        @include body-s;
        border-top: 1px solid $medium-gray-93;
        padding: 8px 0 32px;
        width: 100%;
        font-family: $open-sans;
        font-weight: $open-sans-bold;
    }
    
    &__subtitle--regular {
        font-weight: $open-sans-regular;
    }

    &__field {
        width: 100%;
    }

    &__radio {
        @include flexbox;
        @include flex-direction(row);
        padding-bottom: 20px;

        &-title {

            &--bold {
                font-weight: $open-sans-bold;
            }

            &--margin-20 {
                margin-bottom: 20px;
            }
        }

        label+label {
            margin-left: 64px;
        }
    }

    &--full-width {
        padding: 0;
        width: 100%;
    }
}
