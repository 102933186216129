// ============== MANAGE ENROLL COMPONENT =================
.manage-enroll {
    &-wrapper {
        margin-bottom: 20px;
        background-color: $white;

        @include bp-medium {
            margin-bottom: 50px;
        }
    }

    &__unenroll {
        margin: 30px 0;
    }

    &__contain {
        @include transition(all 0.5s ease);
        @include opacity(1);
        padding-top: 15px;
        height: auto;

        @include bp-medium {
            padding: 15px 0 0 40px;
        }

        &--error {
            @include bp-medium {
                padding: 0;
            }
        }

        &--close {
            display: none;
        }

        &--post-payment {
            padding-left: 0;
            text-align: left;
            color: $black;
        }

        @media not all and (min-resolution: 0.001dpcm) {
            &:not(&--post-payment) {
                @supports (-webkit-appearance: none) {
                    @include transition(none);
                }
            }
        }
    }

    &__gutter {
        padding: 40px 20px 0;

        @include bp-medium {
            padding: 60px 0 0 14.5%;
            width: 71%;
        }
    }

    &__signature {
        &-head {
            margin: 20px 0 30px;
        }

        &-field {
            margin: 40px 0 0;
        }

        &-submit {
            margin: 25px 0 0;
            width: 100%;

            @include bp-medium {
                width: auto;
            }
        }

        &-copy {
            &-container {
                max-width: 800px;
            }
        }

        &-action {
            &-form {
                margin: 15px 0;
            }
        }
    }

    &__list {
        border-top: 0;
        padding-bottom: 60px;

        @include bp-smaller {
            &--inactive .manage-enroll__icon::before,
            &--alert .manage-enroll__icon::before {
                top: -16px;
            }
        }

        .manage-enroll__icon {
            position: relative;
            padding-right: 0;

            &::before {
                top: -10px;
                left: -7px;
            }
        }

        &--inactive {
            color: $medium-gray;

            .manage-enroll__icon {
                @extend .icon-close;
                color: $medium-gray;
            }

            .manage-enroll__icon::before {
                top: -14px;
                left: -15px;
                font: calculate-rem(50) 'coned-icons';
            }
        }

        &--alert {
            color: $medium-gray;

            .manage-enroll__icon {
                @extend .icon-report-problem;
            }

            .manage-enroll__icon::before {
                top: -7px;
                left: -7px;
                font: calculate-rem(35) 'coned-icons';
            }
        }
    }

    &__warning {
        padding: 10px;

        @include bp-medium {
            padding: 10px 20px;
        }
    }

    &__title {
        border-bottom: 3px solid $medium-gray;
        padding-bottom: 10px;
        text-align: left;
    }

    // In order to avoid the nesting depth lint issue
    &__paragraph-description {
        > span {
            &.error {
                color: $red;
            }
        }

        a {
            body:not(.mouse-user):not(.is-oru) &:focus {
                @extend .coned-focus-outline;
            }

            body:not(.mouse-user) &:focus {
                @extend .oru-focus-outline;
            }

            &:focus::before {
                content: none !important;
            }
        }
    }

    &__paragraph {
        &-title {
            @include body-m;
            color: $mine-shaft;
            font-family: $lexend-family;
            font-weight: $lexend-extra-bold;
        }

        &-title-description {
            @include body-l;
            color: $dark-gray;
            font-family: $lexend-family;
            font-weight: $lexend-semi-bold;
        }

        &-subtitle {
            @include heading-s;
            padding-top: 10px;
            color: $dark-gray;
            font-family: $lexend-family;
            font-weight: $lexend-extra-bold;
        }

        &-description {
            @include body-m;
            color: $dark-gray;
            font-family: $open-sans-family;
            font-weight: $open-sans-semi-bold;

            @include bp-medium {
                a {
                    white-space: nowrap;
                }
            }

            > span {
                color: $blue;
                font-weight: $open-sans-bold;
            }

            &--success {
                color: $green;
            }

            &--error {
                color: $red;
            }

            &--medium-grey {
                color: $medium-gray;
            }
        }

        &-description--extra-bold {
            font-family: $lexend-family;
            font-weight: $lexend-extra-bold;
        }

        &-description--heading {
            @include body-m;
            color: $tundora;
            font-family: $open-sans-family;
            font-weight: $open-sans-semi-bold;
        }

        &-description--bottom {
            @include body-m;
            color: $tundora;
            font-family: $open-sans-family;
            font-weight: $open-sans-regular;
        }

        &-description--centered {
            display: block;
            margin: 0 auto;
            text-align: center;
        }

        &-description-list {
            ul {
                padding: 0 0 35px 20px;
            }
            
            li {
                list-style-type: disc;
            }
        }

        &-link {
            @extend .button-link, .button-link--extra-low-emphasis;
            display: block;
            margin: 20px 0 40px;
        }

        &-link-inline {
            @extend .button-link, .button-link--extra-low-emphasis;
            display: inline-block;
            margin: 0 0 40px;
        }

        &-description--margin-top {
            margin-top: 30px;
        }
    }

    &__table {
        padding: 0;
    }

    &__cta { 
        @extend .button-link, .button-link--extra-low-emphasis;
        display: block;
        margin-top: 35px;
        color: $blue;

        &:hover {
            text-decoration: underline;
        }
    }

    // Payment Agreement Enrolled
    &__payment-agreement-enrolled-container {
        margin: 20px 0 30px;
    }

    &__payment-agreement-disclaimer-container {
        p {
            @include body-m;
            padding-bottom: 15px;
            color: $dark-gray;
            font-family: $open-sans-family;
            font-weight: $open-sans-semi-bold;
        }
    }

    &__payment-agreement-top-amounts {
        @extend %flexbox;
        @include align-items(flex-start);
        @include flex-direction(column);

        margin: 25px auto;

        @include bp-medium {
            @include align-items(center);
            @include flex-direction(row);
            @include justify-content(space-around);
        }
    }

    &__payment-agreement-top-amount {
        @extend %flexbox;
        @include align-items(center);
        @include flex-direction(column);
        @include display-m;

        border: 1px solid $medium-gray;
        padding: 30px 0;
        width: 100%;
        color: $dark-gray;
        font-family: $lexend-family;
        font-weight: $lexend-extra-bold;

        @include bp-small {
            &:not(:last-child) {
                border-bottom: 0;
            }
        }

        @include bp-medium {
            @include flex-basis(50%);
            margin: 5px 10px;
            width: auto;
        }

        &--description {
            @include body-s;
            text-transform: uppercase;
            color: $medium-gray;
            font-family: $open-sans-family;
            font-weight: $open-sans-extra-bold;
        }
    }

    &__payment-agreement-progress-bar-amount {
        @include body-m;
        color: $dark-gray;
        font-family: $lexend-family;
        font-weight: $lexend-extra-bold;

        @include bp-medium {
            display: inline-block;

            &:nth-child(2) {
                position: absolute;
                left: 20%;
            }

            &:nth-child(3) {
                float: right;
                text-align: right;
            }
        }

        @include bp-small {
            position: absolute;

            &:nth-child(1) {
                top: 0;
            }

            &:nth-child(2) {
                @include transform(translateY(-50%));
                top: 50%;
            }

            &:nth-child(3) {
                bottom: 0;
            }
        }

        &--grayed {
            color: $medium-gray;
        }
    }

    &__payment-agreement-progress-bar-description {
        @include body-s;
        font-family: $open-sans-family;
    }

    &__payment-agreement-progress-bar-container {
        @extend %flexbox;
        @include flex-direction(row);

        margin: 30px 0;
        height: 500px;

        @include bp-medium {
            @include flex-direction(column);

            width: 100%;
            height: auto;
        }
    }

    &__payment-agreement-progress-bar {
        @include flex-basis(45px);

        position: relative;
        margin-right: 25px;

        @include bp-medium {
            @include order(1);

            margin-top: 25px;
            margin-right: 0;
        }
    }

    &__payment-agreement-progress-bar-amounts-container {
        @include flex-grow(1);
        @include order(0);

        position: relative;
    }

    &__payment-agreement-progress-bar-small-bg {
        @include transform(translateX(-50%));

        position: absolute;
        top: 2.5%;
        left: 50%;
        background-color: $light-gray;
        width: 3px;
        height: 95%;

        @include bp-small {
            &--started {
                @include linear-gradient(top, middle, bottom, $green, 50%, $medium-gray, 50%);
            }
        }

        @include bp-medium {
            @include transform(translateY(-50%));

            top: 50%;
            left: 2.5%;
            width: 95%;
            height: 2px;

            &--started {
                @include linear-gradient(middle, left, right, $green, 50%, $medium-gray, 50%);
            }
        }

        &--finished {
            background-color: $green;
        }
    }

    &__payment-agreement-progress-bar-big-bg {
        @include transform(translate(-50%, -50%));

        position: absolute;
        top: 50%;
        left: 50%;
        border: 2px solid $medium-gray;
        border-radius: 20px;
        background-color: $white;
        width: 35px;
        height: 60%;

        @include bp-medium {
            width: 60%;
            height: 35px;
        }
    }

    &__payment-agreement-progress-bar-checkpoint {
        @include transform(translateX(-50%));

        position: absolute;
        left: 50%;
        z-index: 10;
        border: 2px solid $medium-gray;
        border-radius: 50%;
        background: $white;
        width: 36px;
        height: 36px;

        @include bp-medium {
            @include transform(translateY(-50%));

            top: 50%;

            &--downpayment {
                left: 0;
            }

            &--installments {
                @include transform(translate(-50%, -50%));

                left: 0;
            }

            &--final {
                right: 0;
                left: auto;
            }

            &.icon-arrow {
                @include transform(translate(-50%, -50%));
            }
        }

        @include bp-small {
            &--downpayment {
                top: 0;
            }

            &--installments {
                @include transform(translate(-50%, -50%));
            }

            &.icon-arrow {
                @include transform(translate(-50%, -50%) rotate(90deg));
            }
        }

        &--final {
            bottom: 0;
        }

        &--active {
            border-color: $green;
            color: $green;
        }

        &[class^='icon-'],
        &[class*=' icon-'] {
            position: absolute;
            padding: 0;

            &::before {
                font-size: calculate-rem(35);
            }
        }
    }
}

// Focus outline
.manage-enroll__contain {
    button {
        &:focus {
            outline: none;

            body:not(.mouse-user):not(.is-oru) &::before {
                @extend .coned-button-focus;
            }

            body:not(.mouse-user) &::before {
                @extend .oru-button-focus;
            }
        }
    }

    form {
        outline: none;
    }
}

.manage-enroll--oru {
    .transactional-review__edit-link,
    .manage-enroll__cta,
    .manage-enroll__paragraph-link,
    .manage-enroll__paragraph-description > a,
    .manage-enroll__paragraph-description > span,
    .payment-table-wrapper__cta-link--right,
    .payment-table-wrapper__cta-link--left,
    .popup__close-cta,
    .coned-tooltip .coned-tooltip--open,
    .coned-tooltip .coned-tooltip--close {
        color: $teal;
    }

    .popup-container {
        border-top: 3px solid $orange;
    }
}
