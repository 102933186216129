// ============== EDIT PROFILE COMPONENT =================
.edit-profile {
    &__field {
        position: relative;
        border-bottom: 2px solid $medium-gray;
        padding-bottom: 10px;

        &--last {
            border-bottom: 0;
        }

        &--active {
            border: 0;
        }
    }

    &__title-container {
        @extend %flexbox;
        @include align-items(center);
    }

    &__form {
        margin-bottom: 35px;
    }

    &__section-title {
        @extend %text-truncate-wrap;
        @include box-sizing(border-box);
        display: block;
        position: relative;
        clear: both;
        border-bottom: 3px solid $medium-gray;
        padding-right: 35px;
        padding-bottom: 15px;
        width: 100%;
        color: $dark-gray;

        @include bp-medium {
            width: auto;
        }
    }

    &__title {
        @include body-s;
        margin-right: 10px;
        color: $medium-gray;
        font-family: $open-sans-family;
        font-weight: $open-sans-semi-bold;
    }

    &__description {
        @extend %text-truncate;
        @include body-l;

        color: $dark-gray;
        font-family: $open-sans-family;
        font-weight: $open-sans-semi-bold;

        @include bp-smaller {
            width: 80%;
            word-break: normal;
        }

        &--empty {
            color: $alto;
        }
    }

    &__edit-link {
        @extend .button-link, .button-link--extra-low-emphasis;
        position: absolute !important;
        top: 8px;
        right: 0;
        z-index: 10;

        body:not(.mouse-user):not(.is-oru) &:focus {
            @extend .coned-focus-outline;
        }

        body:not(.mouse-user) &:focus {
            @extend .oru-focus-outline;
        }

        &--cancel {
            right: 60px;
            color: $medium-gray;

            &-send-code {
                right: 210px;
            }
        }

        &--email-cancel {
            color: $medium-gray;
        }
    }

    &__download-accounts-link {
        @extend .button-link, .button-link--extra-low-emphasis;
        margin-bottom: 10px;
        text-decoration: none;

        body:not(.mouse-user):not(.is-oru) &:focus {
            @extend .coned-focus-outline;
        }

        body:not(.mouse-user) &:focus {
            @extend .oru-focus-outline;
        }

        [class*='icon-'] {
            position: absolute;
            top: -3px;
            font-size: calculate-rem(23);
        }

        &--text {
            margin-left: 30px;
            text-decoration: underline;

            &:hover:not([disabled]) {
                text-decoration: none;
            }
        }
    }

    &__error {
        @extend %flexbox;
        @include align-items(center);
        @include justify-content(center);
        color: $red;
        font: $open-sans-semi-bold calculate-rem(22) / calculate-rem(32) $open-sans-family;

        &--error-notification {
            position: absolute;
            bottom: -10px;
            left: 0;
            font-size: calculate-rem(11);

            @include bp-medium {
                font-size: calculate-rem(22);
            }
        }
    }

    &-error-icon {
        margin-right: 10px;
    }

    &__email-list {
        margin-top: 10px;
    }

    &-email__list-item {
        @extend %flexbox;
        @include flex-direction(column);
        @include align-items(baseline);

        @include bp-medium {
            @include flex-direction(row);
            @include align-items(center);
        }
    }

    &__address {
        @include body-m;
        color: $medium-gray;
        font-family: $open-sans-family;

        @include bp-medium {
            margin-left: 10px;
        }
    }

    &__paragraph {
        @include body-s;
        padding: 20px 20px 20px 0;
        font-family: $open-sans-family;
    }
}
