// ============== CONFIRMATION SCREEN MAIN COMPONENT =================

.confirmation-screen__main {
    @include flex(1 1 100%);
    background-color: $white;
    padding: 30px 20px;
    overflow: hidden;
    text-align: center;

    @include bp-medium {
        @include flexbox;
        @include flex-direction(column);
        @include justify-content(center);
        @include align-items(center);
        padding-right: 150px;
        padding-left: 150px;
    }

    &--left-aligned {
        text-align: left;

        @include bp-medium {
            @include align-items(flex-start);
            padding-right: 250px;
            padding-left: 50px;
        }

        &.confirmation-screen__main--2-columns {
            @include bp-medium {
                padding-right: 150px;
                padding-left: 50px;
            }
        }
    }

    &--2-columns {
        @include bp-small {
            margin-bottom: 10px;
        }

        @include bp-medium {
            @include flex-basis(0);
            margin-right: 20px;
            padding-right: 100px;
            padding-left: 100px;
        }
    }

    &--hidden {
        display: none;
    }

    
    &-image {
        margin-bottom: 20px;
        width: 240px;
    }

    &-title {
        @include heading-l;
        margin-bottom: 20px;
        font-family: $lexend-family;
        font-weight: $lexend-extra-bold;
        word-wrap: break-word;
    }
}
