// ============ INPUT ===============

.coned-input {
    @include box-sizing(border-box);
    @include justify-content(left);
    @include body-l;
    background-color: $white;
    padding: 37px 0 4px;
    width: 100%;
    height: 70px;
    color: $dark-gray;
    font-family: $open-sans-family;
    font-weight: $open-sans-semi-bold;
    touch-action: manipulation;

    @include bp-medium {
        text-indent: 20px;
    }

    &:focus {
        border-color: $green;

        + label {
            @include body-s;
            padding: 10px 0 0;

            @include bp-medium {
                padding: 10px 20px 0;
            }
        }
    }

    &:disabled,
    &:disabled ~ .coned-input__icon {
        @include opacity(0.5);
        pointer-events: none;
    }

    &--has-tooltip {
        padding-right: 45px;
        text-overflow: ellipsis;
    }

    &--filled ~ label {
        @include body-s;
        padding: 10px 0 0;
        color: $green;

        @include bp-medium {
            padding: 10px 20px 0;
        }
    }

    &--filled ~ .border-bar {
        border-color: $green;
    }

    &--tooltip {
        padding-right: 50px;
    }

    &__icon {
        position: absolute;
        top: 33px;
        right: 20px;
        cursor: pointer;
        color: $blue;
        font-size: calculate-rem(35);
    }

    &__icon--calendar {
        right: 45px;
    }

    &__icon--no-tooltip {
        right: 5px;
    }

    &--long-label {
        &:focus {
            + label {
                bottom: 32px;
            }
        }

        &.coned-input--filled ~ label {
            bottom: 32px;
        }
    }

    .is-oru & {
        &__icon {
            color: $teal;
        }
    }
}
