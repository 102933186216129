// ============== BOPA UNITS LIST =================

.bopa-units-step {
    background-color: transparent;
}

.bopa-units-list {
    padding: 34px 30px 25px;

    @include bp-medium {
        margin: 0 auto;
        padding: 62px 0 25px;
        width: 90%;
        max-width: 786px;
    }

    &__wrapper {
        margin: 10px;
        background-color: $white;
        padding-bottom: 20px;

        @include bp-medium {
            margin: 10px auto;
        }

        &--no-margin {
            margin: 0;
        }

        &--transparent {
            background: transparent;
        }
    }

    &__description {
        @include body-m;
        padding-bottom: 33px;
        color: $dark-gray;
        font-family: $open-sans-family;
    }

    &__container {
        border-top: 1px solid $alto2;
    }

    &__item {
        @include flexbox();
        @include flex-direction(row);
        @include align-items(center);
        border-bottom: 1px solid $alto2;
        min-height: 110px;

        @include bp-medium {
            height: 86px;
            min-height: 86px;
        }

        &--heading {
            border-top: 2px solid $alto2;
        }

        &-check {
            @include flexbox();
            @include flex-direction(row);
            @include align-items(center);
            min-width: 125px;
        }

        &-owner {
            margin-left: 5px;
            color: $dark-gray;
            font: calculate-rem(16) $open-sans;
            font-weight: $open-sans-bold;

            @include bp-medium {
                margin-left: 78px;
                font-size: calculate-rem(18);
            }
        }

        &--hidden {
            display: none;
        }

        &--disabled {
            .enroll-units-list__item-box {
                visibility: hidden;
                pointer-events: none;
            }

            .enroll-units-list__item-owner,
            .enroll-units-list__item-name {
                color: $nobel;
                pointer-events: none;
            }
        }
    }

    &__counters {
        display: none;
    }

    &__radio-container {
        border-top: 1px solid $alto2;
        padding-top: 50px;
    }

    &__radio-title {
        @include body-m;
        color: $dark-gray;
        font-family: $open-sans-family;
        font-weight: $open-sans-bold;
    }

    &__radio-list {
        display: block;
        padding: 35px 0 50px;

        @include bp-medium {
            @include flexbox();
            @include flex-direction(row);
            @include justify-content(space-between);
            max-width: 635px;
        }
    }

    &__radio {
        padding: 11px 0;

        @include bp-medium {
            padding: 0;
        }

        &:first-child {
            padding-top: 0;

            @include bp-medium {
                padding: 0;
            }
        }

        &:last-child {
            padding-bottom: 0;

            @include bp-medium {
                padding: 0;
            }
        }
    }

    &__terms-container {
        border-top: 1px solid $alto2;
        padding: 50px 20px 0;
        text-align: center;
    }

    &__terms {
        @include body-m;
        color: $dark-gray;
        font-family: $open-sans-family;
    }

    &__button-container {
        @include flexbox();
        @include align-items(center);
        @include justify-content(center);
        margin: 0 30px;
    }
}
