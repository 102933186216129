// ============== LOGIN COMPONENT =================

.login {
    display: none;
    position: fixed;
    top: 0;
    bottom: 0;
    z-index: 10;
    background-color: $white;
    width: 100%;
    height: 0;
    overflow: auto;

    &--a11y-200-zoom {
        max-height: calc(100vh - 122px);
        overflow-y: auto;
    }

    &__title {
        margin: 0 0 20px;
        border: 0;

        h1,
        h2 {
            font: $lexend-extra-bold calculate-rem(35) / calculate-rem(42) $lexend-family;

            @include bp-medium {
                font: $lexend-extra-bold calculate-rem(40) / calculate-rem(47) $lexend-family;
            }
        }
    }

    &--dropdown {
        @include bp-medium {
            display: none;
            position: absolute;
            right: 0;
            bottom: auto;
            transition: opacity 0.6s ease, top 0.3s ease;
            opacity: 0;
            width: 314px;
        }
    }

    &--active {
        display: block;

        @include bp-medium {
            display: block;
            opacity: 1;
            height: auto;
        }
    }

    &__wrapper {
        position: relative;
        margin: 0 auto;
        padding: 10px 10px 10px 0;
        max-width: 1400px;

        @include bp-medium {
            padding: 10px;
        }

        &--no-padding {
            padding: 0;
        }
    }

    &__content-wrapper {
        @include bp-medium {
            @include flexbox;
            @include align-items(center);
            @include justify-content(space-between);
        }
    }

    &__header {
        @include transition(all, 0.6s, ease);
        @include opacity(1);
        position: relative;
        top: 0;

        @include bp-medium {
            padding-top: 40px;
        }

        &-container {
            @include flexbox;
            @include align-items(center);
            @include justify-content(space-between);

            &--end {
                @include bp-medium {
                    @include justify-content(flex-end);
                }
            }

            &--hidden-desktop {
                @include bp-medium {
                    display: none;
                }
            }
        }

        &--hidden-desktop {
            @include bp-medium {
                display: none;
            }
        }

        &--no-padding-top {
            @include bp-medium {
                padding-top: 0;
            }
        }
    }

    &__form {
        @include transition(all, 0.3s, ease);
        @include opacity(1);
        position: relative;
        top: 0;
        padding: 30px 20px 0 30px;

        @include bp-medium {
            padding: 30px 35px 0;
            width: 100%;
        }

        &-error {
            p {
                margin-left: 30px;
            }
        }

        &-container-wrapper {
            @include flexbox;
            @include flex-direction(column);

            @include bp-medium {
                @include flex-direction(row);
                @include flex-wrap(wrap);
                @include align-items(center);
                @include justify-content(space-between);
            }

            &-input {
                margin-top: 20px;
                width: 100%;

                @include bp-medium {
                    margin-top: 20px;
                    width: 50%;
                }
            }

            &-input--dropdown {
                margin-top: 0;

                @include bp-medium {
                    width: 100%;
                }
            }
        }

        &--form-container {
            padding: 0;
            width: 100%;
        }

        &-password {
            position: relative;
        }

        &-password-input {
            width: calc(100% - 132px);
        }

        &-password-button {
            @extend .button-link, .button-link--extra-low-emphasis;
            position: absolute;
            top: 45px;
            right: 0;
            text-transform: uppercase;
        }

        &-half {
            @include box-sizing(border-box);
            display: inline-block;
            width: 100%;
            height: 70px;
            vertical-align: top;

            @include bp-medium {
                width: 49%;
            }
        }

        &-half--right {
            text-align: right;
        }

        &--dropdown {
            padding-top: 0;

            @include bp-medium {
                border-bottom: solid 3px $blue-brand;
                padding: 30px;
            }
        }
    }

    &__form-password-button {
        body:not(.mouse-user):not(.is-oru) &:focus {
            outline: none;

            &::before {
                @extend .coned-button-focus;
            }
        }

        body:not(.mouse-user) &:focus {
            outline: none;

            &::before {
                @extend .oru-button-focus;
            }
        }
    }

    &__list {
        @include transition(all, 0.1s, ease);
        @include order(2);
        @include opacity(1);
        position: relative;
        top: 0;
        padding-bottom: 30px;

        @include bp-medium {
            @include box-sizing(border-box);
            @include order(0);
            padding-bottom: 80px;
            padding-left: 10%;
            width: 50%;
        }

        &-link {
            @extend %flexbox, .button-link;
            @include align-items(center);
            margin-top: 10px;
            text-decoration: none;
        }

        &-link--green {
            color: $green;
        }

        &-icon {
            font-size: calculate-rem(35);
        }

        &-text {
            padding-left: 5px;
            text-decoration: underline;

            &:hover {
                text-decoration: none;
            }
        }

        &--dropdown {
            @include bp-medium {
                padding: 0;
                width: 100%;
            }
        }
    }

    &__logo {
        position: relative;
        margin: 0;
    }

    &__close-button {
        position: relative;
        outline: 0;
        background-color: transparent;
        padding: 0;
        width: auto;
        height: auto;
        color: $blue;
        font-size: calculate-rem(55);

        &:focus {
            body:not(.mouse-user):not(.is-oru) &::after {
                @extend .coned-button-focus;
            }

            body:not(.mouse-user) &::after {
                @extend .oru-button-focus;
            }
        }
    }

    &--animator {
        @include opacity(0);
        top: 50px;
    }

    .border-bar::after {
        border-color: $green;
    }

    .form__actions {
        margin: 5px 0 25px;
        padding: 0;
        text-align: center;

        @include bp-medium {
            @include justify-content(center);
        }

        .icon-arrow {
            display: none;
        }
    }

    .login-form__footer--modal {
        .form__actions {
            @include bp-medium {
                position: relative;
                margin: 0;
                width: auto;
            }
        }
    }

    .coned-checkbox__indicator::after {
        color: $white;
    }

    .coned-input-message--error,
    .coned-input-top-message--error {
        ~ .border-bar {
            border-color: $red;
        }
    }
}

.login--oru {
    .login__close-button {
        color: $teal;
    }

    .login-form {
        .coned-input--filled {
            ~ label {
                color: $green;
            }

            ~ .border-bar {
                border-color: $green;
            }
        }

        .login__close-button {
            color: $teal;
        }
    }
}

.avoid-clicks {
    pointer-events: none;
}
