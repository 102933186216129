// ============== BILLING TABLE COMPONENT =================

.billing-table {
    margin-top: 45px;
    width: 100%;

    @include bp-coned-hero {
        margin: 54px auto 0;
        width: 55%;
    }

    &__column {
        @include box-sizing(border-box);
        @include heading-s;
        text-align: left;
        font-family: $open-sans-family;
        font-weight: $open-sans-regular;

        &-total {
            padding: 25px 0 25px 15px;
            font-family: $open-sans-family;
            font-weight: $open-sans-bold;

            @include bp-medium {
                padding-top: 0;
                padding-left: 0;
            }
        }
    }

    &__description {
        width: 75%;

        @include bp-medium {
            width: 50%;
            text-align: left;
            white-space: nowrap;
        }
    }

    &__amount {
        width: 25%;
        text-align: right;
    }

    &__total {
        font-weight: $open-sans-bold;
    }
}
