// ============ ADDRESS DROPDOWN CTA ===============

.address-dropdown {
    position: relative;
    margin: 0 auto;

    @include bp-medium {
        width: 440px;
        max-width: 100%;

        &--full-width {
            width: 100%;
        }
    }

    &__container {
        margin: 50px 0;

        &--no-margin {
            margin: 0;
        }

        &--eap-margin {
            margin: 10px 0;
        }
    }

    &__select {
        padding-right: 45px;
    }

    &__button {
        position: relative;

        &:not(.address-dropdow__button--no-pointer) {
            cursor: pointer;
        }

        &:not(.address-dropdow__button--no-pointer):hover,
        &:focus {
            .address-box__container {
                background-color: $blue;
            }

            .address-box__info {
                color: $white;
            }

            .address-dropdown__icon {
                border-color: $white;
                background-color: $white;
                color: $blue;
            }
        }

        body:not(.mouse-user):not(.is-oru) &:focus {
            @extend .coned-focus-outline;
        }

        body:not(.mouse-user) &:focus {
            @extend .oru-focus-outline;
        }
    }

    &__label {
        margin: 0 auto 10px;
        color: $medium-gray;
        font: calculate-rem(18) / calculate-rem(24) $open-sans-family;
        font-weight: $open-sans-semi-bold;

        @include bp-medium {
            width: 440px;
            max-width: 100%;
        }
    }

    &__icon {
        @include border-radius(50%);
        position: absolute;
        top: calc((100% - 28px) / 2);
        right: 20px;
        border: 2px solid $medium-gray;
        background-color: $medium-gray;
        padding: 7px;
        width: 10px;
        height: 10px;
        color: $white;

        &::before {
            position: absolute;
            top: 1px;
            right: 0;
            font-size: calculate-rem(25);
        }

        &--check {
            border: 0;
            background-color: $transparent;
            color: $medium-gray;
        }

        &--up {
            @include transform(rotate(180deg));
        }
    }

    &__list {
        @include transition(all, 0.4s, ease);
        position: absolute;
        top: 0;
        left: 0;
        z-index: 100;
        width: 100%;
        max-height: 358px;
        overflow-y: auto;

        &-item {
            @include opacity(1);
            position: relative;
            top: 0;
            cursor: pointer;

            & + & .address-box__container {
                border-top: 0;
            }
        }

        &-item:hover,
        &-item:focus {
            .address-box__container {
                background-color: $blue;
            }

            .address-box__info,
            .address-dropdown__icon--check {
                color: $white;
            }
        }

        &-item--disabled {
            cursor: default;
            pointer-events: none;

            .address-box__container {
                background-color: $light-gray;
            }
        }

        &-item--hidden {
            display: none;
        }
    }

    &--no-margin {
        margin: 0 !important;
    }

    .is-oru & {
        &__list-item:hover,
        &__list-item:focus {
            .address-box__container {
                background-color: $teal;
            }
        }

        &__button:not(.address-dropdow__button--no-pointer):hover,
        &__button:focus {
            .address-box__container {
                background-color: $teal;
            }

            .address-dropdown__icon {
                color: $teal;
            }
        }
    }
}
