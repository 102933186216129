.navigation-buttons {
    padding: 0 0 50px;
    width: 100%;

    @include bp-medium {
        margin: 0 auto;
        padding: 0 20px 50px;
        max-width: 446px;
    }

    @include bp-large {
        max-width: 812px;
    }

    &__wrapper {
        width: 100%;
    
        @include bp-medium {
            margin: 0 auto;
            max-width: 554px;
        }
    
        @include bp-large {
            max-width: 1048px;
            box-sizing: border-box;
        }
    }

    &__content {
        @include flexbox;
        @include justify-content(space-between);
        @include flex-direction(column);
        @include gap(16px);
        margin: 0 auto;
        padding: 0 20px;

        @include bp-medium {
            @include flex-direction(row);
            @include gap(0);
            padding: 0;
            max-width: 1048px;         
        }

        &--first-step {
            @include justify-content(flex-end);
        }
    }

    &__back-button {
        @extend .button;
        @extend .button--secondary-color;
    }

    &__continue-button {
        @extend .button, .button--primary-color;

        span[class*='icon-'] {
            &::before {
                margin-left: -8px;
                font-size: calculate-rem(45);
            }
        }
    }

    &--full-width {
        @include bp-large {
            max-width: 1048px;
            box-sizing: border-box;
        }
    }
}
