// ============== VIDEO COMPONENT =================

.coned-video {
    @extend %flexbox;
    @include flex-direction(column);
    background-color: $white;
    overflow: hidden;

    @include bp-medium {
        @include flex-direction(row);
    }

    &-wrapper {
        padding-left: 0;
        width: 100%;

        @include bp-medium {
            padding-bottom: 95px;
            padding-left: 14.5%;
            width: 60%;
        }
    }

    &__player {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    &-outer-container {
        position: relative;
        padding-bottom: 56.25%;
        height: 0;
    }

    &__info-wrapper {
        @extend %flexbox;
        @include flex-direction(column);
        @include align-items(flex-start);
        margin: 10px 0;
        padding: 0 20px 50px;
        width: 84%;

        @include bp-medium {
            @include flex-direction(column);
            margin: 0 10px;
            padding-bottom: 0;
            width: 13%;
        }
    }

    &__info-icon {
        display: block;
        margin: -15px 0 -8px -18px;
        color: $dark-gray;
        font-size: calculate-rem(65);
    }

    &__title {
        @include body-s;
        color: $dark-gray;
        font-family: $open-sans-family;
        font-weight: $open-sans-bold;
    }

    &__description {
        @include body-s;
        display: inline;
        position: relative;
        margin-left: 2px;
        width: 100%;
        max-height: 230px;
        overflow: hidden;
        color: $medium-gray;
        font-family: $open-sans-family;
    }

    &__paragraph--ellipsis::after {
        @extend .icon-carrot::before;
        @include linear-gradient(top, left, bottom, $transparent, 0%, $white, 50%);
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        cursor: pointer;
        width: 100%;
        height: 45px;
        text-align: center;
        color: $blue;
        font-size: calculate-rem(40) / calculate-rem(50);
    }

    &__description--open {
        padding-bottom: 20px;

        &::after {
            @include transform(rotate(180deg));
            background: $white;
            height: 20px;
            line-height: calculate-rem(10);
        }
    }

    &__videos-wrapper {
        @extend %flexbox;
        @include align-items(center);
        width: 100%;
    }

    &__video-item {
        @include box-sizing(border-box);
        @include flex-shrink(0);
        display: block;
        position: relative;
        cursor: pointer;
        padding: 5px;
        width: 100%;
    }

    &__video-item--shortlist {
        width: auto;
    }

    &--video-item--active {
        @extend .icon-youtube;

        &::before {
            @include transform(translate(-50%, -50%) rotate(0.001deg));
            @include opacity(0.5);
            position: absolute;
            top: 50%;
            left: 50%;
            color: $white;
            font: calculate-rem(24) 'coned-icons';
        }

        .coned-video__video-item-thumbnail {
            border: 1px solid $blue;
        }
    }

    &__video-item-thumbnail {
        @include transition(all, 0.3s, ease);
        @include flex-shrink(0);
        display: block;
        border: 1px solid $silver;
        width: 100%;
        max-height: 119px;
    }

    &__thumbnail-title {
        @include body-xs;
        position: absolute;
        bottom: 6px;
        left: 6px;
        background-color: $black-semi-transparent;
        padding: 5px;
        max-width: 85%;
        color: $white;
        font-family: $open-sans-family;
        font-weight: $open-sans-bold;
    }

    &__controls {
        @extend %flexbox;
        margin: 4px 0 10px;
        width: 100%;

        @include bp-medium {
            width: auto;
        }
    }
}

.swiper-container--video-list {
    width: 100%;
    max-width: 100%;
}

.coned-video--oru {
    .coned-video__paragraph--ellipsis::after {
        color: $orange;
    }
}
