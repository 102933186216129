.coned-focus-outline {
    outline: solid 2px $white;
    border: solid 2px $default-selection-blue;
    border-radius: 0;
}

.coned-focus-outline--reversed {
    outline: solid 2px $default-selection-blue;
    border: solid 2px $white;
    border-radius: 0;
}

.oru-focus-outline {
    outline: solid 2px $white;
    border: solid 2px $teal;
    border-radius: 0;
}

.oru-focus-outline--reversed {
    outline: solid 2px $teal;
    border: solid 2px $white;
    border-radius: 0;
}

.button-focus {
    position: absolute;
    top: -4px;
    left: -4px;
    transition: none;
    z-index: 1;
    padding: 2px;
    width: 100%;
    height: 100%;
    content: '';
}

.button-focus-inset {
    top: 1px;
    left: 1px;
    width: calc(100% - 10px);
    height: calc(100% - 10px);
}

/* Safari focus outline fix for when an ancestor has a rotate */
.focus-outline-safari-rotation-fix-desktop {
    @include bp-medium {
        -webkit-transform: perspective(0);
    }
}

.coned-button-focus {
    @extend .button-focus;
    @extend .coned-focus-outline;
}

.coned-button-focus--reversed {
    @extend .button-focus;
    @extend .coned-focus-outline--reversed;
}

.oru-button-focus {
    @extend .button-focus;
    @extend .oru-focus-outline;
}

.oru-button-focus--reversed {
    @extend .button-focus;
    @extend .oru-focus-outline--reversed;
}

a:not(.no-custom-outline) {
    &:focus {
        position: relative;
        outline: 0;

        body:not(.mouse-user):not(.is-oru) &::before {
            @extend .coned-button-focus;
        }

        body:not(.mouse-user) &::before {
            @extend .oru-button-focus;
        }

        body:not(.mouse-user):not(.is-oru) &.link-focus-outline-safari-fix-desktop::before {
            @extend .focus-outline-safari-rotation-fix-desktop;
        }

        body:not(.mouse-user) &.link-focus-outline-safari-fix-desktop::before {
            @extend .focus-outline-safari-rotation-fix-desktop;
        }
    }
}

%focus-outline-on-element {
    body:not(.mouse-user):not(.is-oru) &:focus {
        @extend .coned-focus-outline;
    }

    body:not(.mouse-user) &:focus {
        @extend .oru-focus-outline;
    }

    &::before {
        content: none !important;
    }
}

@mixin theme-before-outline {
    body:not(.mouse-user) &::before {
        @extend .oru-focus-outline;
    }

    body:not(.mouse-user):not(.is-oru) &::before {
        @extend .coned-focus-outline;
    }
}

@mixin theme-focus-outline {
    body:not(.mouse-user) & {
        @extend .oru-focus-outline;
    }

    body:not(.mouse-user):not(.is-oru) & {
        @extend .coned-focus-outline;
    }
}

@mixin before-outline {
    @include theme-before-outline;
    position: relative;
    outline: none;

    &::before {
        @extend .button-focus;
    }

    &.outline-focus {
        &--lg {
            @include theme-before-outline;

            &::before {
                top: -6px;
                right: -6px;
                bottom: -6px;
                left: -6px;
                width: auto;
                height: auto;
            }
        }

        &--no-outline {
            outline: none !important;

            &::before {
                outline: none !important;
            }
        }
    }
}

@mixin btn-focus-outline {
    &:focus {
        @include before-outline;
    }
}

@mixin btn-icon-focus {
    body:not(.mouse-user):not(.is-oru) &:focus {
        @extend .coned-focus-outline;
    }

    body:not(.mouse-user) &:focus {
        @extend .oru-focus-outline;
    }
}
