// ============== HERO COMPONENT =================

.hero {
    @include flexbox;
    @include flex-direction(column);

    @include bp-medium {
        @include flex-direction(row);
    }

    &__block-wrapper {
        position: relative;
        margin-bottom: -2px;
        width: 100%;
        height: auto;
        overflow: hidden;

        @include bp-small {
            max-height: 485px;
        }

        @include bp-medium {
            height: 560px;
        }
    }

    &__cut-content {
        display: -webkit-box;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
    }

    &__block {
        @include flex(1);

        &-container {
            @include flexbox;
            @include flex-flow(row wrap);
            @include align-content(flex-end);
            @include box-sizing(border-box);
            @include transition(all, 0.5s, ease);
            position: absolute;
            bottom: 0;
            left: 0;
            padding: 0 30px 25px;
            width: 100%;
            height: 100%;
            color: $dark-gray;

            @include bp-smallerer {
                padding: 0 30px 25px;
            }

            @include bp-medium {
                padding: 0 80px 50px;
            }

            .hero__block--blue & {
                @include linear-gradient-two-breaks(
                    top,
                    left,
                    bottom,
                    $black-barely-transparent,
                    0%,
                    $black-barely-transparent,
                    18%,
                    $black-semi-transparent-2,
                    89%,
                    $black-semi-transparent-2,
                    100%
                );

                @include bp-medium {
                    @include linear-gradient-two-breaks(
                        top,
                        left,
                        bottom,
                        $transparent,
                        0%,
                        $transparent,
                        33%,
                        $black-semi-transparent-2,
                        89%,
                        $black-semi-transparent-2,
                        100%
                    );
                }
            }

            .hero__block--white & {
                @include linear-gradient-two-breaks(
                    top,
                    left,
                    bottom,
                    $white-barely-transparent,
                    0%,
                    $white-barely-transparent,
                    18%,
                    $white-semi-transparent,
                    92%,
                    $white-semi-transparent,
                    100%
                );

                @include bp-medium {
                    @include linear-gradient-two-breaks(
                        top,
                        left,
                        bottom,
                        $white-transparent,
                        0%,
                        $white-transparent,
                        33%,
                        $white-semi-transparent-2,
                        89%,
                        $white-semi-transparent-2,
                        100%
                    );
                }
            }

            &--white {
                color: $white;
            }

            &--blue {
                color: $dark-gray;
            }
        }
    }

    &__title {
        @include align-self(flex-end);
        @include heading-s;
        width: 100%;
        font-family: $lexend-family;
        font-weight: $lexend-extra-bold;

        @include bp-min-smallerer {
            @include heading-m;
        }

        @include bp-min-smaller {
            margin-bottom: 20px;
            line-height: calculate-rem(38);
            font-size: calculate-rem(29);
        }

        @include bp-medium {
            line-height: calculate-rem(43);
            font-size: calculate-rem(33);
        }

        @include bp-extra-large {
            line-height: calculate-rem(60);
            font-size: calculate-rem(46);
        }

        &-greeting-name {
            word-break: break-all;
        }
    }

    &__subtitle {
        @include align-self(flex-end);
        @include body-m;
        width: 100%;
        font-family: $open-sans-family;
        font-weight: $open-sans-regular;
    }

    &__actions {
        @extend %flexbox;
        @include box-sizing(border-box);
        @include flex-wrap(wrap);
        @include transition(all, 0.4s, ease);
        @include opacity(1);
        position: relative;
        top: 0;
        width: 100%;

        &--animation {
            @include opacity(0);
            top: 30px;
        }

        &-item {
            @extend %flexbox;
            @include flex(1);
            @include box-sizing(border-box);
            min-width: 25%;
        }

        &-item__title {
            @include button-xs;
            text-align: center;
            font-family: $lexend-family;
            font-weight: $lexend-semi-bold;

            @include bp-medium {
                width: 75%;
            }

            @include bp-small {
                width: 85%;
            }
        }

        &-item-anchor {
            @extend %flexbox;
            @include flex-direction(column);
            @include align-items(center);
            @include transition(background-color 0.2s ease);
            position: relative;
            border-top: 3px solid;
            border-right: 1px solid;
            cursor: pointer;
            padding: 20px;
            width: 100%;
            text-decoration: none;
            font-weight: bold;

            @include bp-medium {
                padding: 35px 0 0;
                height: 131px;
            }

            .hero__actions--blue & {
                border-top-color: $blue;
                border-right-color: $white;
                border-bottom-color: $blue;
                background-color: $blue;
                color: $white;
            }

            .hero__actions--white & {
                border-top-color: $light-gray;
                border-right-color: $medium-gray;
                border-bottom-color: $medium-gray;
                background-color: $white;
                color: $dark-gray;
            }

            .hero__actions--blue &:hover .hero__actions-item__title,
            .hero__actions--white &:hover .hero__actions-item__title {
                text-decoration: underline;
            }
        }

        &-item__icon {
            @extend %flexbox;
            @include justify-content(center);
            @include align-items(center);
            @include transition(all 0.2s ease);
            @include border-radius(100px);
            margin-bottom: 7px;
            width: 25px;
            height: 25px;
            font-size: calculate-rem(25);

            @include bp-medium {
                margin-bottom: 20px;
                width: 40px;
                height: 40px;
                font-size: calculate-rem(30);
            }

            .hero__actions--blue & {
                background-color: $white;
                color: $blue;
            }

            .hero__actions--white & {
                background-color: $blue;
                color: $white;
            }
        }
    }

    &__actions-item-anchor {
        &::before {
            @include transition(all 0.2s ease);
            position: absolute;
            top: -3px;
            left: 50%;
            width: 0;
            content: '';
        }

        .hero__actions--blue &::before {
            border-top: 3px solid $white;
        }

        .hero__actions--white &::before {
            border-top: 3px solid $blue-brand;
        }
    }

    &__actions-item-anchor:hover {
        &::before {
            left: 0;
            width: 100%;
        }
    }

    &__picture-image {
        width: 100%;
        height: 100%;
        object-position: center top;
        object-fit: cover;

        &--greeting {
            background-color: $white;
            object-position: 25px 50%;
            object-fit: contain;

            @include bp-large-homepage-hero {
                object-position: center top;
            }

            @include bp-lt-large {
                width: 130%;
                height: 130%;
                object-position: 25px 15%;
            }

            @include bp-small {
                width: 100%;
                height: 100%;
                object-position: unset;
                object-fit: contain;
            }
        }

        &--greeting-night {
            background-color: $dark-blue;

            .is-oru & {
                background-color: $teal;
            }
        }
    }
}

.hero--oru {
    .hero__actions--blue {
        .hero__actions-item-anchor {
            border-top-color: $orange-brand;
            border-right-color: $teal;
            border-bottom-color: $teal;
            background-color: $orange;
            color: $teal;

            &::before {
                border-top: 3px solid $teal;
            }

            &:hover .hero__actions-item__title {
                text-decoration: underline;
            }
        }

        .hero__actions-item__icon {
            background-color: $teal;
            color: $white;
        }
    }

    .hero__actions--white {
        .hero__actions-item-anchor {
            color: $dark-gray;

            &::before {
                border-top: 3px solid $orange-brand;
            }
        }

        .hero__actions-item__icon {
            background-color: $orange;
            color: $teal;
        }
    }
}
