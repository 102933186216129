.register-success {
    margin: 40px 10px 10px;
    background-color: $white;
    padding: 50px 20px 0;

    @include bp-medium {
        margin: 50px 0;
        padding: 60px 100px;
    }

    &__cards-container {
        display: flex;
        margin: auto;
        max-width: 350px;
        flex-direction: column;
        justify-content: center;

        @include bp-medium {
            display: flex;
            padding: 0 25px;
            max-width: none;
            flex-direction: row;
            justify-content: center;
        }

        div:first-child {
            border-bottom: 2px solid $light-gray;

            @include bp-medium {
                margin-bottom: 0;
                border-right: 2px solid $light-gray;
                border-bottom: 0;
            }
        }
    }

    &__card {
        display: flex;
        padding: 40px 0 50px;
        flex-direction: column;
        align-items: center;

        @include bp-medium {
            display: flex;
            padding: 30px 20px;
            width: 50%;
            flex-direction: column;
            align-items: start;
        }
    }

    &__image {
        margin: 0 auto;
        padding: 0 48px 30px;
        height: 120px;
    }

    &__image-add-account {
        margin-top: 10px;
        height: 100px;

        @include bp-medium {
            margin: 10px auto;
        }
    }

    &__title {
        @include heading-l;
        margin: auto;
        max-width: 354px;
        text-align: center;
        font-family: $lexend-family;

        @include bp-medium {
            padding: 0;
            max-width: 812px;
        }
    }

    &__subtitle {
        @include body-l;
        margin-bottom: 20px;
        width: 100%;
        height: 80%;
        text-align: center;
        font-family: $open-sans-family;

        @include bp-medium {
            margin-bottom: 15px;
            padding-top: 20px;
        }
    }

    &__info-box {
        margin: 28px auto 35px;
        max-width: 1000px;

        @include bp-medium {
            margin: 40px auto;
            max-width: 812px;
        }
    }

    &__button {
        @extend .button, .button--flex;
        margin: 0 auto;
        max-width: 440px;

        &-icon {
            font-size: calculate-rem(30);
        }
    }
}
