// ============== PAY MY BILL HEADER COMPONENT =================

.pay-my-bill {
    background-color: $blue;
    padding: 50px 10px;
    text-align: center;
    color: $white;

    &--margin-bottom {
        margin-bottom: 15px;
        
        @include bp-medium {
            margin-bottom: 35px;
        }
    }

    &--big-padding-sides {
        padding-right: 20px;
        padding-left: 20px;

        @include bp-medium {
            padding-right: 100px;
            padding-left: 100px;    
        }
    }

    &__bill-matrix-paragraph {
        padding: 30px 0;

        > p {
            @include body-m;
            padding: 10px 0 0;
            color: $medium-gray;
            font-family: $open-sans-family;
            font-weight: $open-sans-regular;
        }

        &--no-padding,
        &--no-padding > p {
            padding: 0;
        }
    }

    &__subtitle {
        a {
            white-space: nowrap;
            color: $teal;

            // scss-lint:disable NestingDepth
            body:not(.is-oru) & {
                color: $white;
            }
        }
    }

    &__subtitle,
    &__link {
        @include body-l;
        font-family: $open-sans-family;
        font-weight: $open-sans-bold;

        &--padding-top {
            @include bp-medium {
                padding-top: 15px;
            }
        }

        &--uppercase {
            margin: 20px 0;
            text-align: center;
            text-transform: uppercase;
        }
        
        &-due {
            @include heading-s;
            font-family: $open-sans-family;
            font-weight: $open-sans-bold;
        }
    }

    &__link,
    &__account-link {
        display: inline-block;
        color: $white;
    }

    &__subtitle--big {
        @include heading-l;
        margin: 0 auto;
        max-width: 1000px;
        font-family: $lexend-family;
        font-weight: $lexend-extra-bold;
    }

    &__notification {
        margin-top: 50px;
        border: 1px solid $medium-gray;
        padding: 15px;
        color: $medium-gray;
        font-weight: $open-sans-semi-bold;

        &-icon {
            @include border-radius(50%);
            position: relative;
            left: 0;
            padding-right: 27px;

            &::before {
                position: absolute;
                top: -3px;
                left: -2px;
                font-size: calculate-rem(25);
            }
        }
    }

    &__account,
    &__account-link {
        @include body-s;
        font-family: $open-sans-family;

        &-number {
            font-weight: $open-sans-bold;
        }
    }

    &__title {
        @include display-l;
        display: inline-block;
        font-family: $lexend-family;
        font-weight: $lexend-extra-bold;

        @include bp-medium {
            padding-top: 30px;
        }
    }

    &__description {
        @include body-m;
        font-family: $open-sans-family;
        font-weight: $open-sans-semi-bold;

        &-container {
            margin: 25px auto 0;
            max-width: 510px;
        }
    }

    &__address-title {
        @include heading-s;
        font-family: $lexend-family;
        font-weight: $lexend-extra-bold;

        @include bp-medium {
            padding-left: 20px;
        }
    }

    &__date {
        margin: 0 auto;
        width: 90%;

        @include bp-extra-large {
            width: 75%;
        }
    }

    &__date,
    &__account-container,
    &__description-container {
        @extend %flexbox;
        @include justify-content(center);
        @include align-items(center);
    }

    &__button,
    &__button-link {
        @extend %inline-flex;
        @include justify-content(center);
        @include align-items(center);

        &:hover &-icon--rotate {
            transform: rotate(-90deg) scale(1.025) !important;
        }

        span[class*='icon-'] {
            @extend .button-icon;

            &::before {
                position: absolute;
                top: -3px;
                right: -7px;
                font-size: calculate-rem(30);
            }
        }

        &-icon--rotate {
            @include transform(rotate(-90deg));

            &::before {
                left: -7px;
            }
        }

        body:not(.mouse-user):not(.is-oru) &:focus {
            outline: none;

            &::before {
                @extend .coned-button-focus;
            }
        }

        body:not(.mouse-user) &:focus {
            outline: none;

            &::before {
                @extend .oru-button-focus;
            }
        }
    }

    &__button {
        @extend .button;
        margin-top: 15px;

        @include bp-medium {
            margin-top: 0;
        }

        &--wider {
            @extend .button--high-emphasis;
            width: 100%;
            text-transform: capitalize;

            @include bp-medium {
                padding-right: 100px;
                padding-left: 100px;
                width: auto;
            }
        }

        &-container {
            margin: 0 auto;
            padding-top: 45px;
            max-width: 600px;

            @include bp-medium {
                @include flexbox();
                @include flex-direction(row);
                @include justify-content(space-around);
            }
        }

        body:not(.is-oru) & {
            @extend .button--secondary-color;
        }
    }

    &__button-link {
        @extend .button-link;

        @include bp-medium {
            margin-top: 0;
        }

        &--small {
            @include button-s;
        }

        &-container {
            @include flexbox();
            @include flex-direction(column);
            @include align-items(center);
            margin: 0 auto;
            padding-top: 45px;
            max-width: 600px;

            @include bp-medium {
                @include flex-direction(row);
                @include justify-content(space-around);
            }

            &--column {
                padding-top: 25px;

                @include bp-medium {
                    @include flex-direction(column);
                }
            }
        }

        & + & {
            @include bp-small {
                margin-top: 20px;
            }
        }

        body:not(.is-oru) & {
            @extend .button-link--secondary-color;
        }
    }

    &__button-link-container {
        .pay-my-bill__button-link + .pay-my-bill__button-link {
            @include bp-medium {
                margin-left: 50px;
            }
        }

        &--column {
            .pay-my-bill__button-link {
                margin-top: 25px;
            }
        }
    }

    &__button-icon--left-side {
        span[class*='icon-'] {
            &::before {
                left: -11px;
            }
        }
    }

    &__radio--margin {
        margin-bottom: 20px !important;
    }

    &__batch-container {
        @include flexbox;
        @include flex-direction(column);
        @include align-items(flex-start);
        @include justify-content(space-between);

        border-bottom: 1px solid $medium-gray;
        padding: 20px 0;

        @include bp-medium {
            @include flex-direction(row);
            @include align-items(center);
        }

        &:first-child {
            border-top: 2px solid $medium-gray;
        }
    }

    &__batch-description {
        @include flexbox;
        @include align-items(center);
        padding-bottom: 5px;
    }

    &__batch-icon {
        @include flex-shrink(0);

        position: relative;

        margin-right: 15px;
        border-radius: 50%;
        width: 40px;
        height: 40px;

        font-family: 'coned-icons' !important;
        box-sizing: border-box;

        &::before {
            @include transform(translate(-50%, -50%));

            position: absolute;
            top: 50%;
            left: 50%;
            font-size: calculate-rem(30);
        }

        &-check {
            @extend .icon-check-status;
            background-color: $green;
            color: $white;
        }

        &-warning {
            @extend .icon-info;
            border: 2px solid $black;
            background-color: $dark-yellow;
            color: $black;
        }

        &-error {
            @extend .icon-info;
            background-color: $red;
            color: $white;
        }
    }

    &__batch-description-paragraph {
        @include body-l;
        text-align: left;
        font-family: $lexend-family;
        font-weight: $lexend-extra-bold;
    }

    &__batch-buttons {
        @include flexbox;
        @include flex-direction(column);
        padding-top: 20px;
        width: 100%;

        @include bp-medium {
            @include flex-direction(row);
            padding-top: 0;
            width: auto;
        }

        &--pay-batch {
            @extend .button, %inline-flex;
            margin: 10px 0;

            @include bp-medium {
                margin: 0 10px !important;
            }
        }

        &--pay-batch:focus {
            outline: none;

            body:not(.mouse-user):not(.is-oru) &::before {
                @extend .coned-button-focus;
            }

            body:not(.mouse-user) &::before {
                @extend .oru-button-focus;
            }
        }
    }

    .coned-tooltip--white-blue .coned-tooltip--open,
    .coned-tooltip--white-red .coned-tooltip--open {
        &::after {
            top: -9px;
            right: -6px;
            font-size: calculate-rem(30);

            @include bp-medium {
                top: -7px;
            }
        }
    }
}

.pay-my-bill-card {
    @extend %flexbox;
    @include justify-content(center);
    @include align-items(center);
    top: 0;
    left: 0;
    width: 100%;
    height: 50px;
    color: $white;

    &__title {
        @include body-m;
        font-family: $lexend-family;
        font-weight: $lexend-extra-bold;
    }

    &--red {
        border-bottom: 2px $red solid;
        background: repeating-linear-gradient(-60deg, $red, $red 20px, $red 20px, $red 40px);
    }

    &--offset-margin-top-50 {
        margin: -50px 0 50px;
    }
}

.pay-my-bill--oru {
    .pay-my-bill {
        background-color: $orange;
        color: $teal;
    }
}

.error-messages-container {
    @include bp-medium {
        padding-left: 20px;
    }

    &__title {
        color: $red;
        font: $lexend-extra-bold calculate-rem(18) / calculate-rem(24) $lexend-family;

        @include bp-medium {
            font: $lexend-extra-bold calculate-rem(22) / calculate-rem(24) $lexend-family;
        }
    }

    &__list {
        font: $lexend-regular calculate-rem(18) / calculate-rem(24) $lexend-family;

        @include bp-medium {
            font: $lexend-regular calculate-rem(22) / calculate-rem(24) $lexend-family;
        }
    }

    &__list-element {
        margin-bottom: 5px;
        color: $red;
    }

    &__paragraph-cta {
        margin-bottom: 10px;
        font: calculate-rem(16) / calculate-rem(20) $open-sans-family;

        @include bp-medium {
            margin-bottom: 0;
            font: calculate-rem(19) / calculate-rem(30) $open-sans-family;
        }
    }
}
