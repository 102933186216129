// ============== SEARCH BAR RESULTS =================

.search-results-bar {
    background-color: $white;

    &-wrapper {
        @extend %flexbox;
        @include flex-direction(column);
        background-color: $white;
    }

    &__related-nav {
        @extend %flexbox;
        display: none;

        @include bp-medium {
            @include flexbox();
            margin: 0 0 80px 30px;
        }
    }

    &__title {
        @include body-s;
        text-transform: uppercase;
        font-family: $open-sans-family;
        font-weight: $open-sans-bold;

        &-icon {
            margin-right: 10px;
        }
    }

    &__list {
        margin-left: 20px;
        max-width: 60%;
        font: calculate-rem(14) $open-sans-family;
        font-weight: $open-sans-semi-bold;

        &-item {
            display: inline-block;
            margin-right: 10px;
        }
    }
}

.search-results-bar--oru {
    .search-box__search-button {
        color: $teal;
    }
}
