@mixin button-l {
    line-height: calculate-rem(20);
    font-size: calculate-rem(18);
}

@mixin button-m {
    line-height: calculate-rem(18);
    font-size: calculate-rem(16);
}

@mixin button-s {
    line-height: calculate-rem(18);
    font-size: calculate-rem(14);
}

@mixin button-xs {
    line-height: calculate-rem(14);
    font-size: calculate-rem(12);
}
