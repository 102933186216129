// ============== ENROLL UNITS ANIMATION TRUMPS =================

.loading-animation {
    .submit-button {
        &__top {
            background-color: $white;
        }

        &__container--bg-container {
            background-color: $light-gray;
        }

        &__container--bg-container-reversed,
        &__slice-right,
        &__slice-left {
            background-color: $blue;
        }

        &__slice-right,
        &__slice-left {
            &--white {
                background-color: $light-gray;
            }
        }
    }

    .form__actions {
        padding: 0;
    }

    .submit-button__container--oru {
        &.submit-button__container--bg-container-reversed,
        .submit-button__slice-right,
        .submit-button__slice-left {
            background-color: $teal;

            &--white {
                background-color: $light-gray;
            }
        }
    }
}
