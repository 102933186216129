.program-savings-filters {
    width: 100%;

    &-page {
        margin-top: -50px;
        width: 100%;

        @include bp-medium {
            margin-top: -95px;
        }
    }

    &__wrapper {
        margin: 0 auto;
        background: $white;
        padding: 24px 32px;
        width: 100%;
        max-width: $content-max-width;
        box-sizing: border-box;

        &-headings {
            margin-bottom: 24px;
            width: 100%;
        }

        &-content {
            @include flexbox;
            @include gap(32px);
            margin-bottom: 40px;
        }

        // scss-lint:disable NestingDepth
        &-selects {
            display: none;
            width: 100%;
            min-width: 310px;
            max-width: 310px;

            @include bp-extra-large {
                display: block;
            }

            .select-multiple {
                margin-bottom: 24px;
            }
        }

        // scss-lint:enable NestingDepth

        &-items {
            @include flexbox;
            @include flex-direction(column);
            @include gap(16px);
            width: 100%;
        }

        &-sort {
            @include flexbox;
            @include align-items(center);
        }
    }

    &__tag {
        @include flexbox;
        @include flex-wrap(nowrap);
        @include body-s;
        border: 1px solid $dark-gray;
        border-radius: 6px;
        background-color: $lighter-blue;
        padding: 8px 16px;
        color: $dark-gray;
        font-family: $open-sans-family;
        font-weight: $open-sans-regular;
        text-wrap: nowrap;
    }

    &__content {
        padding: 0 20px;
        width: 100%;
    }

    [aria-hidden="true"]:not(.modal):not(.coned-radio__indicator) {
        display: none;
        visibility: hidden;
    }

    &__headings {
        display: none;

        @include bp-extra-large {
            @include flexbox;
            @include gap(32px);
        }

        &-container {
            @include flexbox;
            @include justify-content(space-between);
            @include align-items(center);
            border-bottom: 3px solid $blue;
            padding: 0 0 12px;
            width: 100%;
            box-sizing: border-box;

            &--filter {
                min-width: 310px;
                max-width: 310px;
            }
        }

        &-mobile {
            @include flexbox;

            @include bp-extra-large {
                display: none;
            }
        }

        &-information {
            @include body-s;
            color: $dark-gray;
            font-family: $open-sans-family;
            font-weight: $open-sans-bold;
        }

        // scss-lint:disable NestingDepth
        &-span {
            &-filter {
                @include flexbox;
                @include align-items(center);
                @include gap(6px);
                @include body-m;
                color: $dark-gray;
                font-weight: $open-sans-bold;

                .icon-filter {
                    font-size: 1.6rem;
                }
            }
        }

        // scss-lint:enable NestingDepth
    }

    &__item {
        @include flexbox;
        @include justify-content(center);
        @include align-items(center);
        @include body-m;
        min-height: 100px;
        color: $medium-gray;
        font-family: $open-sans-family;
        font-weight: $open-sans-bold;

        // scss-lint:disable NestingDepth
        .accordion {
            max-width: 100%;

            .collapsible-container__item {
                border: 0;

                &-content {
                    @include flexbox;
                    @include flex-direction(column);
                    padding: 0;
                    box-sizing: border-box;
                }
            }
        }

        // scss-lint:enable NestingDepth
    }

    &__sort {
        &-span {
            @include body-s;
            margin-right: 8px;
            color: $dark-gray;
            font-family: $open-sans-family;
            font-weight: $open-sans-bold;
        }

        &-dropdown {

            // scss-lint:disable NestingDepth
            .dropdown__button {
                padding: 4px 4px 4px 16px;
                min-width: 172px;

                &-text {
                    @include body-m;
                    font-size: calculate-rem(14);
                }

                .icon-carrot {
                    font-size: 3rem;
                }
            }

            .dropdown__content {
                padding: 4px;
            }

            // scss-lint:enable NestingDepth
        }
    }

    &__button {
        &-clear {
            @extend .button-link;
            @extend .button-link--hover-underline;
            @include btn-focus-outline;
            text-decoration: underline;
            font-size: calculate-rem(14);
            font-weight: $open-sans-bold;

            &:hover {
                text-decoration: none;
            }

            &[aria-hidden="true"] {
                display: none;
            }

            // scss-lint:disable NestingDepth
            &--focus-visible {
                opacity: 0;
                height: 0;
                overflow: hidden;

                &:focus {
                    opacity: 1;
                    height: auto;
                    overflow: unset;
                }
            }

            // scss-lint:disable NestingDepth
        }

        &-modal {
            @extend .button--outline-theme;
            @include btn-focus-outline;
            @include body-m;
            @include flexbox;
            @include justify-content(center);
            @include align-items(center);
            position: relative;
            background: $white;
            padding: 5px 10px;
            color: $dark-gray;
            font-weight: $open-sans-semi-bold;

            .icon-filter {
                position: static;
                margin-right: 6px;
                font-size: calculate-rem(20);
            }
        }
    }

    &__heading {
        @include heading-s;
        margin-top: 16px;
        margin-bottom: 10px;
        color: $dark-gray;
        font-family: $lexend-family;
        font-weight: $lexend-extra-bold;

        &-empty {
            @include flexbox;
            @include flex-direction(column);
            @include align-items(center);
            @include gap(20px);
            @include heading-s;
            margin-bottom: 16px;
            color: $medium-gray-93;
            font-family: $lexend-family;
            font-weight: $lexend-extra-bold;

            // scss-lint:disable NestingDepth
            i {
                @include flexbox;
                @include justify-content(center);
                @include align-items(center);
                border-radius: 100%;
                background-color: $light-gray;
                width: 100px;
                height: 100px;
                color: $medium-gray-93;

                &::before {
                    font-size: 8rem;
                }
            }

            // scss-lint:enable NestingDepth
        }
    }

    &__paragraph {
        @include body-m;
        margin-bottom: 32px;
        font-family: $lexend-family;
        font-weight: $lexend-regular;

        &-empty {
            @include body-m;
            text-align: center;
            color: $medium-gray-93;
            font-family: $lexend-family;
            font-weight: $lexend-regular;
        }
    }

    &__cta {
        border-top: 1px solid $medium-gray-93;
        width: 100%;
        text-decoration: none;
        box-sizing: border-box;

        &-text {
            @include flexbox;
            @include justify-content(space-between);
            @include align-items(center);
            padding: 8px 16px;
            width: 100%;
            box-sizing: border-box;
        }

        &-icon-text {
            @include flexbox;
            @include gap(8px);
            @include align-items(center);
            @include body-m;
            font-family: $lexend-family;
            font-weight: $lexend-extra-bold;
        }

        i {
            text-decoration: none;
            font-size: 4rem;
            font-weight: $lexend-regular;
        }
    }

    &__accordion {

        &-wrapper,
        &-content {
            margin: 0;
            padding: 0;
            width: 100%;
            max-width: 100%;
        }

        &-header {
            @include flexbox;
            @include justify-content(center);
            @include align-items(center);
            @include gap(4px);
            border-top: 1px solid $medium-gray-93;
            background-color: $white;
            width: 100%;
            height: 56px;

            &-text {
                text-transform: uppercase;
                text-decoration: underline;
                color: $blue;
            }

            .icon-carrot {
                position: static;
                top: unset;
                right: unset;
                bottom: unset;
                left: unset;
                color: $blue;
                font-size: 2.5rem;
                font-weight: $lexend-extra-bold;
            }
        }
    }

    .select-multiple {
        label {
            @include body-m;
            margin-bottom: 0;
            color: $dark-gray;

            &:first-of-type {
                @include body-l;
            }
        }

        .coned-checkbox {
            padding: 0 5px;
        }
    }
}

.card-program-savings {
    border: 1px solid $medium-gray-93;
    width: 100%;

    &__wrapper {
        &-content {
            @include flexbox;
            @include flex-direction(column);
            @include gap(16px);

            @include bp-medium {
                @include flex-direction(row);
                padding: 0 16px 0 0;
            }
        }

        &-picture {
            width: 100%;

            @include bp-medium {
                width: 310px;
                min-width: 310px;
                max-width: 310px;
                height: auto;
            }

            picture {
                @include flexbox;
            }

            img {
                width: 100%;
                max-width: 100%;
                min-height: 180px;
                object-fit: cover;

                @include bp-medium {
                    min-height: 238px;
                }
            }
        }

        &-text {
            padding: 0 16px;

            @include bp-extra-large {
                padding: 0;
            }
        }

        &-tags {
            @include flexbox;
            @include flex-wrap(wrap);
            @include gap(8px);
            margin-bottom: 24px;
        }

        &-empty {
            padding-top: 40px;
            max-width: 500px;

            @include bp-medium {
                padding-top: 60px;
            }
        }
    }

    &__empty {
        @include flexbox;
        @include justify-content(center);
        width: 100%;
    }
}
