.module {
    width: 100%;

    &--top-spacing {
        margin-top: 50px;
    }

    &--bottom-spacing {
        margin-bottom: 50px;
    }
}

.module-1 {
    @extend %content-gutter;
}

.module-2 {
    img {
        width: 100%;
    }
}
