// ============== SESSION TIME OUT =================

.session-timeout {

    z-index: 1000000 !important;

    &__title {
        font: $lexend-extra-bold calculate-rem(30) $lexend-family;

        @include bp-medium {
            font-size: calculate-rem(33);
        }
    }

    &__sub-title {
        padding-top: 20px;
        font: calculate-rem(16) $open-sans-family;
    }

    &__timer {
        padding-top: 15px;
        height: 70px;
        text-align: left;
        font: $lexend-extra-bold calculate-rem(50) $lexend-family;
    }

    &__buttons {
        @include flex-direction(column);
        margin: 25px 0 0;
        text-align: right;

        @include bp-medium {
            margin: 0;
        }
    }

    &__button-stay-in {
        margin-right: 0 !important;
    }

    &__button-stay-in,
    &__button-close {
        @extend .button, %inline-flex;
    }

    &__button-log-out {
        @extend .button, .button--secondary-color;

        text-align: center;

        @include bp-medium {
            margin-right: 20px !important;
        }
    }

    .popup-container {
        border-bottom: none !important;
    }
}
