// ============== ENROLL UNITS BUILDING =================

.enroll-units-building {
    &__description {
        @include body-l;
        color: $dark-gray;
        font-family: $open-sans-family;

        &-container {
            position: relative;
            margin: 0 30px;
            border-bottom: 1px solid $medium-gray;
            padding: 40px 0;
            max-width: 786px;
            text-align: center;

            @include bp-medium {
                margin: 0 auto;
            }
        }
    }

    &__pagination {
        background: $white;

        ul {
            padding-bottom: 35px;
        }

        .pagination--desktop {
            padding-top: 0;
        }
    }

    &__item {
        padding: 0 30px;
        text-align: center;

        @include bp-medium {
            margin: 0 auto;
            max-width: 786px;
            text-align: initial;
        }

        &-container {
            @extend %flexbox;
            @include flex-wrap(wrap);
            @include align-items(center);
            @include justify-content(space-between);
            position: relative;
            border-bottom: 1px solid $medium-gray;
            padding: 39px 0;
            text-align: left;
        }

        &-address {
            @include body-l;
            display: inline-block;
            width: 60%;
            color: $dark-gray;
            font-family: $lexend-family;
            font-weight: $lexend-extra-bold;
        }

        &-cta {
            @extend .button-link, .button-link--extra-low-emphasis;
            margin: 0;
            white-space: nowrap;
        }
    }
}
