// ============== FORM PROGRESS BAR COMPONENT =================

.form-progress-bar {
    background: linear-gradient(90deg, $green 50%, $white 50%);
    height: 60px;

    &--finish {
        background: $green;
    }

    &-wrapper {
        background-color: $white;
    }

    &__progress {
        @extend %flexbox;
        position: absolute;
        z-index: 1;
    }

    &__progress-pointer {
        @include border-radius(0 50% 50% 0%);
        display: block;
        background-color: $green;
        width: 60px;
        height: 60px;

        @include bp-progress-bar-large {
            margin-left: 0;
            width: 60px;
            height: 60px;
        }
    }

    &__progress-body {
        @include transition(all, 0.4s, ease);
        display: block;
        background-color: $green;
        width: 0;
        height: 60px;

        &--static {
            width: 0;

            @include bp-progress-bar-large {
                margin-left: 0;
                width: 108px;
            }
        }
    }

    &__list {
        @extend %flexbox;
        @include justify-content(space-between);
        position: relative;
        z-index: 2;
        padding: 0 10px;
    }

    &__list--hide-title {
        @include bp-form-progres-bar {
            .form-progress-bar__item-name {
                display: none;
            }

            .form-progress-bar__item-dot {
                margin-left: 10px;
            }
        }
    }

    &__item {
        @extend %flexbox;
        @include align-items(center);
        @include transition(all, 0.4s, ease);
        cursor: pointer;
        min-width: 1%;
        max-width: 100%;
        height: 60px;

        &--small {
            @include bp-progress-bar-large {
                .form-progress-bar__item-number {
                    padding-right: 5px;
                    font: $lexend-extra-bold calculate-rem(18) / calculate-rem(24) $lexend-family;
                }

                .form-progress-bar__item-number::after {
                    content: none;
                }

                .form-progress-bar__item-name {
                    display: none;
                }

                .form-progress-bar__item-dot {
                    margin-left: 10px;
                }
            }
        }

        &-button {
            @include flexbox;
            @include align-items(center);
            @include justify-content(center);
            @include body-s;
            background: 0;
            text-decoration: none;
            color: inherit;

            body:not(.mouse-user):not(.is-oru) &:focus {
                @extend .coned-focus-outline;
            }

            body:not(.mouse-user) &:focus {
                @extend .oru-focus-outline;
            }

            &:focus::before {
                content: none !important;
            }
        }
    }

    &__item--active {
        .form-progress-bar__item-button {
            color: $white;
        }

        .form-progress-bar__item-name {
            text-decoration: underline;
        }

        .form-progress-bar__item-number {
            text-decoration: underline;

            @include bp-progress-bar-large {
                text-decoration: none;     
            }
        }

        .form-progress-bar__item-dot {
            background-color: transparent;

            &::before {
                @extend .icon-check-status::before;
                position: absolute;
                top: -12px;
                left: -8px;
                color: $white;
                font: calculate-rem(30) 'coned-icons';

                @include bp-progress-bar-large {
                    top: -8px;
                    left: -15px;
                }
            }
        }

        &.form-progress-bar__item--valid {
            .form-progress-bar__item-dot::before {
                color: transparent;
            }
        }
    }

    &__item--current {
        .form-progress-bar__item-number {
            text-decoration: none;
        }

        .form-progress-bar__item-name {
            text-decoration: none;
        }

        .form-progress-bar__item-dot {
            background-color: $white;

            &::before {
                display: none;
            }
        }
    }

    &__item--valid {
        .form-progress-bar__item-button {
            text-decoration: none;
            color: $medium-gray;
        }

        .form-progress-bar__item-dot {
            background-color: $light-gray;
        }
    }

    &__item--static {
        cursor: default;
    }

    &__item-disabled {
        cursor: default;
        pointer-events: none;
    }

    &__finish {
        @include justify-content(center);
        @include align-items(center);
        display: none;
        background-color: $green;
        width: 100%;
        height: 60px;
        color: $white;
        font: calculate-rem(16) / calculate-rem(22) $open-sans-family;
        font-weight: $open-sans-bold;
    }

    &__finish--active {
        @include flexbox;
        position: relative;
        z-index: 10;
    }

    &__item-dot {
        @include border-radius(50%);
        @include flex-shrink(0);
        position: relative;
        margin-top: 19px;
        margin-right: 19px;
        margin-bottom: 19px;
        margin-left: 5px;
        background-color: $light-gray;
        width: 8px;
        height: 8px;

        @include bp-progress-bar-large {
            margin-left: 0;
            width: 14px;
            height: 14px;
        }
    }

    &__item-number {
        font-family: $lexend-family;
        font-weight: $lexend-extra-bold;

        &::after {
            @include bp-progress-bar-large {
                content: '.';
            }
        }
    }

    &__item-name {
        display: none;
        padding-left: 5px;

        @include bp-progress-bar-large {
            display: block;
            margin: 0 15px 0 0;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            font-family: $open-sans-family;
            font-weight: $open-sans-semi-bold;
        }
    }

    &__paragraph-information {
        border-bottom: solid 1px $dark-gray;
        background-color: $white;
        padding: 4px 0;
        text-align: center;
        color: $dark-gray;
        font: $lexend-extra-bold calculate-rem(18) / calculate-rem(42) $lexend-family;

        @include bp-progress-bar-large() {
            display: none;
        }
    }
}

.form-step {
    display: none;
}
