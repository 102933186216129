.content-block {
    &__text-container-padding {
        padding: 40px;

        @include bp-medium {
            padding: 30px;
        }

        @include bp-large {
            padding: 52px 30px;
        }

        @include bp-portrait {
            padding: 10% 50px;
        }

        @include bp-large {
            padding: 15% 100px;
        }
    }

    &__category {
        @include body-s;
        margin-bottom: 13px;
        font-family: $open-sans-family;
        font-weight: $open-sans-bold;

        @include bp-medium {
            margin-bottom: 12px;
        }
    }

    &__title {
        @include heading-l;
        display: inline-block;
        margin-bottom: 22px;
        font-family: $lexend-family;
        font-weight: $lexend-extra-bold;
    }

    &__description {
        @include heading-s;
        margin-bottom: 30px;
        font-family: $open-sans-family;
    }

    &__cta {
        @extend .button;
    }
}
