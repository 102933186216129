.account-focus {
    margin: 0 !important;
    border-bottom: 3px solid $blue !important;
    width: 100% !important;

    @include bp-medium {
        margin-top: 5% !important;
        padding: 60px 118px 0 !important;
        width: auto !important;
        max-width: 1108px;
    }

    &__description {
        margin: 0 8px 20px;
        text-align: center;

        @include bp-medium {
            margin: 0 auto 20px;
            max-width: 70%;
        }
    }

    &__account {
        margin-bottom: 20px;
        background: none;
        cursor: pointer;
        width: 100%;
        text-align: left;
        text-decoration: none;

        @include bp-large {
            width: 48%;
            height: auto;
        }

        &--inactive {
            padding-top: 15px !important;
        }

        p {
            cursor: pointer;
        }

        body:not(.mouse-user):not(.is-oru) &:focus {
            @extend .coned-focus-outline;
        }

        body:not(.mouse-user) &:focus {
            @extend .oru-focus-outline;
        }

        &:focus::before {
            content: none !important;
        }
    }

    &__accounts-container {
        @include flexbox;
        @include flex-direction(row);
        @include flex-wrap(wrap);
        @include justify-content(space-between);

        .transactional__paragraph--address {
            margin-top: 0;
        }
    }

    &__accounts-wrapper {
        @include flexbox;
        @include flex-direction(row);
        @include flex-wrap(wrap);
        @include justify-content(space-between);
    }

    &__link {
        @include justify-content(center !important);
        @include body-m;
        margin: 0 !important;
        padding: 20px 0 40px !important;
        font-family: $open-sans-family;
        font-weight: $open-sans-extra-bold;
    }
}

.popup--oru {
    .popup-container {
        border-bottom: 3px solid $orange !important;
    }
}
