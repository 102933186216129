.image-button {
    @include flex-basis(48%);
    @include transition(border 0.2s ease-in-out);
    margin: 1%;
    border: 2px solid $medium-gray;
    background-color: $white;
    cursor: pointer;
    padding: 10px;
    max-height: 250px;
    overflow: hidden;

    @include bp-smaller {
        @include flex-basis(100%);
        margin: 2% 0;
    }

    @include bp-medium {
        @include flex-basis(23%);
        max-height: 270px;
    }

    &--overflow-visible-focus {
        overflow: visible;
    }

    &:hover,
    &:focus {
        .image-button__picture {
            @include transform(scale(1.1));
        }
    }

    body:not(.mouse-user) &:focus {
        @extend .oru-focus-outline;
    }

    body:not(.mouse-user):not(.is-oru) &:focus {
        @extend .coned-focus-outline;
    }

    &__picture {
        @include transition(all 0.2s ease-in-out);
        display: block;
        position: relative;
        margin: 0 auto;
        padding-top: 170px;
        width: 165px;
        overflow: hidden;

        @include bp-extra-small {
            width: 149px;
        }

        @include bp-smaller {
            padding-top: 60%;
            width: 60%;
        }

        @include bp-medium {
            padding-top: 85%;
            width: 85%;
        }

        img {
            @include transform(translate(-50%, -50%));
            position: absolute;
            top: 50%;
            left: 50%;
            width: 65%;
            height: 65%;
        }
    }

    &__title {
        @include body-m;
        padding: 0 10px;
        height: 100px;
        text-align: center;
        font-family: $lexend-family;
        font-weight: $lexend-extra-bold;
    }

    &__link {
        text-decoration: none;
        color: inherit;
    }
}
