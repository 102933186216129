// ============== CARDS TILE TYPE IMAGE COMPONENT =================

.card-tile-grid {
    &--type-full {
        min-height: 400px;
    }

    &--type-image,
    &--type-full {
        .card-tile-grid__link-wrapper {
            @include flex-direction(column);
        }
    }

    &--type-image {
        .card-tile-grid__title {
            @include bp-medium {
                display: -webkit-box;
                overflow: hidden;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
            }
        }
    }
}

.card-tile-grid--oru {
    .card-tile-grid--type-image,
    .card-tile-grid--type-full {
        .card-tile-grid__icon {
            background-color: $orange;
        }
    }
}
