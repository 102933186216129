// ============== MODAL LOGGED IN COMPONENT =================

.modal-logged-in {
    background-color: $white;

    &__logo {
        position: absolute;
        top: 13px;
    }

    &-wrapper {
        @extend %flexbox;
        @include flex-direction(column);
        @include transition(all 0.2s ease);
        display: none;
        position: relative;
        z-index: 19;
        border-bottom: 1px solid $medium-gray;
        background-color: $white;
        height: 0;
        overflow: hidden;

        @include bp-medium {
            @include flex-direction(row);
            @include align-items(center);
        }

        &--open {
            @include bp-small {
                @include transition(all 0.6s ease);
                display: block;
                position: absolute;
                top: 0;
                background-color: $white;
                width: 100%;
                height: calc(100vh - 3px);
                overflow-y: scroll;
            }
        }
    }

    &-close {
        position: absolute;
        top: -10px;
        right: -10px;
        z-index: 999;
        outline: 0;
        background-color: transparent;
        width: auto;
        height: auto;
        color: $blue;
        font-size: calculate-rem(60);

        @include bp-medium {
            top: 5px;
        }

        .is-oru & {
            color: $teal;
        }

        &:focus {
            body:not(.mouse-user):not(.is-oru) &::after {
                @extend .coned-button-focus;
            }

            body:not(.mouse-user) &::after {
                @extend .oru-button-focus;
            }
        }
    }

    &__title {
        margin: 100px 30px 50px;
        font: $lexend-extra-bold calculate-rem(40) $lexend-family;
    }

    &__list {
        @extend %flexbox;
        @include flex-direction(column);
        margin: 60px 30px 0;

        @include bp-medium {
            @include flex-direction(row);
        }
    }

    &__item {
        margin: 20px 0;

        @include bp-medium {
            margin: 0;
        }

        &--profile,
        &--preferences,
        &--logout {
            padding-left: 30px;
        }

        &--profile {
            margin-top: 40px;
            margin-bottom: 0;
        }

        &--preferences {
            margin-bottom: 0;
        }
    }

    &__link {
        display: block;
        position: relative;
        margin-right: 30px;
        width: 100%;
        color: $dark-gray;
        font: $lexend-extra-bold calculate-rem(30) $lexend-family;
    }

    &__icon {
        color: $blue;
    }

    .primary-nav-item__title {	
        @include heading-m;
        padding: 15px 25px 0;	
        color: $dark-gray;	
        font-family: $lexend-family;
        font-weight: $lexend-extra-bold;
    }
}
