// ============ ADD THIS ===============

.social-share {
    .at-share-tbx-element {
        display: none !important;
    }

    &-link {
        display: inline-block;
        margin-right: 10px;
    }

    &-icon {
        @extend .additional-button, .additional-button--primary-color;
        @include border-radius(50%);
        padding: 9px 0 0 9px;
        width: 32px;
        height: 32px;

        &::before {
            position: absolute;
            top: 1px;
            left: 1px;
            font-size: calculate-rem(40);
        }
    }
}

/*
.social-share {
    .at-share-tbx-element {
        .at-share-btn {
            margin: 0 10px 0 0 !important;
            border: 1px solid $alto !important;
            background-color: $blue !important;
            padding: 5px !important; 
        }

        .at-icon-wrapper svg {
            fill: $white !important;
        }
    }

    .at-share-btn:active {
        @include transform-important(none);
        outline: none;
        background-color: $blue !important;
    }

    .at-share-btn:focus {
        @include transform-important(none);
        outline: none;
    }
}

.social-share--oru {
    .at-share-tbx-element {
        .at-share-btn {
            background-color: $teal !important;
        }

        .at-icon-wrapper svg {
            fill: $white !important;
        }
    }
    
    .at-share-btn:active {
        background-color: $black !important;
    }
}*/
