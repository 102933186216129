// ==============   SUBMIT A METER FORM COMPONENT =================

.submit-a-meter-form {
    &__canvas-container {
        margin-bottom: 25px;

        &-item {
            display: block;

            @include bp-medium {
                display: inline-block;
            }
        }

        &--hidden {
            display: none;
        }
    }

    &__container {
        display: block;

        &--hidden {
            display: none;
        }

        &--last {
            padding-bottom: 40px;
        }

        &--position {
            position: absolute;
            top: 0;
        }
    }

    &__error {
        @extend .icon-more-info;
        @include align-items(center);
        display: none;
        position: relative;
        margin: 10px 0;
        padding-left: 30px;
        color: $red;
        font: calculate-rem(13) $open-sans-family;
        font-weight: $open-sans-semi-bold;

        &::before {
            @include flexbox;
            @include border-radius(50%);
            @include justify-content(center);
            @include align-items(center);
            position: absolute;
            left: 0;
            background-color: $red;
            width: 23px;
            height: 23px;
            color: $white;
            font-family: 'coned-icons';
            background-clip: padding-box;
        }
    }
}
