// ============== BREAKPOINTS =================

$page-max-width: 1700px;
$content-max-width: 1400px;
$desktop-container-width: 960px;

$min-width-small: 480px; // Used for mobile landscape
$min-width-medium: 768px; // Used for tablet portrait
$min-width-large: 960px; // Used for desktop and tablet landscape
$min-width-extra-large: 1024px; // Used for desktop and ipad pro

$max-width-smallest: 320px;
$max-width-smaller: 440px;
$max-width-extra-small: 479px;
$max-width-small: 767px;
$max-width-medium: 959px;
$max-width-large: 1366px; // Used for ipad pro portrait
$max-width-extra-large: 1023px; // Used for desktop and ipad pro

// COMPONENTS BREAKPOINTS
$min-width-page-hero-small: 350px;
$min-width-page-hero-medium: 750px;
$min-width-page-hero-large: 1000px;
$min-width-page-hero-extra-large: 1300px;
$min-width-primary-nav: 380px;
$min-width-tips-filters: 440px;
$min-width-bill-card: 425px;
$min-width-bill-card-alternative: 375px;
$min-width-mob-alerts: 500px;
$min-width-mid-small: 535px;
$min-width-coned-hero: 550px;
$min-width-dashboard-header: 620px;
$min-width-coned-images: 900px;
$min-width-green-data: 940px;
$min-width-coned-title: 1001px;
$min-width-large-header: 990px;
$min-width-in-page-banner: 850px;
$min-width-hero: 1086px;
$min-width-extra-large-header: 1270px;
$min-width-faceted-search: 768px;
$min-width-nav-icons: 750px;
$min-width-cards: 1100px;
$min-width-landing-hero-xl: 1280px;
$min-width-homepage-hero-xl: 1600px;
$min-width-billing: 1024px;
$min-width-billing-tabs: 600px;
$min-width-opower-widget: 873px;
$min-width-report-expand: 768px;
$min-width-table-tabs-by-nav: 1229px;
$min-width-enroll-units: 786px;
$min-width-start-service-profile: 1100px;
$min-width-unsupported-browser-banner: 1540px;

$max-width-small-devices: 380px;
$max-width-smallerer: 320px;
$max-width-contact-us: 672px;
$max-width-article-actions: 945px;
$max-width-contact-us-hero-mid: 1180px;
$max-width-card-tiles: 1140px;
$max-width-faceted-search: 1090px;
$max-width-nav-icons: 849px;
$max-width-from-progress-bar: 1200px;
$max-width-payment-agreement: 600px;
$max-width-login-modal: 870px;
$max-width-report-expand: 950px;
$max-width-cards: 1099px;

$min-width-extra-large-hero: 1366px;
$min-width-progress-bar-large: 900px;

$min-width-graphics-mobile: 480px;
$min-width-graphics-desktop: 768px;
$min-width-graphics-desktop-large: 1024px;
$min-width-graphics-desktop-extra-large: 1400px;
