// ============== CARDS TILE TYPE QUOTE COMPONENT =================

.card-tile {
    &--type-quote {
        @extend %flexbox;
        @include align-items(center);
        min-height: 275px;

        &::before {
            border-top: 0;
        }

        .card-tile__zoom,
        .card-tile__zoom:hover {
            background-color: $blue;
            height: 100%;
        }
    }

    &__info-container--type-quote {
        position: relative;
        margin: auto;
        padding-bottom: 20px;
        overflow: hidden;
        text-align: center;
        color: $white;
    }

    &__title--type-quote {
        margin-top: 32px;

        @include bp-medium {
            margin-top: 0;
        }

        &::before {
            display: block;
            position: relative;
            line-height: 40px;
            font-size: calculate-rem(64);
            content: '“';
        }
    }

    &__author {
        @include body-l;
        display: block;
        margin-top: 30px;
        font-family: $open-sans-family;
        font-weight: $open-sans-bold;
    }
}

.card-tile--oru {
    .card-tile {
        &--type-quote {
            .card-tile__zoom,
            .card-tile__zoom:hover {
                background-color: $orange;
            }
        }

        &__info-container--type-quote {
            color: $teal;
        }
    }
}
