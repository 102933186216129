// ============== CORRESPONDENCE COMPONENT =================

.correspondence {
    background: $white;

    &__header {
        margin-top: 85px;

        &--no-margin-top {
            margin-top: 0;
        }

        &-wrapper {
            padding: 45px 30px 20px;

            @include bp-medium {
                padding: 95px 15% 0;
            }

            @include bp-billing {
                padding: 95px 20% 0;
            }

            &--no-padding {
                padding: 0;
            }
        }
    }

    &__title {
        @include heading-l;
        border-bottom: 3px solid $medium-gray;
        padding-bottom: 20px;
        color: $dark-gray;
        font-family: $lexend-family;
        font-weight: $lexend-extra-bold;

        @include bp-small {
            text-align: center;
        }
    }
}

.correspondence-body {
    &__title {
        padding: 45px 30px 0;
        text-align: center;

        @include bp-medium {
            padding: 50px 15% 0;
            text-align: initial;
        }

        @include bp-billing {
            padding: 50px 20% 0;
        }

        &s {
            border-bottom: 2px solid $blue;
            padding: 20px 0;
        }

        &--doc {
            display: inline-block;
            width: 100%;
            font: $lexend-extra-bold calculate-rem(20) / calculate-rem(20) $lexend-family;

            @include bp-medium {
                width: 80%;
            }
        }

        &--sendby {
            display: none;
            width: 19%;
            text-transform: uppercase;
            font: calculate-rem(12) / calculate-rem(30) $open-sans-family;
            font-weight: $open-sans-bold;

            @include bp-medium {
                display: inline-block;
            }
        }
    }

    &__table {
        padding-top: 50px;
        text-align: center;

        @include bp-medium {
            padding: 20px 15% 95px;
        }

        @include bp-billing {
            padding: 20px 20% 95px;
        }

        &--no-padding {
            padding: 0;
        }
    }

    &__show-more {
        margin: 15px auto;

        body:not(.mouse-user):not(.is-oru) &:focus {
            outline: none;

            &::before {
                @extend .coned-button-focus;
            }
        }

        body:not(.mouse-user) &:focus {
            outline: none;

            &::before {
                @extend .oru-button-focus;
            }
        }
    }

    &__message {
        padding: 45px 20px;

        @include bp-medium {
            padding: 50px 15%;
        }

        @include bp-billing {
            padding: 50px 20%;
        }

        &-text {
            @include body-m;
            color: $dark-gray;
            font-family: $open-sans-family;
        }
    }
}

.correspondence-item {
    @include transition(all, 0.2s, ease);
    border-bottom: 1px solid $medium-gray;
    padding: 10px 20px;

    @include bp-medium {
        padding: 40px 0;
    }

    &__container {
        text-align: center;

        @include bp-medium {
            @include flexbox;
            @include align-items(center);
        }

        &-block {
            @include align-items(center);
            display: inherit;

            @include bp-medium {
                width: 80%;

                &--right {
                    width: 20%;
                }
            }
        }
    }

    &__date {
        @include body-m;
        padding: 5px;
        color: $medium-gray;
        font-family: $open-sans-family;
        font-weight: $open-sans-semi-bold;
    }

    &__type {
        @include body-m;
        padding: 5px;
        text-transform: uppercase;
        color: $dark-gray;
        font-family: $open-sans-family;
        font-weight: $open-sans-extra-bold;

        @include bp-medium {
            padding-left: 30px;
            width: 40%;
        }

        @include bp-coned-title {
            width: 40%;
        }
    }

    &__link {
        @extend .button-link, .button-link--extra-low-emphasis;
        display: block;
        margin: 10px 0;
        white-space: nowrap;

        @include bp-medium {
            margin: 0 0 0 30px;
        }
    }

    &__send-by {
        @include body-m;
        padding: 5px;
        color: $dark-gray;
        font-family: $open-sans-family;
        font-weight: $open-sans-semi-bold;
    }
}

.correspondence--oru {
    .correspondence-body__titles {
        border-color: $orange;
    }
}
