.table {
    width: 100%;
    table-layout: fixed;
    box-sizing: border-box;

    tbody tr {
        display: table-row;
        visibility: visible;

        &[aria-hidden="true"] {
            display: none;
            visibility: hidden;
        }
    }

    &__row {
        @include flexbox;
        @include flex-direction(column);
        border-bottom: 1px solid $medium-gray;

        @include bp-medium {
            display: table-row;
            border-bottom: 0;
        }
    }

    &__cell {
        @include flexbox;
        margin-bottom: 0;
        padding: 20px 0 0;
        vertical-align: top;
        text-align: start;

        @include bp-medium {
            display: table-cell;
            border-bottom: 1px solid $medium-gray;
            padding: 20px 0 20px 20px;
        }


        &:last-child {
            padding: 20px 0;

            @include bp-medium {
                padding: 20px;
            }
        }
    }

    &__text {
        @include body-s;
        font-family: $open-sans-family;
        font-weight: $open-sans-regular;
        word-wrap: break-word;

        &--bold {
            font-weight: $open-sans-bold;
        }

        &--blue {
            color: $blue;
        }

        &--underline {
            text-decoration: underline;

            &:hover {
                text-decoration: none;
            }
        }

        &--hover-underline {
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }
        }

        &--s {
            @include body-s;
        }

        &--m {
            @include body-m;
        }

        &--l {
            @include body-l;
        }
    }

    &__title {
        @include body-s;
        text-transform: uppercase;
        color: $dark-gray;
        font-family: $open-sans-family;
        font-weight: $open-sans-bold;
        word-wrap: break-word;
    }
}
