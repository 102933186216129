// ============== ANCHOR LINK COMPONENT =================

.anchor-link {
    position: relative;
    z-index: 10;
    margin-top: -10px;
    background-color: $blue;
    width: 100%;

    @include bp-medium {
        margin-top: -35px;
    }

    &__wrapper {
        @extend %flexbox;
    }

    &__list {
        width: 100%;
    }

    &__list-nav {
        display: block;
        background-color: $blue;
        text-align: center;
    }

    &--hidden {
        height: 0;
        overflow: hidden;

        &-display {
            display: none;
        }
    }

    &__select-text {
        @extend %text-truncate;
        position: absolute;
        z-index: 1;
        padding: 10px 70px 0 30px;
        width: calc(100% - 100px);
        height: 40px;
        text-align: center;
        color: $teal;
        font: $lexend-extra-bold calculate-rem(14) / calculate-rem(32) $lexend-family;

        @include bp-medium {
            padding: 12px 94px 0 50px;
            width: calc(100% - 144px);
            height: 48px;
            font: $lexend-extra-bold calculate-rem(22) / calculate-rem(32) $lexend-family;
        }

        body:not(.is-oru) & {
            color: $white;
        }
    }

    &__select {
        @include opacity(0);
        position: relative;
        z-index: 2;
        border: 0;
        background-color: $transparent;
        padding: 0 70px 0 30px;
        height: 50px;
        text-shadow: none;
        font: $lexend-extra-bold calculate-rem(14) / calculate-rem(32) $lexend-family;

        @include bp-medium {
            padding: 0 50px;
            height: 60px;
            font: $lexend-extra-bold calculate-rem(22) / calculate-rem(32) $lexend-family;
        }

        &:focus {
            body:not(.mouse-user):not(.is-oru) & {
                @extend .coned-focus-outline;
            }

            body:not(.mouse-user) & {
                @extend .oru-focus-outline;
            }
        }

        &-icon {
            @include border-radius(50%);
            top: 22%;
            right: 30px;
            background-color: $white;
            width: 30px;
            height: 30px;
            color: $blue;

            @include bp-medium {
                right: 50px;
                width: 34px;
                height: 34px;
            }
        }

        &-icon::before {
            position: absolute;
            top: -2px;
            left: -5px;

            @include bp-medium {
                top: 0;
                left: -2px;
            }
        }

        option {
            color: $black;
        }

        &-container {
            position: relative;
            z-index: 2;
        }
    }

    &__nav-item {
        @include transition(border-bottom-color 0.2s 0.1s ease-out);
        @include body-s;
        border-bottom: 4px solid transparent;
        background-color: transparent;
        padding: 15px 20px 15px 15px;
        width: auto;
        color: $white;
        font-family: $lexend-family;
        font-weight: $lexend-extra-bold;
    }

    &__nav-item:hover {
        border-bottom: 4px solid $cornflower;
    }

    &__nav-item:focus {
        body:not(.mouse-user):not(.is-oru) & {
            @extend .coned-focus-outline;
        }

        body:not(.mouse-user) & {
            @extend .oru-focus-outline;
        }
    }

    &__nav-item--active {
        border-bottom: 4px solid $white;
    }

    .coned-select__label {
        color: $blue;
    }
}

.anchors-wrappers {
    margin-top: 35px;

    @include bp-medium {
        margin-top: -10px;
    }
}

.anchor-link--oru {
    background-color: $orange;

    .anchor-link__select-icon {
        color: $teal;
    }

    .anchor-link__list-nav {
        background-color: $orange;
    }

    .coned-select__label {
        color: $teal;
    }

    .anchor-link__nav-item {
        color: $teal;

        &:hover {
            border-bottom: 4px solid $teal;
        }
    }

    .anchor-link__nav-item--active {
        border-bottom: 4px solid $teal;
    }
}
