// ============== PAGE =================

.page-content {
    position: relative;
    padding-top: 60px;

    @include bp-medium {
        padding-top: 120px;
    }

    .padding-top {
        padding-top: 95px;
    }

    .padding-bottom {
        padding-bottom: 95px;
    }
}

.page-content--disabled {
    pointer-events: none;
}
