// ============== ENROLL UNITS LIST =================

.enroll-units-list {
    padding: 34px 30px 42px;

    @include bp-medium {
        margin: 0 auto;
        padding: 62px 0 40px;
        width: 90%;
        max-width: 786px;
    }

    &__wrapper {
        margin: 10px 10px 20px;
        background-color: $white;

        @include bp-medium {
            margin: 10px auto;
        }

        &--no-margin {
            margin: 0;
        }

        &--transparent {
            background: transparent;
        }
    }

    &__top-container {
        @include flexbox();
        @include flex-direction(column);
        border-bottom: 2px solid $light-gray;
        padding-bottom: 20px;

        @include bp-medium {
            @include flex-direction(row);
            @include justify-content(space-between);
            @include align-items(flex-end);
            border-bottom-width: 3px;
            padding-bottom: 22px;
        }

        &-left {
            padding-bottom: 7px;

            @include bp-medium {
                padding-bottom: 0;
                max-width: 420px;
            }

            @include bp-large {
                max-width: 500px;
            }
        }

        &-right {
            @include inline-flex();

            @include bp-medium {
                @include justify-content(flex-end);
            }
        }

        &-subtitle {
            padding: 0 0 10px;
            text-transform: uppercase;
            color: $dark-gray;
            font: calculate-rem(14) $open-sans;
            font-weight: $open-sans-extra-bold;
        }

        &-title {
            display: block;
            color: $dark-gray;
            font: $lexend-extra-bold calculate-rem(20.2) / calculate-rem(26) $lexend-family;

            @include bp-medium {
                font: $lexend-extra-bold calculate-rem(25) / calculate-rem(30) $lexend-family;
            }

            @include bp-large {
                font: $lexend-extra-bold calculate-rem(30) / calculate-rem(36) $lexend-family;
            }
        }

        &-tooltip {
            @include flexbox();
            @include flex-direction(row);
            @include align-items(center);
        }
    }

    .top-container-tooltip {
        &__text {
            display: inline;
            text-transform: uppercase;
            color: $blue;
            font: calculate-rem(14) $open-sans;
            font-weight: $open-sans-extra-bold;
        }
    }

    &__item {
        @include flexbox();
        @include flex-direction(row);
        @include align-items(center);
        position: relative;
        border-bottom: 1px solid $medium-gray;
        min-height: 110px;

        @include bp-medium {
            height: 86px;
            min-height: 86px;
        }

        &-heading-cta {
            @extend .button-link,.button-link--extra-low-emphasis;
            @include flexbox();
            @include align-self(flex-start);
            margin-top: 10px;
            margin-left: 65px;
            text-transform: uppercase;
            white-space: nowrap;

            @include bp-medium {
                @include align-self(center);
                margin: 0 10px 0 0;
            }
        }

        &--heading {
            @include flexbox();
            @include flex-direction(column);
            @include justify-content(center);
            border-bottom-width: 1px;

            @include bp-medium {
                @include flexbox();
                @include flex-direction(row);
                @include align-items(center);
                @include justify-content(space-between);
            }
        }

        &-check {
            @include flexbox();
            @include flex-direction(row);
            @include align-items(center);
            width: 100%;
            min-width: 125px;

            &--column {
                @include flex-direction(column);
                @include align-items(flex-start);
            }
        }

        &-box {
            margin-right: 5px;
            padding: 10px 15px;
        }

        &-name,
        &-owner {
            @include body-m;
            color: $dark-gray;
            font-family: $open-sans-family;
            font-weight: $open-sans-bold;
            
        }

        &-owner {
            margin-left: 5px;

            @include bp-medium {
                margin-left: 78px;
            }
        }

        &-error {
            @include body-s;
            padding: 0 0 20px;
            font-family: $open-sans-family;
            font-weight: $open-sans-semi-bold;
        }

        &--hidden {
            display: none;
        }

        &--no-height {
            height: auto;
        }

        &--disabled {
            .enroll-units-list__item-box {
                visibility: hidden;
                pointer-events: none;
            }

            .enroll-units-list__item-owner,
            .enroll-units-list__item-name {
                color: $nobel;
                pointer-events: none;
            }
        }
    }

    &__counters {
        display: none;
    }

    &__bottom {
        &-container {
            @include flexbox();
            @include flex-direction(column);
            @include align-items(center);
            @include justify-content(center);
            border-top: 1px solid $medium-gray;

            @include bp-medium {
                @include flex-direction(row);
                @include align-items(center);
                @include justify-content(center);
                height: 91px;
            }

            &--no-border {
                border: 0;
            }

            &--no-height {
                height: auto;
            }
        }

        &-cta {
            @extend .button-link;
            margin: 30px 0;

            @include bp-medium {
                margin: 0 22px;
            }
        }

        &-button {
            @extend .button;

            &:hover:not([disabled]) span {
                display: inline-block !important;
            }
        }
    }

    &__bottom-button {
        &:focus {
            outline: none;

            body:not(.mouse-user):not(.is-oru) &::before {
                @extend .coned-button-focus;
            }

            body:not(.mouse-user) &::before {
                @extend .oru-button-focus;
            }
        }
    }

    &__info {
        border-bottom: 3px solid $medium-gray;
        padding: 0 0 36px;

        @include bp-medium {
            padding: 0 0 56px;
        }

        &-title,
        &-text {
            @include body-l;
            display: inline;
            color: $dark-gray;
            font-family: $open-sans-family;
        }

        &-title {
            font-weight: $open-sans-bold;
        }

        &-link {
            @extend .button-link;
        }

        &-section {
            display: block;
            padding-bottom: 20px;

            @include bp-medium {
                padding-bottom: 5px;
            }

            &:last-child {
                padding: 0;
            }
        }
    }

    &__section {
        &--empty {
            padding: 56px 0 30px;
        }
    }

    &--no-padding {
        padding: 0 0 30px;
    }

    &--no-margin {
        margin: 0;
    }

    &--full-width {
        width: 100%;
    }
}

.enroll-units-list--oru {
    .enroll-units-list__item-heading-cta {
        color: $teal;
    }
}

// ============== EMPTY PAGE =================
.enroll-units-empty {
    margin-bottom: 20px;

    @include bp-medium {
        margin-bottom: 50px;
    }
}
