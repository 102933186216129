// ============== FOOTER COMPONENT =================

.footer {
    &__primary {
        border-top: 1px solid $medium-gray;
        background-color: $light-gray;

        &-items {
            @include bp-medium {
                @include flexbox;
                @include flex-flow(row wrap);
                @include justify-content(space-between);
                padding: 100px 50px 20px;
            }
        }

        &-item {
            display: block;

            @include bp-medium {
                display: inline-block;
                margin-bottom: 80px;
                padding-right: 3%;
                width: 17%;
            }
        }

        &-item:not(:last-child) {
            border-bottom: solid 1px $medium-gray;

            @include bp-medium {
                border-bottom: 0;
            }
        }

        &-item-text {
            display: none;
            
            @include bp-medium {
                @include body-m;
                display: block;
                padding-bottom: 15px;
                overflow: hidden;
                text-overflow: ellipsis;
                color: $dark-gray;
                font-family: $lexend-family;
                font-weight: $lexend-extra-bold;
            }
        }

        &-item-btn {
            @include body-m;
            position: relative;
            outline: 0;
            background-color: transparent;
            padding: 25px 55px 25px 30px;
            width: 100%;
            text-align: left;
            color: $dark-gray;
            font-family: $lexend-family;
            font-weight: $lexend-extra-bold;

            @include bp-medium {
                display: none;
            }

            body:not(.mouse-user):not(.is-oru) &:focus::before {
                @extend .coned-button-focus;
            }

            body:not(.mouse-user) &:focus::before {
                @extend .oru-button-focus;
            }
        }

        &-item-icon {
            position: absolute;
            top: 30%;
            right: 20px;
            font-size: calculate-rem(35);
        }

        &-item-icon--open {
            @include transform(rotate(180deg));
            padding: 0 10px 0 0;
        }

        &-item-links {
            @include transition(all 0.2s ease);
            @include opacity(0);
            display: none;
            height: 0;
            overflow: hidden;

            @include bp-medium {
                @include opacity(1);
                display: block;
                padding: 4px 0 0 4px;
                height: auto;
            }
        }

        &-item-links--social {
            @include bp-medium {
                margin-top: -10px;
                padding-left: 0;
            }
        }

        &-item-links--open {
            @include opacity(1);
            display: block;
            padding-bottom: 35px;
            height: auto;

            @include bp-medium {
                padding-bottom: 0;
            }
        }

        &-item-link {
            padding: 5px 25px 25px 30px;

            @include bp-medium {
                padding: 0 0 11px;
            }
        }

        &-item-link:last-child {
            padding: 0 25px 0 30px;

            @include bp-medium {
                padding: 0;
            }
        }

        &-link-icon {
            @include border-radius(50%);
            display: inline-block;
            position: relative;
            z-index: 1;
            margin-right: 10px;
            background-color: $dark-gray;
            padding: 9px 0 0 9px;
            width: 24px;
            height: 24px;
            color: $white;

            &::before {
                position: absolute;
                top: 2px;
                left: 2px;
                font-size: calculate-rem(30);
            }
        }

        &-link-animation {
            @include transition(all 0.3s ease);
            @include border-radius(50%);
            position: absolute;
            top: 50%;
            left: 50%;
            z-index: -1;
            background-color: $black;
            width: 0;
            height: 0;
        }

        &-item-anchor {
            @extend .not-underlined-anchor;
            @include body-xs;
            font-family: $open-sans-family;
            font-weight: $open-sans-semi-bold;

            &,
            .is-oru & {
                color: $dark-gray;
            }
        }

        &-item-anchor--social {
            @include flexbox;
            @include align-items(center);
            position: relative;
            text-transform: uppercase;
        }
    }

    &__primary-item-anchor:hover {
        text-decoration: underline;
        color: $black;

        .footer__primary-link-animation {
            top: 0;
            left: 0;
            z-index: -1;
            width: 33px;
            height: 33px;
        }
    }

    &__primary-item-anchor {
        body:not(.mouse-user):not(.is-oru) &:focus {
            @extend .coned-focus-outline;

            &::before {
                content: none;
            }
        }

        body:not(.mouse-user) &:focus {
            @extend .oru-focus-outline;

            &::before {
                content: none !important;
            }
        }
    }

    &__secondary-wrapper {
        @include bp-medium {
            padding: 50px;
        }
    }

    &__secondary {
        background-color: $dark-gray;
        font-family: $open-sans-family;
        font-weight: $open-sans-semi-bold;

        &-items {
            @include body-xs;
            @include flexbox;
            @include justify-content(space-between);
            @include flex-wrap(wrap);
            padding: 25px 30px 20px;
            text-align: left;

            @include bp-medium {
                display: inline-block;
                padding: 0 50px 0 0;
            }
        }

        &-item {
            white-space: nowrap;
            flex-basis: 40%;

            @include bp-medium {
                display: inline-block;
            }
        }

        &-item:not(:last-child) {
            @include bp-medium {
                margin-right: 80px;
            }
        }

        &-link {
            @extend .not-underlined-anchor;

            &,
            .is-oru & {
                color: $white;
            }
        }
    }

    &__disclaimer {
        @include body-xs;
        padding: 0 30px 25px;
        text-align: left;
        color: $white;

        @include bp-medium {
            display: inline-block;
            padding: 0;
        }
    }

    &--add-margin-bottom {
        margin-bottom: 120px;

        @include bp-medium {
            margin-bottom: 72px;
        }
    }
}
