// ============== ENROLL UNITS COMPONENT =================

.data-connections {
    padding: 48px 30px 38px;

    @include bp-medium {
        margin: 0 auto;
        padding: 67px 0 60px;
        width: 90%;
        max-width: 786px;
    }

    &--padding-bottom-106 {
        padding-bottom: 106px;
    }

    &--side-padding-mobile-20 {
        padding-right: 20px;
        padding-left: 20px;
    }

    &--padding-top-mobile-67 {
        padding-top: 67px;
    }

    &__wrapper {
        margin: 10px;
        background-color: $white;

        @include bp-medium {
            margin: 10px auto;
        }
    }

    &__title {
        @include heading-m;
        font-family: $lexend-family;
        font-weight: $lexend-extra-bold;
    }

    &__subtitle {
        @include body-m;
        padding-bottom: 16px;
        color: $dark-gray;
        font-family: $open-sans-family;
        font-weight: $open-sans-semi-bold;

        @include bp-medium {
            padding-bottom: 12px;
        }
    }

    &__form {
        background-color: transparent;
    }

    &__form-section {
        margin-bottom: 55px;

        &--top {
            margin-bottom: 35px;

            @include bp-medium {
                @include flexbox();
                @include justify-content(space-between);
                margin-bottom: 48px;
            }
        }

        &--no-margin {
            margin: 0;
        }

        &--margin-bottom-48 {
            margin-bottom: 48px;
        }
        
        &--margin-bottom-mobile-60 {
            @include bp-small {
                margin-bottom: 60px;
            }
        }
        
        &--margin-bottom-desktop-40 {
            @include bp-medium {
                margin-bottom: 40px;
            }
        }

        &--header {
            @include flexbox();
            @include align-items(center);
            margin-bottom: 12px;
        }
    }

    &__form-section-column {
        margin-bottom: 45px;

        @include bp-medium {
            margin-bottom: 0;
            width: 49%;
        }

        @include bp-large {
            width: 48%;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__form-section-title {
        @include body-m;
        margin-bottom: 5px;
        color: $medium-gray;
        font-family: $open-sans-family;
        font-weight: $open-sans-semi-bold;

        &--margin-bottom-20 {
            margin-bottom: 20px;
        }
    }

    &__form-section-subtitle {
        @include body-s;
        margin-bottom: 5px;
        color: $medium-gray;
        font-family: $open-sans-family;
    }

    &__form-caption {
        @include body-m;
        color: $dark-gray;
        font-family: $open-sans-family;

        @include bp-medium {
            padding-left: 20px;
        }

        a {
            body:not(.mouse-user):not(.is-oru) &:focus {
                @extend .coned-focus-outline;
            }

            body:not(.mouse-user) &:focus {
                @extend .oru-focus-outline;
            }  

            &:focus::before {
                content: none !important;
            }
        }

        &--bold {
            font-weight: $open-sans-bold;
        }
    }

    &__form-checkbox-box {
        padding: 10px 15px;
    }

    &__form-checkbox-label {
        a {
            @extend .button-link;

            body:not(.mouse-user):not(.is-oru) &:focus {
                @extend .coned-focus-outline;
            }

            body:not(.mouse-user) &:focus {
                @extend .oru-focus-outline;
            }  

            &:focus::before {
                content: none !important;
            }
        }
    }

    &__form-submit-wrapper {
        @include flexbox();
        @include justify-content(center);
        margin: 0 15px;
    }

    &__icon {
        margin-right: 4px;
        padding: 9px 13px;
        width: 54px;
        height: auto;
    }

    &__paragraph {
        @include body-m;
        padding-bottom: 4px;
        font-family: $open-sans-family;

        &--bold {
            font-weight: $open-sans-bold;
        }
    
        &s-container {
            padding-bottom: 20px;
            color: $dark-gray;
        }
    }

    &__fieldset-subheader {
        @include body-m;
        color: $medium-gray;
        font-family: $open-sans-family;
        font-weight: $open-sans-semi-bold;
    }

    &--display-inline-block {
        display: inline-block;
    }
}
