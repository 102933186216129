// ============ CHECKBOX SWITCH ===============
.coned-checkbox-switch {
    @include flexbox();
    @include justify-content(flex-end);
    @include flex-direction(column);
    @include align-items(center);
    margin: 10px;
    text-align: left;

    &__input {
        opacity: 0;

        &--on-focus {
            &:focus + label {
                outline: 3px solid $green;
            }
        }
    }

    &__toggle {
        @include border-radius(20px);
        @include transition(all, 0.2s, ease);
        display: inline-block;
        position: relative;
        border: 2px solid $medium-gray;
        background-color: $white;
        cursor: pointer;
        width: 55px;
        height: 30px;

        @include bp-smaller {
            width: 48px;
        }

        &::after {
            @extend .icon-check-status::before;
            @include transition(all, 0.2s, ease);
            display: none;
            position: absolute;
            right: 0;
            z-index: 2;
            color: $gallery;
            font: calculate-rem(30) 'coned-icons';
        }
    }

    &__toggle-handler {
        @include border-radius(50%);
        @include transition(all, 0.2s, ease);
        @include transform(translateX(0) rotate(0.001deg));
        display: inline-block;
        position: relative;
        top: -1px;
        left: -1px;
        z-index: 1;
        background-color: $medium-gray;
        width: 32px;
        height: 32px;
    }

    &--edit-profile {
        @include align-items(flex-end);
    }

    &--focus {
        padding: 2px;

        body:not(.mouse-user):not(.is-oru) & {
            @extend .coned-focus-outline;
        }

        body:not(.mouse-user) & {
            @extend .oru-focus-outline;
        }
    }
}

.coned-checkbox-switch__input:checked + .coned-checkbox-switch__toggle {
    border-color: $green;

    &::after {
        display: inline;
        right: 0;
        color: $white;
    }

    .coned-checkbox-switch__toggle-handler {
        @include transform(translateX(25px) rotate(0.001deg));
        border-color: $green;
        background-color: $green;

        @include bp-smaller {
            @include transform(translateX(18px));
        }
    }
}
