// ============== BOPA STATUS COMPONENT =================

.bopa-status {
    margin: -10px 0 25px;
    background: $blue;
    padding: 0 12px 0 28px;
    height: 120px;

    @include bp-medium {
        margin: -34px 0 35px;
        padding: 0 15px 0 80px;
        height: 60px;
    }

    &__container {
        @include flexbox();
        @include align-items(center);
        @include justify-content(space-between);
        margin: 0 auto;
        flex-direction: column;

        @include bp-medium {
            height: 100%;
            flex-direction: row;
        }
    }

    &__left-container {
        @include flexbox();
        @include flex-wrap(wrap);
        margin-top: 10px;
        margin-bottom: 5px;

        @include bp-medium {
            @include flex-direction(row);
            @include align-items(center);
        }
    }

    &__right-container {
        @include flex-shrink(0);
        margin-left: 40px;
        padding-top: 10px;
        align-self: flex-start;

        @include bp-medium {
            align-self: auto;
        }
    }

    &__icon {
        position: relative;
        margin-right: 15px;
        width: 25px;
        height: 25px;
        line-height: 2;
        color: $white;

        @include bp-medium {
            line-height: 1;
        }

        &::before {
            position: absolute;
            top: -20px;
            left: -7px;
            font-size: calculate-rem(40);

            @include bp-medium {
                top: -7px;
            }
        }
    }

    &__title {
        @include body-l;
        padding-top: 10px;
        color: $white;
        font-family: $lexend-family;
        font-weight: $lexend-extra-bold;

        @include bp-medium {
            padding-top: 0;
        }
    }

    &__subtitle,
    &__number {
        @include body-m;
        padding-top: 5px;
        color: $white;
        font-family: $open-sans-family;

        @include bp-medium {
            padding-top: 0;
        }
    }

    &__subtitle {
        margin-left: 40px;

        @include bp-medium {
            margin-left: 10px;
        }
    }

    &__number {
        font-weight: $open-sans-bold;
    }

    &__button {
        @extend .button-link, .button-link--secondary-color;

        @include bp-medium {
            margin: 10px 23px;
        }
    }

    &--alert {
        background: $red;
    }
}
