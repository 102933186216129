.live-chat {
    &__container {
        @include flexbox;
        @include flex-direction(column);

        &--center {
            @include justify-content(center);
        }             
    }

    &__informational-box {
        margin: 0 auto 48px;

        @include bp-medium {
            margin: 20px auto 40px;
        }

        &--stop {
            margin: -30px auto 20px !important;

            @include bp-medium {
                margin: -10px auto 25px !important;
            }
        }

        &--stop-no-auth {
            margin: 0 auto 20px;
            border: 0;
            padding: 0 16px 4px;

            @include bp-medium {
                margin: 0 auto 24px;
            }
            

        }

        &--transfer-stop {
            margin: 24px auto;

            @include bp-medium {
                margin: 50px auto 40px;
            }
        }

        &--transfer-start {
            margin: 8px auto 48px;

            @include bp-medium {
                margin: 20px auto 40px;
            }
        }
    }
    
    &__button {
        @extend .button, .button--high-emphasis; 
        margin: 0;

        @include bp-medium {
            margin: 0 auto;
            width: 236px;
        }

        &--center {
            @extend .button--center;
        }

        &--stop-no-auth {
            width: 100%;
        }

        &--start-service {
            margin: 0 auto;
            width: 440px;            
            
            @include bp-medium {
                width: 236px;
            }
        }
         
        &:focus {
            overflow: visible;
        }
    }

    &__no-available {
        margin: -30px 0;

        @include bp-medium {
            margin: -10px 0;
        }

        &--transfer-stop {
            margin: 40px 0 0;
            text-align: center;

            @include bp-medium {
                margin: 50px 0 0;
            }    
        }

        &--transfer-start {
            margin: 24px 0 48px;

            @include bp-medium {
                margin: 30px 0;
            }    
        }
    }
}
