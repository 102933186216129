// ============ RADIO ===============

.coned-chart-radio {
    @extend %flexbox;
    display: inline-flex;
    position: relative;
    margin-right: 10px;
    margin-left: 10px;
    cursor: pointer;
    color: $disabled-gray;
    font: calculate-rem(12) $open-sans-family;
    font-weight: $open-sans-bold;

    &__input {
        @include opacity(0);
        position: absolute;
        z-index: -1;
    }

    &__label-name {
        padding-top: 12px;

        &--nowrap {
            white-space: nowrap;
        }
    }

    &__indicator {
        @include border-radius(50%);
        @include transition(all 0.4s ease);
        @include flex-shrink(0);
        @include justify-content(center);
        @include align-items(center);
        display: inline-block;
        position: relative;
        margin-right: 5px;
        border: 2px solid $medium-gray;
        background-color: $white;
        width: 40px;
        min-width: 30px;
        height: 40px;

        &::after {
            @include border-radius(50%);
            @include transition(all 0.3s ease);
            @include transform(rotate(-90deg) scale(1, 1));
            display: block;
            position: absolute;
            top: 50%;
            left: 50%;
            background: $green;
            width: 0;
            height: 0;
            content: '';
        }
    }

    &__group {
        @include bp-medium {
            @include flexbox();
            @include flex-direction(row);
            @include justify-content(space-between);
        }

        @include bp-large {
            @include justify-content(space-around);
        }
    }

    /* Hover and focus states */
    &__input:checked ~ .coned-chart-radio__indicator {
        border: 2px solid $green;
    }

    /* Disabled state */
    &__input:disabled ~ .coned-chart-radio__indicator {
        @include opacity(0.6);
        background-color: $green;
        pointer-events: none;
    }

    /* Show circle mark */
    &__input:checked ~ .coned-chart-radio__indicator::after {
        display: block;
        top: 3px;
        left: 3px;
        width: 34px;
        height: 34px;
    }

    /* Disabled circle colour */
    &__input:disabled ~ .coned-chart-radio__indicator::after {
        background: $white;
    }
}
