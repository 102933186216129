// ============== TEXT AND VIDEO COMPONENT =================

.text-and-video-content {
    @include flexbox;
    @include flex-direction(column);
    padding: 0 25px;

    @include bp-medium {
        @include flex-direction(row);
        padding: 0;
    }

    &__media-container {
        @include flexbox;
        @include flex-direction(column);
        position: relative;
        background-color: $white;
        padding-top: 83.1%;
        // padding-top: 421.44px / 1127.34px * 100%;
        height: 0;
        overflow: hidden;

        @include bp-medium {
            @include flex-grow(1);
            @include flex-shrink(1);
            @include flex-basis(0);
            @include justify-content(center);

            padding-top: 41.4%;

            &--right {
                order: 1;
            }
        }
    }

    &__text-container {
        @include flexbox;
        @include flex-direction(column);
        @include justify-content(center);
        background-color: $white;

        @include bp-medium {
            @include flex-grow(1);
            @include flex-shrink(1);
            @include flex-basis(0);
        }
    }

    &__text-container--link {
        &:focus {
            body:not(.mouse-user):not(.is-oru) & {
                @extend .coned-focus-outline;
            }

            body:not(.mouse-user) & {
                @extend .oru-focus-outline;
            }

            &::before {
                content: none !important;
            }
        }
    }

    &__video {
        width: 100%;
    }

    &__button {
        position: absolute;
        right: 0;
        bottom: 5%;
        margin-right: 16px !important;
        background: transparent;

        @include bp-medium {
            margin-right: 33px !important;
        }
    }

    &__button--blue {
        body:not(.mouse-user) &:focus {
            outline: none;

            &::before {
                @extend .coned-button-focus;
            }
        }
    }

    &__button--orange {
        body:not(.mouse-user) &:focus {
            outline: none;

            &::before {
                @extend .oru-button-focus;
            }
        }
    }

    &__button_icon {
        display: inline-block;
        border: 2px solid $blue;
        border-radius: 50px;
        background-color: $white;
        padding: 7px;
        width: 45px;
        height: 45px;
        color: $blue;
        box-sizing: border-box;

        &::before {
            position: absolute;
            top: 13px;
            left: 14px;
            font-size: calculate-rem(18);
        }

        .is-oru & {
            border: 2px solid $teal;
            color: $teal;
        }
    }

    .coned-video__player {
        display: inline-block;

        video {
            pointer-events: none;
        }
    }

    .vjs-control-bar,
    .vjs-big-play-button,
    .vjs-menu {
        display: none !important;
    }
}
