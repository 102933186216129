// ============== 404 - NOT FOUND COMPONENT =================

.not-found {
    @include box-sizing(border-box);
    margin: 10px;
    background-color: $blue;
    max-width: $content-max-width;

    @include bp-medium {
        @include flexbox;
        @include justify-content(center);
        @include align-items(center);
        margin: 45px auto;
        padding: 30px 0;
    }

    &__image-container {
        @include box-sizing(border-box);
        width: 100%;

        @include bp-medium {
            width: 40%;
        }

        &-picture {
            display: block;
            padding: 75px 0;
        }

        &-img {
            display: block;
            margin: 0 auto;
            width: 31%;

            @include bp-medium {
                width: auto;
            }
        }
    }

    &__info-container {
        @include box-sizing(border-box);
        padding: 0 50px;
        width: 100%;
        vertical-align: bottom;
        color: $white;

        @include bp-medium {
            @include flexbox;
            @include justify-content(center);
            @include align-items(center);
            width: 58%;
        }

        &-block {
            height: 100%;

            @include bp-medium {
                padding-top: 40px;
            }
        }

        &-title {
            @include display-m;
            @include transition(all, 0.4s, ease);
            display: block;
            font-family: $lexend-family;
            font-weight: $lexend-extra-bold;
        }

        &-byline {
            @include transition(all, 0.4s, ease);
        }

        &-em-dash {
            display: inline-block;
            margin-right: 15px;
            background-color: $white;
            width: 50px;
            height: 3px;
            vertical-align: super;
        }

        &-subtitle {
            @include body-m;
            display: inline-block;
            padding: 20px 0;
            text-transform: uppercase;
            font-family: $open-sans-family;
            font-weight: $open-sans-extra-bold;

            @include bp-min-smallerer {
                padding: 30px 0;
            }
        }

        &-text {
            @include heading-m;
            @include transition(all, 0.4s, ease);
            display: block;
            padding-bottom: 75px;
            font-family: $lexend-family;
            font-weight: $lexend-regular;

            @include bp-medium {
                width: 84%;
            }
        }
    }
}

.not-found--oru {
    background-color: $orange;

    &__info-container {
        color: $teal;

        &-em-dash {
            background-color: $teal;
        }
    }
}
