// ============== CARDS TILE BG COMPONENT =================

.card-tile {
    &--lighter-bg,
    &--darker-bg {
        &::before {
            border-color: $white;
        }

        .card-tile__icon {
            border: 2px solid $white;
            color: $white;

            .is-oru & {
                border-color: $teal;
                color: $teal;
            }
        }
    }

    &--white-bg {
        background-color: $white;
    }

    &--lighter-bg {
        background-color: $blue;
    }

    &--darker-bg {
        background-color: $medium-gray;
    }
}

.card-tile--lighter-bg .card-tile__description,
.card-tile--darker-bg .card-tile__description {
    a {
        color: $white;
    }
}

.card-tile--oru {
    .card-tile--darker-bg .card-tile__description {
        a {
            color: $white;
        }
    }

    .card-tile--lighter-bg .card-tile__description,
    .card-tile--white-bg .card-tile__description {
        a {
            color: $teal;
        }
    }
}
