// ============== MARKET RATES COMPONENT =================

.market-rates {
    margin-top: 35px;
    background-color: $white;

    &--table-container {
        width: 100%;
    }

    &--tile {
        margin: 35px 10px 0;

        @include bp-medium {
            margin: 35px auto 0;
        }
    }

    &__title {
        margin-bottom: 40px;
        padding-bottom: 20px;
        color: $dark-gray;
        font: $lexend-extra-bold calculate-rem(30) / calculate-rem(35) $lexend-family;

        &--table {
            text-align: center;

            @include bp-medium {
                text-align: left;
            }
        }

        &-wrapper {
            padding: 45px 20px 20px;

            @include bp-medium {
                padding: 95px 15% 0;
            }

            @include bp-billing {
                padding: 95px 20% 0;
            }
        }
    }

    &__tile {
        padding: 30px 20px;

        @include bp-medium {
            padding: 95px 15%;
        }

        @include bp-billing {
            padding: 95px 20%;
        }

        &-description {
            color: $dark-gray;
            font: calculate-rem(18) / calculate-rem(26) $open-sans-family;
        }
    }

    &__table {
        margin-top: 50px;

        @include bp-medium {
            padding: 0 15% 95px;
        }

        @include bp-billing {
            padding: 0 20% 95px;
        }

        &-titles {
            @extend %flexbox;
            @include flex-flow(row wrap);
            @include align-items(center);
            @include justify-content(flex-end);
            margin: 0 20px;
            padding: 10px 0;

            @include bp-medium {
                margin: 0;
                border-bottom: 2px solid $orange;
            }
        }

        &-subtitles {
            @extend %flexbox;
            @include flex-flow(row wrap);
            @include align-items(center);
            margin: 0 20px;
            border-bottom: 2px solid $orange;
            padding: 10px 0;

            @include bp-medium {
                margin: 0;
            }
        }

        &-title {
            @include flex-basis(auto);
            @include box-sizing(border-box);
            display: none;
            padding-right: 5px;
            width: calc(100% / 7 * 2.13);
            text-align: center;
            text-transform: uppercase;
            font: calculate-rem(12) / calculate-rem(30) $open-sans-family;
            font-weight: $open-sans-bold;

            @include bp-medium {
                display: inline-block;
            }
        }

        &-subtitle {
            @include flex-basis(auto);
            @include box-sizing(border-box);
            display: none;
            padding-right: 10px;
            width: calc(100% / 7);
            text-transform: uppercase;
            font: calculate-rem(12) / calculate-rem(30) $open-sans-family;
            font-weight: $open-sans-semi-bold;

            @include bp-medium {
                display: inline-block;
            }
        }

        &-item {
            @extend %flexbox;
            @include transition(all, 0.2s, ease);
            @include flex-flow(column wrap);
            @include align-items(center);
            border-bottom: 1px solid $light-gray;
            padding: 10px 20px;

            @include bp-medium {
                @include flex-direction(row);
                padding: 30px 0;
            }
        }

        &-text {
            @include flex-basis(auto);
            @include box-sizing(border-box);
            text-align: center;
            color: $dark-gray;
            font: calculate-rem(12) / calculate-rem(24) $open-sans-family;
            font-weight: $open-sans-semi-bold;

            @include bp-medium {
                padding-right: 10px;
                width: calc(100% / 7);
                text-align: left;
                line-height: calculate-rem(30);
            }

            &--date {
                text-transform: uppercase;
                color: $medium-gray;
            }
        }
    }

    &__paragraph {
        padding-left: 30px;
        color: $dark-gray;
        font: calculate-rem(18) / calculate-rem(26) $open-sans-family;
    }

    &__row-legend {
        @include opacity(1);
        height: auto;

        @include bp-medium {
            @include opacity(0);
            height: 0;
            overflow: hidden;
        }
    }

    &__show-more {
        margin: 20px 0;
        background-color: $ghost-white;
        padding: 15px 0;
        height: 60px;
        color: $silver-chalice;
        font: $lexend-extra-bold calculate-rem(14) / calculate-rem(20) $lexend-family;

        &:hover {
            background-color: $silver2;
            color: $medium-gray;
        }
    }

    &__container {
        @extend %flexbox;
        @include flex-direction(column);
    }

    &__description {
        @extend %flexbox;
        @include flex-direction(column);
        width: 100%;

        @include bp-medium {
            @include flex-direction(row);
        }
    }

    &__disclaimer {
        padding-bottom: 40px;

        @include bp-medium {
            width: 40%;
        }

        p {
            @include body-m;
            color: $medium-gray;
            font-family: $open-sans-family;
        }

        strong {
            font-weight: $open-sans-bold;
        }
    }

    &__link {
        @extend .button-link;
        display: block;
        margin: 10px 0;
    }

    &__description-list {
        @include bp-medium {
            width: 50%;
        }
    }

    &__description-item {
        @extend %flexbox;
        @include flex-direction(column);
        @include body-l;
        margin-bottom: 10px;
        color: $dark-gray;
        font-family: $open-sans-family;
        font-weight: $open-sans-semi-bold;

        span {
            @include body-s;
            margin-bottom: 5px;
            color: $medium-gray;
        }
    }

    &__table-titles--oru {
        @extend %flexbox;
        @include flex-flow(row wrap);
        @include align-items(center);
        position: relative;
        border-bottom: 2px solid $orange;
        padding: 10px 0;

        @include bp-medium {
            margin: 0;
        }
    }

    &__table-title--oru {
        @include body-xs;
        @include flex-basis(auto);
        @include box-sizing(border-box);
        padding-right: 5px;
        width: calc(100% / 3);
        text-align: left;
        text-transform: uppercase;
        font-family: $open-sans-family;
        font-weight: $open-sans-bold;
    }

    &__table-description {
        @extend %flexbox;
        @include body-xs;
        border-bottom: 1px solid $medium-gray;
        padding: 20px 0;
        color: $dark-gray;
        font-family: $open-sans-family;

        strong {
            color: $medium-gray;
            font-weight: $open-sans-bold;
        }
    }

    &__table-text--oru {
        width: calc(100% / 3);
    }

    &__details {
        margin-bottom: 30px;
    }

    &__more-information {
        @include body-m;
        padding: 5px 0 40px;
        color: $dark-gray;
        font-family: $open-sans-family;
    }
}
