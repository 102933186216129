// ============== LANGUAGE SELECTOR COMPONENT =================

.modal-selector {
    border-bottom: 1px solid $medium-gray;
    background-color: $white;

    &__logo {
        position: absolute;
        top: 13px;
    }

    &__logo--oru {
        top: 16px;
    }

    &-wrapper {
        @extend %flexbox;
        @include flex-direction(column);
        position: relative;
        visibility: hidden;
        z-index: 19;
        background-color: $white;
        height: 0;
        overflow: hidden;

        @include bp-medium {
            @include flex-direction(row);
            @include align-items(center);
        }

        &--active {
            visibility: visible;
        }
    }

    &-close {
        position: absolute;
        top: 5px;
        right: 5px;
        z-index: 999;
        background-color: transparent;
        width: auto;
        height: auto;
        color: $blue;
        font-size: calculate-rem(60);

        @include bp-medium {
            top: 5px;
            right: 2px;
        }

        &:focus {
            outline: 0;

            body:not(.mouse-user):not(.is-oru) &::after {
                @extend .coned-button-focus;
            }

            body:not(.mouse-user) &::after {
                @extend .oru-button-focus;
            }
        }
    }

    &__title {
        margin: 100px 30px 50px;
        font: $lexend-extra-bold calculate-rem(40) $lexend-family;

        @include bp-medium {
            margin: 0 0 0 53px;
            font-size: calculate-rem(22);
        }

        @include bp-smallerer {
            font-size: calculate-rem(22);
        }
    }

    &__list {
        @extend %flexbox;
        @include flex-direction(column);
        margin-left: 30px;
        overflow: auto;

        @include bp-medium {
            @include flex-direction(row);
        }
    }

    &__item {
        margin: 20px 0;
        padding: 4px;

        @include bp-medium {
            margin: 0;
        }
    }

    &__link {
        @include transition(all, 0.2s, ease);
        display: block;
        position: relative;
        margin-right: 30px;
        width: 100%;
        color: $dark-gray;
        font: $lexend-regular calculate-rem(30) $lexend-family;

        @include bp-medium {
            width: auto;
            font-size: calculate-rem(24);
        }

        @include bp-smallerer {
            font-size: calculate-rem(24);
        }

        &:hover {
            color: $blue;
        }
    }

    &__link--active {
        color: $blue;

        &::after {
            @extend %flexbox;
            @include border-radius(50%);
            @include justify-content(center);
            @include align-items(center);
            position: absolute;
            top: 2px;
            right: 20px;
            background-color: $blue;
            width: 35px;
            height: 35px;
            color: $white;
            font-family: 'coned-icons';
            font-size: calculate-rem(40);
            content: '\e96e';

            @include bp-smallerer {
                top: 4px;
                width: 25px;
                height: 25px;
                font-size: calculate-rem(30);
            }

            @include bp-medium {
                display: none;
            }
        }
    }
}

.our-businesses {
    .modal-selector {
        &__title {
            margin: 80px 30px 20px;
            font-size: calculate-rem(20);

            @include bp-min-smallerer {
                font-size: calculate-rem(23);
            }

            @include bp-min-smaller {
                font-size: calculate-rem(32);
            }

            @include bp-medium {
                margin: 0 0 0 53px;
                font-size: calculate-rem(20);
            }
        }

        &__link {
            font-size: calculate-rem(20);

            @include bp-min-smallerer {
                font-size: calculate-rem(23);
            }

            @include bp-min-smaller {
                font-size: calculate-rem(32);
            }

            @include bp-medium {
                margin-right: 15px;
                font-size: calculate-rem(14);
            }

            @include bp-hero {
                font-size: calculate-rem(17);
            }

            @include bp-extra-large-header {
                font-size: calculate-rem(20);
            }
        }

        &__link--last {
            margin-right: 40px;
        }
    }
}
