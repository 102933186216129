// ============== USAGE HISTORY COMPONENT =================

.usage-history {
    margin-top: 35px;
    background-color: $white;

    &--no-margin-top {
        margin-top: 0;
    }

    &--tile {
        margin: 35px 10px;

        @include bp-medium {
            margin: 35px auto;
        }
    }

    &__title {
        @include heading-l;
        margin-bottom: 40px;
        border-bottom: 2px solid $medium-gray;
        padding-bottom: 20px;
        color: $dark-gray;
        font-family: $lexend-family;
        font-weight: $lexend-extra-bold;

        &--table {
            text-align: center;

            @include bp-medium {
                text-align: left;
            }
        }

        &--20-margin-top {
            margin-top: 20px;
        }

        &-wrapper {
            padding: 45px 20px 20px;

            @include bp-medium {
                padding: 95px 15% 0;
            }

            @include bp-billing {
                padding: 95px 20% 0;
            }

            &--no-padding-sides {
                padding-right: 0;
                padding-left: 0;
            }

            &--no-padding-top {
                padding-top: 0;
            }
        }
    }

    &__tile {
        padding: 30px 20px;

        @include bp-medium {
            padding: 95px 15%;
        }

        @include bp-billing {
            padding: 95px 20%;
        }

        &-description {
            @include body-l;
            color: $dark-gray;
            font-family: $open-sans-family;
        }

        &-link {
            @extend .button-link, .button-link--extra-low-emphasis;
            display: block;
            margin-top: 40px;
        }
    }

    &__table {
        @include bp-medium {
            padding: 0 15% 95px;
        }

        @include bp-billing {
            padding: 0 20% 95px;
        }

        &--no-padding-sides {
            padding-right: 0;
            padding-left: 0;
        }

        &--no-padding-bottom {
            padding-bottom: 0;
        }

        &-titles {
            @extend %flexbox;
            @include flex-flow(row wrap);
            @include align-items(center);
            border-bottom: 2px solid $blue;
            padding: 10px 0;

            @include bp-medium {
                margin: 0;
            }
        }

        &-title {
            @include body-l;
            @include flex-basis(auto);
            @include box-sizing(border-box);
            display: none;
            padding-right: 5px;
            width: calc(100% / 5);
            text-transform: uppercase;
            font-family: $lexend-family;
            font-weight: $lexend-extra-bold;

            @include bp-medium {
                display: inline-block;
            }
        }

        &-item {
            @extend %flexbox;
            @include transition(all, 0.2s, ease);
            @include flex-flow(column wrap);
            @include align-items(center);
            border-bottom: 1px solid $medium-gray;
            padding: 10px 20px;

            @include bp-medium {
                @include flex-direction(row);
                padding: 30px 0;
            }
        }

        &-text {
            @include body-m;
            @include flex-basis(auto);
            @include box-sizing(border-box);
            text-align: center;
            color: $dark-gray;
            font-family: $open-sans-family;
            font-weight: $open-sans-semi-bold;

            @include bp-medium {
                padding-right: 10px;
                width: calc(100% / 5);
                text-align: left;
            }

            &--date {
                text-transform: uppercase;
                color: $medium-gray;
            }

            &--total {
                text-transform: uppercase;
                color: $dark-gray;
                font-weight: $open-sans-extra-bold;
            }

            &--link {
                @extend .button-link, .button-link--extra-low-emphasis;
                font-weight: $open-sans-extra-bold;
            
            }

            &--no-padding {
                padding: 0;
            }
        }

        &-section {
            @include body-l;
            text-transform: uppercase;
            color: $dark-gray;
            font-weight: 800;
        }
    }

    &__show-more {
        @extend .button;
        display: block;
        margin: 20px auto;

        &:focus {
            outline: none;

            body:not(.mouse-user):not(.is-oru) &:before {
                @extend .coned-button-focus;
                @extend .focus-outline-safari-rotation-fix-desktop;
            }

            body:not(.mouse-user) &:before {
                @extend .oru-button-focus;
                @extend .focus-outline-safari-rotation-fix-desktop;
            }
        }
    }
}

.usage-history--oru {
    .usage-history__table-titles {
        border-color: $medium-gray;
    }
}
