// ============== PAGE HERO COMPONENT =================

.page-hero-wrapper {
    @extend %flexbox;
    @include flex-wrap(wrap);

    @include bp-medium {
        @include flex-wrap(nowrap);
    }

    @include bp-coned-images {
        @include flex-wrap(wrap);
    }
}

.page-hero {
    @extend %flexbox;
    @include flex-basis(100%);

    @include bp-medium {
        max-width: 50%;
        height: 425px;
    }

    @include bp-coned-images {
        @include flex-basis(50%);
    }

    &__link {
        @extend .button-link, .button-link--extra-low-emphasis;
        margin-left: 30px;

        &-icon {
            position: absolute;
            top: -5px;
            left: -40px;
            font-size: calculate-rem(35);

            @include bp-medium {
                top: -8px;
            }
        }

        &:focus {
            body:not(.mouse-user):not(.is-oru) & {
                @extend .coned-focus-outline;
            }

            body:not(.mouse-user) & {
                @extend .oru-focus-outline;
            }

            &::before {
                content: none !important;
            }
        }
    }

    &__content {
        @extend %flexbox;
        @include flex-basis(100%);
        @include align-items(center);
        @include flex-flow(row wrap);
        overflow: hidden;

        @include bp-coned-images {
            @include flex-basis(50%);
        }
    }

    &__content--blue {
        background-color: $blue;
        color: $white;

        .page-hero__link {
            @extend .button-link--secondary-color;
        }
    }

    &__content--white {
        background-color: $white;
        color: $blue;
    }

    &__content--white-retiree {
        background-color: $white;
        color: $black;
    }

    &__text-block {
        padding: 60px 30px;
        overflow: hidden;
        overflow-wrap: break-word;

        @include bp-medium {
            padding: 0 30px;
        }

        @include bp-large {
            overflow-wrap: inherit;
        }

        @include bp-page-hero-large {
            padding: 0 60px;
        }
    }

    &__title {
        padding-bottom: 25px;
        font: $lexend-extra-bold calculate-rem(30) / calculate-rem(40) $lexend-family;

        @include bp-page-hero-small {
            font-size: calculate-rem(45);
        }

        @include bp-medium {
            padding-bottom: 15px;
            line-height: calculate-rem(28);
            font-size: calculate-rem(24);
        }

        @include bp-coned-title {
            line-height: calculate-rem(35);
        }

        @include bp-hero {
            font-size: calculate-rem(32);
        }

        @include bp-page-hero-large {
            font-size: calculate-rem(40);
        }
    }

    &__sub-title {
        padding-bottom: 40px;
        font: calculate-rem(22) / calculate-rem(24) $open-sans-family;
        font-weight: $open-sans-semi-bold;

        @include bp-medium {
            padding-bottom: 30px;
            font-size: calculate-rem(14);
        }
    }

    &__item {
        display: inline-block;
        padding-bottom: 15px;

        @include bp-page-hero-small {
            padding-right: 65px;
        }

        @include bp-medium {
            display: block;
            padding-right: 0;
        }

        &:not(:last-child) {
            padding-bottom: 15px;
        }
    }

    &__image {
        @include flex-basis(100%);
        display: none;
        height: 100%;
        overflow: hidden;

        @include bp-coned-images {
            @include flex-basis(50%);
            display: block;
        }
    }

    &__picture {
        &-image {
            width: 425px;
            height: 100%;
        }
    }
}

.page-hero--oru {
    .page-hero__content--white {
        color: $black;
    }

    .page-hero__content--blue {
        background-color: $orange;
        color: $teal;
    }
}
