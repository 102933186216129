// ============== CARDS TILE TYPE WIDTH COMPONENT =================

.card-tile {
    &--full-width {
        margin-bottom: 0;
        width: 100%;
        min-height: 400px;

        @include bp-medium {
            &:not(:nth-child(2n + 1)) {
                margin-left: 0;
            }

            &:not(:nth-child(2n + 2)) {
                margin-right: 0;
            }
        }

        @include bp-cards {
            &:not(:nth-child(2n + 1)) {
                margin-left: 0;
            }

            &:not(:nth-child(2n + 2)) {
                margin-right: 0;
            }

            &:not(:nth-child(3n + 1)) {
                margin-left: 0;
            }

            &:not(:nth-child(3n + 3)) {
                margin-right: 0;
            }
        }

        .card-tile__title {
            display: -webkit-box;
            overflow: hidden;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
        }

        .card-tile__description {
            display: -webkit-box;
            overflow: hidden;
            line-height: 2em;
            -webkit-line-clamp: 4;
            -webkit-box-orient: vertical;
        }

        .card-tile__link {
            margin: 0 20px;
            padding: 10px 0;

            @include bp-medium {
                margin: 0 50px;
            }
        }
    }

    &--half-width {
        @extend %flexbox;
        @include align-items(center);
        margin-bottom: 35px;

        @include bp-cards {
            width: calc(50% - 10px);

            &:not(:nth-child(3n + 1)) {
                margin-left: 0;
            }

            &:not(:nth-child(3n + 3)) {
                margin-right: 0;
            }

            &:not(:nth-child(2n + 1)) {
                margin-left: 10px;
            }

            &:not(:nth-child(2n + 2)) {
                margin-right: 10px;
            }
        }

        &--20-margin-bottom-mobile {
            @include bp-small {
                margin-bottom: 20px;
            }
        }

        &--40-margin-bottom-desktop {
            @include bp-medium {
                margin-bottom: 40px;
            }
        }

        &.card-tile:hover::before {
            width: 0;
        }

        .card-tile__title {
            @include heading-m;
            margin-top: 0;
            border-bottom: 2px solid $alto;
            font-family: $lexend-family;
            font-weight: $lexend-extra-bold;

            @include bp-medium {
                display: -webkit-box;
                overflow: hidden;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
            }
        }

        .card-tile__description {
            @include body-l;
            font-family: $open-sans-family;

            @include bp-medium {
                display: -webkit-box;
                overflow: hidden;
                -webkit-line-clamp: 4;
                -webkit-box-orient: vertical;
            }
        }

        .card-tile--darker-bg,
        .card-tile--lighter-bg,
        .card-tile--white-bg {
            @include justify-content(center);
        }

        .card-tile__link {
            margin: 30px 50px 0;
        }

        .card-tile__picture {
            display: block;
            margin: 0 auto 25px;
            width: 245px;
            height: 110px;
        }

        .card-tile__picture-image {
            width: auto;
        }

        .card-tile__info-container--type-cta {
            margin: 0 30px;
            padding: 40px 0;

            .card-tile__title {
                @include heading-m;
                margin: 40px 0 15px;
                border-bottom: 0;
                font-family: $lexend-family;
                font-weight: $lexend-extra-bold;

                @include bp-medium {
                    margin: 35px 0 20px;
                }
            }
        }

        .card-tile__ctas {
            @extend %flexbox;
            @include flex-flow(column wrap);
            @include align-items(center);
        }

        .card-tile__cta-link {
            margin: 40px 0 0;

            @include bp-medium {
                margin-top: 20px;
            }

            &:first-child {
                @include bp-medium {
                    margin-top: 25px;
                }
            }
        }
    }
}

.card-tile--oru {
    .card-tile__info-container--type-cta {
        .card-tile__service-title,
        .card-tile__title {
            color: $teal;
        }

        .card-tile__description {
            color: $teal;
        }
    }
}
