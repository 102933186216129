// ============ RADIO ===============

.coned-radio {
    @extend %flexbox;
    @include body-m;
    position: relative;
    cursor: pointer;
    color: $medium-gray;
    font-family: $open-sans-family;
    font-weight: $open-sans-semi-bold;

    &:hover {
        color: $medium-gray;
    }

    &__input {
        @include opacity(0);
        position: absolute;
        top: 1px;
        left: 1px;
    }

    &__label-name {
        padding-top: 15px;

        &--nowrap {
            white-space: nowrap;
        }
    }

    &__opt-label {
        font-size: 0;
    }

    &__indicator {
        @extend %flexbox;
        @include border-radius(50%);
        @include transition(all 0.4s ease);
        @include flex-shrink(0);
        @include justify-content(center);
        @include align-items(center);
        display: block;
        position: relative;
        margin-right: 20px;
        border: 2px solid $medium-gray;
        background-color: $white;
        width: 50px;
        min-width: 32px;
        height: 50px;

        &::after {
            @include border-radius(50%);
            @include transition(all 0.3s ease);
            @include transform(rotate(-90deg) scale(1, 1));
            display: block;
            position: absolute;
            top: 50%;
            left: 50%;
            background: $green;
            width: 0;
            height: 0;
            content: '';
        }
    }

    &__indicator--focus {
        &::before {
            position: absolute;
            z-index: 1;
            margin: -5px;
            padding: 2px;
            width: calc(100% + 2px);
            height: calc(100% + 2px);
            content: '';

            body:not(.mouse-user):not(.is-oru) & {
                @extend .coned-focus-outline;
            }

            body:not(.mouse-user) & {
                @extend .oru-focus-outline;
            }
        }
    }

    &__group {
        @include bp-medium {
            @include flexbox();
            @include flex-direction(row);
            @include justify-content(space-between);
        }

        @include bp-large {
            @include justify-content(space-around);
        }
    }

    /* Disabled state */
    &__input:disabled ~ .coned-radio__indicator {
        @include opacity(0.6);
        background-color: $white;
        pointer-events: none;
    }

    /* Hover and focus states */
    &__input:checked ~ .coned-radio__indicator {
        border: 2px solid $green;
    }

    /* Show circle mark */
    &__input:checked ~ .coned-radio__indicator::after {
        display: block;
        top: 5px;
        left: 5px;
        width: 40px;
        height: 40px;
    }

    &__input:checked ~ .coned-radio__label-name {
        color: $green;
    }

    /* Disabled circle colour */
    &__input:disabled ~ .coned-radio__indicator::after {
        background: $green;
    }

    &--black {
        color: $black;
    }
}
