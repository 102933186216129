.transactional {
    background-color: $transparent;

    &--hidden {
        display: none;
    }

    &__wrapper {
        position: relative;
        margin-top: 40px;
        background-color: $white;

        &--margin-bottom {
            margin-bottom: 40px;
        }

        &--no-margin {
            margin-top: 0;
        }

        &--no-margin-top-mobile {
            @include bp-small {
                margin-top: 0;
            }
        }

        &--margin-top-20-mobile {
            @include bp-small {
                margin-top: 20px;
            }
        }
    }

    &__form {
        background-color: $transparent;
        padding: 0;
        width: 100%;
    }

    &__submit {
        @extend .button, .button--high-emphasis;
    }

    &__container {
        padding: 50px 30px;
        width: 100%;
        min-width: 0;

        @include bp-small {
            @include box-sizing(border-box);
        }

        @include bp-medium {
            margin: 0 auto;
            padding: 50px 0;
            width: 58%;
        }

        &--extra-width {
            @include bp-medium {
                width: 80%;
            }
        }

        &--edit-profile {
            overflow: hidden;
        }

        &--preference-center {
            overflow: hidden;
        }

        .coned-form-wrapper & {
            padding: 50px 20px;

            &--padding-top-bottom-30-mobile {
                @include bp-small {
                    padding-top: 30px;
                    padding-bottom: 30px;
                }
            }
        }

        a:not([class]) {
            body:not(.mouse-user):not(.is-oru) &:focus {
                @extend .coned-focus-outline;
            }

            body:not(.mouse-user) &:focus {
                @extend .oru-focus-outline;
            }

            &::before {
                content: none !important;
            }
        }

        &--no-padding {
            margin: 30px 0 !important;
            padding: 0 !important;
            width: 100% !important;
        }

        &--no-margin-no-padding {
            margin: 0 !important;
            padding: 0 !important;
            width: 100% !important;
        }

        &--72-percent-desktop {
            @include bp-medium {
                width: 72%;
                max-width: 1000px;
            }
        }
    }

    &--finish {
        display: none;
    }

    &__full {
        width: 100%;
    }

    &__single-field {
        margin: 40px 0 60px;
    }

    &__half {
        @include flexbox;
        @include flex-wrap(wrap);
        @include justify-content(space-between);

        &-block {
            margin-bottom: 20px;
            width: 100%;

            @include bp-cards {
                display: inline-block;
                width: 47%;
            }

            &--strict {
                flex: 49% 0 0;
            }
        }

        &--baseline {
            @include align-items(flex-end);
        }

        &--top {
            @include align-items(flex-start);
        }

        &--inline {
            @include justify-content(flex-start);
            @include align-items(flex-end);
        }

        &--inline-center {
            @include justify-content(flex-start);
            @include align-items(center);
        }

        &--center {
            @include flexbox;
            @include justify-content(center);
            @include align-items(center);
            width: 100%;

            @include bp-medium {
                width: auto;
            }
        }

        &--full {
            width: 100%;
        }

        &--gutter-bottom {
            margin-bottom: 25px;
        }

        &--split {
            margin-bottom: 20px;
            width: 100%;

            @include bp-medium {
                margin: 0 10px;
            }

            &-container {
                margin: 25px 0;

                @include bp-medium {
                    @include flex-wrap(nowrap);
                }
            }
        }
    }

    &__inline-block {
        display: block;

        @include bp-medium {
            margin-right: 50px;
        }
    }

    &__center {
        margin: 0 auto;
        text-align: center;
        color: $dark-gray;

        @include bp-medium {
            width: 75%;
        }
    }

    &__legend {
        @include box-sizing(border-box);
        position: relative;
        padding-right: 35px;
        padding-bottom: 15px;
        width: 100%;

        &--no-margin-top {
            margin-top: 0;
        }

        &--no-padding-right {
            padding-right: 0;
        }

        &--padding-bottom-20 {
            padding-bottom: 20px;
        }
    }

    &__header {
        @include display-m;
        margin-bottom: 50px;
        text-align: center;
        color: $dark-gray;
        font-family: $lexend-family;
        font-weight: $lexend-extra-bold;

        &--benefits-title {
            padding-top: 40px;
        }

        &--no-margin-bottom {
            margin-bottom: 0;
        }
    }

    &__title {
        @include heading-l;
        margin-bottom: 0;
        text-align: center;
        color: $dark-gray;
        font-family: $lexend-family;
        font-weight: $lexend-extra-bold;

        &--benefits-title {
            padding-top: 40px;
        }

        &--margin-bottom {
            margin-bottom: 20px;
        }
    }

    &__title--left {
        text-align: left;
    }

    &__subtitle {
        @include body-s;
        padding: 10px 0 20px;
        text-align: center;
        text-transform: uppercase;
        color: $dark-gray;
        font-family: $open-sans-family;
        font-weight: $open-sans-bold;

        &--divider {
            padding: 50px 0 20px;
            text-align: left;
        }

        &--average-amount {
            margin: 0 auto;
            width: 50%;
            text-transform: none;
            font-weight: $open-sans-semi-bold;
            font-style: italic;
        }

        &--bolder {
            font-weight: $open-sans-bold;
        }
    }

    &__paragraph {
        @include body-m;
        padding: 20px;
        font-family: $open-sans-family;

        p > a:not(:last-child) {
            margin-right: 25px;
        }

        &s-container {
            @include body-m;
            padding: 20px;
            font-family: $open-sans-family;
    
            p:not(:last-child) {
                margin-bottom: 25px;
            }

            &--full-width {
                padding-right: 0;
                padding-left: 0;
            }
           
            &--no-padding-top {
                padding-top: 0;
            }

            &--no-padding-bottom {
                padding-bottom: 0;
            }
        }

        &--report {
            @include body-l;
            padding-bottom: 30px;
            font-family: $open-sans-family;

            @include bp-medium {
                width: 80%;
            }
            
            &-medium {
                @include body-m;
                padding-bottom: 30px;
                font-family: $open-sans-family;
            }
        }

        &--covid-info {
            @include body-l;
            padding: 20px 0 30px;
            font-family: $open-sans-family;

            @include bp-medium {
                width: 80%;
            }
        }

        &--no-padding-mobile {
            padding-top: 0;

            @include bp-medium {
                padding: 20px;
            }
        }

        &--no-padding-left {
            padding-left: 0;
        }

        &--no-padding-left-mobile {
            padding-left: 0;

            @include bp-medium {
                padding: 30px 20px;
            }
        }

        &--no-side-padding {
            padding-right: 0;
            padding-left: 0;
        }

        &--no-padding-bottom {
            padding-bottom: 0;
        }

        &--full-width {
            @include bp-medium {
                width: 100%;
            }
        }

        &--center {
            text-align: center;
        }

        &--bold {
            font-weight: $open-sans-semi-bold;
        }

        &--bolder {
            font-weight: $open-sans-bold;
        }

        &--extra-bold {
            font-weight: $open-sans-extra-bold;
        }

        &--scorpio-color {
            color: $medium-gray;
        }

        &--large {
            @include body-l;
        }
    }

    &__image {
        width: 100%;
    }

    &__label {
        @include body-m;
        margin: 7.5% 0 5%;
        text-align: center;
        color: $medium-gray;
        font-family: $open-sans-family;
        font-weight: $open-sans-bold;
    }

    &__disclaimer {
        @include body-s;
        padding: 10px 0 20px;
        text-align: center;
        color: $medium-gray;
        font-family: $open-sans-family;
        font-weight: $open-sans-semi-bold;
    }

    &__link {
        @extend .button-link;
        display: block;
        margin: 5px 0;
        width: fit-content;

        @include bp-medium {
            margin-left: 20px;
        }

        &--no-decoration {
            display: inline;
            margin: 0;
        }

        &--no-uppercase {
            text-transform: none;
            font-size: inherit;
            font-weight: $open-sans-semi-bold;

            &::before {
                content: none !important;
            }
    
            body:not(.mouse-user):not(.is-oru) &:focus {
                @extend .coned-focus-outline;
            }
    
            body:not(.mouse-user) &:focus {
                @extend .oru-focus-outline;
            }
        }

        &::before {
            content: none !important;
        }

        body:not(.mouse-user):not(.is-oru) &:focus {
            @extend .coned-focus-outline;
        }

        body:not(.mouse-user) &:focus {
            @extend .oru-focus-outline;
        }
    }

    &__paragraph--description,
    &__paragraph--description > p {
        @include body-m;
        text-align: center;
        color: $medium-gray;
        font-family: $open-sans-family;

        span {
            font-weight: $open-sans-bold;
        }
    }

    &__paragraph--description-black,
    &__paragraph--description-black > p {
        @include body-m;
        text-align: center;
        color: $dark-gray;
        font-family: $open-sans-family;

        span {
            font-weight: $open-sans-bold;
        }
    }

    &__paragraph--red,
    &__paragraph--red > p {
        color: $red;
    }

    &__paragraph--dark-gray,
    &__paragraph--dark-gray > p {
        color: $dark-gray;
    }

    &__paragraph--left,
    &__paragraph--left > p {
        padding-left: 0;
        text-align: left;
    }

    &--padding-top,
    &--paffing-top > p {
        padding-top: 20px;
    }

    &__tooltip-description {
        @extend %flexbox;
        @include align-items(center);
        @include body-s;
        margin: 10px 0 0;
        color: $dark-gray;
        font-family: $open-sans-family;

        &--margin-bottom {
            @include inline-flex();
            margin-bottom: 35px;
        }
    }

    &__input-description {
        display: inline-block;
        margin-top: 50px;
        font-weight: $open-sans-semi-bold;
    }

    &__checkbox-description {
        @include body-m;
        color: $medium-gray;
        font-family: $open-sans-family;
        font-weight: $open-sans-semi-bold;

        @include bp-cards {
            padding: 25px 25px 0;
        }
    }

    &__radio-description {
        @include body-m;
        color: $dark-gray;
        font-family: $open-sans-family;
        font-weight: $open-sans-bold;

        &--margin-bottom {
            display: inline-block;
            margin-bottom: 20px;
        }
    }

    &__bottom-input-description-wrapper {
        @include bp-medium {
            padding-left: 20px;
        }
    }

    &__bottom-input-description {
        @include body-s;
        color: $medium-gray;
        font-family: $open-sans-family;
        font-weight: $open-sans-semi-bold;

        &--green {
            color: $green;
            font: $open-sans-semi-bold calculate-rem(16) / calculate-rem(20) $open-sans-family;
        }
    }

    &__button {
        @extend .button, .button--high-emphasis-large;
        margin: 20px auto;
    }

    &__authentication {
        @extend %flexbox;
        @include flex-direction(column);
        width: 100%;

        @include bp-medium {
            @include justify-content(space-between);
        }

        &-button {
            @extend .button;
            margin: 0 auto;
        }

        &-button--wide {
            @extend .button--high-emphasis-large;
        }
    }

    &-form {
        background-color: transparent;
    }

    &--one-third {
        float: left;
        width: 100%;

        @include bp-medium {
            @include box-sizing(border-box);
            width: 32%;
        }
    }

    &--first-item {
        margin-top: 35px;
    }

    &__informational-box {
        @include flexbox;
        @include justify-content(center);
        @include align-items(center);
        border: solid 1px $medium-gray;
        padding: 16px 20px;
        color: $dark-gray;

        > p {
            @include flexbox;
            @include flex-flow(row);

            span {
                @include flex-direction(column);
            }
        }

        .transactional__paragraph {
            @include body-s;
            padding: 0;
        }

        .transactional__paragraph-m {
            @include body-m;
        }

        &--background-grey {
            border: 0;
            background-color: $light-gray;
            color: $dark-gray;            
        }

        &--margin-bottom { 
            margin-bottom: 20px;
        }

        &--content-left-align {
            @include justify-content(left);
        }

        &--icon {
            @include border-radius(50%);
            position: relative;
            left: 0;
            padding-right: 5px;
            color: $dark-gray;

            &::before {
                font-size: calculate-rem(25);
            }
        }

        &--icon-red {
            color: $red;
        }

        &--image {
            @include justify-content(left);
            padding: 15px 30px;

            img {
                margin-right: 25px;
            }

            .transactional__paragraph--address {
                margin-top: 10px;
                font-family: $lexend-family;
                font-weight: $lexend-extra-bold;
            }
        }

        &--address {
            border: 2px solid $medium-gray;

            span {
                display: block;
            }
        }

        &--check-outage-no-service-error {
            margin-top: 20px;

            @include bp-medium {
                margin: 25px 0;
            }
        }
    }

    &__error {
        @include body-m;
        padding: 20px;
        color: $red;
        font-family: $open-sans-family;
        font-weight: $open-sans-semi-bold;

        body:not(.mouse-user):not(.is-oru) &:focus {
            @extend .coned-focus-outline;
        }

        body:not(.mouse-user) &:focus {
            @extend .oru-focus-outline;
        }

        a:not(.transactional__error-link) {
            color: $red;
        }

        &-link {
            color: $blue;

            &:hover {
                text-decoration: underline;
            }
        }

        &-blue-link {
            a {
                color: $blue !important;
            }
        }

        &-icon {
            @include border-radius(50%);
            position: relative;
            left: 0;
            padding-right: 27px;
            color: $red;

            &::before {
                position: absolute;
                top: -3px;
                left: -2px;
                font-size: calculate-rem(25);
            }
        }

        &-cta {
            font: $open-sans-semi-bold calculate-rem(16) $open-sans-family;
        }

        &--center {
            text-align: center;
        }

        &--white {
            @include box-sizing(border-box);
            margin: 50px 0 -50px;
            background-color: $white;
            width: 100%;
        }

        &--sides-margin-negative-10 {
            margin-right: -10px;
            margin-left: -10px;
            width: calc(100% + 20px);
        }

        &--white-box-border-red {
            position: absolute;
            bottom: 0;
            left: 0;
            margin: 0;
            border-top: 4px solid $red;
            background-color: $white;
            padding: 20px 0;
            width: 100%;
        }

        &--no-pad {
            padding: 0;
        }

        &--no-pad-bottom {
            padding-bottom: 0;
        }

        &--no-pad-top {
            padding-top: 0;
        }

        &--no-side-padding {
            padding-right: 0;
            padding-left: 0;
        }
 
        &--padding-top-bottom-12 {
            padding-top: 12px;
            padding-bottom: 12px;
        }

        &--paragraph {
            display: contents;
        }
    }

    &__error--dashboard-updated {
        padding: 20px 0;
    }

    &__error--energy-usage {
        padding: 10px 0;
        font: $open-sans-semi-bold calculate-rem(14) / calculate-rem(20) $open-sans-family;
    }

    &__credit-card-container {
        margin-bottom: 45px;
    }

    &__tonp-message {
        color: $medium-gray;
        font: calculate-rem(14) / calculate-rem(26) $open-sans-family;
        font-weight: $open-sans-semi-bold;
    }

    .coned-tooltip--open::after {
        font-size: 24px;
    }
}
