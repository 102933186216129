.select-single {
    &__option {
        @include body-s;
        position: relative;
        cursor: pointer;
        color: $black;
        font-weight: $open-sans-semi-bold;

        &,
        * {
            @extend .no-selection;
        }

        &:hover {
            text-decoration: underline;
            color: $blue;
        }

        &:focus {
            @include before-outline;
        }

        .icon-check-status {
            position: absolute;
            top: -0.35rem;
            left: 0.5rem;
            opacity: 0;
            color: $blue;
            font-size: 4rem;
        }

        &[aria-selected="true"] {
            color: $blue;

            .icon-check-status {
                opacity: 1;
            }
        }
    }

    &__wrapper {
        &-option {
            @include flexbox;
            padding: calculate-rem(8) calculate-rem(14) calculate-rem(8) calculate-rem(40);
            overflow: hidden;
        }
    }
}
