// ============== UNSUPPORTED BROWSER BANNER COMPONENT =================

.unsupported-browser-banner {
    @extend %flexbox;
    @include flex-flow(row nowrap);
    @include align-items(center);
    @include justify-content(space-between);
    position: relative;

    @include bp-medium {
        padding: 16px 0;
    }

    @include bp-unsupported-browser-banner {
        padding-right: calc((100% - 1540px) / 2);
        padding-left: calc((100% - 1540px) / 2);
    }

    &-wrapper {
        border-bottom: 3px solid $blue;
        background: $white;
        color: $dark-gray;
    }

    &__type-icon {
        display: inline-block;
        margin: -20px 0 0;
        height: 25px;
        color: $blue;
        font-size: calculate-rem(45);

        @include bp-medium {
            margin: -20px 0 0 25px;
        }
    }

    &__block {
        @extend %flexbox;
        @include flex-flow(row wrap);
        @include align-items(center);
        width: 100%;

        &--full-mobile {
            @include bp-small {
                margin: 0 30px;
                padding: 10px 0;
            }
        }
    }

    &__content {
        padding: 5px 0;
        width: calc(100% - 67px);
        overflow: hidden;

        @include bp-medium {
            margin: 0;
        }

        &--flex {
            @include flexbox;
            @include align-items(center);

            width: 100%;
        }

        &-title {
            display: inline-block;
            margin-right: 5px;
            font: $lexend-extra-bold calculate-rem(15) / calculate-rem(24) $lexend-family;

            @include bp-medium {
                margin-right: 10px;
                line-height: calculate-rem(26);
                font-size: calculate-rem(15);
            }
        }

        &-text {
            display: inline;
            position: relative;
            font: calculate-rem(16) / calculate-rem(24) $open-sans-family;

            @include bp-medium {
                margin-right: 35px;
                line-height: calculate-rem(26);
                font-size: calculate-rem(16);
            }

            &--absolute {
                padding-left: 5px;
                
                @include bp-medium {
                    padding-left: 10px;
                }
            }
        }
    }
    
    &__content-text a:focus {
        body:not(.mouse-user):not(.is-oru) & {
            @extend .coned-focus-outline;
        }
        
        body:not(.mouse-user) & {
            @extend .oru-focus-outline;
        }

        &::before {
            content: none !important;
        }
    }

    .is-oru & {
        &-wrapper {
            border-bottom: 3px solid $orange;
        }

        &__type-icon {
            color: $teal;
        }
    }
}
