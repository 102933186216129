// ============== ARTICLE ACTIONS COMPONENT =================

.article {
    &__actions {
        @extend %flexbox;
        @include flex-flow(row wrap);
        @include justify-content(space-between);
        padding-top: 40px;

        @include bp-article-actions {
            @include justify-content(center);
        }

        &-wrapper {
            margin: 0 10px;
            background-color: $white;

            @include bp-medium {
                margin: 0 auto;
            }
        }

        &-wrapper--faq {
            margin-bottom: 95px;
        }

        &-padding {
            padding: 0 20px 20px;

            @include bp-medium {
                padding-right: 0;
                padding-left: 21.5%;
                width: 60%;
            }
        }

        .social-share,
        .was-this-helpful {
            margin-bottom: 20px;
        }
    }
}
