// ============== REPORT OUTAGE COMPONENT =================

.report-outage {
    background-color: transparent;

    &__subtitle {
        @include body-l;
        padding-top: 20px;
        padding-bottom: 12px;
        font-family: $lexend-family;
        font-weight: $lexend-extra-bold;
    }

    &__mask {
        @include transition(all 0.3s ease-out);
        height: auto;
    }

    &__alorica-title {
        padding: 10px 0 0;
    }

    &__description,
    &__issue-description {
        @include body-m;
        margin: 0 0 20px;
        color: $dark-gray;
        font-family: $open-sans-family;
        font-weight: $open-sans-semi-bold;
    }

    &__description--location {
        margin-top: 20px;
        text-align: center;
    }

    &__description--left {
        text-align: left;
    }

    &__description--center {
        text-align: center;
    }
    
    &__description--padding-bottom-10 {
        padding-bottom: 10px;
    }

    &__description-link {
        body:not(.mouse-user):not(.is-oru) &:focus {
            @extend .coned-focus-outline;
        }

        body:not(.mouse-user) &:focus {
            @extend .oru-focus-outline;
        }

        &:focus::before {
            content: none !important;
        }
    }

    &__description--bold {
        font-weight: $open-sans-bold;
    }

    &__paragraph {
        @include body-m;
        margin: 0 0 20px;
        color: $dark-gray;
        font-family: $open-sans-family;
        font-weight: $open-sans-semi-bold;

        &--center {
            text-align: center;
        }

        &--padding {
            padding-top: 25px;
            padding-bottom: 10px;
        }
    }

    &__address {
        margin-top: 70px;
    }

    &__borough-container,
    &__borough-select {
        width: 100%;
    }

    &__account-option--active,
    &__animation--active,
    &__step--active,
    &__wire-tree-condition--active {
        display: block;
    }

    &__address-link {
        @extend .button-link;
        display: block;
        margin: -10px auto 0;
        width: 215px;
        text-align: center;
    }

    &__affected-container {
        @include opacity(0);
        margin-top: 20px;
    }

    &__affected-container--active {
        @include transition(opacity 1.5s ease-out);
        @include opacity(1);
        transition-delay: 0.5s;
    }

    &__affected-alert {
        border: 2px solid $red;
        padding: 20px;
        color: $red;
    }

    &__affected-alert-title {
        @include body-l;
        margin-bottom: 10px;
        font-family: $lexend-family;
        font-weight: $lexend-extra-bold;
    }

    &__affected-alert-description {
        @include body-s;
        font-family: $open-sans-family;
        font-weight: $open-sans-semi-bold;
    }

    &__affected-checkbox {
        margin-top: 10px;
    }

    &__my-address,
    &__other-issue-banner {
        margin-top: 40px;
    }

    &__address-dropdown {
        margin-top: 35px;
    }

    &__address-box {
        margin: 35px 0;
    }

    &__wire-location {
        margin-top: 30px;
    }

    &__link {
        @extend .button-link;
        display: inline-block;
    }

    &__next-steps {
        @include flexbox;
        @include justify-content(space-between);
        @include flex-direction(column);
        margin: 20px 10px;

        @include bp-medium {
            @include flex-direction(row);
            margin: 0;
        }

        &__card {
            @include flexbox;
            @include flex-direction(column);
            @include justify-content(space-between);
            margin-bottom: 20px;
            background-color: $blue;
            width: auto;
            text-align: center;
            color: $white;

            @include bp-medium {
                margin: 0;
            }

            &__wrapper {
                padding: 60px 30px;
            }

            &__title {
                @include heading-m;
                margin-bottom: 20px;
                font-family: $lexend-family;
                font-weight: $lexend-extra-bold;
            }

            &__text {
                @include body-l;
                margin-bottom: 30px;
                font-family: $open-sans-family;
                font-weight: $open-sans-regular;
            }

            &__cta {
                @extend .button, %inline-flex, .button--high-emphasis;
                @include body-m;
                margin-top: auto;
                font-family: $open-sans-family;
                font-weight: $open-sans-extra-bold;

                @include bp-medium {
                    @include flex-direction(row);
                    @include align-self(center);
                }
            }

            &--half {
                @include bp-medium {
                    width: 49%;
                }
            }
        }

        &__card__cta {
            body:not(.is-oru) & {
                @extend .button--secondary-color;
            }
        }
    }

    &__finish {
        margin-bottom: 50px;
    }
}

.is-oru {
    .report-outage__next-steps {
        &__card {
            background-color: $orange;

            &__title,
            &__text {
                color: $teal;
            }
        }
    }
}
