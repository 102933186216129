// ============== LOGIN FORM COMPONENT =================

.login-form {
    &__title {
        display: block;
        border: 0;
        text-align: center;
    }

    &__heading-l {
        @include heading-l;
        font-family: $lexend-family;
        font-weight: $lexend-extra-bold;
    }

    &__heading-m {
        @include heading-m;
        font-family: $lexend-family;
        font-weight: $lexend-extra-bold;
    }

    &__subtitle-container {
        display: block;
        border: 0;
        padding-top: 20px;
        padding-bottom: 13px;
        text-align: center;

        @include bp-medium {
            padding: 20px;
        }
    }

    &__subtitle {
        @include body-l;
        color: $medium-gray;
        font-family: $open-sans-family;
        font-weight: $open-sans-regular;
    }

    &__wrapper {
        @include bp-medium {
            margin-top: 45px;
        }
    }

    &__container {
        margin: 9px 10px 0;
        background-color: $white;
        padding: 45px 30px 20px;

        @include bp-medium {
            margin: 0;
            padding: 65px 20% 20px;
        }

        &--padding-dropdown {
            padding-top: 0;

            @include bp-medium {
                padding: 0 30px 30px;
            }
        }

        &-wrapper {
            @include bp-medium {
                @include flexbox;
                @include align-items(center);
                @include justify-content(space-between);

                @include bp-medium {
                    @include flex-wrap(wrap);
                }
            }

            &-input {
                margin-top: 15px;
                width: 100%;

                @include bp-medium {
                    margin-top: 20px;
                    width: 45%;
                }

                @include bp-in-page-banner {
                    width: 50%;
                }
            }
        }

        &-msg {
            display: block;
            text-align: center;
            text-transform: uppercase;

            &--intro {
                @include body-m;
                margin-top: 20px;
                text-transform: none;
                color: $medium-gray;
                font-family: $open-sans-family;
            }

            &--newdevice {
                @include heading-m;
                margin-top: 70px;
                text-transform: initial;
                color: $dark-gray;
                font-family: $lexend-family;
                font-weight: $lexend-extra-bold;
            }

            &--subtitle {
                @include body-s;
                padding-top: 10px;
                color: $dark-gray;
                font-family: $open-sans-family;
                font-weight: $open-sans-bold;
            }

            &--no-margin-top {
                @include bp-medium {
                    margin-top: 0;
                }
            }

            &--left {
                text-align: left;
            }

            &--less-margin-top {
                margin-top: 10px;
            }

            &--intro-less-margin-top {
                margin-top: 10px;
            }

            &--heading {
                @include heading-s;
            }

            &--paragraph {
                @include body-m;
            }
        }

        &-error {
            @include body-s;
            display: none;
            padding: 20px 0;
            color: $red;
            font-family: $open-sans-family;
            font-weight: $open-sans-semi-bold;

            body:not(.mouse-user):not(.is-oru) &:focus {
                @extend .coned-focus-outline;
            }

            body:not(.mouse-user) &:focus {
                @extend .oru-focus-outline;
            }

            &-icon {
                @include border-radius(50%);
                position: relative;
                left: 0;
                margin-right: 10px;
                background-color: $red;
                padding: 5px;
                color: $white;
                font-size: calculate-rem(15);
            }

            &--login-dropdown {
                padding: 20px 0;
            }
        }
    }

    &-actions--login-button {
        padding-top: 0;

        @include bp-medium {
            @include order(3);
            width: 100%;
        }
    }

    &__footer {
        margin: 0 auto;
        padding: 70px 35px 30px;
        text-align: center;

        @include bp-medium {
            padding: 30px 50px;
        }

        &-paragraph > p {
            @include body-xs;
            padding-bottom: 20px;
            text-align: center;
            color: $dark-gray;
            font-family: $open-sans-family;
            font-weight: $open-sans-semi-bold;

            @include bp-medium {
                margin: 0 auto;
                width: 700px;
            }

            a {
                color: $dark-gray;
            }
        }
    }

    &__description {
        color: $medium-gray;
        font: calculate-rem(16) / calculate-rem(22) $open-sans-family;

        @include bp-medium {
            padding-left: 20px;
        }
    }

    &__checkbox {
        &-title {
            display: block;
            margin-left: 20px;
            white-space: nowrap;
            color: $medium-gray;
            font: $open-sans-semi-bold calculate-rem(14) $open-sans-family;
        }

        &--rem {
            @include flex-direction(row-reverse);
        }
    }

    &__form {
        @include transition(all, 0.3s, ease);
        @include opacity(1);
        position: relative;
        top: 0;
        background-color: transparent;
        width: 100%;

        &--form-container {
            padding: 0;
            width: 100%;
        }

        &-password {
            position: relative;

            &-input {
                width: calc(100% - 108px);
            }

            &-button {
                position: absolute;
                top: 45px;
                right: 0;
                background-color: $white;
                padding: 0;
                width: auto;
                color: $blue;
                font: $open-sans-semi-bold calculate-rem(14) $open-sans-family;
            }
        }

        &-half {
            @include bp-login-modal {
                @include box-sizing(border-box);
                display: inline-block;
                width: 49%;
                height: 70px;
                vertical-align: top;
            }

            &--right {
                text-align: right;
            }
        }
    }

    &__list {
        @include transition(all, 0.1s, ease);
        @include opacity(1);
        position: relative;
        top: 0;

        @include bp-medium {
            @include box-sizing(border-box);
            padding-bottom: 70px;
            padding-left: 10%;
            width: 45%;
        }

        &-link {
            @extend %flexbox;
            padding: 10px;
            color: $blue;
            font: $lexend-extra-bold calculate-rem(16) / calculate-rem(28) $lexend-family;

            &:hover .login__list-text {
                text-decoration: underline;
            }
        }

        &-icon {
            display: none;

            @include bp-medium {
                display: block;
            }
        }

        &-text {
            padding-left: 10px;
        }
    }

    .coned-input-message--error,
    .coned-input-top-message--error {
        ~ .border-bar {
            border-color: $red;
        }
    }

    .coned-input:-webkit-autofill {
        ~  label {
            padding: 10px 0 0;
            color: $green;
            font-size: calculate-rem(14);
            
            @include bp-medium {
                padding: 10px 20px 0;
            }
        }
    }
}

.login-form--oru {
    .login__list-link,
    .login__form-password-button {
        color: $teal;
    }
}

.avoid-clicks {
    pointer-events: none;
}
