// ============ DATE BOX CTA ===============

.date-box {
    padding-bottom: 20px;
    width: 100%;

    &--no-padding {
        padding: 0;
    }

    &__title {
        @include body-s;
        color: $medium-gray;
        font-family: $open-sans-family;
        font-weight: $open-sans-semi-bold;
        word-break: break-all;

        @include bp-medium {
            padding-left: 20px;
        }

        &--break-word {
            word-break: normal;
        }
    }

    &__date {
        @include body-l;
        color: $dark-gray;
        font-family: $open-sans-family;
        font-weight: $open-sans-semi-bold;

        @include bp-medium {
            padding-left: 20px;
        }
    }
}
