.icons-navigation {
    @include flexbox;
    @include flex-direction(column);
    width: 100%;

    @include bp-medium {
        @include flex-direction(row);
        @include justify-content(center);
        @include flex-wrap(wrap);
        margin: 0 50px;
    }

    @include bp-large {
        margin: 0 100px;
    }

    &__item {
        @include flexbox;
        border-bottom: 1px solid $light-gray;
        background-color: $white;
        padding: 20px 40px;
        min-height: 90px;
        text-align: center;

        @include bp-medium {
            @include justify-content(center);
            @include flex-grow(1);
            @include flex-shrink(1);
            transition: all 500ms ease;
            margin: 0 5px 20px;
            border-radius: 50%;
            background-color: $white-transparent-85;
            padding: 0;
            max-width: 125px;
            min-height: auto;
            max-height: 125px;

            &::before {
                display: inline-block;
                padding-top: 100%;
                content: '';
            }

            &:hover {
                background-color: $white-full-opacity;

                .icons-navigation__item-image {
                    transform: scale(1.3);
                }
            }

            &:first-child {
                margin-left: 0;
            }

            &:last-child {
                margin-right: 0;
            }
        }

        @include bp-large {
            margin: 0 10px 20px;
            max-width: 125px;
            max-height: 125px;
        }

        @include bp-extra-large {
            max-width: 140px;
            max-height: 140px;
        }

        @include bp-extra-large-header {
            max-width: 160px;
            max-height: 160px;
        }

        /* Portrait */
        @include bp-portrait-ipad-pro {
            max-width: 130px;
            max-height: 130px;
        }
    }

    &__item-image {
        position: absolute;
        transition: all 500ms ease;
        margin: 0 30px 0 0;
        max-width: 40px;
        max-height: 40px;

        @include bp-medium {
            position: relative;
            margin: 0;
            width: 100%;
            max-width: 30px;
            max-height: 30px;
        }

        @include bp-extra-large {
            margin: 0;
            width: 100%;
            max-width: 40px;
            max-height: 40px;
        }
    }

    &__item-title {
        @include body-s;
        margin-left: 70px;
        text-align: left;
        letter-spacing: 0.09px;
        color: $black;
        font-family: $lexend-family;
        font-weight: $lexend-semi-bold;

        @include bp-medium {
            margin-top: 10px;
            margin-left: inherit;
            width: 100%;
            max-width: 90px;
            min-height: 45px;
            overflow: hidden;
            text-align: center;
        }

        @include bp-extra-large {
            margin-top: 10px;
            max-width: 92px;
        }

        @include bp-extra-large-header {
            margin-top: 20px;
            max-width: 100px;
        }
    }

    &__item-cta {
        @include flexbox;
        @include flex-direction(row);
        @include align-items(center);
        width: 100%;
        text-decoration: none;

        @include bp-medium {
            @include flex-direction(column);
            @include justify-content(center);
        }
    }
}
