.additional-button {
    @include transition(font-size, 0.2s, ease);
    display: inline-block;
    position: relative;
    border: solid 2px;
    background-clip: padding-box;

    &:hover:not([disabled]) {
        &::before {
            transform: scale(1.05);
        }
    }

    &--primary-color {
        border-color: $white;
        background-color: $blue;
        color: $white;

        .is-oru & {
            border-color: $white;
            background-color: $teal;
            color: $white;
        }
    }

    &--secondary-color {
        border-color: $blue;
        background-color: $white;
        color: $blue;

        .is-oru & {
            border-color: $teal;
            background-color: $white;
            color: $teal;
        }
    }

    &:active:not([disabled]) {
        border-color: $white;
        background-color: $dark-blue;
        color: $white;

        .is-oru & {
            border-color: $black;
            background-color: $black;
            color: $white;
        }
    }

    &:disabled {
        opacity: 0.5;
        background-color: $light-gray;
        color: $medium-gray;

        [class^='icon-'],
        [class*=' icon-'] {
            color: $medium-gray;
        }
    }
}
