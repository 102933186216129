// ============== FINANCIAL ADVISOR RESULT COMPONENT =================
.financial-advisor-result {
    @include flexbox;
    @include align-items(center);
    @include flex-direction(column);
    background: $white;
    padding-top: 50px;
    width: 100%;
    color: $dark-gray;
    font-family: $lexend;

    &__image {
        @include flexbox;
        margin-right: auto;
        margin-left: auto;
    }

    &__account-information {
        @include heading-s;
        @include flexbox;
        @include flex-direction(column);
        padding: 0 20px;
        width: 100%;
        box-sizing: border-box;

        @include bp-medium {
            @include flex-direction(row);
            @include justify-content(space-between);
            max-width: 594px;
        }

        @include bp-extra-large {
            max-width: 1048px;
        }
    }

    &__title {
        @include heading-l;
        padding: 24px 0 12px 20px;
        width: 100%;
        font-weight: $lexend-extra-bold;
        box-sizing: border-box;

        @include bp-medium {
            padding: 24px 20px 12px;
            max-width: 594px;
        }

        @include bp-extra-large {
            padding: 24px 20px 12px;
            max-width: 1048px;
        }

        &--padding-left {
            padding: 24px 20px 12px 0;
        }
    }

    &__information-account {
        @include heading-s;
        padding: 0 50px 24px 0;

        @include bp-medium {
            width: 30%;
        }

        &__description {
            width: 100%;
        }
    }

    &__information-paragraph {
        @include body-l;
        font-family: $open-sans;

        @include bp-medium {
            width: 45%;
        }
    }

    &-row {
        @include bp-medium {
            @include flexbox;
            @include flex-wrap(wrap);
            @include justify-content(space-between);
        }
    }

    &-buttons {
        @include flexbox;
        @include flex-direction(column);
        @include align-items(start);
        margin-bottom: 50px;
        padding: 16px 8px 0 20px;
        width: 100%;
        box-sizing: border-box;

        @include bp-medium { 
            max-width: 594px;
        }

        @include bp-extra-large { 
            max-width: 1048px;
        }

        a + a {
            margin-top: 16px;

            @include bp-medium {
                margin-top: 24px;
            }
        }

        &-not-match {
            @include flex-direction(column);
            padding-top: 8px;

            @include bp-extra-large {
                @include flex-direction(row);
                @include align-items(end);

                a + a {
                    margin: 0 0 0 10px;
                }
            }
        }

        &__button {
            padding: 0 25px;
            width: auto;
            height: auto;
            color: $white;
    
            &-text {
                @include body-m;
                text-transform: uppercase;
                font-family: $open-sans;
                font-weight: $open-sans-extra-bold;
            }
        }
        
        &--white {
            border: solid 2px $blue;
            background: $white;
            color: $blue;
        }
    }

    &-programs-info {
        padding: 0 20px;
        width: 100%;
        box-sizing: border-box;

        @include bp-medium {
            max-width: 594px;
        }

        @include bp-extra-large {
            max-width: 1048px;
        }
    }

    &-programs {
        width: 100%;

        @include bp-medium {
            max-width: 594px;
        }

        @include bp-extra-large {
            max-width: 1048px;
        }
    }

    &__wrapper {
        @include flexbox;
        @include align-items(center);
        @include flex-direction(column);
        padding: 0 20px;
        width: 100%;
        box-sizing: border-box;

        @include bp-medium {
            max-width: 594px;
        }

        @include bp-extra-large {
            max-width: 1048px;
        }
    }

    &--center-image {
        margin-right: auto;
        margin-left: auto;
    }

    &--rotate {
        @include transform(rotate(180deg));
    }

    &--margin-0 {
        margin: 0;
    }

    .is-oru & {
        &-buttons {
            &--white {
                border: solid 2px $teal;
                background: $white;
                color: $teal;
            }
        }
    }
}
