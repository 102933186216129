// ============== CONFIRMATION SCREEN TABLE COMPONENT =================
.confirmation-screen__table {
    @include body-m;
    width: 100%;
    color: $black;
    font-family: $open-sans-family;

    &-container {
        margin-bottom: 20px;
        width: 100%;
        
        @include bp-medium {
            max-width: 440px;
        }
    }

    &-row {
        display: table-row;
        border-bottom: 1px solid $medium-gray;

        &:first-child {
            border-top: 1px solid $medium-gray;
        }
    }

    &-cell {
        @include box-sizing(border-box);
        display: table-cell;
        outline: 0;
        padding: 8px 0;
        width: 50%;
        font-weight: $open-sans-semi-bold;

        &:not(:first-child) {
            padding-left: 10px;
        }
    }

    &-header {
        font-weight: $open-sans-regular;
    }

    &-text {
        text-align: left;

        &--right-aligned {
            text-align: right;
        }
    }
}
