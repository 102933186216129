// ============== CARDS TILE COMPONENT =================
.card-tile-grid {
    // .card-tile--grid {
    @include transition(all, 0.7s, ease);
    position: relative;
    margin: 0 0 10px !important;
    background-color: $gray-light-3;
    width: 100%;
    height: 100%;
    overflow: hidden;

    @include bp-medium {
        margin: 0 25px 25px 0 !important;
        width: calc(29.8%);

        &:hover::before,
        &:focus::before {
            left: 0%;
            width: 100%;
        }
    }

    @include bp-cards {
        margin-right: 25px !important;
        width: calc(22.5%);
    }

    &--hidden.card-tile-grid {
        display: none;
    }

    &__image-container {
        @include transition(transform 0.3s ease);
        position: absolute;
        top: 0;
        left: 0;
        background-image: none !important;
        height: 100% !important;

        @include bp-large {
            height: 100% !important;
        }

        &--type-image {
            position: relative;
            left: 0 !important;
            background-color: $white;
            width: 100% !important;
        }
    }

    &__picture {
        display: block;
        position: relative;
        height: 100%;
    }

    &__picture-image {
        position: relative;
        width: 100%;
        height: 100%;
    }

    &__info-container {
        color: $dark-gray;
        word-wrap: break-word;

        &--type-image {
            position: relative;
            background-color: $white;
            padding: 10px 0;
            width: 100%;

            @include bp-medium {
                height: 100px;
            }
        }
    }

    &__title {
        @include body-l;
        margin: 0 20px 10px;
        font-family: $lexend-family;
        font-weight: $lexend-extra-bold;

        @include bp-medium {
            margin: 0 25px;
        }

        @include bp-large {
            margin: 0 25px 5px;
        }
    }

    &__link {
        @extend .button-link, .button-link--extra-low-emphasis;
        display: inline-block;
        margin: 10px 20px 5px;

        @include bp-medium {
            position: absolute;
            bottom: 20px;
            margin: 5px 25px 0;
        }

        &-wrapper {
            @extend %flexbox;
            @include align-items(center);
            text-decoration: none;
        }

        &-icon {
            position: relative;
            padding-right: 20px;

            &::before {
                position: absolute;
                top: -7px;
                left: -7px;
                font-size: calculate-rem(30);
            }
        }

        &--multiple:hover {
            text-decoration: underline;
        }
    }

    &__page-wrapper {
        @extend %flexbox;
        @include flex-wrap(wrap);
        padding: 0 10px;

        @include bp-medium {
            padding: 0 5px 15px 30px !important;
        }
    }

    &__zoom {
        background-color: $white;
        height: 100%;

        @include bp-medium {
            @include transition(all, 0.4s, ease);
            @include transform(scale(1) rotate(0.001deg));
            overflow: hidden;
        }

        &:hover {
            background-color: $ghost-white;
        }

        &--animation {
            @include bp-medium {
                @include transform(scale(0) rotate(0.001deg));
            }
        }
    }

    .article-page + &__page-wrapper {
        padding: 10px 10px 0;

        @include bp-medium {
            padding: 35px 0 15px;
        }
    }

    &::before {
        @include transition(all, 0.4s, ease);
        position: absolute;
        top: 0;
        left: 50%;
        z-index: 1;
        border-top: 3px solid $blue-brand;
        width: 0;
        height: 0;
        content: ' ';
    }
}

@include bp-medium {
    .card-tile-grid__link-wrapper:hover {
        .card-tile-grid__image-container {
            @include transform(scale(1.07) rotate(0.001deg));
        }
    }
}

.card-tile-grid-wrapper--no-hover {
    &.card-tile::after {
        border: 0;
    }

    .card-tile {
        &__zoom:hover {
            background-color: $white;
        }

        &__link-wrapper:hover .card-tile__image-container {
            @include transform(none);
        }
    }
}

.card-tile-grid--oru {
    .card-tile {
        &::before {
            border-color: $orange;
        }

        &__icon {
            border-color: $orange;
            color: $orange;
        }

        &__link {
            color: $orange;
        }
    }

    .card-tile-grid__description li::before {
        color: $orange;
    }

    .card-tile-grid__link {
        color: $orange;
    }

    &::before {
        border-top: 3px solid $orange-brand;
    }
}
