// ============ INFO LIST CTA ===============

.check-list {
    @include body-l;
    border-top: 2px solid $medium-gray;
    color: $green;
    font-family: $open-sans-family;
    font-weight: $open-sans-semi-bold;

    &__item {
        position: relative;
        border-bottom: 2px solid $medium-gray;
        padding: 20px 0;

        @include bp-medium {
            padding: 30px 40px 30px 0;
        }

        @include bp-smaller {
            padding: 20px 0;
        }

        @include bp-smallerer {
            padding: 20px 0;
        }

        &-container {
            @include flexbox;
            padding-left: 30px;

            @include bp-medium {
                padding-left: 40px;
                width: 75%;
            }

            &--centered {
                @include inline-flex;

                @include bp-smaller {
                    @include flexbox;

                    padding-left: 50px;
                }
            }
        }

        &-container--multipay-post-payment {
            @include flexbox;
            @include justify-content(space-between);
            padding-left: 10px;
            width: 100%;
        }

        &-value {
            color: $medium-gray;
        }

        &--manage-account {
            border-bottom: 1px solid $medium-gray;

            .transactional-review__edit-link {
                background: $white;
            }

            .button-accordion {
                padding: 0;
                width: auto;
            }
        }

        &--animation {
            &:hover,
            &:hover > .transactional-review__edit-link {
                background-color: $alabaster;
            }
        }

        &--no-padding-right {
            padding-right: 0;
        }

        &-text {
            &--black {
                color: $black;
            }

            &--red {
                color: $red;
            }

            &--semi-bold {
                font-weight: $open-sans-semi-bold;
            }

            &--bold {
                font-weight: bold;
            }

            &--tab-title {
                font-family: $lexend-family;
                font-weight: $lexend-extra-bold;
            }

            &--large {
                @include body-l;
            }
        }

        .icon-check-status {
            position: relative;
            padding-right: 18px;

            &::before {
                position: absolute;
                font-size: calculate-rem(40);
            }
        }
    }

    &--ebill {
        display: none;
    }

    &--no-border:empty {
        border-top: 0;
    }

    &__item--ebill {
        border-bottom: 0;
    }

    &--no-list-decoration {
        list-style-type: none;
        list-style-image: url(data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7);
    }
}
