// ============ TEXT SEARCH ===============

.text-search {
    @extend %flexbox;
    @include align-items(center);
    @include justify-content(center);
    position: relative;
    padding: 15px 25px;

    &__input {
        @include border-radius(50px);
        @include body-xs;
        display: inline-block;
        outline: 0;
        border: 1px solid $medium-gray;
        background-color: $white;
        padding: 10px 10px 10px 15px;
        width: 100%;
        height: auto;
        font-family: $open-sans-family;
        font-weight: $open-sans-semi-bold;

        @include placeholder {
            color: $medium-gray;
        }
    }

    &__icon {
        position: absolute;
        top: 30px;
        right: 15%;
        width: 13px;
        height: 13px;
        color: $medium-gray;

        &::before {
            position: absolute;
            top: -15px;
            left: -10px;
            font-size: calculate-rem(40);
        }
    }
}
