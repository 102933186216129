// ============ ARROW ===============
.arrow {
    @include inline-flex;
    @include align-items(center);
    @include justify-content(space-between);
    position: relative;
    background-color: transparent;
    height: 20px;
    color: $white;
    font-size: calculate-rem(20);

    &__icon {
        @include transform(rotate(90deg));

        &::after {
            position: relative;
        }
    }

    &__text {
        display: inline-block;
        font-size: calculate-rem(13);
    }

    &--right {
        @include flex-flow(row-reverse);

        .arrow__icon {
            @include transform(rotate(-90deg));
        }
    }

    &--blue {
        color: $blue;
    }
}
