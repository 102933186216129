// ============== TRANSACTIONAL THANKS COMPONENT =================

.transactional-thanks {
    display: block;
    margin: 35px 0 50px;
    background-color: $white;
    padding: 80px 20px;
    text-align: center;

    &__image,
    &__image--svg {
        margin-bottom: 30px;
        width: 125px;
    }

    &__paragraph {
        &-top {
            @include heading-s;
            font-family: $open-sans-family;
            font-weight: $open-sans-bold;
        }

        &-bottom {
            @include body-m;
            font-family: $open-sans-family;
        }

        &-bottom:not(:last-child) {
            margin-top: 20px;
        }
    }

    &__tonp-paragraph {
        @include body-l;
        margin: 15px auto 20px;
        width: 65%;
        color: $dark-gray;
        font-family: $lexend-family;
        font-weight: $lexend-regular;
    }

    &__owe-paragraph {
        @include body-l;
        margin: 0;
        font-family: $lexend-family;
        font-weight: $lexend-regular;

        &-paragraph,
        &-paragraph a {
            display: inline-block;
        }
    }

    &__title {
        @include heading-l;
        font-family: $lexend-family;
        font-weight: $lexend-extra-bold;
    }

    &__error {
        padding: 20px;
    }
}
