// ============ MODIFIERS DATE PICKER CALENDAR =============

.ui-datepicker {
    outline: 0;
    border-radius: 0;
    padding: 0;
    width: 87%;

    @include bp-medium {
        width: 50%;
    }

    @include bp-date-picker {
        width: 38.4%;
    }

    table {
        font-size: calculate-rem(7);
    }

    &-trigger {
        @extend .icon-calendar;
        @extend .icon-default-container;
        position: absolute;
        top: 35px;
        right: 15px;
        background: none;
        width: 35px;
        height: 35px;
        font-size: calculate-rem(0);

        &:before {
            color: $blue;
            font-size: calculate-rem(35);

            .is-oru & {
                color: $teal;
            }
        }

        body:not(.mouse-user):not(.is-oru) &:focus {
            @extend .coned-focus-outline;
        }

        body:not(.mouse-user) &:focus {
            @extend .oru-focus-outline;
        }

        .coned-field--has-tooltip & {
            right: 45px;
        }
    }

    &-header {
        border: 0;
        background-color: $white;
    }

    &-month,
    &-year {
        @include body-m;
        font-family: $lexend-family;
        font-weight: $lexend-extra-bold;
    }

    &-title {
        padding: 20px 0;
        color: $mine-shaft;
    }
    
    &-calendar {
        background-color: $white;

        th {
            @include body-xs;
            border-bottom: 1px solid $medium-gray;
            color: $dark-gray;
            font-family: $open-sans-family;
            font-weight: $open-sans-semi-bold;
        }

        td {
            padding: 0;
            text-align: center;
        }
    }

    &.ui-widget {
        font-family: $open-sans-family;
    }

    &.ui-widget-content {
        transition: width 0s 0.1s, visibility 0s 0.2s;
        visibility: hidden;
        border-color: $medium-gray;
    }

    .ui-datepicker-prev,
    .ui-datepicker-next {
        @include border-radius(50%);
        display: table;
        top: 15px;
        border: solid 2px $blue;
        cursor: pointer;
        width: 25px;
        height: 25px;

        @include bp-medium {
            top: 10px;
        }

        &::after {
            @extend .icon-carrot::before;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            margin: auto;
            width: 25px;
            height: 25px;
            color: $blue;
            font: calculate-rem(24) 'coned-icons';
            clip: rect(0, 25px, 23px, 0);

            @include bp-medium {
                width: 24px;
                height: 24px;
                font: calculate-rem(24) 'coned-icons';
            }
        }

        &:focus {
            position: absolute !important;
        }

        &:hover {
            background-color: $white;

            &::after {
                transform: scale(1.025);
            }
        }

        &:active:not([disabled]) {
            border-color: $white;
            background-color: $dark-blue;

            &::after {
                color: $white;
            }

            .is-oru & {
                background-color: $black;
            }
        }
    }

    .ui-datepicker-prev {
        @include transform(rotate(90deg));
        left: 5%;

        @include bp-medium {
            left: 20%;
        }
    }

    .ui-datepicker-next {
        @include transform(rotate(-90deg));
        right: 5%;

        @include bp-medium {
            right: 20%;
        }
    }

    .ui-state-default,
    .ui-widget-content .ui-state-default {
        @include body-m;
        border: 0;
        background-color: $white;
        padding: 9px 0;
        text-align: center;
        color: $dark-gray;
        font-family: $open-sans-family;
        font-weight: $open-sans-semi-bold;

        @include bp-medium {
            padding: 10px;
        }

        &:hover {
            text-decoration: underline;
        }
    }

    .ui-state-active,
    .ui-widget-content .ui-state-active {
        position: relative;
        background-color: $green;
        text-decoration: underline;
        color: $white;
    }

    .ui-widget-header .ui-icon {
        background-image: none;
    }

    .is-oru & {
        .ui-datepicker-prev,
        .ui-datepicker-next {
            border-color: $teal;

            &::after {
                color: $teal;
            }
        }
    }

    .ui-instructions {
        border-top: 1px solid $medium-gray;
        overflow: auto;
    }

    .ui-instructions-acc-btn {
        @include body-s;
        display: flex;
        position: relative;
        border: 0;
        background: none;
        padding: 16px 30px 16px 20px;
        width: 100%;
        text-align: left;
        font-family: $lexend-family;
        font-weight: $lexend-extra-bold;

        @include bp-medium {
            padding: 13px 40px;
        }

        &[aria-expanded='true'] {
            .ui-instructions-acc-btn-icon {
                transform: rotate(180deg);
            }
        }

        body:not(.mouse-user):not(.is-oru) &:focus {
            @extend .coned-focus-outline;
        }

        body:not(.mouse-user) &:focus {
            @extend .oru-focus-outline;
        }
    }

    .ui-instructions-acc-btn-icon {
        display: flex;
        position: absolute;
        top: 0;
        right: 5px;
        bottom: 0;
        font-size: calculate-rem(32);
        align-items: center;
        pointer-events: none;

        @include bp-medium {
            right: 15px;
        }
    }

    .ui-instructions-body {
        display: none;
        margin: 0 25px 15px;

        &--expanded {
            display: block;
        }

        ul {
            margin-left: 15px;

            @include bp-medium {
                margin-left: 25px;
            }
        }

        li {
            @include body-s;
            margin-bottom: 5px;
            list-style-type: disc;
        }
    }

    .ui-state-disabled,
    .ui-state-disabled .ui-state-default,
    .ui-widget-content .ui-state-disabled,
    .ui-widget-header .ui-state-disabled {
        color: $medium-gray;

        &.ui-datepicker-prev,
        &.ui-datepicker-next {
            opacity: 0.5;
            border-color: $light-gray;
            background-color: $light-gray;

            &:after {
                color: $medium-gray;
            }
        }
    }
}
