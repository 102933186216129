// ============== CONED IFRAME COMPONENT =================

.coned-iframe {
    padding: 0 20px;

    @include bp-medium {
        padding: 0 0 0 21.5%;
        width: 75%;
    }

    &-wrapper {
        background-color: $white;
        padding-bottom: 50px;

        @include bp-medium {
            padding-bottom: 95px;
        }
    }

    &__title {
        margin-bottom: 25px;
        font: $lexend-extra-bold calculate-rem(20) / calculate-rem(26) $lexend-family;
    }

    &__container {
        overflow-y: auto;
    }
}
