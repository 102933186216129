.error-chart-message {
    display: flex;
    margin: 0 10px 20px;
    background-color: $pastel-light-blue;
    padding-top: 10px;
    text-align: center;
    flex-direction: column;

    &--image-icon {
        width: 80%;

        @include bp-medium {
            width: 400px;
        }
    }

    &--title {
        margin: 0 50px 30px;
        font-family: $open-sans-family;

        @include bp-medium {
            margin: 0 20% 30px;
        }

        .heading {
            font-size: calculate-rem(24);
            font-weight: bold;
        }

        .paragraph {
            margin-top: 25px;
            font-family: $open-sans-family;
            font-size: calculate-rem(16);

            @include bp-medium {
                font-size: calculate-rem(14);
            }
        }
    }
}
