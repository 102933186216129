.filter-group {
    @include flexbox;
    width: 100%;

    &__wrapper {
        @include flexbox;
        @include gap(20px);
        @include transition(opacity 0.2s ease);
        opacity: 0;
        width: 100%;

        &-items,
        &-modal,
        &-clear {
            @include flexbox;
        }

        &-items {
            @include gap(20px);
        }

        &-clear {
            @include justify-content(center);
            @include align-items(center);
            margin-left: auto;
        }

        &.modal-enable {

            .filter-group__wrapper-items,
            .filter-group__wrapper-clear {
                display: none;
            }
        }

        &--fade-in {
            opacity: 1;
        }
    }

    &__button {
        &-open {
            @extend .button--outline-theme;
            @include btn-focus-outline;
            @include body-m;
            @include flexbox;
            @include justify-content(center);
            @include align-items(center);
            position: relative;
            background: $white;
            padding: 5px 10px;
            color: $blue;
            font-weight: $open-sans-semi-bold;

            .is-oru & {
                color: $teal;
            }

            &[tabindex="-1"] {
                border-color: transparent !important;
                cursor: unset;
                padding: 0 0 0 15px;
            }

            &[role="button"] {
                cursor: pointer;

                // scss-lint:disable NestingDepth
                &:hover {
                    background-color: $blue;
                    color: $white;

                    .is-oru & {
                        background-color: $teal;
                    }
                }

                &:active {
                    .is-oru & {
                        background-color: $black;
                        color: $white;
                    }
                }

                // scss-lint:enable NestingDepth
            }

            .icon-filter {
                position: static;
                margin-right: 6px;
                font-size: calculate-rem(30);
            }
        }

        &-clear {
            @extend .button-link;
            @extend .button-link--hover-underline;
            @include btn-focus-outline;
            visibility: visible;

            &[aria-hidden="true"] {
                visibility: hidden;
            }
        }
    }
}
