// ============== CONTACT COMPONENT =================

.contact {
    background-color: $white;

    &__content {
        @include box-sizing(border-box);
        border: 1px solid $light-gray;
        padding: 20px;
        width: 100%;
    }

    &__title {
        display: block;
        margin-top: 0;
        margin-bottom: 20px;
        text-align: left;
        color: $medium-gray;
        font: calculate-rem(19) $open-sans-family;
        font-weight: $open-sans-bold;

        @include bp-small {
            font-size: calculate-rem(24);
        }

        @include bp-smaller {
            font: calculate-rem(16) / calculate-rem(20) $open-sans-family;
            font-weight: $open-sans-bold;
        }
    }

    &__text {
        display: block;
        margin-top: 0;
        margin-bottom: 20px;
        text-align: left;
        font: calculate-rem(10) $open-sans-family;

        @include bp-medium {
            margin-bottom: 20px;
        }
    }

    &__actions {
        @extend %flexbox;
        @include flex-direction(row);
        @include flex-wrap(wrap);

        &-item {
            @include box-sizing(border-box);
            padding-bottom: 10px;
            width: 100%;

            @include bp-medium {
                padding: 10px;
                width: 33%;
            }
        }

        &-item:first-child {
            padding-left: 0;
        }

        &-item:last-child {
            padding-right: 0;
        }

        &-item-anchor {
            @include box-sizing(border-box);
            @include border-radius(5px);
            display: block;
            padding: 15px;
            width: 100%;
            text-align: center;
            font: $lexend-semi-bold calculate-rem(13) $lexend-family;

            @include bp-smaller {
                font-size: calculate-rem(12);
            }
        }

        &-item-anchor-chat {
            border: 3px solid $twitter-blue;
            background-color: $white;
            color: $twitter-blue;
        }

        &-item-anchor-phone {
            border: 3px solid $twitter-blue;
            background-color: $white;
            color: $twitter-blue;
        }

        &-item-anchor-email {
            border: 3px solid $twitter-blue;
            background-color: $twitter-blue;
            color: $white;
        }

        &-item-icon-chat {
            color: $twitter-blue;
        }

        &-item-icon-phone {
            color: $twitter-blue;
        }

        &-item-icon-email {
            color: $white;
        }
    }
}
