// ============== DATA TABLE COMPONENT =================

.data-table {
    padding: 0 20px 50px;
    color: $dark-gray;

    @include bp-medium {
        margin: auto;
        max-width: 1180px;
    }

    &-wrapper {
        background-color: $white;
    }

    &__body {
        @include bp-small {
            display: block;
        }
    }

    &__content {
        width: 100%;

        @include bp-small {
            display: block;
        }
    }

    &__header {
        border-bottom: 3px solid $blue-brand;

        @include bp-small {
            display: none;
        }

        &-column {
            @include body-l;
            padding-top: 5px;
            padding-bottom: 10px;
            text-align: left;
            text-transform: uppercase;
            font-family: $open-sans-family;
            font-weight: $open-sans-bold;

            &--smaller {
                @include body-xs;
            }
        }

        &-column:first-child {
            @include heading-s;
            padding-bottom: 15px;
            width: 35%;
            text-transform: none;
            font-family: $lexend-family;
            font-weight: $lexend-extra-bold;
        }

        &-column--smaller:first-child {
            @include body-l;
        }

        &-column:not(:last-child) {
            padding-right: 3%;
        }
    }

    &__row {
        border-bottom: 1px solid $medium-gray;
        height: 115px;

        @include bp-small {
            display: block;
            margin-bottom: 70px;
            border-bottom: 0;
            height: auto;

            &:last-child {
                margin-bottom: 0;
            }
        }

        th {
            text-align: left;
        }
    }

    &__column {
        @include heading-l;
        padding: 15px 0;
        font-family: $lexend-family;
        font-weight: $lexend-extra-bold;

        @include bp-small {
            @include flexbox;
            @include align-items(center);
            @include flex-flow(row wrap);
        }

        &:first-child {
            @include body-l;
            text-transform: uppercase;
            font-family: $open-sans-family;
            font-weight: $open-sans-bold;

            @include bp-small {
                border-bottom: 3px solid $blue-brand;
                padding-bottom: 5px;
            }
        }

        &:not(:last-child) {
            padding-right: 3%;

            @include bp-small {
                padding-right: 0;
            }
        }

        &:not(:first-child) {
            @include bp-small {
                border-bottom: 1px solid $medium-gray;
            }
        }

        a:focus {
            body:not(.mouse-user):not(.is-oru) & {
                @extend .coned-focus-outline;
            }

            body:not(.mouse-user) & {
                @extend .oru-focus-outline;
            }
            
            &::before {
                content: none !important;
            }
        }
    }

    &__column:not(.data-table__column--rich-text-mobile)::before, 
    &__column-mobile {
        @include body-l;
        @include flex-basis(48%);
        margin-right: 2%;
        overflow: hidden;
        text-align: left;
        text-transform: uppercase;
        font-family: $open-sans-family;
        font-weight: $open-sans-bold;
        content: attr(data-th);
        word-break: break-word;

        @include bp-medium {
            display: none;
        }
    }
    
    &__column:not(.data-table__column--rich-text-mobile)::before {
        content: attr(data-th);
    }

    &__column--smaller::before, 
    &__column--smaller:first-child,
    &__column--smaller {
        @include body-xs;
    }

    &__column:first-child {
        &::before {
            @include body-l;
            padding-bottom: 10px;
            text-transform: initial;
            font-family: $lexend-family;
            font-weight: $lexend-extra-bold;
        }
    }

    &__column-text {
        @include bp-small {
            @include flex-basis(48%);
            margin-left: 2%;
            overflow: hidden;
            text-align: right;
            word-break: break-word;
        }

        &--date {
            @include body-xs;
            color: $medium-gray;
            font-family: $open-sans-family;
            font-weight: $open-sans-bold;
        }

        &--data {
            @include body-xs;
            font-family: $open-sans-family;
            font-weight: $open-sans-semi-bold;
        }

        &--small-mobile {
            @include heading-l;
            font-family: $lexend-family;
            font-weight: $lexend-extra-bold;
        }

        &--small {
            @include heading-l;
            font-family: $lexend-family;
            font-weight: $lexend-extra-bold;
        }
    }

    &__description-title {
        margin-top: 70px;
    }

    &--full-width {
        padding: 0;
        width: 100%;
    }

    &--market-padding {
        padding-top: 30px;
    }
}

.data-table--collapsible-table {
    padding: 0;

    @include bp-medium {
        width: 100%;
    }

    .data-table__header {
        &-column:first-child {
            width: auto;
            text-transform: capitalize;
            font: $lexend-extra-bold calculate-rem(12) / calculate-rem(12) $lexend-family;
        }
    }

    .data-table__column {
        text-transform: capitalize;
        font: calculate-rem(12) / calculate-rem(18) $open-sans-family;

        @include bp-small {
            line-height: calculate-rem(28);
            font-size: calculate-rem(20);
        }

        @include bp-smallerer {
            line-height: calculate-rem(22);
            font-size: calculate-rem(16);
        }

        &:first-child {
            width: auto;
            text-transform: capitalize;
            font: calculate-rem(12) / calculate-rem(18) $open-sans-family;

            @include bp-small {
                border-bottom: 3px solid $blue-brand;
                padding: 0;
                height: 0;
                overflow: hidden;
            }
        }
    }
}

.data-table--small-text {
    .data-table__column {
        @include body-m;
        font-family: $open-sans-family;
        font-weight: $open-sans-regular;
        
        &:first-child {
            @include heading-s;
            font-family: $open-sans-family;
            font-weight: $open-sans-bold;
        }
    }
}

.data-table--oru {
    .data-table__column:first-child {
        @include bp-small {
            border-bottom: 3px solid $orange-brand;
        }
    }

    .data-table__header {
        border-bottom: 3px solid $orange-brand;
    }
}
