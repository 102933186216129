// ============== TEXT AND IMAGE COMPONENT =================

.text-and-image-content {
    @include flexbox;
    @include flex-direction(column);
    padding: 0 25px;

    @include bp-medium {
        @include flex-direction(row);
        padding: 0;
    }

    &__media-container {
        @include flexbox;
        @include flex-direction(column);
        background-color: $white;

        @include bp-medium {
            @include flex-grow(1);
            @include flex-shrink(1);
            @include flex-basis(0);
            @include justify-content(center);

            &--right {
                order: 1;
            }
        }
    }

    &__text-container {
        @include flexbox;
        @include flex-direction(column);
        @include justify-content(center);
        background-color: $white;

        @include bp-medium {
            @include flex-grow(1);
            @include flex-shrink(1);
            @include flex-basis(0);
        }
    }

    &__text-container--link {
        &:focus {
            body:not(.mouse-user):not(.is-oru) & {
                @extend .coned-focus-outline;
            }

            body:not(.mouse-user) & {
                @extend .oru-focus-outline;
            }

            &::before {
                content: none !important;
            }
        }
    }

    &__image {
        width: 100%;
    }
}
