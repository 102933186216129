// ============== PAYMENT TABLE COMPONENT =================

.payment-table {
    @include body-s;
    color: $dark-gray;
    font-family: $open-sans-family;

    &-wrapper {
        @extend %flexbox;
        @include flex-direction(column);

        margin-bottom: 20px;
        border: 1px solid $medium-gray;
        padding: 10px 20px;

        &__cta-link--right,
        &__cta-link--left {
            @extend .button-link, .button-link--extra-low-emphasis, %flexbox;
            margin: 15px 0;
        }

        &__cta-link--right {
            @include align-self(flex-end);
        }

        &__cta-link--left {
            @include align-self(flex-start);
            display: inline-flex;
        }

        &:focus {
            outline: none;
        }
    }

    &__row {
        &-total {
            border-top: 1px solid $dark-gray;
        }
    }

    &__span {
        &--no-bold {
            font-weight: $open-sans-semi-bold !important;
        }
    }

    &__column {
        &-description {
            @include box-sizing(border-box);
            padding: 5px 0;
            width: 70%;

            span {
                font-weight: $open-sans-extra-bold;
            }

            .no-bold {
                font-weight: $open-sans-semi-bold;
            }
        }

        &-description-inline-text {
            display: inline;
        }

        &-paid {
            @include box-sizing(border-box);
            padding: 5px 15px;
            width: 15%;
            text-align: right;
            color: $red;

            @include bp-smaller {
                padding: 5px;
            }
        }

        &-paid-total {
            font-weight: $open-sans-extra-bold;
        }

        &-outstanding {
            padding: 5px 15px;
            text-align: right;
            color: $red;

            @include bp-smaller {
                padding: 5px;
            }
        }

        &-total {
            padding: 25px 0;
            text-align: right;

            span {
                font-weight: $open-sans-extra-bold;
            }
        }

        &--title {
            color: $dark-gray;
        }
    }

    &__popup {
        position: absolute;
        left: 9999px;
        visibility: hidden;
        pointer-events: none;

        &--active {
            position: fixed;
            left: 0;
            visibility: visible;
            pointer-events: auto;
        }
    }
}
