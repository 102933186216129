.js-recaptcha {
    .grecaptcha-badge {
        bottom: 40px !important;
        z-index: 2;
    }

    &--maid-active {
        .grecaptcha-badge {
            bottom: 125px !important;

            @include bp-medium {
                bottom: 77px !important;
            }
        }
    }
}
