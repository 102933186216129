// ============ MODIFIERS FORM =============

.coned-input-message--error,
.coned-input-top-message--error {
    @extend %flexbox;
    @extend .icon-more-info;
    border-color: $red;

    @include selection {
        background: $blue;
        color: $white;
    }

    &::before {
        @extend %flexbox;
        @include border-radius(50%);
        @include justify-content(center);
        @include align-items(center);
        position: absolute;
        top: -1px;
        left: 0;
        color: $red;
        font: normal calculate-rem(25) 'coned-icons';
    }

    ~ label {
        color: $red;
    }

    &:not(.coned-input--filled) {
        &:focus ~ label {
            color: $red;
        }

        ~ label {
            color: $dark-gray;
        }
    }

    ~ .border-bar,
    ~ .border-bar::after {
        border-color: $red;
    }
}
