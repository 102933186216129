// ============== BUTTONS =================

.button {
    @extend .button--primary-color;
    @include transition(font-size, 0.2s, ease);
    @include inline-flex;
    @include justify-content(center);
    @include align-items(center);
    @include button-m;
    position: relative;
    outline: none;
    border: 2px solid;
    border-radius: 50px;
    padding: 10px 25px;
    height: 50px;
    min-height: 50px;
    text-align: center;
    text-transform: uppercase;
    text-decoration: none;
    font-family: $open-sans-family;
    font-weight: $open-sans-extra-bold;
    background-clip: padding-box;
    box-sizing: border-box;

    body:not(.mouse-user):not(.is-oru) &:focus::before {
        @extend .coned-button-focus;
    }

    body:not(.mouse-user) &:focus::before {
        @extend .oru-button-focus;
    }

    &:hover:not([disabled]) {
        span {
            display: block;
            transform: scale(1.025);
        }

        [class^='icon-'],
        [class*=' icon-'] {
            display: inline;
        }
    }

    &--primary-color {
        border-color: $white;
        background-color: $blue;
        color: $white;

        .is-oru & {
            border-color: $white;
            background-color: $teal;
            color: $white;
        }
    }

    &--secondary-color {
        border-color: $blue;
        background-color: $white;
        color: $blue;

        .is-oru & {
            border-color: $teal;
            background-color: $white;
            color: $teal;
        }
    }

    &:active:not([disabled]) {

        &,
        [class^='icon-'],
        [class*=' icon-'] {
            border-color: $white;
            background-color: $dark-blue;
            color: $white;

            .is-oru & {
                background-color: $black;
            }
        }
    }

    &--high-emphasis {
        @include button-l;
        padding: 15px 30px;
        min-height: 60px;
        text-transform: capitalize;
        font-family: $lexend-family;
        font-weight: $lexend-extra-bold;

        &-large {
            @extend .button--high-emphasis, .button--flex;
            margin: 0 auto;
            width: 100%;
            max-width: 440px;
        }
    }

    &:disabled {
        opacity: 0.5;
        background-color: $light-gray;
        color: $medium-gray;
    }

    &-icon {
        position: relative;
        width: 25px;
        height: 25px;
    }

    &--flex {
        @include flexbox;
        @include align-items(center);
        @include justify-content(center);
    }

    &--purple {

        &,
        .is-oru & {
            margin: 5px;
            background: $purple;
            color: $white;

            &:active:not([disabled]) {
                background-color: $dark-purple;
            }
        }
    }

    &--white {

        &,
        .is-oru & {
            margin: 5px;
            background: $white;
            color: $purple;
        }
    }

    &--outline-theme {
        @include border-radius(4px);
        border: 2px solid $blue;

        .is-oru & {
            border: 2px solid $teal;
        }
    }

    &--border {
        &-dark-gray {
            border: 1px solid $dark-gray;

            body:not(.is-oru) & {
                border: 1px solid $dark-gray;
            }
        }

        &-radius {
            &-sm {
                border-radius: 3px;
            }
        }
    }

    /**
        Deprecated classes. Do not use them.
    */

    &-default {
        @extend .button;
    }

    &--blue-inverted-outline {
        @extend .button--secondary-color;
    }

    &--blue,
    &--orange,
    &-small,
    &-medium {
        @extend .button;
    }

    &--center {
        margin: 15px auto;
    }
}
