// ============== EXTRA HELP =================

.extra-help {
    @include justify-content(center);
    margin-top: 40px;

    @include bp-mid-small {
        @include flexbox;
        @include flex-wrap(wrap);
    }

    &__container {
        @extend %flexbox;
        @include flex-direction(row);
        @include align-items(center);
        @include box-sizing(border-box);
        padding: 0 0 65px;
        width: 100%;
        max-width: 375px;
        text-align: center;

        @include bp-medium {
            padding: 0 20px;
            max-width: none;

            &:first-child {
                padding-left: 0;
            }

            &:last-child {
                padding-right: 0;
            }
        }

        &--half {
            @include bp-medium {
                width: 50%;
            }
        }

        &--one-third {
            @include bp-medium {
                width: 33.33%;
            }
        }

        &--one-fourth {
            @include bp-medium {
                width: 25%;
            }
        }

        &:last-child {
            padding-bottom: 0;
        }
    }

    &__image-container {
        @include flex-shrink(0);
        @include align-self(flex-start);
        margin-right: 20px;
        width: 130px;
        height: 130px;

        @include bp-medium {
            margin-right: 15px;
            width: 110px;
            height: 110px;
        }

        @include bp-start-service-profile {
            @include align-self(auto);
            margin-right: 35px;
            width: 180px;
            height: 180px;
        }

        img {
            width: 100%;
            height: auto;
        }
    }

    &__checkbox-container {
        @extend %flexbox;
        @include flex-direction(column);
        @include align-items(flex-start);
        @include justify-content(flex-start);
        @include box-sizing(border-box);
        width: 100%;
        text-align: left;
    }

    &__checkbox,
    &__radio {
        @include flex-direction(row);
        @include justify-content(center);
        padding-top: 15px;

        &-box {
            padding: 10px 15px;
        }

        &-label {
            text-align: left;
        }
    }

    &__radio {
        @include flex-direction(column);

        @include bp-start-service-profile {
            @include flexbox();
            @include flex-direction(row);
            @include justify-content(space-between);
            width: 100%;
            max-width: 240px;
        }
    }

    &__title {
        @include body-s;
        margin-bottom: 10px;
        font-family: $lexend-family;
        font-weight: $lexend-extra-bold;
    }

    &__text-container {
        position: relative;
        padding-bottom: 20px;
    }

    &__paragraph {
        @include body-s;
        padding: 10px 0;
        color: $medium-gray;
        font-family: $open-sans-family;
        font-weight: $open-sans-semi-bold;
    }

    &__tooltip {
        @extend %inline-flex;

        &-container {
            max-width: 100%;

            @include bp-start-service-profile {
                max-width: 250px;
            }
        }
    }
}
