.form-to-table {
    position: relative;
    margin: 0 auto;
    border-bottom: 1px solid $white;
    padding: 59px 0;
    width: 100%;
    max-width: 1400px;

    @include bp-medium {
        position: initial;
        padding: 47px 0;
    }

    &--label-padding {
        @include bp-medium {
            padding: 37px 0 67px;
        }

    }

    &--bg {
        &-purple {
            background-color: $light-purple;
        }
    }

    &__wrapper {
        padding: 0 20px;

        @include bp-medium {
            margin: 0 auto;
            padding: 0;
            width: 100%;
            max-width: 68.428%;
        }

        &-header {
            @include flexbox;
            @include flex-direction(column);
            @include gap(16px);

            @include bp-medium {
                @include flex-direction(row);
                @include flex-wrap(wrap);
                @include justify-content(space-between);
                @include gap(20px);
                position: relative;
            }
        }

        &-heading {
            @include flexbox;
            @include align-items(center);
            @include flex(2);
        }

        &-selects {
            @include flexbox;
            @include flex-direction(column);
            @include align-items(baseline);
            @include gap(16px);
            position: relative;
            margin: auto;

            @include bp-medium {
                @include flex-direction(row);
                @include flex-wrap(nowrap);
                @include gap(16px);
                margin: 0;
            }
        }

        &-table {
            table {
                display: none;
            }

            &--show {
                margin: 30px 0;
                background: $white;
                padding: 0 20px 20px;

                @include bp-medium {
                    padding: 30px 30px 50px;
                }
                // scss-lint:disable NestingDepth
                table {
                    display: table;
                }
                // scss-lint:enable NestingDepth
            }
        }
    }

    &__container > .form-to-table:last-of-type {
        margin: 0 auto 50px;

        @include bp-medium {
            margin: 0 auto 95px;
        }

        &.form-to-table--without-margin {
            margin: 0;
        }
    }

    &__span {
        @include flexbox;
        @include justify-content(center);
        @include align-items(center);
        @include body-s;
        position: absolute;
        top: -33px;
        left: 2px;
        font-weight: $open-sans-bold;

        @include bp-medium {
            position: initial;
        }

        .icon-filter {
            margin-right: 8px;
            font-size: 2rem;
        }
    }

    &__label-wrapper {
        margin: auto;
        width: 296px;

        @include bp-medium {
            margin: 0;
            max-width: 100%;
        }

    }

    &__byline {
        
        &-wrapper {
            margin-bottom: 20px;
        }

        &-hr {
            display: inline-block;
            margin: 0 10px 2px 0;
            background-color: $white;
            width: 75px;
            height: 3px;
        }
    }
    

    &__label {
        @include body-s;
        position: relative;
        margin: 0 0 15px;
        text-align: left;
        text-transform: uppercase;
        font-family: $lexend;
        font-weight: $lexend-semi-bold;
    }

    &__heading {
        @include heading-s;
        margin: 0 auto 52px;
        width: 295px;
        max-width: 100%;
        text-align: left;
        font-family: $lexend-family;
        font-weight: $open-sans-bold;

        @include bp-medium {
            @include flex(2);
            margin: 0;
        }
    }

    &__table {
        display: table;

        .table__row--header {
            .table__cell {
                @include bp-medium {
                    border-bottom: 3px solid $blue;
                }

                // scss-lint:disable NestingDepth
                &:last-of-type {
                    border-bottom: 3px solid $blue;
                }

                // scss-lint:enable NestingDepth
            }
        }

        .table__cell > a {
            display: inline-block;          
            max-width: 100%;    
            overflow: hidden;    
            text-overflow: ellipsis;             
            white-space: nowrap;            

            
            &:focus {
                @extend %focus-outline-on-element;
            }
        }

        &[aria-hidden="true"] {
            display: none;
        }
    }
}
