// ============== MANAGE PREFERENCES COMPONENT =================
.account-details {
    @include flexbox;
    @include justify-content(space-between);
    @include flex-flow(wrap column);
    margin-bottom: 35px;
    background-color: $white;

    @include bp-medium {
        @include flex-direction(row);
    }

    &__container {
        margin-bottom: 20px;
        background-color: $white;
        padding: 40px 20px;

        @include bp-medium {
            padding: 40px;
        }

        &--center {
            margin: 40px auto;
            padding: 40px 20px;

            @include bp-medium {
                width: 58%;
            }
        }

        &--extra-margin-bottom {
            margin-bottom: 50px;
        }
    }

    &__select--active {
        .coned-select {
            border-bottom: 2px solid $green;
        }

        .coned-select__label {
            @include opacity(1);
            display: block;
            top: 16px;
        }
    }

    &__icon {
        @include border-radius(10px);
        position: relative;
        top: -2px;
        margin-left: 5px;
        background-color: $green;
        padding: 5px;
        color: $white;

        &-container {
            @include flexbox;
            @include flex-wrap(wrap);
            @include justify-content(flex-start);
        }

        &--unchecked {
            background-color: $red;
        }
    }

    &__tooltip {
        @include transform(rotate(0deg));
        background-color: $transparent;

        &:hover {
            background-color: $transparent;
        }

        &--true::after {
            @extend .icon-check-status::before;
            color: $green;
            font-family: 'coned-icons';
        }

        &--false::after {
            @extend .icon-close::before;
            color: $red;
            font-family: 'coned-icons';
        }
    }

    &__info {
        &-wrapper {
            @include flexbox;
            @include justify-content(space-between);
            @include flex-wrap(wrap);
            @include bp-large {
                @include flex-wrap(nowrap);
            }
        }

        &-container {
            @include flexbox;
            @include justify-content(flex-start);
            @include flex-direction(column);
            margin-bottom: 20px;
            width: 100%;

            @include bp-large-header {
                width: 49%;
            }

            &--max-width {
                width: 100%;
            }
        }

        &-text {
            @include body-m;
            display: inline-block;
            margin-right: 4px;
            font-family: $open-sans-family;
            word-break: break-word;

            &:first-child {
                margin-right: 0;
            }

            &--extension {
                padding-left: 4px;
            }
        }

        &-description {
            @include body-s;
            padding-bottom: 10px;
            font-family: $open-sans-family;
            font-weight: $open-sans-regular;
        }

        &-link {
            @extend .button-link;
            display: inherit;
            margin-top: 15px;
            outline: none;

            &--no-margin-top {
                margin-top: 0;
            }

            body:not(.mouse-user):not(.is-oru) &:focus::before {
                @extend .coned-button-focus;
            }

            body:not(.mouse-user) &:focus::before {
                @extend .oru-button-focus;
            }
        }
    }

    &__title {
        @include heading-l;
        margin-bottom: 25px;
        border-bottom: 3px solid $medium-gray;
        padding-bottom: 20px;
        font-family: $lexend-family;
        font-weight: $lexend-extra-bold;
    }

    &__option-title {
        @include body-l;
        margin-bottom: 10px;
        font-family: $open-sans-family;
        font-weight: $open-sans-semi-bold;
    }

    &__extension-field {
        margin-right: 0;
    }

    &__switch {
        @include flexbox;
        @include justify-content(space-between);
        margin-bottom: 10px;
        border-bottom: 1px solid $light-gray;

        @include bp-smaller {
            @include flex-direction(column);
            margin: 15px 0;
            padding-bottom: 20px;
        }

        &-wrapper {
            @include flexbox;
            @include align-items(center);
            @include box-sizing(border-box);
            padding: 20px 0;
            width: 100%;

            @include bp-smaller {
                padding: 20px 0 0;
            }

            @include bp-medium {
                width: 50%;
            }
        }

        &-container {
            display: inline-block;
            margin: 0;
        }

        &-text {
            display: inline-block;
            padding-left: 10px;
            color: $green;
            font: calculate-rem(18) / calculate-rem(32) $open-sans-family;
            font-weight: $open-sans-semi-bold;
        }
    }

    &--center-content {
        margin: 40px auto;
        background-color: $white;
    }
}

.account-details__container--open {
    border-bottom: 2px solid $light-gray;
    width: 100%;
}

.account-details--oru {
    .account-details__info-link {
        color: $teal;
    }
}
