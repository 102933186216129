// ============== FORM  =================

.coned-form,
.power-your-way-form,
.lead-gen-form,
.real-time-form {
    background-color: $white;
    padding: 50px 20px 65px;
    overflow: hidden;

    @include bp-medium {
        padding: 100px 0 125px 21.5%;
        width: 50%;
    }

    &--small-padding-bottom {
        padding-bottom: 80px;
    }

    &--card-module {
        background-color: transparent;
        padding: 10px 0;
        width: 100%;
    }

    &-wrapper {
        background-color: $white;

        @include bp-small {
            margin: 10px;
        }

        &--margin-top-mobile-60 {
            margin-top: 60px;
        }
    }

    &__fieldset {
        margin: 10px;
        padding-top: 30px;

        &--no-padding {
            padding-top: 0;
        }

        &-header {
            @include heading-s;
            margin: 35px 0;
            color: $dark-gray;
            font-family: $lexend-family;
            font-weight: $lexend-extra-bold;

            &--small-margin-bottom { 
                margin-bottom: 20px;
            }
        }

        &-subheader {
            @include body-m;
            margin: 50px 0 20px;
            color: $dark-gray;
            font-family: $open-sans-family;
            font-weight: $open-sans-bold;
        
            &--no-margin-top {
                margin-top: 0;
            }
        }
    }

    &__fields-wrapper {
        margin-bottom: 25px;
        width: 100%;
    }

    &__field-container {
        margin-bottom: 20px;

        &--one-half {
            float: left;
            width: 100%;

            @include bp-medium {
                width: 50%;
            }
        }

        &--padd-20-r-l {
            padding: 0 calculate-rem(20);
        }

        &--one-third {
            float: left;
            width: 100%;

            @include bp-medium {
                @include box-sizing(border-box);
                width: 32%;
            }
        }

        &--one-third:not(:last-child) {
            margin-right: 2%;
        }

        &--one-third:only-child {
            margin-right: 0;
        }

        &--two-thirds {
            float: left;
            width: 100%;

            @include bp-medium {
                width: 68%;
            }
        }

        &--asymetric-left-third {
            display: inline-block;
            width: 100%;

            @include bp-cards {
                @include box-sizing(border-box);
                margin-left: 2%;
                width: 19%;
                vertical-align: top;

                &:first-child {
                    margin-left: 0;
                    width: 44%;
                }

                &:last-child {
                    width: 31%;
                }
            }
        }

        &--inline-element {
            display: inline-block;
            margin-right: 100px;
        }

        &--checkbox {
            @extend %flexbox;
            @include align-items(center);

            @include bp-medium {
                @include inline-flex;
                margin-right: 50px;
            }
        }

        &--hidden {
            display: none;
        }

        &--margin-top {
            margin-top: 40px;
        }

        &--space-between {
            @include bp-medium {
                @include flexbox;
                @include justify-content(space-between);
            }
        }

        &--no-margin-bottom {
            margin-bottom: 0;
        }

        &--inline {
            display: inline-block !important;
        }
    }

    &__field-disabled {
        opacity: 0.5;
    }

    &__legend-container {
        display: block;
        float: left;
        clear: both;
        margin: 45px 0 0;
        border-bottom: 3px solid $medium-gray;
        width: 100%;
        color: $dark-gray;
        font-family: $lexend-family;
        font-weight: $lexend-extra-bold;

        &-title {
            @include heading-m;
        }
    }

    &__alert-block {
        border: 1px solid $medium-gray;
        padding: 15px 12px 18px 21px;

        &-paragraph {
            @include flexbox;
            @include body-m;
            color: $medium-gray;
            font-family: $open-sans-family;
            font-weight: $open-sans-semi-bold;

            &--dark-gray {
                color: $dark-gray;
            }
        }

        &-icon {
            position: relative;
            margin-right: 40px;
            font-size: calculate-rem(26);

            &::before {
                position: absolute;
                top: -2px;
            }

            &--red {
                color: $red;
            }

            &--margin-right-25 {
                margin-right: 25px;
            }
        }

        &--pading-20 {
            padding: 20px;
        }

        &--margin-bottom-40-destkop {
            @include bp-medium {
                margin-bottom: 40px;
            }
        }
        
        &--margin-bottom-24-mobile {
            @include bp-small {
                margin-bottom: 24px;
            }
        }
    }

    &__field-container--description {
        @include body-m;
        clear: both;
        margin-bottom: 10px;
        padding-top: 20px;
        width: 100%;
        color: $dark-gray;
        font-family: $open-sans-family;
        
        &-no-padding {
            margin-bottom: 10px !important;
            padding: 0;
        }

        &-no-padding-top {
            padding-top: 0;
        }

        &-align-right {
            text-align: right;
        }

        &-margin-bottom {
            margin-bottom: 50px;
        }

        &-eap {
            margin: 0;
            padding: 40px 0 0;
            
        }

        .transactional__paragraph--bolder {
            @include body-l;
        }

        a {
            body:not(.mouse-user):not(.is-oru) &:focus {
                @extend .coned-focus-outline;
            }

            body:not(.mouse-user) &:focus {
                @extend .oru-focus-outline;
            }

            &:focus::before {
                content: none !important;
            }
        }
    }

    &__field-container--box {
        @include box-sizing(border-box);
        margin-top: 40px;
        border: 1px solid $medium-gray;
        padding: 25px 20px;
    }

    &__field-container--fieldset-no-margin {
        margin-top: -20px;
    }

    &__field-container--card-form {
        margin-bottom: 0;
    }

    &__field-container---margin-bottom-10 {
        margin-bottom: 10px;
    }

    &__field-group-subtitle {
        @include body-l;
        color: $dark-gray;
        font-family: $open-sans-family;
        font-weight: $open-sans-bold;
    }

    &__article-subsection {
        h2 {
            @include heading-s;
            margin: 20px 0;
            color: $dark-gray;
            font-family: $lexend-family;
            font-weight: $lexend-extra-bold;
        }

        h3,
        .coned-form__article-subsection-subheader {
            margin-top: 40px;
            color: $dark-gray;
            font-family: $open-sans-family;
            font-weight: $open-sans-bold;
            
            &:not(.coned-form__fieldset-subheader) {
                @include body-l;
            }
        }

        p {
            @include body-l;
            clear: both;
            margin-bottom: 10px;
            color: $dark-gray;
            font-family: $open-sans-family;
        }

        ul {
            margin: 0 0 50px 50px;
            list-style: none;

            li {
                @include body-s;
                margin: 30px 0;
                color: $dark-gray;
                font-family: $open-sans-family;
                font-weight: $open-sans-semi-bold;
            }

            li::before {
                display: inline-block;
                margin-left: -1em;
                width: 1em;
                color: $blue;
                font-weight: bold;
                content: '\2022';
            }
        }

        a {
            body:not(.mouse-user):not(.is-oru) &:focus {
                @extend .coned-focus-outline;
            }

            body:not(.mouse-user) &:focus {
                @extend .oru-focus-outline;
            }

            &::before {
                content: none !important;
            }
        }

        &--with-padding {
            padding-top: 30px;
        }

        &--checkboxes {
            display: inline-block;
            padding-top: 15px;
        }

        &--with-paragraph {
            @include body-m; 
            color: $dark-gray;
            font-family: $open-sans-family;
            
            p {
                @include body-m; 
            }
        }
    }

    &__consent-paragraph {
        padding: 20px;
        color: unset;
    }

    &__radio-list {
        @include bp-medium {
            @include flexbox;
            @include flex-direction(row);
            @include align-items(center);
            position: relative;

            .coned-field-error-wrapper {
                position: absolute;
                top: -35px;
            }
        }

        &--margin-top {
            margin-top: 40px;
        }

        &--column {
            margin-top: 40px;

            @include bp-medium {
                @include flex-direction(column);
                @include align-items(flex-start);
            }

            .coned-form__field-container:last-child {
                margin-bottom: 0;
            }
        }
    }

    &__radio {
        margin-bottom: 20px;

        @include bp-medium {
            margin-right: 30px;
        }
    }

    &__radio-list--flex-wrap {
        @include bp-medium {
            @include flex-wrap(wrap);

            .coned-form__radio {
                @include flex(50% 0 0);

                margin-right: 0;
            }
        }
    }

    &__group {
        margin: 30px 0;

        &-header {
            @include body-l;
            color: $dark-gray;
            font-family: $open-sans-family;
            font-weight: $open-sans-bold;
        }
    }

    &__addresses-container {
        @extend %flexbox;

        @include flex-wrap(wrap);
        @include justify-content(center);
    }

    &__address-box-button-wrapper {
        @include flex(1 1 50%);

        margin: 10px;
        min-width: 350px;
        max-width: 46.5%;
        height: 120px;

        @include bp-smaller {
            min-width: 250px;
        }
    }

    &__address-box-button+label {
        @extend %flexbox;

        @include flex-direction(row);
        @include justify-content(start);
        @include align-items(center);
        @include transition(all 0.3s);

        position: relative;
        border: 1px solid $dark-gray;
        background-color: $white;
        cursor: pointer;
        padding: 10px 50px 10px 20px;
        height: 100%;
        box-sizing: border-box;

        @include bp-smaller {
            padding: 10px 40px 10px 15px;
        }

        &:hover {
            border: 1px solid $blue-brand;

            .coned-form__address-box-button-image {
                @include transform(scale(1.1));
            }
        }
    }

    &__address-box-button {
        &:focus + label {
            body:not(.mouse-user):not(.is-oru) &::before {
                @extend .coned-button-focus;
            }
        
            body:not(.mouse-user) &::before {
                @extend .oru-button-focus;
            }

            .coned-form__address-box-button-image {
                @include transform(scale(1.1));
            }
        }

        &-image {
            @include flex(20% 0 0);
            @include transition(all 0.3s);

            max-height: 70%;
        }

        &-address {
            padding-left: 15px;
            text-align: left;
            text-transform: uppercase;
            font: $lexend-extra-bold calculate-rem(13) / calculate-rem(20) $lexend-family;
        }

        &-check {
            @include transform(translateY(-50%));

            display: none;
            position: absolute;
            top: 50%;
            right: 20px;
            width: 20px;
            height: 20px;

            &::before {
                @include transform(translate(-50%, -50%));

                position: absolute;
                top: 50%;
                left: 50%;
                color: $medium-gray;
                font-size: calculate-rem(30);
            }
        }

        &:checked + label .coned-form__address-box-button-check {
            display: block;
        }
    }

    &__group-heading {
        @include body-m;
        margin: calculate-rem(40) 0 calculate-rem(25);
        color: $dark-gray;
        font-family: $open-sans-family;
        font-weight: $open-sans-bold;
    }

    &__total-title {
        margin-right: 20px;
    }

    &.steam-email-form {
        overflow: visible;
    }

    &__contact-information {
        margin-top: 40px;
        padding: 0;

        p {
            line-height: 1.6rem;
        }        
    }
}

.coned-form-wrapper {
    &.coned-form-wrapper--margin-bottom-bigger {
        margin-bottom: 15px;
        
        @include bp-medium {
            margin-bottom: 35px;
        }
    }

    &--no-background {
        background-color: $transparent;
    }

    &--width-67-desktop {
        margin-right: 0;
        margin-left: 0;
        width: 100%;

        @include bp-medium {
            @include box-sizing(border-box);
            margin-right: 20px;
            width: calc(67.6% - 20px);
        }
    }

    &--no-margin-top {
        margin-top: 0;
    }
}

.coned-form--oru {
    .coned-form__address-box-button + label:hover {
        border: 1px solid $orange-brand;
    }

    .coned-form__article-subsection {
        ul li::before {
            color: $orange;
        }
    }
}
