// ============== SEARCH FILTERS COMPONENT =================

.search-filters {
    background-color: $blue;
    width: 100%;

    &__wrapper {
        @extend %flexbox;
        position: relative;
        height: 106px;
    }

    &__by {
        @include flexbox;
        @include flex-direction(row);
        z-index: 100;
        width: 100%;

        &-nav {
            @include flexbox;
            margin: 26px 0 0 30px;
            height: 80px;

            @include bp-small {
                @include align-self(flex-end);
                margin: 0 0 0 5%;
                height: 60px;
            }
        }

        &-nav-item {
            @include transition(border-bottom-color 0.2s 0.1s ease-out);
            @include body-s;
            margin-right: 20px;
            border-bottom: 4px solid transparent;
            background-color: transparent;
            padding-bottom: 25px;
            width: auto;
            text-transform: uppercase;
            color: $white;
            font-family: $open-sans-family;
            font-weight: $open-sans-bold;

            @include bp-medium {
                margin-right: 30px;
                padding: 17px 10px;
            }
        }

        &-nav-item--active {
            border-bottom: 5px solid $white;
        }
    }

    &__options {
        @extend %flexbox;
        @include flex-direction(column);
        position: absolute;
        top: 105px;
        z-index: 90;
        width: 100%;

        @include bp-medium {
            @include flex-direction(row);
            @include justify-content(flex-end);
            position: relative;
            top: 0;
            margin: 40px 0 0;
        }
    }

    &__clear-filters {
        @extend .button-link, .button-link--extra-low-emphasis;
        position: absolute !important;
        right: 10px;
        margin-top: 10px;

        @include bp-medium {
            right: 30px;
        }

        body:not(.is-oru) & {
            @extend .button-link--secondary-color;
        }
    }

    &__text {
        pointer-events: none;
    }
}

.search-filters__by-nav-item {
    body:not(.mouse-user):not(.is-oru) &:focus {
        position: relative;
        outline: none;

        &::before {
            @extend .coned-button-focus;
        }
    }

    body:not(.mouse-user) &:focus {
        position: relative;
        outline: none;

        &::before {
            @extend .oru-button-focus;
        }
    }
}

.dropdown {
    &-title {
        @include body-s;
        display: none;
        border-bottom: 1px solid $medium-gray;
        border-radius: 0;
        background-color: $white;
        padding: 18px 30px;
        text-align: left;
        text-transform: uppercase;
        color: $dark-gray;
        font-family: $open-sans-family;
        font-weight: $open-sans-bold;

        @include bp-medium {
            @include flexbox;
            @include align-items(center);
            @include border-radius(24px);
            border: 2px solid $white;
            background-color: $blue;
            padding: 10px 20px;
            width: 170px;
            height: 45px;
            color: $white;
        }

        &--active {
            background-color: $dark-blue !important;
        }

        &-icon {
            display: none;

            @include bp-medium {
                display: block;
                position: absolute;
                top: 10px;
                right: 12px;
                font-size: calculate-rem(30);
            }
        }

        body:not(.mouse-user):not(.is-oru) &:focus {
            position: relative;
            outline: none;

            &::before {
                @extend .coned-button-focus;
            }
        }

        body:not(.mouse-user) &:focus {
            position: relative;
            outline: none;

            &::before {
                @extend .oru-button-focus;
            }
        }
    }

    &-title--mobile {
        @include border-radius(24px);
        display: block;
        position: relative;
        margin: 42px 10px 0;
        border: 2px solid $white;
        background-color: $blue;
        padding: 10px 15px;
        width: 170px;
        min-width: 100px;
        height: 45px;
        color: $white;

        @include bp-medium {
            display: none;
        }

        .dropdown-title-icon {
            display: block;
            position: absolute;
            top: 8px;
            right: 12px;
            font-size: calculate-rem(30);
        }
    }

    &-filter-title {
        @extend %text-truncate;
        max-width: 90%;
        text-transform: uppercase;

        @include bp-medium {
            max-width: 105px;
        }
    }

    &-wrapper {
        position: relative;

        @include bp-medium {
            margin-right: 30px;
        }
    }

    &-list {
        display: none;
        position: relative;
        z-index: 1;
        background-color: $white;
        width: 100%;

        @include bp-medium {
            @include transition(top 0.2s ease);
            position: absolute;
            top: 60px;
            left: -10px;
            border: 1px solid $medium-gray;
            width: 205px;
        }

        &--tips {
            position: absolute;
            top: 115px;
            left: 0;
            z-index: 90;
            width: 100%;

            @include bp-tips-filters {
                top: 75px;
            }

            @include bp-medium {
                top: 60px;
                left: 50px;
                width: 205px;
            }
        }
    }

    &-list__arrow {
        @include bp-medium {
            position: relative;
            background: $white;

            &::after,
            &::before {
                position: absolute;
                bottom: 1px;
                left: 50%;
                z-index: 10;
                border: solid transparent;
                width: 0;
                height: 0;
                content: '';
                pointer-events: none;
            }

            &::after {
                margin-left: -15px;
                border-width: 15px;
                border-color: $blue-transparent;
                border-bottom-color: $white;
            }

            &::before {
                margin-left: -16px;
                border-width: 16px;
                border-color: $blue-transparent;
                border-bottom-color: $medium-gray;
            }
        }
    }
}

.dropdown-list-item {
    @include flexbox;
    border-bottom: 1px solid $medium-gray;

    @include bp-medium {
        &:last-child {
            border-bottom: 0;
        }
    }

    &-link {
        @extend %flexbox, .not-underlined-anchor;
        position: relative;
        padding: 21px 30px;
        width: 100%;
        height: 100%;

        @include bp-medium {
            padding: 18px;
        }

        @include bp-smaller {
            max-width: 85%;
        }

        &:hover {
            background-color: $blue;
            color: $white;
        }

        &:focus::before {
            body:not(.mouse-user):not(.is-oru) & {
                @extend .coned-button-focus--reversed;
                @extend .button-focus-inset;
            }

            body:not(.mouse-user) & {
                @extend .oru-button-focus--reversed;
                @extend .button-focus-inset;
            }
        }

        &,
        .is-oru & {
            color: $dark-gray;
        }

        &--active {
            &::after {
                @include border-radius(20px);
                position: absolute;
                top: 50%;
                right: 10px;
                transform: translateY(-50%);
                background-color: $blue;
                width: 30px;
                height: 30px;
                text-align: center;
                color: $white;
                font: calculate-rem(25) / calculate-rem(30) 'coned-icons';
                content: '\e96e';
            }

            &:hover::after {
                background-color: $white;
                color: $blue;
            }
        }
    }

    &-title {
        @include body-xs;
        overflow: hidden;
        text-transform: uppercase;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-family: $open-sans-family;
        font-weight: $open-sans-semi-bold;

        @include bp-medium {
            max-width: 110px;
        }
    }

    &-paragraph {
        padding: 15px;
        color: $blue;
        font-size: calculate-rem(14);
        font-weight: $open-sans-bold;
    }

    &-icon {
        position: relative;
        margin: 2px 10px 0 0;
        width: 16px;

        &::before {
            position: absolute;
            top: -5px;
            left: -5px;
            font-size: calculate-rem(25);
        }
    }
}

.search-filters--oru {
    background-color: $orange;

    .search-filters {
        &__by-nav-item {
            color: $teal;

            &--active {
                border-color: $teal;
            }
        }
    }

    .dropdown-title {
        @include bp-medium {
            border-color: $teal;
            background-color: $orange;
            color: $teal;
        }

        &--active {
            background-color: $black !important;
            color: $white !important;
        }
    }

    .dropdown-title--mobile {
        border-color: $teal;
        background-color: $orange;
        color: $teal;
    }

    .dropdown-list {
        border-color: $medium-gray;
        background-color: $white;

        @include bp-small {
            border: 0;
            border-bottom: 1px solid $teal;
        }
    }

    .dropdown-list__arrow {
        @include bp-medium {
            background: $white;

            &::after {
                border-color: $orange-transparent;
                border-bottom-color: $white;
            }

            &::before {
                border-color: $orange-transparent;
                border-bottom-color: $medium-gray;
            }
        }
    }

    .dropdown-list-item {
        &-link {
            &:hover {
                background-color: $teal;
                text-decoration: underline;
                color: $white;
            }

            &--active::after {
                background-color: $teal;
                color: $white;
            }

            &--active:hover::after {
                background-color: $white;
                color: $teal;
            }
        }

        &-paragraph {
            color: $teal;
        }
    }
}
