// ============== ALERT COMPONENT =================

.alert {
    @extend %flexbox;
    @include flex-flow(row nowrap);
    @include align-items(center);
    @include justify-content(space-between);
    position: relative;
    padding: 10px 0;

    @include bp-medium {
        padding: 16px 0;
    }

    &-wrapper {
        color: $white;

        &--red {
            background: $red;
        }

        &--yellow {
            border-bottom: 1px solid $darker-yellow;
            background: $dark-yellow;
            color: $dark-gray;
        }

        &--green {
            border-bottom: 1px solid $green;
            background: $green;
        }

        &--blue {
            border-bottom: 1px solid $blue;
            background: $blue;
        }

        &--purple {
            background: $purple;
        }
    }

    &__type-icon {
        display: inline-block;
        margin: -20px 0 0;
        height: 25px;
        font-size: calculate-rem(45);

        @include bp-medium {
            margin: -20px 0 0 20px;
        }
    }

    &__block {
        @extend %flexbox;
        @include flex-flow(row wrap);
        @include align-items(center);
        margin-right: 20px;
        width: 100%;

        &--full-mobile {
            @include bp-small {
                margin: 0 30px;
                padding: 10px 0;
            }
        }
    }

    &__content {
        padding: 5px 0;
        width: calc(100% - 67px);
        overflow: hidden;

        @include bp-medium {
            margin: 0;
        }

        &--flex {
            @include flexbox;
            @include flex-direction(column);
            @include align-items(center);

            width: 100%;

            @include bp-medium {
                @include flex-direction(row);
                @include justify-content(space-between);
            }
        }

        &-title {
            @include body-m;
            display: inline-block;
            margin-right: 5px;
            font-family: $lexend-family;
            font-weight: $lexend-extra-bold;
        }

        &-text {
            @include body-m;
            display: inline;
            position: relative;
            font-family: $open-sans-family;

            &--absolute {
                padding-left: 50px;

                @include bp-medium {
                    padding-left: 80px;
                }
            }
        }

        &-text--absolute {
            padding-left: 50px;

            @include bp-medium {
                padding-left: 80px;
            }

            .alert__type-icon {
                @include transform(translateY(-50%));

                position: absolute;
                top: 75%;
                left: 0;

                @include bp-medium {
                    top: 100%;
                }
            }
        }

        &-cta {
            display: inline-block;
            text-transform: uppercase;
            font: calculate-rem(11) / calculate-rem(16) $open-sans-family;
            font-weight: $open-sans-bold;

            @include bp-primary-nav-small {
                line-height: calculate-rem(18);
                font-size: calculate-rem(13);
            }

            @include bp-mob-alerts {
                line-height: calculate-rem(22);
                font-size: calculate-rem(15);
            }

            @include bp-medium {
                line-height: calculate-rem(18);
                font-size: calculate-rem(13);
            }

            &,
            .is-oru & {
                color: $white;
            }
        }

        &-cta:hover {
            text-decoration: underline;
        }
    }

    &__close {
        position: relative;
        margin-right: 23px;
        outline: none;
        background-color: transparent;        
        width: 40px;
        height: 40px;
                
        body:not(.mouse-user):not(.is-oru) &:focus::after {
            @extend .coned-button-focus;
        }
        
        body:not(.mouse-user) &:focus::after {
            @extend .oru-button-focus;
        }
    
        &::before {
            position: absolute;
            top: -10px;
            left: -10px;
            font-size: calculate-rem(60);
        }
    }

    &__cta {
        margin: 20px 0 0;

        @include bp-medium {
            margin: 5px 9px 5px 0;
        }

        &:last-child {
            margin: 20px 0 10px;

            @include bp-medium {
                margin: 5px 0 5px 9px;
            }
        }
    }

    &__cta-container {
        @extend %flexbox;
        @include flex-grow(0);
        @include flex-shrink(0);
        @include flex-direction(column);
        width: 100%;

        @include bp-medium {
            @include flex-direction(row);
            width: auto;
        }
    }
}

.alert-wrapper--yellow {
    .alert__content-cta {
        color: $dark-gray;
    }

    .alert__close {
        color: $dark-gray;
    }
}
