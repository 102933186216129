// ============ CHECKBOX RECEIPT ===============

.checkbox-receipt {
    @include flexbox;
    padding: 20px 0;
    width: 100%;
    color: $white;

    &--overdue {
        .checkbox-receipt__date,
        .checkbox-receipt__title,
        .checkbox-receipt__tooltip {
            color: $red;
        }
    }

    &--disabled {
        .checkbox-receipt__date {
            font-weight: $open-sans-bold;
        }

        .checkbox-receipt__item-box {
            visibility: hidden;
        }
    }

    &__info {
        @include flexbox;
        @include flex-direction(column);
        width: calc(100% - 65px);

        @include bp-medium {
            @include flex-direction(row);
            @include justify-content(space-between);
        }

        &-top {
            @include bp-medium {
                @include flexbox;
                @include flex-direction(row);
                width: 100%;
            }
        }

        &-bottom {
            margin-top: 10px;
            margin-right: 10px;

            @include bp-medium {
                @include align-self(center);
                margin-top: 0;
            }
        }
    }

    &__account {
        @include body-s;
        color: $black;
        font-family: $open-sans-family;
        font-weight: $open-sans-bold;

        &-container {
            @include flexbox;
            @include flex-direction(row);

            @include bp-medium {
                @include flex-direction(column);
                @include justify-content(center);
                margin-right: 15px;
                width: 220px;
            }
        }

        &-number {
            font-weight: $open-sans-semi-bold;

            @include bp-medium {
                display: block;
            }

            &::before {
                padding: 0 4px;
                font-weight: 500;
                content: '/';

                @include bp-medium {
                    display: none;
                }
            }
        }
    }

    &__title {
        @include body-s;
        color: $black;
        font-family: $open-sans-family;
        font-weight: $open-sans-bold;

        @include bp-small {
            margin-bottom: 10px;
        }

        @include bp-medium {
            display: block;
        }

        &-container {
            @include flexbox;
            @include flex-direction(row);

            @include bp-medium {
                @include justify-content(center);
                @include flex-direction(column);
            }
        }
    }

    &__date {
        @include body-s;
        color: $black;
        font-family: $open-sans-family;
        font-weight: $open-sans-semi-bold;

        &-container {
            @include flexbox;
        }

        &.coned-tooltip__paragraph {
            @include inline-flex;
            flex-direction: column;
            align-items: start;
        }
    }

    &__link {
        @extend .button-link, .button-link--extra-low-emphasis;
        padding-top: 20px;

        @include bp-medium {
            @include align-self(center);
            padding: 0;
            white-space: nowrap;
        }
    }

    &__label {
        display: none;
    }

    &__item-box {
        margin-right: 15px;
        padding: 10px 15px;
        width: 20px;
        height: 30px;
        flex: 1 0 0;
    }

    &__tooltip {
        margin-bottom: -2px;
        align-self: flex-end;
    }
}
