// ============ SUBMIT BUTTON ===============

.submit-button {
    @extend .button, .button--high-emphasis-large;

    &--close {
        display: none;
    }

    &--circle {
        display: opacity;
    }

    &--error {
        background-color: $red;
    }

    &__text--large {
        @include body-l;
    }

    &__text--hidden {
        span {
            color: transparent;
        }
    }

    &__icon,
    &--post-payment-icon {
        @extend .button-icon;
        margin: 0 5px;

        &::before {
            position: absolute;
            top: -12px;
            left: -15px;
            font-size: calculate-rem(50);
        }
    }

    &__container {
        @include border-radius(32px);
        position: relative;
        margin: 0 auto;
        width: 100%;
        height: 64px;

        @include bp-medium {
            max-width: 440px;
        }

        &--bg-container {
            background-color: $ghost-white;
            width: 64px;
        }

        &--bg-container-reversed {
            background-color: $green;
        }

        &--centered-content {
            display: flex;
            justify-content: center;
        }
    }

    &__clip-right {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 64px;
        clip: rect(0, 64px, 64px, 32px);
        pointer-events: none;
    }

    &__slice-right {
        @include transition(all, 0, ease);
        @include border-radius(32px);
        @include transform(rotate(0));
        position: absolute;
        background-color: $green;
        width: 100%;
        height: 64px;
        clip: rect(0, 32px, 64px, 0);
    }

    &__slice-right--white {
        background-color: $ghost-white;
    }

    &__clip-left {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 64px;
        clip: rect(0, 32px, 64px, 0);
        pointer-events: none;
    }

    &__slice-left {
        @include transition(all, 0, ease);
        @include border-radius(32px);
        @include transform(rotate(0));
        position: absolute;
        background-color: $green;
        width: 100%;
        height: 64px;
        clip: rect(0, 64px, 64px, 32px);
    }

    &__slice-left--white {
        background-color: $ghost-white;
    }

    &__top {
        @include border-radius(32px);
        display: none;
        position: absolute;
        top: 5px;
        left: 5px;
        background-color: $white;
        width: calc(100% - 10px);
        height: 54px;
    }

    &__top--show {
        display: block;
    }

    &__container--login-modal {
        @include bp-medium {
            margin: 0;
        }
    }

    &:focus {
        outline: none;

        body:not(.mouse-user):not(.is-oru) &::before {
            @extend .coned-button-focus;
        }

        body:not(.mouse-user) &::before {
            @extend .oru-button-focus;
        }
    }
}

.submit-button__container--blue {
    .submit-button__slice-right,
    .submit-button__slice-left {
        background-color: $blue;
    }

    .submit-button__slice-right--white,
    .submit-button__slice-left--white {
        background-color: $ghost-white;
    }

    .submit-button--login-modal {
        @include bp-login-modal {
            padding: 20px 10px;
            width: 130px;
        }
    }

    &.submit-button__container--bg-container-reversed {
        background-color: $blue;
    }
}

.submit-button__container--oru {
    .submit-button__slice-right,
    .submit-button__slice-left {
        background-color: $teal;
    }

    .submit-button__slice-right--white,
    .submit-button__slice-left--white {
        background-color: $ghost-white;
    }

    &.submit-button__container--bg-container-reversed {
        background-color: $teal;
    }
}
