// ============== SUBORDINATE ACCOUNT COMPONENT =================
.summary-billing-card {    
    &__content {
        @extend %flexbox;
        @include flex-grow(1);
        @include flex-direction(column);
        @include align-items(center);
        @include justify-content(center);
        position: relative;
        margin: 20px 0;
        background-color: $blue;
        padding: 50px 30px;
        width: auto;
        text-align: center;
        color: $white;

        @include bp-bill-card {
            padding: 30px 40px 68px;
        }

        @include bp-medium {
            @include box-sizing(border-box);
        }
    }

    &__error {
        position: relative;
        width: 100%;
        text-align: center;
    }

    &__price {
        @include display-l;
        font-family: $lexend-family;
        font-weight: $lexend-extra-bold;

        &--credit {
            color: $blue;
        }
    }

    &__previous-bill {
        @include body-s;
        margin-bottom: 30px;
        font-family: $open-sans-family;
        font-weight: $open-sans-semi-bold;

        strong {
            font-family: $lexend-family;
            font-weight: $lexend-extra-bold;
        }
    }

    &__previous-bill-amount {
        font-family: $lexend-family;
        font-weight: $lexend-extra-bold;
    }

    &__due {
        @include heading-s;
        margin: 10px 0 30px;
        font-family: $open-sans-family;
        font-weight: $open-sans-bold;

        @include bp-medium {
            margin-bottom: 5px;
        }
        
        p > a {
            color: $white;
        }
    }

    &__payment-notice {
        @include body-s;
        padding: 30px 0 0;
        font-family: $open-sans-family;
        font-weight: $open-sans-semi-bold;

        @include bp-medium {
            padding: 40px 0 0;
        }
    }

    &__cta-container {
        @extend %flexbox;
        @include flex-wrap(wrap);
        @include justify-content(space-around);

        max-width: 650px;
    }

    &__cta {
        @extend .button, .button--high-emphasis, .button--secondary-color;

        margin: 10px 0;
       
        width: 100%;
        
        @include bp-medium {
            width: auto;
            min-width: 280px;
        }
    }

    &__cta-icon {
        @extend .button-icon;

        &::before {
            position: absolute;
            top: -4px;
            left: -8px;
            font-size: calculate-rem(35);
        }
    }

    &__tooltip {
        &::after {
            top: -10px;
            right: -5px;
            font-size: calculate-rem(35);
        }
    }
}

.summary-billing-information {
    margin-top: 35px;
    background-color: $white;

    &--tile {
        margin: 35px 10px 0;

        @include bp-medium {
            margin: 35px auto 0;
        }
    }

    &__title {
        @include heading-l;
        margin-bottom: 40px;
        color: $dark-gray;
        font-family: $lexend-family;
        font-weight: $lexend-extra-bold;

        span {
            @include heading-s;
            display: block;
            margin-top: 15px;
            font-family: $lexend-family;
            font-weight: $lexend-light;

            @include bp-medium {
                display: inline-block;
                margin-top: 0;
            }
        }

        &--table {
            margin-bottom: 0;
            text-align: start;

            @include bp-medium {
                text-align: left;
            }
        }

        &-wrapper {
            padding: 45px 20px 0;

            @include bp-medium {
                padding: 95px 10% 0;
            }
        }

        &-container {
            border-bottom: 2px solid $medium-gray;
            padding-bottom: 20px;
        }

        &-error {
            padding-top: 20px;
            color: $medium-gray;
            font: calculate-rem(16) / calculate-rem(23) $open-sans-family;
        }
    }

    &__tile {
        padding: 30px 20px;

        @include bp-medium {
            padding: 95px 15%;
        }

        @include bp-billing {
            padding: 95px 20%;
        }

        &-description {
            color: $dark-gray;
            font: calculate-rem(18) / calculate-rem(32) $open-sans-family;
        }

        &-link {
            display: block;
            margin-top: 40px;
            text-transform: uppercase;
            color: $blue;
            font: calculate-rem(14) / calculate-rem(30) $open-sans-family;
            font-weight: $open-sans-extra-bold;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    &__table {
        @include body-m;
        padding-bottom: 15px;
        width: 100%;
        color: $dark-gray;
        font-family: $open-sans-family;
        font-weight: $open-sans-semi-bold;

        @include bp-medium {
            padding: 30px 10% 95px;
            width: auto;
        }
        
        &-error {
            padding: 10px;
            text-align: center;

            @include bp-medium {
                margin-bottom: 20px; 
            }
        }

        &-head {
            display: none;

            @include bp-medium {
                display: table-header-group;
            }
        }

        &-titles {
            @extend %flexbox;
            @include flex-flow(row wrap);
            @include align-items(center);
            margin: 0 20px;

            @include bp-medium {
                margin: 0;
            }
        }

        &-title {
            @include body-l;
            text-transform: uppercase;
            font-family: $open-sans-family;
            font-weight: $open-sans-bold;
        }

        &-row {
            @include flexbox;
            @include flex-flow(column wrap);
            @include align-items(center);
            border-bottom: 1px solid $medium-gray;
            padding: 30px;

            @include bp-medium {
                display: table-row;
                padding: 0;
                height: 8vw;
            }

            &--no-borders {
                border: 0;
                padding-bottom: 10px;

                @include bp-medium {
                    padding: 0;
                    height: 5vw;
                }
            }

            &--error-container {
                padding: 0 0 30px;

                @include bp-medium {
                    height: 1vw;
                }
            }
        }

        &-cell {
            @include flexbox;
            @include flex-direction(column);
            @include align-items(center);
            @include justify-content(center);
            position: relative;
            margin-bottom: 20px;
            outline: 0;

            @include bp-small {
                &::before {
                    @include body-l;
                    text-transform: uppercase;
                    font-family: $open-sans-family;
                    font-weight: $open-sans-bold;
                    content: attr(data-mobile-header);
                }
            }

            @include bp-medium {
                display: table-cell;
                margin-bottom: 0;
                padding: 20px 0;
                width: 25%;
                text-align: start;

                &--anchor-parent {
                    text-align: center;
                }

                &--account,
                &--total {
                    width: 15%;
                }

                &--total {
                    text-align: end;
                }
            }

            body:not(.mouse-user):not(.is-oru) &:focus::after {
                @extend .coned-button-focus;
            }

            body:not(.mouse-user) &:focus::after {
                @extend .oru-button-focus;
            }

            &--total {
                margin-bottom: 0;
            }
        }

        &-text {
            @include flex-basis(auto);
            @include box-sizing(border-box);
            text-align: center;

            @include bp-medium {                
                text-align: left;
            }

            &--charge {
                @include body-l;
                text-align: right;
                color: $dark-gray;
                font-weight: $open-sans-bold;

                @include bp-medium {
                    @include body-m;
                    font-weight: $open-sans-extra-bold;
                }
            }

            &--location {
                text-transform: uppercase;
                font-weight: $open-sans-semi-bold;

                @include bp-medium {
                    font-weight: $open-sans-bold;
                }
            }

            &--lower-font {
                @include body-s;
                text-align: right;
                color: $medium-gray;
            }
        }

        &-text--link {
            @extend .button-link--extra-low-emphasis;
            @include inline-flex;

            @include bp-medium {
                margin: 0 1vw;
            }

            @include bp-small {
                &:first-child {
                    margin: 2vw 0;
                }
            }
        }
    }

    &__cta-icon {
        @extend .button-icon;

        &::before {
            position: absolute;
            top: -1px;
            left: 0;
            font-size: calculate-rem(25);

            @include bp-medium {
                top: 2px;
            }
        }
    }

    &__show-more {
        @extend .button;
        display: block;
        margin: 30px auto;
    }
}

.summary-billing-card--oru {
    .is-oru & {
        .summary-billing-card__cta {
            @extend .button--primary-color;
        }
    }
    
    .summary-billing-card__content {
        background-color: $orange !important;
        color: $teal !important;
    }
} 

.is-oru { 
    .summary-billing-card__due {
        p > a {
            color: $teal !important;
        }
    }
}
