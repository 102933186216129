.landing-hero {
    position: relative;

    &__content {
        position: relative;
        z-index: 2;
    }

    &__title-wrapper {
        margin: 0;
        background-color: $white;
        padding: 40px;
        width: 100%;
        color: $black;

        @include bp-medium {
            margin: 95px 0;
            background-color: transparent;
            padding: 50px;
            width: 500px;
            color: $white;
        }

        /* Portrait */
        @include bp-portrait {
            margin: 60px 0;
            padding: 50px;
        }

        @include bp-large {
            margin: 150px 0;
            padding: 50px 70px 50px 100px;
            width: 700px;
        }

        @include bp-large-landing-hero {
            margin: 168px 0;
        }

        &--black-on-white {
            background-color: $white;
            color: $black;

            @include bp-medium {
                background-color: $white-transparent-85;
                color: $black;
            }
        }
    }

    &__title {
        @include display-m;
        position: relative;
        margin-bottom: 70px;
        font-family: $lexend-family;
        font-weight: $lexend-extra-bold;

        @include bp-medium {
            margin-bottom: 50px;
        }

        /* Portrait */
        @include bp-portrait {
            margin-bottom: 50px;
        }

        @include bp-large {
            margin-bottom: 70px;
        }

        &::after {
            display: block;
            position: absolute;
            bottom: -36px;
            left: 0;
            background-color: $blue;
            width: 135px;
            height: 6px;
            content: '';
        }
    }

    &__subtitle {
        @include heading-m;
        position: relative;
        margin-bottom: 20px;
        font-family: $lexend-family;
        font-weight: $lexend-semi-bold;
    }
}

.landing-hero--oru {
    .landing-hero__title {
        &::after {
            background-color: $orange-brand;
        }
    }
}
