// ============== REGISTER ACCOUNT COMPONENT =================

.register-accounts {
    padding: 50px 30px;
    text-align: center;

    @include bp-medium {
        margin: 0 auto;
        padding: 70px 20px;
        width: 80%;
    }

    &-wrapper {
        margin: 50px auto;
        background-color: $white;
    }

    &__error {
        display: inline-block;

        &-paragraph {
            margin: 0 auto;
            padding: 20px 40px 20px 20px;
            max-width: 60%;
            box-sizing: border-box;

            @include bp-small {
                max-width: 100%;
            }
        }

        &-icon {
            vertical-align: top;
        }
    }

    &__title {
        @include heading-l;
        padding: 40px 0 10px;
        font-family: $lexend-family;
        font-weight: $lexend-extra-bold;
    }

    &__subtitle {
        @include body-m;
        margin: 20px 0;
        text-align: center;
        color: $medium-gray;
        font-family: $open-sans-family;
    }

    &__paragraph {
        @include body-s;
        margin: 0 auto 40px;
        width: 50%;
        font-family: $open-sans-family;

        &--last-item {
            margin: 40px auto;
        }
    }

    &__list {
        @include flexbox;
        @include flex-direction(column);
        @include align-items(center);
        @include flex-wrap(wrap);
        @include flex-wrap(wrap);
        @include justify-content(center);
        margin: 40px 0;

        @include bp-medium {
            @include flex-direction(row);
        }
    }

    &__item {
        @include box-sizing(border-box);
        margin-bottom: 20px;
        width: 100%;

        @include bp-medium {
            margin: 0 30px 20px 0;
            width: 30%;
        }
    }

    &__button {
        @extend .button;
        margin: 0 auto;
        width: 100%;
        max-width: 440px;

        @include bp-medium {
            width: auto;
        }

        &--continue {
            display: block;
            margin-top: 60px;
        }

        &--high-emphasis {
            @extend .button, .button--high-emphasis;
            width: 100%;
            max-width: 440px;

            @include bp-medium {
                width: auto;
            }
            
            .form__actions:not(.form__actions--regular-column) & {
                @include bp-medium {
                    margin-right: 0;
                }    
            }
        }

        &--margin-bottom-80 {
            margin-bottom: 80px;
        }
        
        &-icon {
            font-size: calculate-rem(30);
        }

        &:focus {
            outline: none;

            body:not(.mouse-user):not(.is-oru) &::before {
                @extend .coned-button-focus;
            }

            body:not(.mouse-user) &::before {
                @extend .oru-button-focus;
            }
        }
    }

    &__input-description {
        padding: 20px 0 0;
        font-weight: $open-sans-semi-bold;

        @include bp-medium {
            padding: 20px 30px 0;
        }

        &--dark {
            @include body-s;
            margin-top: 50px;
            color: $dark-gray;
            font-family: $open-sans-family;
            font-weight: $open-sans-semi-bold;
        }

        &--bigger {
            margin-top: 60px;
            color: $dark-gray;
            font: calculate-rem(20) / calculate-rem(26) $open-sans-family;
            font-weight: $open-sans-semi-bold;
        }
    }

    &__center-link {
        padding-top: 40px;
        font: $lexend-extra-bold calculate-rem(16) / calculate-rem(20) $lexend-family;
    }

    &__checkbox {
        &--phone {
            margin-top: 20px;
        }
    }

    &__verification-description {
        margin: 20px 0;
    }

    &--no-mobile {
        display: none;

        @include bp-medium {
            display: block;
        }
    }

    &__link {
        @extend .button-link;
    }

    &__show-more {
        @extend .button, .button--secondary-color;
    }
}

.register-accounts--oru {
    .login__form-password-button,
    .transactional__error-link {
        color: $teal;
    }
}
