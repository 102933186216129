// ============== START STOP TRANSFER SERVICE =================

.start-stop-transfer {
    &__image-container {
        left: -13rem;
        width: 650px;

        @include bp-medium {
            left: 0;
            width: 100%;
        }

        &__faq-button:hover {
            background-color: $orange;
        }
    }
}

.service-wizard-visual-decision-step {
    &__container {
        div:first-child {
            background-color: $white;
        }
    }
}

