// ============== TRANSACTIONAL START SERVICE COMPONENT =================

.transactional {
    &__skip {
        @extend %flexbox, .button-link;
        @include justify-content(center);
        margin: 40px 0;
    }

    &__acount-description {
        @include body-m;
        margin: 0 0 40px;
        color: $dark-gray;
        font-family: $open-sans-family;
        font-weight: $open-sans-bold;

        &--bold {
            margin: 20px 0;
            text-align: center;
            text-transform: uppercase;
            font-weight: $open-sans-extra-bold;
        }
    }

    &__check-list:last-child {
        border-bottom: 0;
    }

    &__review-title {
        margin-top: 45px;
    }

    &__review-description {
        margin-bottom: 30px;
    }

    &__check-image {
        width: 100%;
    }

    &__support-description {
        margin: 80px 0 0;
    }

    &-care-options__mask,
    &-sleeping-mask {
        display: none;
        overflow: hidden;
    }

    &__mailing-options {
        display: none;
    }

    &__address-box-container {
        margin: 30px auto;
        max-width: 350px;
    }

    .recaptcha-wrapper {
        @extend %center-block;

        .recaptcha__error-wrapper {
            text-align: center;
        }
    }
}
