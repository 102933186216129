// ============== SERVICE MANAGEMENTS SERVICE =================

.service-management {
    padding: 30px 20px;

    &__container {
        background-color: $white;
        padding: 20px 0 50px;

        @include bp-medium {
            padding: 20px 30px 80px;
        }
    }

    &__button-container {
        text-align: center;
    }

    &__button-cta {
        @extend .button, .button--high-emphasis-large;
        margin: 0 auto 40px;

        @include bp-medium {
            margin: 0 auto 60px;
        }
    }

    &__title-container {
        margin: 0 auto 30px;
    }

    &__title {
        @include heading-l;
        text-align: center;
        font-family: $lexend-family;
        font-weight: $lexend-extra-bold;

        &--final {
            @include heading-m;
            text-align: left;
            font-family: $lexend-family;
            font-weight: $lexend-extra-bold;
        }
    }

    &__subtitle {
        @include body-s;
        margin: 15px auto 0;
        text-align: center;
        font-family: $open-sans-family;
        font-weight: $open-sans-bold;

        @include bp-medium {
            max-width: 900px;
        }

        &--final {
            max-width: 271px;
            text-align: left;

            @include bp-medium {
                max-width: 689px;
            }
        }
    }

    &__steps-container {
        padding: 0 15px;

        @include bp-medium {
            padding: 0;
        }
    }

    &__step {
        @include transition(all 0.5s ease-in-out);
        display: block;
        visibility: visible;
        opacity: 1;
        overflow: hidden;

        @include bp-medium {
            padding: 5px;
        }

        &.inactive {
            display: none;
            visibility: hidden;
            opacity: 0;
            height: 0 !important;
        }
    }

    &__steps-breadcrumbs-container {
        position: relative;
        margin-bottom: 30px;

        @include bp-medium {
            padding: 0 30px;
        }
    }

    &__step-breadcrumb {
        @include transition(all 0.5s ease-in-out);
        display: block;
        position: relative;
        opacity: 1;
        border-bottom: 2px solid $light-gray;
        cursor: pointer;
        padding: 15px 50px 15px 20px;
        height: auto;
        overflow: hidden;
        text-align: left;

        @include bp-medium {
            padding: 15px 35px;
            text-align: center;
        }

        &:hover,
        &:focus {
            .service-management__breadcrumb-text {
                color: $black;
            }
        }

        &.inactive {
            display: none;
        }
    }

    &__breadcrumb-text {
        @include transition(color 0.3s ease-in-out);
        @include heading-m;
        cursor: pointer;
        color: $medium-gray;
        font-family: $lexend-family;
        font-weight: $lexend-extra-bold;
    }

    &__breadcrumb-expand {
        @extend .button-link;
        @include transform(translateY(-50%));
        position: absolute;
        top: 50%;
        right: 15px;

        @include bp-medium {
            right: 0;
            margin-right: 4px;
        }

        body:not(.mouse-user):not(.is-oru) &:focus {
            outline: none;

            &::before {
                @extend .coned-button-focus;
            }
        }

        body:not(.mouse-user) &:focus {
            outline: none;

            &::before {
                @extend .oru-button-focus;
            }
        }
    }

    &__progress-bar-list {
        @include flexbox;
        @include justify-content(space-between);
        position: relative;
        z-index: 2;
        padding: 0 10px;
    }

    &__progress-bar-item {
        @include flexbox;
        @include align-items(center);
        @include transition(all, 0.4s, ease);
        min-width: 1%;
        max-width: 100%;
        height: 60px;
        color: $medium-gray;
    }

    &__progress-bar-item--active {
        color: $white;

        .form-progress-bar__item-dot {
            background-color: $green;
        }
    }

    &__progress-bar-progress-body {
        margin-left: -20px;
        width: 0;

        @include bp-medium {
            margin-left: 0;
            width: 100px;
        }
    }
}

.service-management-decision-step {
    &__container {
        @include flexbox;
        @include justify-content(center);
        @include flex-direction(column);
        margin: auto;
    }

    &__tiles-container {
        @include flexbox;
        @include justify-content(flex-start);
        @include flex-direction(row);
        @include flex-wrap(wrap);
        margin: 0 auto;

        @include bp-medium {
            @include justify-content(center);
            @include flex-wrap(nowrap);
            max-width: 1028px;
        }
    }

    .image-button {
        &:hover {
            border: 2px solid $blue-brand;
        }
    }
}

.service-management-decision-step--oru {
    .image-button {
        &:hover {
            border: 2px solid $teal;
        }
    }
}

.service-management-zipcode-step {
    &__container {
        @include flexbox;
        @include flex-direction(column);
        @include justify-content(center);
        margin: 0 auto;
    }

    &__zipcode-container {
        @include flexbox;
        @include justify-content(center);
        @include flex-direction(column);
        position: relative;
        margin: 70px auto 30px;

        .coned-field {
            display: block;
            margin: 0 auto;
            max-width: 180px;
        }

        .coned-field-error-wrapper {
            @include transform(translateX(-50%));
            position: absolute;
            top: 100%;
            left: 50%;
            padding-left: 25px;

            .coned-input-message--error::before,
            .coned-input-top-message--error::before {
                left: 0;
            }
        }

        .coned-label {
            @include transform(translateX(-50%));
            top: -10px;
            left: 50%;
            padding-right: 0;
            padding-left: 0;
            width: 100%;
            text-align: center;
        }
    }

    &__zipcode-input {
        @include heading-l;
        margin-top: 25px;
        padding: 0;
        padding-right: 25px;
        width: 100%;
        height: 50px;
        text-align: center;
        color: $black;
        font-family: $open-sans-family;
    }

    &__zipcode-icon {
        width: 0;
        height: 36px;
        color: $green;
        font-size: calculate-rem(40);
    }
}

.service-management-final-step {
    &__container {
        margin: auto;
        max-width: 600px;

        @include bp-medium {
            padding: 0 20px;
        }
    }

    &__information-container {
        @include body-m;
        margin: 0 auto 40px;
        font-family: $open-sans-family;

        li {
            margin-left: 20px;
            padding-left: 0;
            list-style-type: disc;

            @include bp-medium {
                padding-left: 5px;
            }
        }
    }
}
