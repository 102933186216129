.bill-comparison {
    @include flexbox;
    @include flex-direction(column);

    margin-top: 25px;
    background-color: $white;
    padding: 20px 30px;

    @include bp-medium {
        @include flex-direction(row);
        @include justify-content(space-between);
        padding: 0 15px;
    }

    &--column {
        @include flex-direction(column);
    }

    &-year {
        @include bp-medium {
            @include flex-direction(column);
        }
    }

    &__content {
        @include flexbox;
        @include flex-direction(column);

        @include bp-medium {
            @include flex-direction(row);
            @include justify-content(space-between);
            padding: 20px 10px 30px;
        }

        @include bp-large {
            padding: 20px 35px 30px;
        }
    }

    &__header {
        border-bottom: 2px solid $light-gray;
        padding: 30px 0;
        text-align: left;

        @include bp-medium {
            @include flexbox;
            @include align-items(center);
            @include flex-direction(column);

            margin: 0 10px;
            padding: 60px 0 30px;
            text-align: center;
        }

        @include bp-large {
            margin: 0 35px;
        }
    }

    &__container {
        margin-bottom: 45px;
    }

    &__title {
        @include heading-l;
        color: $dark-gray;
        font-family: $lexend-family;
        font-weight: $lexend-extra-bold;
    }

    &__description {
        @include body-m;
        padding-top: 22px;
        color: $dark-gray;
        font-family: $open-sans-family;

        &--bold {
            @include heading-s;
            font-family: $lexend-family;
            font-weight: $lexend-extra-bold;
        }

        &-year {
            @include body-m;
            text-align: left;

            @include bp-medium {
                width: 100%;
                max-width: 700px;
                text-align: center;
            }

            @include bp-large {
                max-width: 900px;
            }
        }
    }

    &__date {
        @include bp-medium {
            padding-bottom: 17px;
        }
    }

    &__text {
        @include body-s;
        padding: 45px 0 8px;
        text-transform: uppercase;
        color: $dark-gray;
        font-family: $open-sans-family;
        font-weight: $open-sans-bold;
    }

    &__price {
        @include flexbox;
        @include flex-direction(row);
        @include justify-content(space-between);
        border: 1px solid $light-gray;
        border-left: 0;
        text-align: center;

        &--column {
            @include flex(1 1 0);
            border-left: 1px solid $light-gray;
            padding: 25px 5px;
            width: 50%;

            @include bp-medium {
                padding: 15px 10px;
            }

            @include bp-large {
                padding: 20px 15px;
            }
        }

        &-number {
            @include heading-l;
            color: $dark-gray;
            font-family: $lexend-family;
            font-weight: $lexend-extra-bold;

            &--blue {
                color: $blue;
            }
        }

        &-text {
            @include body-s;
            padding: 5px 10px;
            text-transform: uppercase;
            color: $medium-gray;
            font-family: $open-sans-family;
            font-weight: $open-sans-bold;

            @include bp-medium {
                padding: 0 0 5px;
            }
        }
    }

    &__column {
        margin: 20px 0;
        width: 100%;

        @include bp-medium {
            @include flex(1 1 0);
            margin: 35px 0;
            padding: 15px 4% 40px;
        }

        &--right {
            @include bp-medium {
                padding-top: 55px !important;
            }
        }

        &-year {
            margin: 20px 0;
            width: 100%;

            @include bp-medium {
                @include flex(0 0 45%);
            }

            .bill-comparison__description {
                @include heading-s;
                font-family: $lexend-family;
                font-weight: $lexend-extra-bold;
            }
        }

        + .bill-comparison__column {
            border-top: 2px solid $light-gray;
            padding-top: 15px;

            @include bp-medium {
                border-top: 0;
                border-left: 2px solid $light-gray;
            }
        }
    }

    &__column-flex {
        @include flexbox;
        @include flex-direction(column);
        @include justify-content(space-between);
        height: 100%;
    }
}
