// ============== CARD TILE BANNER COMPONENT =================

.card-tile-banner {
    @include transition(all, 0.7s, ease);
    position: relative;
    margin-bottom: 10px;
    border-top: 3px solid $green;
    background-color: $white;
    width: 100%;
    min-height: 300px;
    overflow: hidden;

    @include bp-medium {
        @include flex-basis(auto);
        @include flex-grow(initial);
        margin-bottom: 20px;
        margin-left: 10px;
        width: calc(50% - 10px);
    }

    @include bp-cards {
        @include flex-basis(100%);
        @include flex-grow(1);
        margin-bottom: 20px;
        margin-left: 0;
        width: calc(50% - 10px);
        height: auto;
        min-height: auto;
    }

    &--first-row {
        width: 100%;
        height: 100%;
        min-height: 200px; 

        @include bp-small {
            padding: auto;
        }

        @include bp-cards {
            min-height: auto;
        } 
    }

    &--top {
        margin-left: 0;
        padding: 0 40px;

        @include bp-small {
            padding: 50px;
        }
    }

    &--padding {
        padding: 50px 20px;

        @include bp-medium {
            padding: 0;
        }

        @include bp-cards {
            padding: 0 50px;
        }
    }

    &__image-container {
        @extend %flexbox;
        @include transition(transform 0.3s ease);
        padding-bottom: 20px;
        width: 240px;
        height: 155px;

        @include bp-medium {
            padding-bottom: 0;
            height: 240px;
        }

        @include bp-cards {
            padding-bottom: 0;
            height: 100%;
        }

        &--first-row {
            @include align-self(flex-end);
            padding-bottom: 0;
            height: 100%;

            @include bp-small {
                @include align-self(center);
                padding-bottom: 20px; 
                height: 155px;
            }

            @include bp-cards {
                margin-bottom: 40px;
                padding: 0 36px;
                width: 313px;
                box-sizing: border-box;
            }
        }

        &--second-row {
            @include bp-medium {
                padding-bottom: 20px;
                height: 155px;
            }
    
            @include bp-cards {
                margin-bottom: 40px;
                padding: 0 36px;
                width: 313px;
                height: 100%;
                box-sizing: border-box;
            }
        }

        &-picture {
            @include flex-shrink(0);
            width: 240px;
            height: 155px;

            @include bp-medium {
                height: 240px;
            }

            @include bp-cards {
                height: 230px;
            }
    
            &-image {
                width: 100%;
                height: 100%;
            }
        }
    }

    &__title {
        @include heading-l;
        padding-bottom: 8px;
        text-align: center;
        color: $black;
        font-family: $lexend-family;
    }

    &__info-container {
        @extend %flexbox;
        @include flex-direction(column);
        @include align-items(center);
        @include justify-content(center);
        @include flex-shrink(0);
        @include align-self(stretch);

        @include bp-cards {
            @include align-items(flex-start);
            @include flex(1 0 0);
            margin-top: 40px;
            margin-bottom: 40px;
        }

        &--top { 
            @include flex(1 0 0);
            @include align-items(center);
            
            @include bp-medium {
                @include align-items(flex-start);
            }
        }
    }

    &__link {
        @extend .button-link, .button-link--extra-low-emphasis, %flexbox;
        margin: 10px 20px 0;

        @include bp-medium {
            margin: 10px 50px 0;
        }

        &-wrapper {
            @extend %flexbox;
            @include flex-direction(column);
            @include align-items(center);
            @include justify-content(center);
            height: calc(100% - 100px);
            text-decoration: none;

            @include bp-medium {
                padding-right: 50px;
                padding-left: 50px;
                height: 100%;
            }

            @include bp-cards {
                @include align-items(end);
                @include flex-direction(row-reverse);
                @include justify-content(space-between);
                height: auto;
            }

            &--top {
                @include bp-medium {
                    @include flex-direction(row-reverse);
                    @include justify-content(space-between);
                    @include flex(1 0 0);
                }
            }
        }
    }

    &__button {
        @extend .button;
        width: auto;

        @include bp-medium {
            margin-bottom: 0;
        }
    }

    &__description {
        @include body-l;
        margin-bottom: 32px;
        text-align: center;
        color: $black;
        font-family: $open-sans-family;
        
        @include bp-medium {
            font-weight: $open-sans-regular;
        }

        @include bp-cards {
            margin-bottom: 28px;
            text-align: start;
        }
    
        &--top {
            @include bp-medium {
                text-align: start;
            }
        }
    }
}
