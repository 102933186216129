// ============ AUTOCOMPLETE ===============

.coned-autocomplete {
    position: relative;

    &__item-list-wrapper {
        @include box-sizing(border-box);
        position: absolute;
        top: 97%;
        left: 0;
        z-index: 10;
        border: solid 2px $blue;
        background: $white;
        padding-left: 18px;
        width: 100%;
    }

    &__item-list {
        width: 100%;
        max-height: 300px;
        text-align: left;
        overflow-y: auto;
        overflow-x: hidden;
    }

    &__item {
        @include body-l;
        margin-right: 18px;
        cursor: pointer;
        padding: 11.5px 0;
        white-space: nowrap;
        font-family: $open-sans-family;
        font-weight: $open-sans-semi-bold;

        &:not(:last-child) {
            border-bottom: 1px solid $medium-gray;
        }

        &--hover {
            .coned-autocomplete__item--highlight {
                color: $blue;
            }

            a,
            button {
                text-decoration: underline;
            }
        }

        a,
        button {
            background: $white;
            color: $blue;
        }

        // Any icon- class
        [class*='icon-'] {
            vertical-align: middle;
            color: $blue;
            font-size: 120%;
        }
    }

    &__item--unhighlight {
        color: $medium-gray;
    }

    &__announcer {
        display: block;
        position: absolute !important;
        visibility: visible;
        margin: -1px;
        border: 0;
        padding: 0;
        width: 1px;
        height: 1px;
        overflow: hidden;
        white-space: nowrap;
        clip: rect(0, 0, 0, 0);
        clip-path: polygon(0 0, 0 0, 0 0, 0 0);
    }

    &__container {
        &--oru {
            .coned-autocomplete__item-list-wrapper {
                border: solid 2px $orange;
            }

            .coned-autocomplete__item--hover .coned-autocomplete__item--highlight {
                color: $teal;
            }

            .coned-autocomplete__item a {
                color: $teal;
            }

            .coned-autocomplete__item button {
                color: $teal;
            }

            .coned-autocomplete__item [class*='icon-'] {
                color: $teal;
            }
        }

        &--multiline-elements {
            .coned-autocomplete__item {
                white-space: unset;
            }
        }
    }

    &__container--dropdown {
        @include bp-medium {
            .coned-autocomplete__item-list {
                max-height: 245px;
            }

            .coned-autocomplete__item {
                padding: 10px 0;
                font: calculate-rem(16) / calculate-rem(20) $open-sans-family;
            }
        }
    }
}
