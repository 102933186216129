// ============ LEGEND ===============

.coned-legend {
    @extend %text-truncate-wrap;
    @include heading-m;
    display: block;
    clear: both;
    margin: 65px 0 20px;
    border-bottom: 3px solid $medium-gray;
    width: 100%;
    color: $dark-gray;
    font-family: $lexend-family;
    font-weight: $lexend-extra-bold;

    &--medium {
        @include heading-s;
        font-family: $lexend-family;
        font-weight: $lexend-extra-bold;
    }

    &--module {
        @include heading-m;
        margin-top: 20px;
        color: $dark-gray;
        font-family: $lexend-family;
        font-weight: $lexend-extra-bold;
    }
    
}
