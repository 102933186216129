// ============== FACETED SEARCH =================

.faceted-search {
    &__list {
        margin-top: 30px;
        border-top: 1px solid $medium-gray;
        padding-top: 45px;

        &--faq {
            margin-top: 75px;
            padding-top: 0;
        }

        &-item {
            @include transition(all, 0.3s, ease);
            margin-bottom: 25px;
        }

        &-item--faq {
            margin-bottom: 0;
        }

        &-item--visible {
            @include opacity(1);
        }

        &-item--hidden {
            display: none;
            margin-bottom: 0;
            border: 0;
            height: 0;
        }

        &-item--hidden .button,
        &-item--hidden span,
        &-item--hidden.faqs__item--open .faqs__item-content {
            position: absolute;
            top: 0;
            padding: 0;
            height: 0;
        }
    }

    &__item-title {
        @include heading-m;
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
        font-family: $lexend-family;
        font-weight: $lexend-extra-bold;

        &:visited {
            color: $purple;
        }

        &:focus {
            body:not(.mouse-user):not(.is-oru) & {
                @extend .coned-focus-outline;
            }

            body:not(.mouse-user) & {
                @extend .oru-focus-outline;
            }

            &::before {
                content: none !important;
            }
        }
    }

    &__item-date {
        @include flex-shrink(0);
        @include flex-basis(115px);
        @include body-l;
        color: $medium-gray;
        font-family: $open-sans-family;
        font-weight: $open-sans-semi-bold;
        align-self: flex-start;
    }

    &__item-description {
        @include body-l;
        margin-top: 15px;
        color: $dark-gray;
        font-family: $open-sans-family;        
    }

    &__wrapper {
        @include flexbox;
        width: 100%;
        height: 100%;
        flex-direction: column;

        @media (min-width: 770px) {
            height: 106px;
            flex-direction: row;

            .search-filters__by {
                position: absolute;
                left: 0;
                width: auto;
            }
        }
    }

    &__show-filters-wrapper {
        @include flexbox;
        @include justify-content(center);
        @include align-items(center);
        justify-content: flex-end;
        order: -1;

        @include bp-medium {
            position: absolute;
            right: 0;
            margin: 0 10px 0 30px;
            order: 1;
        }
    }

    &__show-filters {
        @extend .button;
        margin: 30px 5px;

        @media (max-width: 770px) {
            margin: 15px 10px;
            margin-bottom: 5px;
        }

        @include bp-medium {
            display: none;
        }

        body:not(.is-oru) & {
            @extend .button--secondary-color;
        }
    }

    &__show-filters--desktop {
        display: none;
    }

    &__filters-options--dependency {
        @include justify-content(flex-start);
        @include align-items(center);
        @include flex-wrap(wrap);
        display: none;
        position: absolute;
        top: 86px;
        border-top: 1px solid $white;
        background-color: $blue;
        padding: 20px 0;
        height: auto;

        @include bp-medium {
            top: 66px;
        }

        .dropdown-wrapper {
            margin: 10px;
        }
    }

    &__filters-options--visible {
        @include flexbox;
        position: absolute;
        left: 0;
    }

    &__filters-options--wrap {
        @include flex-wrap(wrap);
    }

    &__filters-bar {
        @include justify-content(center);
        @include align-items(center);
        display: none;
        margin-top: 20px;
        border-bottom: 2px solid $white;
        background-color: $blue;
        height: 80px;
        color: $white;

        @include bp-medium {
            display: none;
        }
    }

    &__filters-back-button,
    &__filters-clear-button {
        @extend .button-link, .button-link--extra-low-emphasis;
        @include flexbox;
        margin: 0 35px;
        outline: none;

        body:not(.is-oru) & {
            @extend .button-link--secondary-color;
        }

        body:not(.mouse-user):not(.is-oru) &:focus::before {
            @extend .coned-button-focus;
        }

        body:not(.mouse-user) &:focus::before {
            @extend .oru-button-focus;
        }
    }

    &__filters-button-icon {
        @include transform(rotate(90deg));
        display: inline-block;
        position: relative;
        margin-right: 4px;
        width: 14px;
        height: 14px;

        &::before {
            position: absolute;
            top: -6px;
            left: -6px;
            font-size: calculate-rem(30);
        }
    }

    &__filters-bar-title {
        @include heading-m;
        margin: 0 5px;
        font-family: $lexend-family;
        font-weight: $lexend-extra-bold;
    }

    &--hidden {
        display: none;
    }

    &__no-results {
        display: none;
    }

    &__dependency-filter-wrapper {
        @include flex-direction(column);

        @include bp-medium {
            @include flex-direction(row);
        }
    }

    &__dependency-filter {
        display: none;
        width: 100%;

        @include bp-medium {
            width: auto;
        }
    }

    &--visible {
        @include flexbox;

        @include bp-medium {
            display: none;
        }

        &.faceted-search__filters-bar--faq {
            display: none;
        }
    }

    &__by-nav-item {
        @include bp-smaller {
            margin-right: 10px;
        }
    }
}

.dropdown-list-item--faceted-search {
    position: relative;
}

.dropdown-title--faceted-search {
    @include bp-small {
        z-index: 1;
        border-bottom: 1px solid $white;
        background-color: $blue;
        padding: 25px 30px;
        width: 100%;
        color: $white;
        font: $lexend-extra-bold calculate-rem(22) / calculate-rem(22) $lexend-family;

        .dropdown-title-icon {
            display: block;
            position: absolute;
            top: 18px;
            right: 10px;
            font-size: calculate-rem(40);
        }
    }
}

.dropdown-list-item-link--faceted-search {
    @include bp-small {
        border: 0;
        background-color: $white;

        &.dropdown-list-item-link--active::after {
            right: 15px;
            background-color: $blue;
            color: $white;
            font-size: calculate-rem(30);
        }
    }
}

.dropdown-list-item-title--faceted-search {
    @include bp-small {
        max-width: 80%;
        font: $lexend-semi-bold calculate-rem(18) / calculate-rem(22) $lexend-family;
    }
}

.drop-down-title-icon--open {
    @include transform(rotate(180deg));
}

.pagination__link-faceted--active {
    background-color: $blue;
    color: $white;
}

.faceted-search__info-no-results {
    display: none;
}

.faceted-search__page-min-height {
    min-height: 600px;
}

.search-filters--oru {
    .faceted-search__filters-options--dependency,
    .faceted-search__filters-bar {
        border-color: $teal;
        background-color: $orange;
        color: $teal;
    }

    .faceted-search__filters-back-button,
    .faceted-search__filters-clear-button {
        background-color: $orange;
        color: $teal;
    }

    .dropdown-title--faceted-search {
        border-bottom: 1px solid $teal;
        background-color: $orange;
        color: $teal;

        &.dropdown-title--active {
            @include bp-medium {
                background-color: $orange;
            }
        }
    }

    .dropdown-list-item-link--faceted-search {
        background-color: $white;

        @include bp-medium {
            background-color: $white;
        }

        &.dropdown-list-item-link--active::after {
            background-color: $teal;
        }
    }
}
