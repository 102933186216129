// ============== ENERGY SAVINGS FORM =================

.energy-savings {
    @include bp-medium {
        padding: 0 30px;
    }

    &__form {
        margin: 0 auto;
        padding: 0;
        width: auto;

        @include bp-medium {
            margin: 0 auto;
            padding: 0 20%;
            width: auto;
        }
    }

    .form__actions {
        display: block;
        padding: 40px 0 20px;
        text-align: center;

        @include bp-medium {
            padding: 40px 0 50px;
        }
    }

    &__container {
        margin: 40px 10px 20px;
        padding: 50px 30px 20px;

        @include bp-medium {
            margin: 50px auto;
            padding: 50px 0 0;
        }

        &--no-padding {
            padding: 0;
        }
    }

    &__title {
        @include heading-l;
        margin: 0 auto 15px;
        max-width: 220px;
        text-align: center;
        font-family: $lexend-family;
        font-weight: $lexend-extra-bold;

        @include bp-small {
            max-width: 300px;
        }

        @include bp-medium {
            max-width: 600px;
        }
    }

    &__sub-title {
        @include body-s;
        margin: 0 auto 30px;
        max-width: 220px;
        text-align: center;
        text-transform: uppercase;
        font-family: $open-sans;
        font-weight: $open-sans-bold;

        @include bp-small {
            max-width: 275px;
        }

        @include bp-medium {
            max-width: 900px;
        }
    }

    &__instructions {
        @include body-m;
        margin: 0 auto 15px;
        max-width: 280px;
        text-align: center;
        font-family: $open-sans;

        @include bp-medium {
            margin: 0 auto 60px;
            max-width: none;
        }

        a {
            @extend %focus-outline-on-element;
        }
    }

    &__field-container {
        margin-bottom: 10px;

        @include bp-medium {
            margin-bottom: 20px;
        }

        &--one-half {
            float: left;
            width: 100%;

            @include bp-medium {
                margin-right: 2%;
                width: 49%;
            }

            & + & {
                margin-right: 0;
            }
        }
    }

    &__message {
        @include flexbox();
        @include flex-direction(column);
        @include align-items(center);
        padding: 20px 0 40px;

        @include bp-medium {
            padding: 45px 0;
        }
    }

    &__message-text {
        @include heading-l;
        text-align: center;
        font-family: $lexend-family;
        font-weight: $lexend-extra-bold;

        @include bp-medium {
            max-width: 800px;
        }

        span {
            @include body-m;
            display: block;
            margin: 40px 0 10px;
            color: $medium-gray;
            font-family: $open-sans;
            font-weight: $open-sans-bold;

            @include bp-medium {
                margin: 20px 0 30px;
                color: $medium-gray;
            }
        }
    }

    &__message-image {
        @include bp-medium {
            margin-bottom: 40px;
        }
    }

    &__card-tiles-container {
        @include flexbox();
        @include flex-direction(row);
        margin: auto;
    }

    &__card-tiles-wrapper {
        @extend %flexbox;
        @include flex-wrap(wrap);
        padding: 0 10px;

        @include bp-medium {
            padding: 0 0 15px;
        }
    }

    &__description-label {
        @include body-m;
        margin-top: 50px;
        color: $dark-gray;
        font-family: $open-sans-family;
        font-weight: $open-sans-bold;
    }
}
