// ============== CONED TABS COMPONENT =================

/*used for generic tabs */
.coned-tabs {
    position: relative;
    padding-left: 2%;
    width: 100%;
    white-space: nowrap;

    &--content {
        width: auto;
    }

    &__dropdown {
        @include transition(all 0.2s ease);
        @include opacity(1);
        position: absolute;
        top: 150px;
        left: 50%;
        z-index: 20;
        margin-top: 20px;
        border: 1px solid $alto;
        padding-left: 0;
        width: 50%;
        height: auto;

        &--hidden {
            @include opacity(0);
            display: none;
            height: 0;
            overflow: hidden;

            @include bp-dashboard-header {
                display: block;
            }
        }
    }

    &-wrapper {
        margin: 30px 0 5px;
        border-bottom: 1px solid $medium-gray;
    }

    &__tab {
        display: inline-block;

        body:not(.mouse-user):not(.is-oru) &:focus {
            position: relative;
            outline: 0 !important;

            &::before {
                @extend .coned-button-focus;
            }
        }

        &-item {
            @include heading-m;
            border-bottom: 3px solid $transparent;
            background-color: $transparent;
            padding: 2px;
            height: 100%;
            white-space: normal;
            color: $medium-gray;
            font-family: $lexend-family;
            font-weight: $lexend-extra-bold;

            @include bp-cards {
                padding: 10px;
            }

            &:hover {
                border-color: $medium-gray;
            }
        }

        &-icon {
            margin-right: 1px;
            font-size: calculate-rem(20);
        }

        &-item--active {
            border-color: $blue;
            color: $dark-gray;

            &:hover {
                border-color: $blue;
            }
        }

        &-item--single {
            @include heading-m;
            border-bottom: 3px solid $blue;

            @include bp-dashboard-header {
                margin-top: 20px;
            }
        }

        &-item--larger {
            /*for bigger text */
            font-size: calculate-rem(20);
        }

        &-item--separated {
            margin: 0 25px;
            padding-bottom: 14px;
            width: auto;
        }

        &--dropdown {
            @extend %flexbox;
            @include align-items(center);
            @include transition(all 0.2s ease);
            @include justify-content(center);
            display: block;
            background-color: $white;
            cursor: pointer;
            padding: 10px 20px;
            width: auto;

            .coned-tabs__tab-item {
                @include button-s;
                border-bottom: 0;
                text-align: left;
            }
        }
    }

    &__tab-item {
        body:not(.mouse-user):not(.is-oru) &:focus {
            position: relative;
            outline: 0 !important;

            &::before {
                @extend .coned-button-focus;
            }
        }
    }

    &__section {
        display: none;

        &--active {
            display: block;
        }

        &--gray-bg {
            background-color: $lighter-gray;
        }
    }

    &__arrow {
        position: absolute;
        top: -10px;
        right: 12%;
        border-right: 10px solid $transparent;
        border-bottom: 10px solid $white;
        border-left: 10px solid $transparent;
        width: 0;
        height: 0;

        @include bp-medium {
            display: none;
        }
    }

    &__selector {
        position: absolute;
        top: 140px;
        right: 6%;
        background: 0;
        color: $medium-gray;
        font: $lexend-extra-bold calculate-rem(20) / calculate-rem(20) $lexend-family;

        body:not(.mouse-user):not(.is-oru) &:focus {
            outline: 0 !important;

            &::before {
                @extend .coned-button-focus;
            }
        }

        body:not(.mouse-user) &:focus {
            outline: 0 !important;

            &::before {
                @extend .oru-button-focus;
            }
        }
    }

    &__mobile-container {
        @include bp-medium {
            display: none;
        }
    }

    &--centered {
        @include flexbox;
        @include justify-content(center);
        padding: 42px 0;

        @include bp-small {
            padding: 14px 0;
        }
    }

    &--visible {
        .coned-tabs__section {
            display: none;
            overflow: hidden;

            &--active {
                display: block;
                position: relative;
            }
        }

        .coned-tabs__selector {
            top: 25px;
        }

        .coned-tabs__dropdown {
            top: 38px;
        }

        &-parent {
            position: relative;
            overflow: hidden;
        }
    }

    &--dropdown {
        padding-left: 0;

        .coned-tabs__tab {
            padding: 0 10px;
        }

        .coned-tabs__tab-item {
            @include button-s;
            @include flexbox();
            padding: 0;
            width: auto;
            text-transform: uppercase;
        }
    }

    &__container {
        margin: 30px 20px 0;
        border-bottom: 3px solid $light-gray;

        @include bp-medium {
            margin: 26px 15%;
        }

        @include bp-billing {
            margin: 26px 20%;
        }

        &--no-sides-margin {
            margin-right: 0;
            margin-left: 0;
        }

        .coned-tabs {
            @include flex-shrink(0);
            display: flex;

            @include bp-medium {
                margin-bottom: 0;
            }
        }

        .coned-tabs__tab {
            padding-right: 30px;
            flex: 1;

            @include bp-medium {
                margin-right: 16px;
                padding: 0 10px 0 0;
                flex: none;
            }

            &-item {
                position: relative;
                bottom: -3px;
                padding-bottom: 5px;
                text-align: left;
            }
        }
    }

    &--visibility-hidden {
        visibility: hidden;
    }
}

.coned-tabs__section--inactive {
    .coned-tabs__section--active {
        visibility: hidden;
    }
}

.coned-tabs--oru {
    .coned-tabs__tab-item--active {
        border-color: $teal;
    }

    .coned-tabs__tab-item {
        body:not(.mouse-user) &:focus {
            position: relative;
            outline: 0 !important;

            &::before {
                @extend .oru-button-focus;
            }
        }
    }
}
