// ============== CARDS TILE COMPONENT =================

.card-tile {
    @include transition(all, 0.7s, ease);
    position: relative;
    margin-bottom: 10px;
    background-color: $white;
    width: 100%;
    height: auto;
    overflow: hidden;

    @include bp-medium {
        @include flex-basis(auto);
        margin-bottom: 20px;
        width: calc(50% - 10px);
        height: 480px;

        &:not(:nth-child(2n + 1)) {
            margin-left: 10px;
        }

        &:not(:nth-child(2n + 2)) {
            margin-right: 10px;

            &.card-tile-banner {
                margin: 0 0 20px;
            }
        }

        &:hover::before,
        &--focus::before {
            left: 0% !important;
            width: 100% !important;
        }
    }

    @include bp-cards {
        @include flex-basis(auto);
        width: calc(33.3% - 13px);

        &:not(:nth-child(2n + 1)) {
            margin-left: 0;
        }

        &:not(:nth-child(2n + 2)) {
            margin-right: 0;
        }

        &:not(:nth-child(3n + 1)) {
            margin-left: 10px;
        }

        &:not(:nth-child(3n + 3)) {
            margin-right: 10px;

            &.card-tile-banner {
                margin-right: 0;
            }
        }
    }

    &::before {
        @include transition(all, 0.4s, ease);
        position: absolute;
        top: 0;
        left: 50%;
        z-index: 1;
        border-top: 3px solid $blue-brand;
        width: 0;
        height: 0;
        content: ' ';
    }

    &--hidden.card-tile {
        display: none;
    }

    &__zoom {
        height: 100%;

        @include bp-medium {
            @include transition(all, 0.4s, ease);
            @include transform(scale(1) rotate(0.001deg));
            overflow: hidden;
        }
        
        .card-tile__cta-link {
            @extend .focus-outline-safari-rotation-fix-desktop;
        }

        &--white {
            &:hover {
                background-color: $white;
            }
        }

        &--animation {
            @include bp-medium {
                @include transform(scale(0) rotate(0.001deg));
            }
        }

        &--bill {
            @include bp-medium {
                height: auto;
            }

            &:hover {
                background-color: $white;
            }
        }

        &--bill-blue {
            &:hover {
                background-color: $blue;
            }
        }

        &--bill-orange {
            &:hover {
                background-color: $orange;
            }
        }

        &--blue,
        &--quote {
            @extend %flexbox;
            @include align-items(center);
            width: 100%;
        }
    }

    &__image-container {
        @include transition(transform 0.3s ease);
        position: absolute;
        top: 0;
        left: 0;
        background-repeat: no-repeat;
        width: 100%;
        height: 100%;
    }

    &__picture {
        display: block;
        height: 100%;

        @include bp-medium {
            display: none;
        }
    }

    &__picture-image {
        width: 100%;
        height: 100%;
    }

    &__icon {
        @extend %flexbox;
        @include align-items(center);
        @include border-radius(50%);
        @include justify-content(center);
        position: absolute;
        top: 20px;
        left: 20px;
        z-index: 3;
        border: 2px solid $blue;
        width: 40px;
        height: 40px;
        color: $blue;

        @include bp-medium {
            top: 40px;
            left: 50px;
        }

        &::before {
            font-size: calculate-rem(35);
        }
    }

    &__info-container {
        word-wrap: break-word;
    }

    &__title {
        @include heading-m;
        margin: 25px 20px 10px;
        font-family: $lexend-family;
        font-weight: $lexend-extra-bold;

        @include bp-medium {
            margin: 0 50px 8px;
        }
    }

    &__link {
        @extend .button-link, .button-link--extra-low-emphasis, %flexbox;
        margin: 10px 20px 0;

        @include bp-medium {
            margin: 10px 50px 0;
        }

        &-wrapper {
            @extend %flexbox;
            @include align-items(center);
            height: 100%;
            text-decoration: none;
        }

        &-icon {
            position: relative;
            padding-right: 20px;

            &::before {
                position: absolute;
                top: -7px;
                left: -7px;
                font-size: calculate-rem(30);
            }
        }

        .card-tile--type-quote &,
        .card-tile--ligter-bg &,
        .card-tile--darker-bg &,
        .card-tile__info-container--type-full & {
            @extend .button-link--secondary-color;
        }
    }

    &__page-wrapper {
        @extend %flexbox;
        @include flex-wrap(wrap);
        margin-top: 35px;
        padding: 0 10px !important;

        @include bp-medium {
            padding: 0 0 15px;
        }
        
        &--no-padding-desktop {
            @include bp-medium {
                padding: 0 !important;
            }
        }

        &--no-margin-top {
            margin-top: 0;
        }
    }

    &--no-hover {
        &::before {
            content: none;
        }
    }

    .article-page + &__page-wrapper {
        padding: 10px 10px 0;

        @include bp-medium {
            padding: 35px 0 15px;
        }
    }

    &--first-row-order {
        @include bp-medium {
            @include flex-basis(auto);
            margin-bottom: 20px;
            width: calc(50% - 10px);
            height: 480px;

            &:not(:nth-child(2n + 1)) {
                margin-left: 0;
            }

            &:not(:nth-child(2n + 2)) {
                margin-right: 0;
            }
    
            &:nth-child(2n + 3) {
                margin-left: 10px;
            }
    
            &:nth-child(2n + 2) {
                margin-right: 10px;
            }
        }
    
        @include bp-cards {
            @include flex-basis(auto);
            width: calc(33.3% - 13px);

            &:not(:nth-child(2n + 1)) {
                margin-left: 0;
            }

            &:not(:nth-child(2n + 2)) {
                margin-right: 0;
            }

            &:not(:nth-child(3n + 1)) {
                margin-left: 0;
            }

            &:not(:nth-child(3n + 3)) {
                margin-right: 0;
            }
    
            &:nth-child(2n + 3) {
                margin-left: 0;
            }
    
            &:nth-child(2n + 2) {
                margin-right: 0;
            }
    
            &:nth-child(3n + 3) {
                margin-right: 20px;
                margin-left: 20px;
            }
        }
    
    }

    &--second-row-order {
        @include bp-medium {
            @include flex-basis(auto);
            margin-bottom: 20px;
            width: calc(50% - 10px);
            height: 480px;
        }
    
        @include bp-cards {
            @include flex-basis(auto);
            width: calc(33.3% - 13px);

            &:not(:nth-child(2n + 2)) {
                margin-right: 0;
            }
            
            &:nth-child(2n + 3) {
                margin-left: 0;
            }

            &:nth-child(2n + 2) {
                margin-right: 0;
                margin-left: 0;
            }
    
            &:nth-child(3n + 3) {
                margin-right: 20px;
                margin-left: 20px;
            }

            &:nth-child(2) {
                margin-right: 20px;
                margin-left: 20px;
            }

            &:nth-child(3) {
                margin-right: 0;
                margin-left: 0;
            }
        }
    }
}

.card-tile--focus {
    outline: none;

    body:not(.mouse-user):not(.is-oru) &:after {
        @extend .coned-button-focus;
        top: 2px;
        left: 2px;
        width: calc(100% - 12px);
        height: calc(100% - 12px);
    }

    body:not(.mouse-user) &:after {
        @extend .oru-button-focus;
        top: 2px;
        left: 2px;
        width: calc(100% - 12px);
        height: calc(100% - 12px);
    }
}

@include bp-medium {
    .card-tile__link-wrapper:hover {
        .card-tile__image-container {
            @include transform(scale(1.07) rotate(0.001deg));
        }
    }
}

.card-tile__description {
    @include body-l;
    margin: 0 20px;
    padding-bottom: 4px;
    font-family: $open-sans-family;

    @include bp-medium {
        margin: 0 50px;
    }

    ul,
    ol {
        @include body-s;
        padding: 10px 0 10px 10px;
        list-style: none;
        font-family: $open-sans-family;
        font-weight: $open-sans-semi-bold;

        @include bp-medium {
            padding-left: 7%;
        }
    }

    li {
        margin-bottom: 10px;

        &::before {
            padding-right: 10px;
            color: $blue;
            content: '•';
        }

        ul,
        ol {
            padding-bottom: 1px;
        }
    }

    ol {
        counter-reset: elementcounter;

        li::before {
            content: counter(elementcounter) '.';
            counter-increment: elementcounter;
        }
    }

    sup {
        top: -0.25em;
        vertical-align: super;
        font-size: 0.65em;
    }

    a:focus {
        position: static !important;
        height: 30px;

        body:not(.mouse-user):not(.is-oru) & {
            @extend .coned-focus-outline;
        }

        body:not(.mouse-user) & {
            @extend .oru-focus-outline;
        }

        &::before {
            content: none !important;
        }
    }
}

.card-tile-wrapper--no-hover {
    &.card-tile::after {
        border: 0;
    }

    .card-tile {
        &__zoom:hover {
            background-color: $white;
        }

        &__link-wrapper:hover .card-tile__image-container {
            @include transform(none);
        }
    }
}

.card-tile--oru {
    .card-tile {
        &::before {
            border-color: $orange-brand;
        }

        &__icon {
            border-color: $teal;
            color: $teal;
        }

        &--lighter-bg {
            &::before {
                border-color: $teal;
            }
        }
    }

    .card-tile__description li::before {
        color: $orange;
    }
}
