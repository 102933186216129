// ============== MAUI HEADER COMPONENT =================

.maui-header {
    background-color: $orange;
    padding: 50px 20px;
    text-align: center;
    color: $teal;

    @include bp-medium {
        padding-right: 100px;
        padding-left: 100px;    
    }

    body:not(.is-oru) & {
        background-color: $blue;
        color: $white;
    }

    &__title {
        @include heading-l;
        margin: 15px auto 0;
        max-width: 1000px;
        font-family: $lexend-family;
        font-weight: $lexend-extra-bold;
    }

    &__button {
        @extend .button;
        @extend .button--high-emphasis;
        @extend %inline-flex;
        @include justify-content(center);
        @include align-items(center);
        width: 100%;
        text-transform: capitalize;

        @include bp-medium {
            margin-top: 0;
            padding-right: 100px;
            padding-left: 100px;
            width: auto;
        }

        &-container {
            @include flexbox();
            @include flex-direction(column);
            @include align-items(center);
            margin: 0 auto;
            padding-top: 30px;
            max-width: 600px;

            @include bp-medium {
                @include flex-direction(row);
                @include justify-content(center);
            }
        }

        body:not(.is-oru) & {
            @extend .button--secondary-color;
        }

        body:not(.mouse-user):not(.is-oru) &:focus {
            outline: none;

            &::before {
                @extend .coned-button-focus;
            }
        }

        body:not(.mouse-user) &:focus {
            outline: none;

            &::before {
                @extend .oru-button-focus;
            }
        }
    }
}
