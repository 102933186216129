// ============== CARDS TILE TYPE TEXT COMPONENT =================

.card-tile {
    &--type-text,
    &--info {
        .card-tile {
            &__title,
            &__description {
                display: -webkit-box;
                overflow: hidden;
                color: $black;
                -webkit-box-orient: vertical;
            }

            &__title--white-text,
            &__description--white-text {
                color: $white;
            }
        }
    }

    &--type-text {
        .card-tile {
            &__title {
                max-height: 98px;
                -webkit-line-clamp: 3;
            }

            &__title--only {
                max-height: 194px;
                -webkit-line-clamp: 6;
            }

            &__description {
                -webkit-line-clamp: 5;
            }
        }
    }

    &--info {
        &::before {
            border-top: 0;
        }

        .card-tile {
            &__title {
                max-height: 66px;
                -webkit-line-clamp: 2;
            }

            &__description {
                -webkit-line-clamp: 4;
            }
        }
    }

    // Overrides the text clamping
    // for non interactive text tiles
    // with long text
    &--type-text-long &__description {
        max-height: none;
        -webkit-line-clamp: unset;
    }

    &--type-text-long &__title {
        max-height: unset;
        -webkit-line-clamp: unset;
    }

    &__info-container {
        &--type-text {
            position: relative;
            padding: 80px 0 35px;
            width: 100%;

            @include bp-medium {
                padding: 0;
            }
        }
    }

    &--text {
        @extend %flexbox;
        @include align-items(center);
        min-width: 100%;

        &.card-tile {
            &:hover::before {
                width: 0;
            }

            &--white-bg {
                background-color: $white;
            }

            &--lighter-bg {
                background-color: $blue;
                color: $white !important;
            }

            &--darker-bg {
                background-color: $medium-gray;
            }
        }

        .card-tile {
            &__info-container--type-text {
                text-align: center;
            }

            &__picture {
                display: block;
                margin: 0 auto 25px;
                width: 245px;
                height: 110px;
            }

            &__picture-image {
                width: auto;
            }

            &__title {
                margin-bottom: 20px;
            }

            &__tip-title {
                @include heading-l;
                display: inline-block;
                margin-bottom: 25px;
                width: 70%;
                text-align: left;
                color: $white;
                font-family: $lexend-family;
                font-weight: $lexend-extra-bold;

                @include bp-medium {
                    margin-bottom: 0;
                }
            }
        }
    }

    &__link--type-text {
        color: $blue;
    }
}

.card-tile--oru {
    .card-tile {
        &--text {
            &.card-tile--white-bg {
                background-color: $white;
            }
        }

        &--lighter-bg {
            background-color: $orange;
        }

        &__link--type-text {
            color: $teal;
        }

        &__tip-title {
            color: $teal;
        }

        &__title--teal-text,
        &__description--teal-text {
            color: $teal;
        }
    }
}
