// ============== SHARE COMPONENT =================

.social-share {
    &__item {
        display: inline-block;
        margin-right: 5px;

        &-link {
            @include transition(all 0.2s ease);
            @include border-radius(50%);
            display: inline-block;
            border: 1px solid $alto;
            background-color: $white;
            padding: 10px;
            color: $blue;
            font-size: calculate-rem(20);
        }

        &-link:hover {
            background-color: $blue;
            color: $white;
        }
    }
}

.social-share--oru {
    .social-share__item-link {
        color: $orange;
    }

    .social-share__item-link:hover {
        background-color: $orange;
        color: $white;
    }
}
