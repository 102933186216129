// ============== SLIDER PANEL COMPONENT =================

.slider-panel {
    @include flexbox;
    position: relative;
    margin-top: 30px;
    background: $blue;
    padding-bottom: 30px;
    width: 100%;
    min-width: 0;
    max-width: 1400px;
    color: $white;

    @include bp-medium {
        padding-bottom: 60px;
    }

    &--in-popup {
        margin: 0 auto;
        width: 100%;

        .slider-panel {
            padding-right: 35px;
            padding-left: 35px;
            width: calc(100% - 70px);

            &__slide-image {
                width: 100%;
            }

            &__slide-container {
                width: 100%;
            }

            &__arrow {
                color: $teal;
                // scss-lint:disable NestingDepth, SelectorDepth
                body:not(.is-oru) & {
                    color: $blue;
                }
                // scss-lint:enable NestingDepth, SelectorDepth
            }

            &__arrow--right {
                right: 0;
            }

            &__arrow--left {
                left: 0;
            }

            &__slide-description {
                margin-bottom: 20px;

            }

            &__slide-description--left-aligned {
                text-align: left;
            }

            &__counter {
                @extend %flexbox;
                @include justify-content(center);
                @include align-items(center);
                @include body-s;
                position: absolute;
                top: 20px;
                right: 36px;
                z-index: 2;
                background-color: $dark-gray;
                width: 30px;
                height: 30px;
                color: $white;
                font-family: $lexend-family;
                font-weight: $lexend-extra-bold;

                @include bp-medium {
                    top: 38px;
                    width: 35px;
                    height: 35px;
                }
            }
        }
    }

    &--no-margin-top {
        margin-top: 0;
    }

    &--with-margin-bottom {
        margin-bottom: 80px;
    }

    &--transparent-background {
        background-color: $transparent;
        overflow: visible;
        color: $dark-gray;
    }

    &--blue-content {
        color: $blue;
    }

    &__container {
        display: block;
        width: 100%;

        @include bp-medium {
            margin: 0 auto;
            max-width: 530px;
            overflow: hidden;
        }

        @include bp-large {
            max-width: 600px;
        }
    }

    &__slide {
        visibility: hidden;

        &.swiper-slide-active {
            visibility: visible;
        }
    }

    &__slide-container {
        margin: 0 auto;
        width: 280px;
        text-align: center;

        @include bp-medium {
            width: 450px;
        }
    }

    &__slide-image {
        margin: 20px 0;
        width: 125px;
        height: auto;

        @include bp-medium {
            margin: 37px 0 30px;
            width: 144px;
        }

        &--large {
            @include bp-medium {
                width: 165px;
            }
        }
    }


    &__slide-headline {
        @include body-l;
        font-family: $lexend-family;
        font-weight: $lexend-extra-bold;
    }

    &__slide-title {
        @include heading-m;
        margin: 5px 0 10px;
        font-family: $lexend-family;
        font-weight: $lexend-extra-bold;

        @include bp-medium {
            margin: 6px 0 25px;
            padding: 0 30px;
        }
    }

    &__slide-description {
        @include body-l;
        font-family: $open-sans-family;

        &--bold {
            font-weight: $open-sans-bold;
        }

        &--black {
            color: $black;
        }
    }

    &__pagination {
        @include flexbox;
        @include align-items(center);
        @include justify-content(center);
        position: absolute;
        width: 100%;
        text-align: center;

        &--blue {
            color: $blue;
        }
    }

    &__arrow {
        position: absolute;
        top: 50%;
        left: 20px;
        z-index: 10;

        @include bp-medium {
            font-size: calculate-rem(27);
        }

        @include bp-large {
            left: 60px;
        }

        &:hover {
            .slider-panel__arrow-text {
                text-decoration: none;
            }
        }

        &:focus {
            outline: none;

            body:not(.mouse-user):not(.is-oru) &::before {
                @extend .coned-button-focus;
            }

            body:not(.mouse-user) &::before {
                @extend .oru-button-focus;
            }
        }

        &-text {
            @extend .button-link, .button-link--extra-low-emphasis;
            display: none;
            margin-left: 10px;

            @include bp-medium {
                display: inline-block;
            }

            body:not(.is-oru) & {
                @extend .button--primary-color;
            }
        }

        &-text--blue {
            body:not(.is-oru) & {
                @extend .button--secondary-color;
            }
        }

        &--right {
            right: 20px;
            left: auto;

            @include bp-large {
                right: 60px;
                left: auto;
            }

            .slider-panel__arrow-text {
                margin-right: 10px;
            }
        }

        .arrow__icon::before {
            position: absolute;
            top: -15px;
            left: -11px;
            font-size: calculate-rem(40);
        }

        .arrow__icon {
            position: relative;
            width: 20px;
            height: 20px;

            @include bp-medium {
                width: 27px;
                height: 27px;

                &::before {
                    top: -12px;
                    font-size: calculate-rem(50);
                }
            }
        }
    }

    &__button {
        @extend .button;

        body:not(.is-oru) & {
            @extend .button--secondary-color;
        }
    }

    &__button--blue {
        @extend .button;

        body:not(.is-oru) & {
            @extend .button--primary-color;
        }
    }

    &--bottom {
        @include flexbox;
        @include flex-flow(column wrap);
        @include align-items(center);
        position: relative;
        margin: 0 auto 80px;
        background: $blue;
        padding: 5px 0;
        width: 100%;
        max-width: 1400px;
        height: 85px;
        text-align: center;
    }

    &--white-background {
        background-color: $white;
    }
}
