// ============== CARDS TILE TYPE IMAGE COMPONENT =================

.card-tile {
    &--type-full {
        min-height: 400px;

        .card-tile__title {
            display: -webkit-box;
            max-height: 66px;
            overflow: hidden;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
        }

        .card-tile__description {
            display: -webkit-box;
            overflow: hidden;
            -webkit-line-clamp: 4;
            -webkit-box-orient: vertical;

            @include bp-medium {
                display: -webkit-box;
                overflow: hidden;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
            }
        }
    }

    &--type-image {
        .card-tile__title {
            @include bp-medium {
                display: -webkit-box;
                max-height: 66px;
                overflow: hidden;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
            }
        }

        .card-tile__description {
            @include bp-medium {
                display: -webkit-box;
                overflow: hidden;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
            }
        }
    }

    &--type-image,
    &--type-full {
        .card-tile__link-wrapper {
            @include flex-direction(column);
        }

        .card-tile__icon {
            background-color: $white;
            color: $blue;
        }
    }

    &__image-container--full {
        background-position: center center;
        background-size: cover;

        @include bp-small {
            left: calc(((767px - 100vw) / -2) - 10px);
            width: 767px;
        }
    }

    &__image-container--type-image {
        position: relative;
        background-position: center center;
        background-size: cover;

        @include bp-small {
            height: auto;
        }

        @include bp-medium {
            position: absolute;
            height: 80%;
        }
    }

    &__info-container--type-full,
    &__info-container--type-image {
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 3;
        padding-bottom: 20px;
        width: 100%;
        color: $white;
    }

    &__info-container--type-image {
        position: relative;
        background-color: $white;

        @include bp-medium {
            position: absolute;
            padding: 20px 0;
        }
    }

    &__info-container--type-full {
        @extend %flexbox;
        @include flex-flow(column wrap);
        @include justify-content(flex-end);
        @include linear-gradient-two-breaks(
            top,
            left,
            bottom,
            $transparent,
            0%,
            $transparent,
            20%,
            $black-almost-transparent,
            85%,
            $black-almost-transparent,
            100%
        );
        height: 100%;
    }

    &__info-container--no-gradient {
        background: none;
    }

    &__title--type-image,
    &__description--type-image {
        color: $dark-gray;
    }
}

.card-tile--oru {
    .card-tile--type-image,
    .card-tile--type-full {
        .card-tile__icon {
            background-color: $white;
            color: $teal;
        }
    }
}
