.dashboard-third-party {
    &__tab-content {
        margin: 30px auto;
        background: $white;

        &--padding {
            padding: 20px;

            @include bp-medium {
                padding: 20px 50px;
            }
        }
    }

    &__request-access-response-title {
        margin: 30px 0 50px;
        line-height: calculate-rem(32);
        font-size: calculate-rem(28);

        &--success {
            color: $medium-gray;
        }

        &--error {
            color: $red;
        }
    }

    &__response-action {
        margin: 30px auto;
    }

    &__counters-container {
        max-width: 80%;
    }

    &__paragraph {
        @include body-l;
        font-family: $open-sans-family;

        &--semi-bold {
            @include body-l;
            font-family: $open-sans-family;
            font-weight: $open-sans-semi-bold;
        }

        &--bold {
            @include body-l;
            font-family: $open-sans-family;
            font-weight: $open-sans-bold;
        }

        &--extra-bold {
            @include body-l;
            font-family: $open-sans-family;
            font-weight: $open-sans-extra-bold;
        }

        &--error {
            color: $red;
        }
    }

    &__subform-container .coned-form__field-container {
        position: relative;
        margin-bottom: 70px;

        .coned-field-error-wrapper {
            @include transform(translateY(100%));

            position: absolute;
            bottom: -50px;
            left: 0;
            padding-left: 40px;
            width: 100%;
            box-sizing: border-box;
        }

        .coned-input-message--error::before {
            left: 15px;
        }
    }

    &__textarea {
        @include body-l;
        border: 1px solid $black;
        padding: 5px 10px;
        height: 172px;
        font-family: $open-sans-family;
        font-weight: $open-sans-semi-bold;
        resize: none;
        box-sizing: border-box;

        &-counter {
            @include body-m;
            float: left;
            margin-top: -60px;
            margin-left: 20px;
            font-family: $open-sans-family;
            font-weight: $open-sans-semi-bold;

            &--error {
                color: $red;
            }
        }
    }

    &__submit-container {
        @include bp-medium {
            margin: 0;
            max-width: 150px;
        }
    }

    &__submit-button {
        @extend .button;
        display: flex;
        margin: 0 auto;
        width: 100%;
        max-width: 440px;

        @include bp-medium {
            padding: 15px;
        }
    }

    &__response {
        @include bp-medium {
            &-error-list-element--account {
                max-width: 40% !important;
            }

            &-error-list-element--description {
                max-width: 60% !important;
            }
        }

        &-container {
            padding: 20px 0;

            &--border {
                border-top: 2px solid $alto;
            }
        }

        &-title {
            @include heading-m;
            color: $dark-gray;
            font-family: $lexend-family;
            font-weight: $lexend-extra-bold;
        }

        &-description {
            @include body-m;
            margin: 15px 0 10px;
            color: $medium-gray;
            font-family: $open-sans-family;
            font-weight: $open-sans-bold;

            &--error {
                color: $red;
            }
        }

        &-error-list {
            margin: 20px 0;
            width: 100%;
        }

        &-error-list-element {
            @include body-s;
            display: block;
            margin: 7.5px 0;
            white-space: nowrap;
            color: $red;
            font-family: $open-sans-family;
            font-weight: $open-sans-bold;

            &--account {
                display: inline-block;
                padding-right: 20px;
                width: 100%;
                max-width: 50%;
                vertical-align: top;
                white-space: normal;
                word-break: break-all;
                box-sizing: border-box;
            }

            &--description {
                display: inline-block;
                width: 100%;
                max-width: 50%;
                white-space: normal;
                box-sizing: border-box;
            }
        }

        &-error-list-element--title {
            @include body-m;
            margin-bottom: 10px;
            color: $dark-gray;

            .dashboard-third-party__response-error-list-element--account {
                word-break: normal;
            }

            .dashboard-third-party__response-error-list-element--description {
                margin-left: -3px;
                vertical-align: bottom;
            }
        }

        &-button {
            @extend .button-link, .button-link--extra-low-emphasis;
            display: block;
            margin-top: 15px;

            &--margin {
                margin: 30px 0;
            }
        }
    }

    .coned-tabs__mobile-container {
        position: relative;
    }

    .coned-tabs--visible .coned-tabs__selector {
        @include transform(translateY(-25%));
        top: 0;
    }

    .coned-tabs--visible .coned-tabs__dropdown {
        top: 50px;
        right: 25px;
        left: auto;
    }
}
