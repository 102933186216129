.select-multiple {
    @include flexbox;
    @include flex-direction(column);
    @include gap(20px);
    width: 100%;

    &__heading {
        @include body-l;
        margin-bottom: 10px;
        text-align: left;
        font-weight: $open-sans-bold;

        &--text {
            &-dark-gray {
                color: $dark-gray;
            }
        }
    }

    &__wrapper {
        &-option {
            @include flexbox;
        }
    }

    &__option {
        @include flexbox;
        position: relative;
        cursor: pointer;
        width: fit-content;
        text-wrap: nowrap;
        box-sizing: border-box;

        &:focus {
            outline: none;
        }

        &--focus {
            @include before-outline;
        }

        .coned-checkbox {
            padding: 10px 15px;

            @include bp-medium {
                padding: 0 5px;
            }

            &__text {
                cursor: pointer;
            }

            &__input {
                @extend .visually-hidden;
            }
        }

        &-wrapper {
            @include btn-focus-outline;
        }

        &-container {
            @include flexbox;
            @include align-items(center);
            @include gap(8px);
            position: relative;
            width: fit-content;
        }

        &-tooltip {
            @include flexbox;

        }
    }
}
