// ============ MODIFIERS =============

.hidden {
    display: none !important;
}

.visible {
    display: block !important;
}

.hidden-mobile {
    display: none !important;

    @include bp-medium {
        display: block !important;
    }
}

.inline-hidden-mobile {
    display: none !important;

    @include bp-medium {
        display: inline !important;
    }
}

.visible-mobile {
    display: block !important;

    @include bp-medium {
        display: none !important;
    }
}

.mobile-modal-open {
    @include bp-small {
        position: relative;
        overflow: hidden;
    }
}

.mobile-overlay-open {
    @include bp-small {
        overflow: hidden;
    }
}

.modal-open {
    overflow: hidden;
}

.visually-hidden {
    display: block;
    position: absolute;
    margin: -1px;
    border: 0;
    padding: 0;
    width: 1px;
    height: 1px;
    overflow: hidden;
    clip: rect(0 0 0 0);

    &--mobile {
        @include bp-medium {
            position: static;
            margin: unset;
            border: unset;
            padding: unset;
            width: unset;
            height: unset;
            overflow: unset;
            clip: unset;
        }
    }

    &--desktop {
        @include bp-small {
            position: static;
            margin: unset;
            border: unset;
            padding: unset;
            width: unset;
            height: unset;
            overflow: unset;
            clip: unset;
        }
    }
}

.visually-hidden-table-row {
    display: table-row;
}

//Orders
.order-one {
    order: 1;
}

.order-two {
    order: 2;
}

// ANIMATIONS
.rotate30 {
    @include transform(rotate(30deg));
    @include transition(all 100ms ease-out);
}

.rotate150 {
    @include transform(rotate(150deg));
    @include transition(all 100ms ease-out);
}

.rotate45 {
    @include transform(rotate(45deg));
    @include transition(all 100ms ease-out);
}

.rotate135 {
    @include transform(rotate(135deg));
    @include transition(all 100ms ease-out);
}

.collapse {
    @include transition(all 70ms ease-out);
    top: 10px;
}

.no-selection {
    -moz-user-select: none;
    -ms-user-select: none;
    -webkit-user-select: none;
    user-select: none;
}
