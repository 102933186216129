.radio-group {
    @include flexbox;
    @include flex-direction(column);
    @include gap(20px);

    // scss-lint:disable NestingDepth
    .coned-radio {
        &__label-name {
            padding-top: 0;
        }

        &__input:checked~.coned-radio__indicator::after {
            display: block;
            top: 3px;
            left: 3px;
            width: 14px;
            height: 14px;
        }

        &__input {
            width: 24px;
            height: 24px;

            &:focus+.coned-radio__indicator {
                @include theme-before-outline;

                &::before {
                    position: absolute;
                    top: -5px;
                    right: -5px;
                    bottom: -5px;
                    left: -5px;
                    content: '';
                }
            }
        }

        &__indicator {
            margin-right: 12px;
            width: 20px;
            min-width: 20px;
            height: 20px;
        }
    }

    &__label {
        @include body-l;
        margin-bottom: 0;
        text-align: left;
        color: $dark-gray;
        font-weight: $open-sans-bold;
    }
    // scss-lint:enable NestingDepth
}
