.coned-chart {
    margin-top: 30px !important;
    background: $white;

    &--hide {
        display: none;
    }

    &--active {
        display: block;
    }

    &__container {
        display: block;
        margin-left: -11px;
        min-height: 385px;
    }

    &__middle-text {
        display: block;
        position: relative;
        margin: 10px auto 0;
        width: 330px;
        text-align: center;
        font-family: $lexend-family;
        font-weight: $lexend-extra-bold;

        @include bp-medium {
            width: 430px;
        }

        &--date {
            @include heading-s;
            position: relative;
            margin-bottom: 10px;
            padding-top: 2px;
            width: 100%;
            height: 30px;
            color: $dark-gray;

            @include bp-medium {
                padding-top: 0;
            }
        }
    }

    &__header-link {
        display: inline-block;
        font: calculate-rem(13) $open-sans-family;
        font-weight: $open-sans-bold;

        &--blue {
            color: $blue;
        }

        &--orange {
            color: $orange;
        }
    }

    &__peak-title,
    &__header-title {
        display: inline;
    }

    &__offpeak-title {
        display: block;
    }

    &__peak-title,
    &__offpeak-title,
    &__header-title {
        @include body-s;
        width: auto;
        font-family: $open-sans-family;
        font-weight: $open-sans-regular;
    }

    &__chart-tooltip {
        border: 1px solid $medium-gray;
        background: $white;
        padding: 10px;
        width: 170px;
        text-align: center;

        @include bp-large {
            width: 175px;
        }

        &--total {
            font-family: futura;
            font-size: calculate-rem(22);
            font-weight: bold;
        }

        &--date {
            @include body-xs;
            margin-bottom: 4px;
            text-align: left;
            font-family: $open-sans-family;
            font-weight: $open-sans-bold;
        }

        &--data {
            @include body-xxs;
            border-top: 1px solid $light-gray;
            padding-top: 10px;
            width: 100%;
            text-align: left;
            font-family: $open-sans-family;
        }

        &--type {
            &-color {
                display: inline-block;
                margin-right: 5px;
                margin-left: 1px;
                padding: 5px;
            }

            &-numbers {
                @extend %inline-flex;
                @include justify-content(space-around);
                width: 20%;
                font-weight: $open-sans-bold;
            }

            &-text {
                @extend %inline-flex;
                width: 70%;
                text-overflow: ellipsis;
                font-weight: $open-sans-semi-bold;
            }

            &-paragraph {
                font-weight: $open-sans-semi-bold;
            }

            &-paragraph-bold {
                font-weight: $open-sans-extra-bold;
            }

            &-overage {
                outline-offset: -1px;
                outline-width: 1px;
                outline-style: double;
                outline-color: $red;
            }

            &-nonSummer {
                border: 1px solid $medium-gray;
                background: repeating-linear-gradient(
                    135deg,
                    $medium-gray,
                    $medium-gray 1px,
                    $white 1px,
                    $white 3px
                );
                padding: 4px;
            }

            &-subscriptionOverage {
                border: 1px solid $overage-red;
                background: repeating-linear-gradient(
                    135deg,
                    $overage-red,
                    $overage-red 1px,
                    $white 1px,
                    $white 3px
                );
                padding: 4px;
            }
        }

        &--paddingTop {
            padding-top: 12px;
        }
    }

    &__buttons-container {
        display: flex;
        justify-content: center;

        @include bp-large {
            flex-direction: row;
            justify-content: center;
        }

        &--columns {
            flex-direction: column;
            align-items: center;

            @include bp-large {
                flex-direction: row;
                justify-content: center;
            }
        }
    }

    &__buttons {
        @extend %inline-flex;
        margin: 20px 0;

        &--square-transparent {
            border: 1px solid $medium-gray;
            background: none;
            width: 72px;
            height: 40px;
            color: $black;

            span {
                @include body-s;
                color: $dark-gray;
                font-family: $open-sans-family;
                font-weight: $open-sans-semi-bold;
            }
        }

        &--square-blue-disabled {
            border: 1px solid $medium-gray;
            background: $blue !important;

            span {
                color: $white;
            }
        }

        &--square-teal-disabled {
            border: 1px solid $medium-gray;
            background: $teal !important;

            span {
                color: $white;
            }
        }

        &--square-transparent-disabled {
            background: $lighter-gray;

            span {
                color: $disabled-light-gray;
            }
        }

        &--hide {
            display: none !important;
        }

        &--no-border {
            border-right: none !important;
        }
    }

    &__buttons--square-transparent {
        &:focus {
            position: relative;
            outline: none;

            body:not(.mouse-user):not(.is-oru) &::before {
                @extend .coned-button-focus;
            }

            body:not(.mouse-user) &::before {
                @extend .oru-button-focus;
            }
        }
    }

    &__legend-tooltip {
        padding: 0;
    }

    &__legend {
        display: flex;
        margin-top: 17px;
        margin-left: 22px;
        flex-wrap: wrap;

        @include bp-medium {
            margin-left: 40px;
        }

        @include bp-large {
            margin-left: 40px;
        }

        &-color {
            display: inline-block;
            margin-right: 7px;
            padding: 6px;
            vertical-align: bottom;

            @include bp-medium {
                padding: 7px;
            }
        }

        &-type {
            @extend %flexbox;
            padding-bottom: 10px;
            width: 80%;
            height: 25px;
            line-height: 15px;

            @include bp-medium {
                display: inline-block;
                margin-left: 20px;
                width: 45%;
            }

            @include bp-large {
                margin-left: 0;
                width: 50%;
            }
        }

        &-line {
            margin-right: 6px;
            border-top: $white;
            border-right: $white;
            border-left: $white;
            background-color: $white !important;
            width: 14px;
            height: 8px;

            &--demandSubsc {
                border-bottom: $overage-red 1px solid;
                border-bottom-style: dashed;
            }

            &--dailyUsageSupply {
                border-bottom: $blue 1px solid;
            }

            &--actualDemand {
                border-bottom: $light-green 2.5px solid;
            }

            &--offPeakDemand {
                border-bottom: $dark-chart-green 2.5px solid;
            }

            &--nonSummerDemand {
                border-bottom: $medium-gray 2.5px solid;
            }

            &--actualEventDemand {
                border-bottom: $overage-red 2.5px solid;
            }
        }

        &-content-monthly {
            margin-top: -2px;

            @include bp-medium {
                margin-top: 0;
            }
        }

        &-billedOverageDay {
            border-radius: 10px;
        }

        &-determinantDayPeak {
            border: $light-green 3px solid;
            border-radius: 14px;
            background-color: $white !important;
            padding: 4px !important;
        }

        &-determinantDayOffPeak {
            border: $dark-chart-green 3px solid;
            border-radius: 14px;
            background-color: $white !important;
            padding: 4px !important;
        }

        &-determinantDayNonSummer {
            border: $medium-gray 3px solid;
            border-radius: 14px;
            background-color: $white !important;
            padding: 4px !important;
        }

        &-unbilledOverage {
            border: $red 3px solid;
            border-radius: 14px;
            background-color: $white !important;
            padding: 4px !important;
        }

        &-determinantDay {
            border-radius: 14px;
            background-color: $white !important;
            padding: 4px !important;
        }

        &-determinantDayPeakA,
        &-determinantDayPeak2,
        &-determinantDayPeakB,
        &-determinantDayPeakC,
        &-determinantDayPeakD,
        &-determinantDayPeak7,
        &-determinantDayPeak8,
        &-determinantDayPeak9 {
            border: $light-green 3px solid;
        }

        &-determinantDayOffPeakA,
        &-determinantDayOffPeak2,
        &-determinantDayOffPeakB,
        &-determinantDayOffPeakC,
        &-determinantDayOffPeakD,
        &-determinantDayOffPeak7,
        &-determinantDayOffPeak8,
        &-determinantDayOffPeak9 {
            border: $dark-chart-green 3px solid;
        }

        &-determinantDayNonSummerB {
            border: $medium-gray 3px solid;
        }

        &-determinantDayPeakE,
        &-determinantDayPeakF {
            border: $medium-gray 3px solid;
        }

        &-unbilledOverageDeterminantDayE {
            border: $sepia-black 3px solid;
            border-radius: 14px;
            background-color: $white !important;
            padding: 4px !important;
        }

        &-unbilledOverageDeterminantDayF {
            border: $sepia-black 3px solid;
            border-radius: 14px;
            background-color: $white !important;
            padding: 4px !important;
        }

        &-billedOverageDeterminantDayF {
            border: $fire-brick 3px solid;
            border-radius: 14px;
            background-color: $white !important;
            padding: 4px !important;
        }

        &-nonSummerPeakValue {
            border: 1px solid $medium-gray;
            background: repeating-linear-gradient(
                135deg,
                $medium-gray,
                $medium-gray 1px,
                $white 1px,
                $white 3px
            );
            padding: 5px;

            @include bp-medium {
                padding: 6px;
            }
        }

        &-unbilledSubscriptionOverageValue {
            border: 1px solid $overage-red;
            background: repeating-linear-gradient(
                135deg,
                $overage-red,
                $overage-red 1px,
                $white 1px,
                $white 3px
            );
            padding: 5px;

            @include bp-medium {
                padding: 6px;
            }
        }
    }

    &__tooltip {
        @extend %inline-flex;
        @include body-xs;
        margin-bottom: 2px;
        border: 0;
        height: 7px;
        color: $dark-gray;
        font-family: $open-sans;
        font-weight: $open-sans-semi-bold;

        &-content {
            margin-top: -2px;
            margin-left: 5px;
            height: 20px;
        }

        &-determinantDayPeak,
        &-determinantDayOffPeak,
        &-determinantDayNonSummer {
            margin-top: 2px;
        }

        &--icon {
            top: 6px;
        }
    }

    .arrow--blue {
        color: $blue;

        &:active {
            color: $white;

            .arrow__icon {
                border-color: $dark-blue;
                background-color: $dark-blue;
            }
        }

        body:not(.mouse-user):not(.is-oru) &:focus {
            &::before {
                @extend .coned-button-focus;
            }
        }
    }

    .arrow--teal {
        color: $teal;

        span {
            border-color: $teal;
        }

        &:active {
            color: $white;

            .arrow__icon {
                border-color: $black;
                background-color: $black;
            }
        }

        body:not(.mouse-user) &:focus {
            &::before {
                @extend .oru-button-focus;
            }
        }
    }

    &__arrow {
        position: absolute;
        top: 0;
        z-index: 1;
        height: auto;

        &--left {
            left: 0;
        }

        &--right {
            right: 0;
        }

        &--hide {
            display: none;
        }

        span {
            border: 2px solid $blue;
            border-radius: 50%;
            background-color: $white;
            padding: 10px;
            width: 10px;
            height: 10px;

            &::before {
                position: absolute;
                top: 3px;
                left: 2px;
                font-size: calculate-rem(25);
            }

            &:hover::before {
                transform: scale(1.1);
            }
        }

        &:focus {
            outline: none;
        }
    }

    &__peak-content {
        display: flex;
        margin: 20px 0;

        &--text {
            display: inline-block;
            width: auto;
            color: $dark-gray;
            font-family: $open-sans;
            font-size: calculate-rem(12);
            font-weight: $open-sans-bold;
        }

        &--buttons {
            margin: 0 5px;
            border-bottom: 0;
            background: none;
            padding: 0;
            width: auto;
            color: $disabled-gray;
            font-family: $open-sans;
            font-size: calculate-rem(12);
            font-weight: $open-sans-bold;

            &-disabled {
                border-bottom: 1px solid $blue;
                color: $dark-gray;
            }
        }
    }

    &__line-rect {
        fill: $white;
        stroke: $medium-gray;
        stroke-width: 0.5;
    }

    &__target-unbilled-overage {
        outline-offset: -2px;
        outline-width: 1px;
        outline-style: double;
        outline-color: $red;
    }

    &__note {
        @include body-xs;
        margin: 0 13px 0 10px;
        background: $dark-gray;
        padding: 14px;
        font-family: $open-sans-family;

        @include bp-medium {
            margin-right: 0;
        }

        @include bp-large {
            margin: 0 2px;
        }

        &-paragraph {
            @extend %flexbox;
            display: inline-block;
            color: $white;
            font-weight: $open-sans-semi-bold;

            &--bold {
                font-weight: $open-sans-bold;
            }

            &-link--white {
                color: $white !important;
                font-weight: $open-sans-bold;
            }

            &--inlineText {
                display: inline;
            }
        }
    }

    &__table {
        margin: 0 13px 25px;
        border-top: 1px solid $medium-gray;
        border-bottom: 1px solid $medium-gray;

        @include bp-medium {
            margin: 0 2px 25px;
        }

        &.expanded {
            border-bottom: 0;
        }

        &-text {
            padding-bottom: 10px;
            color: $dark-gray;
            font-family: "Open Sans", sans-serif;
            font-size: 1.4rem;

            &--bold {
                font-weight: 600;
            }
        }

        &-button {
            display: block;
            height: 50px;

            body:not(.mouse-user):not(.is-oru) &:focus {
                @extend .coned-focus-outline;
            }
    
            body:not(.mouse-user) &:focus {
                @extend .oru-focus-outline;
            }
        }

        &-title {
            @include body-l;
            padding-left: 5px;
            color: $medium-gray;
            font-family: $open-sans-family;
            font-weight: $open-sans-semi-bold;
        }

        &-icon {
            display: block;
            position: relative;
            float: right;
            font-size: 25px;

            &--up {
                transform: rotate(180deg);
            }

        }

        &-content {
            margin: 0 4px 2px;
        }

        table {
            width: 100%;
            table-layout: fixed;
            text-align: left;

            body:not(.mouse-user):not(.is-oru) &:focus {
                @extend .coned-focus-outline;
            }
    
            body:not(.mouse-user) &:focus {
                @extend .oru-focus-outline;
            }
        }

        th {
            @include body-xs;
            vertical-align: top;
            text-align: left;
            color: $dark-gray;
            font-family: $open-sans-family;
            font-weight: $open-sans-bold;
            overflow-wrap: break-word;
            word-wrap: break-word;
        }

        thead {
            border-bottom: 2px solid $blue;

            .is-oru & {
                border-bottom: 2px solid $teal;
            }

            th {
                padding-right: 10px;
                padding-bottom: 4px;
                height: 50px;

                @include bp-medium {
                    padding-right: 12px;
                    vertical-align: inherit;
                }
            }
            
            th:last-child {
                padding-right: 0;
            }
        }

        tbody {
            tr {
                @include body-xs;
                border-bottom: 1px solid $medium-gray;
                height: 54px;
                font-family: $open-sans-family;

                @include bp-medium {
                    height: 40px;
                }
            }

            td {
                padding: 8px 6px 8px 0;

                @include bp-medium {
                    padding: 5px 8px 5px 0;
                }
            }
        }
    }
}

.text-line-year {
    @include bp-medium {
        display: inline;
    }

    text {
        font-family: $open-sans;
        font-size: calculate-rem(10);
        font-weight: $open-sans-bold;
        fill: $medium-gray;
    }
}
