// ============== TRANSACTIONAL BANNER COMPONENT =================

.transactional-banner {
    margin-bottom: 50px;
    padding: 100px 0 80px;
    text-align: center;
    color: $white;

    &--top {
        margin-top: 50px;
    }

    &-wrapper {
        background-color: $blue;
    }

    &__list {
        &:not(.transactional-banner__list--no-flex) {
            @include flexbox;
            @include justify-content(center);
        }

        &-item {
            margin: 0 20px;
        }
    }

    &__subtitle {
        @include body-l;
        margin: 60px 0 10px;
        text-align: center;
        text-transform: uppercase;
        color: $medium-gray;
        font-family: $lexend-family;
        font-weight: $lexend-extra-bold;
    }

    &__title {
        @include heading-l;
        margin: 0 auto 35px;
        width: 65%;
        font-family: $lexend-family;
        font-weight: $lexend-extra-bold;
    }

    &__text {
        @include body-l;
        margin: 0 auto 35px;
        width: 75%;
        font-family: $lexend-family;
        font-weight: $lexend-regular;

        a {
            color: $white;
            font-family: $lexend-family;
            font-weight: $lexend-extra-bold;

            body:not(.mouse-user):not(.is-oru) &:focus {
                @extend .coned-focus-outline;
            }

            body:not(.mouse-user) &:focus {
                @extend .oru-focus-outline;
            }

            &:focus::before {
                content: none !important;
            }
        }
    }

    &__error {
        padding: 20px;
        color: $white;
        font: $open-sans-semi-bold calculate-rem(16) $open-sans-family;

        a {
            text-decoration: underline;
            color: $white;
        }

        &-icon {
            @include border-radius(50%);
            position: relative;
            left: 0;
            padding-right: 27px;
            color: $white;

            &::before {
                position: absolute;
                top: -3px;
                left: -2px;
                font-size: calculate-rem(25);
            }
        }

        &--white {
            margin: 50px auto 0;
            background-color: $white;
            width: 40%;
            color: $red;
        }
    }

    &__link {
        @extend .button, %inline-flex, .button--high-emphasis;

        body:not(.is-oru) & {
            @extend .button--secondary-color;
        }

        &--wide {
            @extend .button--high-emphasis-large;
        }

        &-icon {
            display: inline-block;
            position: relative;
            width: 18px;
            height: 18px;

            &::before {
                position: absolute;
                top: -5px;
                left: -10px;
                font-size: calculate-rem(30);
            }

            &--big::before {
                top: -12px;
                left: 0;
                font-size: calculate-rem(50);
            }
        }
    }

    &--no-margin-top {
        margin-top: 0;
    }

    &--no-margin-buttom {
        margin-bottom: 0;
    }

    &.transactional-banner--open-sans {
        .transactional-banner__text,
        .transactional-banner__text a,
        .transactional-banner__text span,
        .transactional-banner__link {
            font-family: $open-sans-family;

            @include bp-medium {
                font-family: $open-sans-family;
            }
        }

        .transactional-banner__link,
        .transactional-banner__text a,
        .transactional-banner__text span {
            font-weight: $open-sans-bold;
        }
    }
}

.transactional-banner--oru {
    .transactional-banner {
        background-color: $orange;
        color: $teal;

        &__text a {
            color: $teal;
        }
    }
}
