// ============== INFORMATIONAL MESSAGE =================

.informational-message {
    padding: 15px;
    color: $medium-gray;
    font-weight: $open-sans-semi-bold;

    &__wrapper {
        border: 1px solid $medium-gray;

        &--round-corners {
            border-radius: 4px;
        }

        &--margin-bottom-20 {
            margin-bottom: 20px;
        }
    }

    &__paragraph {
        @include body-m;
        padding: 20px;
        font-family: $open-sans-family;

        &--semi-bold {
            font-weight: $open-sans-semi-bold;
        }

        &--dark-gray {
            color: $dark-gray;
        }
        
        &--indentation-left {
            display: flex;

            .informational-message-icon::before {
                top: 0;
            }
        }
    }

    &-icon {
        @include border-radius(50%);
        position: relative;
        left: 0;
        padding-right: 27px;

        &::before {
            position: absolute;
            top: -3px;
            left: -2px;
            font-size: calculate-rem(25);
        }

        &--red::before {
            color: $red;
        }
    }
}
