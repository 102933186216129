// ============== OVERVIEW HEADER COMPONENT =================
.overview {
    &-header-container {
        @extend %flexbox;
        @include justify-content(center);
        @include flex-wrap(wrap);
        margin: 15px auto -5px;
        padding: 0 10px;

        @include bp-medium {
            @include justify-content(space-between);
            margin: 40px auto 0;
            padding: 0;
        }

        &--no-margin-top {
            margin-top: 0;
        }

        &--margin-bottom-15-mobile {
            @include bp-small {
                margin-bottom: 15px;
            }
        }
    }

    &-bill-card {
        @extend %flexbox;
        @include flex-grow(1);
        @include flex-direction(column);
        @include align-items(center);
        @include justify-content(center);
        position: relative;
        margin-bottom: 15px;
        background-color: $blue;
        padding: 52px 10px 30px;
        width: 100%;
        height: auto;
        text-align: center;
        color: $white;
        overflow-x: hidden;

        @include bp-bill-card {
            padding: 52px 40px 30px;
        }

        @include bp-medium {
            @include box-sizing(border-box);
            margin-right: 20px;
            margin-bottom: 20px;
            padding: 52px 60px 100px;
            width: calc(66% - 10px);
        }

        &--no-top-padding {
            padding-top: 0;
            padding-bottom: 55px;
        }

        &--no-right-margin {
            margin-right: 0;
        }

        &__cta-full-container {
            max-width: 650px;
        }

        &--padding-bottom-mobile-error {
            padding-bottom: 120px;

            @include bp-medium {
                padding-bottom: 100px;
            }
        }

        &__price {
            @include display-l;
            margin: 45px 0 10px;
            font-family: $lexend-family;
            font-weight: $lexend-extra-bold;

            @include bp-medium {
                margin: 70px 0 40px;
            }

            &--credit {
                color: $blue;
            }

            &--centered-mobile {
                margin: 45px 0;

                @include bp-medium {
                    margin: 70px 0 40px;
                }
            }
        }

        &__due {
            @include heading-s;
            max-width: 60ch;
            font-family: $open-sans-family;
            font-weight: $open-sans-bold;

            &-wrapper {
                margin: 10px 0 20px;
            }

            a {
                white-space: nowrap;
                color: $teal;

                // scss-lint:disable NestingDepth
                body:not(.is-oru) & {
                    color: $white;
                }
            }
        }

        &__cta {
            @extend .button, .button--high-emphasis;

            margin: 50px 0 0;

            body:not(.is-oru) & {
                @extend .button--secondary-color;
            }
        }

        &__cta-icon {
            @extend .button-icon;

            &::before {
                position: absolute;
                top: -6px;
                left: -8px;
                font-size: calculate-rem(35);
            }
        }

        &__cta-icon--alternative {
            @include transform(rotate(-90deg));
            position: absolute;
            top: 21px;
            right: 30px;
            margin-right: 0;

            &::before {
                position: initial;
                font-size: calculate-rem(35);
            }
        }

        &__cta-container {
            @include bp-medium {
                @include justify-content(center);
            }

            .overview-bill-card__cta {
                margin-top: 15px;
                min-width: 280px;

                @include bp-medium {
                    margin-top: 30px;
                }
            }

            .overview-bill-card__cta--unauthenticated {
                margin-top: 20px;
                min-width: 100%;
                text-transform: none;

                @include bp-medium {
                    margin: 50px 10px 0;
                    min-width: 275px;
                }

                // scss-lint:disable NestingDepth
                &:first-child {
                    margin-top: 30px;
                }
                // scss-lint:enable NestingDepth
            } 

            .overview-bill-card__cta--half-margin-top {
                @include bp-medium {
                    margin-top: 25px;
                }
            }
        }

        &__cta-container--three-elements {
            @include bp-medium {
                @include flexbox;
                @include flex-wrap(wrap);

                width: 100%;
            }

            .overview-bill-card__cta {
                @include bp-medium {
                    margin-right: 10px;
                    margin-left: 10px;
                }
            }
        }

        &__view-plan {
            @include body-s;
            margin-top: 30px;
            font-family: $open-sans-family;
            font-weight: $open-sans-semi-bold;

            @include bp-medium {
                margin-top: 45px;
            }

            b {
                font-weight: $open-sans-extra-bold;
            }

            a {
                color: $teal;

                // scss-lint:disable NestingDepth
                body:not(.is-oru) & {
                    color: $white;
                }
                // scss-lint:enable NestingDepth
            }
        }

        &__plan-agreement {
            @include body-s;
            margin: 30px auto 0;
            font-family: $open-sans-family;
            font-weight: $open-sans-semi-bold;

            @include bp-medium {
                margin: 40px auto 20px;
            }

            b {
                font-family: $lexend-family;
                font-weight: $lexend-extra-bold;
            }

            strong {
                @include body-l;
            }
        }

        &__tooltip {
            &::after {
                top: -10px;
                right: -5px;
                font-size: calculate-rem(35);
            }
        }

        &--no-banner {
            padding: 0 10vw 30px;

            @include bp-medium {
                margin-right: 0; 
                padding: 52px 12vw 100px;
            }
        }

        &__message {
            @include heading-l;
            font-family: $lexend-family;
            font-weight: $lexend-extra-bold;

            @include bp-medium {
                width: calc(100% - 215px);
            }
        }

        &__unauthenticated {
            padding: 50px 20px;

            @include bp-medium {
                padding: 155px 60px 134px;
            }
        }

        &__payment-options {
            @extend .button-link;
            margin-top: 30px;

            @include bp-medium {
                margin-top: 50px;
            }

            a {
                color: $teal;

                // scss-lint:disable NestingDepth
                body:not(.is-oru) & {
                    color: $white;
                }
                // scss-lint:enable NestingDepth
            }
        }
    }

    &-bill-card-side {
        @extend %flexbox;
        @include flex-direction(column);
        @include justify-content(center);
        margin-bottom: 0;
        border-top: 5px solid $blue-brand;
        background-color: $white;
        padding: 30px;
        width: 100%;

        @include bp-medium {
            @include box-sizing(border-box);
            margin-bottom: 20px;
            width: 32.1%;
        }

        @include bp-cards {
            width: 32.35%;
        }

        &__title {
            @include heading-m;
            color: $dark-gray;
            font-family: $lexend-family;
            font-weight: $lexend-extra-bold;
            word-break: break-word;

            &--overdue {
                color: $red;
            }
        }

        &__description {
            @include body-l;
            margin: 26px 0;
            color: $dark-gray;
            font-family: $open-sans-family;
        }

        &__buttons {
            @include flexbox;
            @include justify-content(space-between);
            @include flex-wrap(wrap);
            @include flex-direction(column);
            @include align-items(flex-start);
        }

        &__button,
        &__cta {
            @extend .button, %inline-flex;
            @include justify-content(center);
            @include align-items(center);
            margin-bottom: 15px;
        }

        &__cta--rounded-border {
            @extend .button;
        }

        &__cta--icon {
            @include transform(rotate(-90deg));
            position: relative;
            margin-left: 5px;
            width: 14px;
            height: 14px;

            &::before {
                position: absolute;
                top: -4px;
                left: -8px;
                font-size: calculate-rem(25);
            }

            &:hover {
                text-decoration: none;
            }
        }

        &__link {
            @extend .button-link, .button-link--extra-low-emphasis;
            margin-bottom: 15px;
            font-family: $open-sans-family;
            font-weight: $open-sans-extra-bold;
        }

        &--margin-bottom-35-desktop {
            @include bp-medium {
                margin-bottom: 35px;
            }
        }

        &--no-margin-bottom-desktop {
            @include bp-medium {
                margin-bottom: 0;
            }
        }
    }

    &-account-card {
        @extend %flexbox;
        @include flex-grow(1);
        @include flex-direction(column);
        @include align-items(center);
        @include justify-content(center);
        position: relative;
        margin: 30px 0 15px;
        background-color: $blue;
        padding: 0 30px;
        width: auto;
        text-align: center;
        color: $white;

        @include bp-bill-card {
            padding: 0 40px;
        }

        @include bp-medium {
            @include box-sizing(border-box);
            margin-bottom: 20px;
        }

        &__title {
            @include heading-l;
            margin: 50px 0 26px;
            font-family: $lexend-family;
            font-weight: $lexend-extra-bold;

            @include bp-medium {
                margin: 30px 0;
            }
        }

        &__address {
            @include heading-s;
            font-family: $lexend-family;
            font-weight: $lexend-extra-bold;
        }

        &__number {
            @include body-m;
            margin-bottom: 50px;
            font-family: $open-sans-family;

            @include bp-medium {
                margin-bottom: 30px;
            }
        }
    }

    &-image {
        @include align-self(center);
        margin-bottom: 25px;

        @include bp-small {
            width: 200px;
        }

        @include bp-medium {
            max-width: 380px;
        }

        &--horizontal {
            width: auto;
            max-width: 100%;
        }
    }
}

.overview-header-container--oru {
    .overview-bill-card {
        background-color: $orange;
        color: $teal;

        &__price--credit {
            color: $orange;
        }
    }

    .overview-bill-card-side {
        border-top-color: $orange-brand;
    }
}
