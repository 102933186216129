// ============== MANAGE HEADER COMPONENT =================

.manage-header {
    @include flexbox;
    @include flex-wrap(wrap);
    @include justify-content(space-between);
    margin: 20px 0;

    @include bp-medium {
        margin: 30px 0 70px;
    }

    &-wrapper {
        margin: 0 auto;
    }

    &-container {
        padding: 0 10px;

        @include bp-medium {
            padding: 0;
        }
    }

    &__link {
        @extend .button-link;

        &--white {
            body:not(.is-oru) & {
                @extend .button--primary-color;
            }
        }

        & + & {
            margin-left: 25px;
        }
    }

    &__cta {
        @extend .button, .button--high-emphasis, %inline-flex;
        @include justify-content(center);
        @include align-items(center);
        margin: 10px;

        @include bp-medium {
            margin: 40px 10px;
        }

        body:not(.is-oru) & {
            @extend .button--secondary-color;
        }

        &-text {
            padding-right: 25px;

            @include bp-medium {
                padding-right: 20px;
            }
        }

        &-icon {
            @extend .button-icon;

            &::before {
                position: absolute;
                top: -12px;
                right: -20px;
                font-size: calculate-rem(50);

                @include bp-medium {
                    right: -15px;
                }
            }
        }

        &-animation-block {
            @include transition(all, 0.4s, ease);
            @include border-radius(50px);
            position: absolute;
            top: 50%;
            left: 50%;
            width: 0;
            height: 0;
        }
    }

    &__info {
        @include flexbox;
        @include box-sizing(border-box);
        @include justify-content(center);
        @include align-items(center);
        @include flex-direction(column);
        border-top: 5px solid $blue-brand;
        background-color: $white;
        padding: 50px 45px;
        width: 100%;
        color: $black;

        @include bp-medium {
            width: 35%;
        }

        &-links {
            @include flexbox;
            margin-top: 50px;
            width: 100%;
        }

        &-account {
            @include box-sizing(border-box);
            margin-bottom: 20px;
            background-color: $blue;
            padding: 50px 30px;
            width: 100%;
            text-align: center;
            color: $white;

            @include bp-medium {
                margin-bottom: 0;
                padding: 100px 30px 80px;
                width: 62%;
            }
        }
    }

    &__links-container {
        margin: 40px 0 10px;
        width: 100%;

        @include bp-medium {
            @include flexbox;
            @include justify-content(center);
            margin: 10px 0;
        }
    }

    &__title {
        @include display-m;
        padding: 10px 0 30px;
        font-family: $lexend-family;
        font-weight: $lexend-extra-bold;
        word-break: break-word;
    }

    &__subtitle {
        @include heading-m;
        width: 100%;
        font-family: $lexend-family;
        font-weight: $lexend-extra-bold;
    }

    &__description {
        @include body-s;
        font-family: $open-sans-family;
        font-weight: $open-sans-semi-bold;
    }

    &__address {
        @include heading-s;
        font-family: $open-sans-family;
        font-weight: $open-sans-bold;
    }
}

.manage-header--oru .manage-header {
    &__info-account {
        background-color: $orange;
        color: $teal;
    }

    &__info {
        border-top-color: $orange-brand;
    }
}
