// ============== MAUI MODULES COMPONENT =================

.billing-module,
.energy-usage-module {
    &__wrapper {
        background-color: $white;

        @include bp-small {
            margin: 0 10px 20px;
        }

        @include bp-medium {
            margin-bottom: 40px;
        }

        &--background-transparent {
            background-color: $transparent;
        }

        .service-unavailable-error {
            text-align: center;
            font: calculate-rem(14) / calculate-rem(145) $open-sans-family;
        }
    }

    &__title {
        @include heading-l;
        margin-top: 20px;
        color: $dark-gray;
        font-family: $lexend-family;
        font-weight: $lexend-extra-bold;

        &--margin-bottom {
            margin-bottom: 30px;

            @include bp-medium {
                margin-bottom: 20px;
            }
        }
    }

    &--paddings {
        padding: 30px 10px;
    
        @include bp-medium {
            padding-right: 50px;
            padding-left: 50px;
        }
    }

    &--center-horizontal {
        @extend %flexbox;
        @include justify-content(center);
    }

    &__section {
        &--gray-bg {
            background-color: $lighter-gray;
        }

        &--min-height {
            min-height: 150px;
        }
    }
}

.billing-module {
    &--top-bottom-padding.billing-module--sides-padding {
        @extend %maui-modules-sides-padding;
    }

    &--sides-padding.billing-module--top-bottom-padding {
        @extend %maui-modules-top-bottom-padding;
    }
}

.energy-usage-module {
    &--top-bottom-padding.energy-usage-module--sides-padding {
        @extend %maui-modules-sides-padding;
    }

    &--sides-padding.energy-usage-module--top-bottom-padding {
        @extend %maui-modules-top-bottom-padding;
    }
}

%maui-modules-sides-padding {
    margin: 0 auto;
        
    @include bp-small {
        padding-right: 20px;
        padding-left: 20px;
    }

    @include bp-medium {
        width: 72%;
        max-width: 1000px;
    }
}

%maui-modules-top-bottom-padding {
    padding-top: 30px;
    padding-bottom: 30px;    

    @include bp-medium {
        padding-top: 50px;
        padding-bottom: 50px;
    }
}
