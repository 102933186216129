// ============== BILL INFO COMPONENT =================
.bill-info {
    margin: auto;
    border-top: 20px solid $light-gray;
    background: $white;
    width: 100%;

    @include bp-medium {
        max-width: 1400px;
    }

    &__wrapper {
        @include flexbox;
        @include flex-direction(column);
        @include box-sizing(border-box);
        margin: 0 auto;
        background: $white;
        padding: 16px;
        width: auto;
        height: auto;
        
        @include bp-medium {
            @include flex-direction(row);
            @include align-items(center);
            padding: 24px 20px;
            max-width: 594px;
        }
    
        @include bp-extra-large {
            @include flex-direction(row);
            @include align-items(center);
            max-width: 1048px;
        }
    }

    &__balance-card {
        @include body-s;
        @include border-radius(8px);
        background: $blue;
        padding: 24px;
        text-transform: uppercase;
        color: $white;
        font-family: $open-sans;
        font-weight: $open-sans-extra-bold;

        @include bp-extra-large {
            width: 41.459%;
        }

        &-title {
            font-family: $open-sans;
            font-weight: $open-sans-bold;
        }

        &-amount {
            @include flexbox;
            @include align-items(center);

            @include bp-medium {
                min-width: 212px;
            }

            &-text {
                @include display-m;
                font-family: $lexend;
                font-weight: $lexend-extra-bold;
            }
        }

        &-icon {
            position: relative;
            top: -3px;
            margin-right: 8px;
            border-radius: 50%;
            width: 30px;
            height: 30px;

            @include bp-extra-large {
                max-width: 1048px;
            }

            &::before {
                border-radius: 50%;
                background: $red;
                color: $white;
                font-size: 3rem;
            }
            
            &--rotate-180 {
                transform: rotate(180deg);
            }

            &--white::before {
                background: $white;
                color: $red;
            }
        }

        .is-oru & { 
            background: $orange;
        }

        &--border {
            border: 2px solid $blue;
            background: $white;
            color: $blue;

            .is-oru & { 
                border: 2px solid $teal;
                background: $white;
                color: $teal;
            }
        }

        &--red-border {
            border: 2px solid $red;
            background: $white;
            color: $red;

            .is-oru & { 
                border: 2px solid $red;
                background: $white;
                color: $red;
            }
        }

        &--teal-color {
            .is-oru & { 
                color: $teal;
            }
        }

        &--read {
            background: $red;
            
            .is-oru & { 
                background: $red;
            }
        }
    }

    &__description {
        @include heading-s;
        width: auto;
        font-family: $lexend;

        @include bp-medium {
            margin-left: 24px;
        }
        
        @include bp-extra-large {
            @include flexbox;
            @include flex-direction(row);
            @include justify-content(space-between);
            @include align-items(center);
            width: calc(70% - 72px);
        }

        &-text {
            margin: 24px 0 0;
            width: 100%;
            
            @include bp-medium {
                margin: 0 0 16px;
            }

            @include bp-extra-large {
                margin: 0;
            }
        }
    }

    &__cta {
        margin-top: 16px;
        width: 100%;

        @include bp-medium {
            margin-top: 0;
            width: auto;
        }

        @include bp-extra-large {
            margin-left: 16px;
            width: 185px;
        }
    }
}
