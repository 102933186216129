// ============== FONT VARIABLES =================

$default-font-size: 10;

// Open Sans
$open-sans: 'Open Sans';
$open-sans-family: $open-sans, sans-serif;

$open-sans-light: 300;
$open-sans-regular: 400;
$open-sans-semi-bold: 600;
$open-sans-bold: 700;
$open-sans-extra-bold: 800;

// Lexend
$lexend: 'Lexend';
$lexend-family: $lexend, sans-serif;

$lexend-light: 300;
$lexend-regular: 400;
$lexend-semi-bold: 600;
$lexend-extra-bold: 800;

// Icons
$coned-icons: 'coned-icons';
