// ============ ADDRESS BOX CTA ===============

.address-box {
    margin-bottom: 40px;
    width: 100%;

    &__title {
        @include body-s;
        color: $medium-gray;
        font-family: $open-sans-family;
        font-weight: $open-sans-semi-bold;
    }

    &__container {
        @include flexbox;
        @include align-items(center);
        @include body-s;
        border: 1px solid $dark-gray;
        background-color: $white;
        padding: 30px 20px 30px 0;

        &-title {
            font-family: $lexend-family;
            font-weight: $lexend-extra-bold;
            
            &--non-authenticated {
                font-family: $open-sans-family;
                font-weight: $open-sans-semi-bold;
            }
        }

        &-subtitle {
            font-family: $open-sans-family;
            font-weight: $open-sans-semi-bold;
        }

        &--no-image {
            padding: 0;
            min-height: 120px;

            .address-box__info {
                padding-left: 15px;

                @include bp-medium {
                    padding-left: 45px;
                }
            }
        }
    }

    &__info {
        padding: 0 25px 0 40px;
        text-align: left;
        color: $dark-gray;
    }

    &--no-margin {
        margin: 0;
    }

    &--item {
        margin: 0;
    }
}
