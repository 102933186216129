// ============== GRAPHICS COMPONENT =================

// GRAPHICS GRID
.graphics-grid {
    @extend %flexbox;
    @include flex-wrap(wrap);
    @include flex-direction(row);
    // 'gap' attribute is not allowed on the default lint configuration
    // scss-lint:disable PropertySpelling, PropertySortOrder
    gap: 16px;
    // scss-lint:enable PropertySpelling, PropertySortOrder
    margin-bottom: 95px;
    background-color: $white;
    padding: 28px 6%;
    max-width: 1400px;
    justify-content: center;
    align-items: flex-start;
    align-content: flex-start;

    @include bp-graphics-mobile {
        padding: 28px 18%;
    }

    @include bp-graphics-desktop {
        padding: 28px 12%;
    }

    &--6 {
        @include bp-graphics-desktop-large {
            padding: 28px 8%;
        }

        @include bp-graphics-desktop-extra-large {
            padding: 28px 60px;
        }
    }

    &--5 {
        @include bp-graphics-desktop-large {
            padding: 28px 15%;
        }

        @include bp-graphics-desktop-extra-large {
            padding: 28px 100px;
        }
    }

    &--4 {
        @include bp-graphics-desktop-large {
            padding: 28px 22%;
        }

        @include bp-graphics-desktop-extra-large {
            padding: 28px 156px;
        }
    }

    &--3 {
        @include bp-graphics-desktop-large {
            padding: 28px 8%;
        }

        @include bp-graphics-desktop-extra-large {
            padding: 28px 294px;
        }
    }

    &--2 {
        @include bp-graphics-desktop-large {
            padding: 28px 22%;
        }
    }

    &-item {
        @extend %flexbox;
        @include flex-direction(column);
        // scss-lint:disable PropertySpelling, PropertySortOrder
        gap: 12px;
        // scss-lint:enable PropertySpelling, PropertySortOrder
        background: linear-gradient(0deg, $white 0%, $white 100%), $noble;
        padding: 20px 0;
        width: 100%;
        align-items: center;

        @include bp-graphics-desktop {
            width: calc(calc(100% - 16px) / 2);
        }

        &--6 {
            @include bp-graphics-desktop-large {
                width: calc(calc(100% - 32px) / 3);
            }

            @include bp-graphics-desktop-extra-large {
                width: calc(calc(100% - 80px) / 6);
            }
        }

        &--5 {
            @include bp-graphics-desktop-extra-large {
                width: calc(calc(100% - 64px) / 5);
            }
        }

        &--4 {
            @include bp-graphics-desktop-extra-large {
                width: calc(calc(100% - 48px) / 4);
            }
        }

        &--3 {
            @include bp-graphics-desktop-large {
                width: calc(calc(100% - 32px) / 3);
            }
        }
    }

    &__image-container {
        position: relative;
        width: 100%;

        img {
            width: 100%;
        }
    }

    &__info-container {
        @extend %flexbox;
        @include flex-direction(column);
        padding: 0 20px;
        overflow: hidden;
        justify-content: flex-start;
        align-items: center;
        align-self: stretch;
        word-wrap: break-word;
    }

    &__paragraph {
        margin-bottom: 20px;
        color: $dark-gray;
    }

    &--content {
        @include body-xs;
        text-align: center;
        color: $medium-gray;
        font-family: $lexend-family;
        font-weight: $lexend-regular;

        h2,
        h3 {
            font-family: $lexend-family;
            font-weight: $lexend-extra-bold;
        }

        h2 {
            @include heading-l;
            margin-bottom: 2px;
        }

        h3 {
            @include body-l;
            margin-bottom: 4px;
        }
    }

    &__cta {
        margin-top: auto;
        margin-bottom: 2px;

        &:not(:last-child) {
            margin-right: 25px;
        }

        &-link {
            @extend .button;
        }
    }

    &__footnote-content-wrapper {
        margin-right: auto;
    }

    &__footnote-content {
        @include body-s;
        padding: 28px 0 0;
        color: $dark-gray;
        font-family: $open-sans-family;
        word-wrap: break-word;

        a {
            color: $dark-gray;     

            &:focus {
                @extend %focus-outline-on-element;
            }
        }
    }
}

// SINGLE GRAPHIC
.single-graphic {
    @extend %flexbox;
    @include flex-wrap(wrap);
    @include flex-direction(column);
    // scss-lint:disable PropertySpelling, PropertySortOrder
    gap: 28px;
    // scss-lint:enable PropertySpelling, PropertySortOrder
    margin-bottom: 95px;
    background-color: $white;
    padding: 20px 0;
    max-width: 1400px;
    justify-content: center;
    align-items: center;
    align-self: stretch;

    @include bp-graphics-mobile {
        padding: 20px 4%;
    }

    @include bp-graphics-desktop {
        padding: 48px 12%;
    }

    @include bp-graphics-desktop-large {
        @include flex-direction(row);
        padding: 48px 4%;
        align-items: flex-start;
    }

    @include bp-graphics-desktop-extra-large {
        @include flex-direction(row);
        padding: 48px 150px;
        align-items: flex-start;
    }

    &__image-container {
        position: relative;
        width: 100%;

        @include bp-graphics-desktop-large {
            width: calc(calc(100% - 28px) / 2);
        }

        img {
            width: 100%;
        }
    }

    &__info-container {
        padding: 0 20px;
        overflow: hidden;
        align-self: stretch;
        word-wrap: break-word;

        @include bp-graphics-desktop {
            padding: 0 90px;
        }

        @include bp-graphics-desktop-large {
            padding: 0;
            width: calc(calc(100% - 28px) / 2);
        }
    }

    &--content {
        @include body-l;
        color: $dark-gray;
        font-family: $open-sans-family;
        font-weight: $open-sans-regular;

        h2 {
            @include heading-s;
            margin-bottom: 20px;
            font-family: $lexend-family;
            font-weight: $lexend-extra-bold;
        }

        h3 {
            @include heading-s;
            font-weight: $open-sans-bold;
        }

        sup {
            vertical-align: super;
            font-size: 0.6em;
        }

        p:not(:last-child) {
            margin-bottom: 35px;
        }

        ul,
        ol {
            @include body-s;
            padding: 0 0 35px 20px;
            list-style: none;
            font-weight: $open-sans-semi-bold;

            @include bp-medium {
                padding-left: 14%;
            }

            &:last-child {
                padding-bottom: 0;
            }
        }

        li {
            margin-bottom: 20px;

            &:last-child {
                margin-bottom: 0;
            }

            ul,
            ol {
                margin-top: 20px;
                padding-bottom: 1px;
            }
        }

        li::before {
            padding-right: 10px;
            color: $orange;
            content: '•';

            body:not(.is-oru) & {
                color: $blue;
            }
        }

        ul li {
            text-indent: -1.1em;
        }

        ol {
            counter-reset: elementcounter;

            li {
                text-indent: -1.6em;
            }

            li::before {
                content: counter(elementcounter) '.';
                counter-increment: elementcounter;
            }

            // For supporting lists depth on rich text. please don't remove
            // scss-lint:disable NestingDepth, SelectorDepth
            ol {
                counter-reset: elementAlphaCounter;

                li {
                    text-indent: -1.6em;

                    &::before {
                        content: counter(elementAlphaCounter, lower-alpha) '.';
                        counter-increment: elementAlphaCounter;
                    }

                    ol {
                        counter-reset: elementRomanCounter;

                        li {
                            text-indent: -1.6em;

                            &::before {
                                content: counter(elementRomanCounter, lower-roman) '.';
                                counter-increment: elementRomanCounter;
                            }
                        }
                    }
                }
            }
            // scss-lint:enable NestingDepth, SelectorDepth
        }
    }

    // Specific for infographic variation
    &--content-infographic {
        ul,
        ol {
            padding-left: 0;

            li {
                text-indent: 0;
            }
        }

        li {
            margin-bottom: 30px;

            h3,
            p {
                @include body-s;
            }

            p {
                font-weight: $open-sans-semi-bold;
            }

            h3 {
                font-family: $lexend-family;
                font-weight: $lexend-extra-bold;
            }
        }

        li::before {
            padding-right: 0;
            font-family: $lexend-family;
            font-weight: $lexend-extra-bold;
            content: '';
        }

        ol li::before {
            padding-right: 10px;
            color: $dark-gray;

            body:not(.is-oru) & {
                color: $dark-gray;
            }
        }
    }
}

// GENERAL MODULE STYLING
.graphic-module {
    &--without-separation {
        margin-bottom: 0;
        padding-top: 0;
        padding-bottom: 0;
    }

    &--without-top-separation {
        padding-top: 0;
    }

    &--gray-background {
        background-color: $gainsboro-gray;
        padding-bottom: 50px;

        @include bp-small {
            margin: 0;
        }
    }

    &--gray-background-with-padding {
        background-color: $gainsboro-gray;
        padding-top: 50px;
        padding-right: 20px;
        padding-left: 20px;

        @include bp-small {
            margin: 0;
        }

        .article-page > &:first-child {
            margin-top: -95px;

            @include bp-small {
                margin-top: -50px;
            }
        }
    }

    &--with-margin-bottom {
        margin-bottom: 50px;
    }

    &--no-body-copy {
        padding: 0 10px 24px;

        @include bp-medium {
            padding: 0 0 24px 21.5%;
            }

    }
}
