// ============ MODIFIERS CAROUSEL =============

.slider-panel {
    &__pagination {
        bottom: 13px !important;

        @include bp-medium {
            bottom: 27px !important;
        }
    }

    .swiper-pagination-bullet {
        @include border-radius(50%);
        @include opacity(0.5);
        position: relative;
        background: $white;
        width: 4px;
        height: 4px;

        @include bp-medium {
            width: 8px;
            height: 8px;
        }

        &-active {
            @include opacity(1);
            background: $white;
            width: 7px;
            height: 7px;

            @include bp-medium {
                width: 13px;
                height: 13px;
            }
        }

        &:focus {
            @include opacity(1);
            outline: none;

            body:not(.mouse-user):not(.is-oru) &::before {
                @extend .coned-button-focus;
            }

            body:not(.mouse-user) &::before {
                @extend .oru-button-focus;
            }
        }
    }

    .slider-panel__pagination--blue-content .swiper-pagination-bullet {
        background: $blue;

        &-active {
            background: $blue;
        }
    }

    &--transparent-background {
        .swiper-pagination-bullet {
            background-color: $transparent;

            &:not(.swiper-pagination-bullet-active) {
                opacity: 1;
                border: 1px solid $dark-gray;
                width: 7px;
                height: 7px;

                @include bp-medium {
                    width: 13px;
                    height: 13px;
                }
            }

            &-active {
                background-color: $medium-gray;
            }
        }
    }
}
