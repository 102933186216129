// ============== SERVER ERROR BANNER COMPONENT =================

.server-error {
    padding: 50% 20px;
    text-align: center;
    color: $dark-gray;

    @include bp-medium {
        margin: 0 auto;
        padding: 70px 0 80px;
        width: 50%;
    }

    @include bp-large {
        padding: 200px 20px;
    }

    &--extra-width {
        @include bp-medium {
            width: 70%;
        }
    }

    &--mobile-app {
        padding: 40px 0;

        @include bp-medium {
            width: 80%;
        }
    }

    &--small-padding {
        padding: 60px 20px;

        @include bp-large {
            padding: 70px 0 80px;
        }
    }

    &-wrapper {
        margin: 0 10px;
        background-color: $white;

        @include bp-medium {
            margin: 35px auto;
        }

        &--margin-top {
            margin-top: 10px;

            @include bp-medium {
                margin: 35px auto;
            }
        }

        &--margin-bottom {
            margin-bottom: 10px;

            @include bp-medium {
                margin: 35px auto;
            }
        }

        &--mobile-app {
            @include bp-small {
                margin-right: 0;
                margin-left: 0;
            }
        }
    }

    &__picture {
        &-image {
            width: 125px;

            &--auto-width {
                width: auto;
                max-width: 100%;
            }
        }
    }

    &__title {
        @include heading-m;
        padding: 40px 0 10px;
        font-family: $lexend-family;
        font-weight: $lexend-extra-bold;
        word-wrap: break-word;

        &--mobile-app {   
            @include bp-small {
                padding-top: 20px;
            }
        }
    }

    &__text {
        @include body-m;
        font-family: $open-sans-family;

        &--margin {
            margin: 50px 0;
        }

        &--mobile-app {
            @include body-m;
        }
    }

    &__button {
        &--mobile-app {
            @extend .button;
            padding: 15px 55px;
            min-height: 50px;
            text-transform: capitalize;
        }

        &-container--mobile-app {
            margin-top: 30px;
        }
    }
}
