// ============== ENERGY SUPPLIER COMPONENT =================

.energy-supplier {
    &__header {
        @extend %flexbox;
        @include justify-content(space-between);
        @include flex-direction(column);
        margin-bottom: 30px;
        border-bottom: 2px solid $medium-gray;
        padding-bottom: 30px;

        @include bp-medium {
            @include flex-direction(row);
        }
    }

    &__navigation-by {
        @extend %flexbox;
        @include flex-direction(column);
        @include align-items(flex-start);
        @include flex-shrink(0);

        @include bp-table-tabs-nav-by {
            @include flex-direction(row);
            @include align-items(center);
        }
    }

    &__information {
        margin-bottom: 40px;
    }

    &__title {
        @include heading-l;
        margin-bottom: 10px;
        color: $dark-gray;
        font-family: $lexend-family;
        font-weight: $lexend-extra-bold;
    }

    &__list-title {
        @include heading-s;
        border-bottom: 3px solid $orange;
        padding-bottom: 15px;
        width: 100%;
        text-transform: none;
        font-family: $lexend-family;
        font-weight: $lexend-extra-bold;
    }

    &__list {
        @extend %flexbox;
        @include flex-direction(column);
        margin-top: 20px;
        width: 100%;

        @include bp-medium {
            @include flex-direction(row);
            @include flex-wrap(wrap);
            margin-top: 40px;
        }
    }

    &__item {
        @include body-m;
        margin-bottom: 20px;
        width: 100%;
        font-family: $open-sans-family;

        @include bp-medium {
            margin-right: 20px;
            margin-bottom: 35px;
            width: 45%;
        }

        strong {
            text-transform: uppercase;
            font-weight: $open-sans-bold;
        }
    }
}
