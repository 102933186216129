.landing {
    overflow: hidden;

    * {
        box-sizing: border-box;
    }

    &_main-wrapper {
        background-color: $light-gray;
        background-repeat: no-repeat;
        background-position: left top;
        background-size: 100%;
    }

    &__container {
        @extend %content-gutter;
        @include flexbox;
        width: 100%;

        &--center {
            @include align-items(center);
            @include justify-content(center);
        }

        &--v-center {
            @include align-items(center);
        }

        &--h-center {
            @include justify-content(center);
        }
    }

    &__nav {
        margin: 40px auto 50px;

        @include bp-medium {
            margin: 6px auto 50px;
        }
    }
}
