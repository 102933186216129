// // ============ TOOLTIP ===============

.coned-tooltip {
    &--centered {
        @include flexbox;
        @include align-items(center);
        margin-top: 10px;

        .manage-cards__title-container & {
            margin: 0;
        }
    }

    &--open,
    &--close {
        @extend %flexbox;
        @include justify-content(center);
        @include align-items(center);
        @include border-radius(50%);
        @include transform(rotate(180deg));
        margin-left: -3px;
        background: $transparent;
        cursor: pointer;
        color: $blue;

        &::after {
            @extend .icon-report-problem::before;
            font: calculate-rem(22) 'coned-icons';
        }
    }

    &--open {
        &:focus {
            outline: none;

            body:not(.mouse-user):not(.is-oru) &::before {
                @extend .coned-button-focus;
            }

            body:not(.mouse-user) &::before {
                @extend .oru-button-focus;
            }
        }
    }

    &--close {
        position: absolute;
        top: 8px;
        right: 1px;

        &::after {
            @extend .icon-close::before;
            font: calculate-rem(35) 'coned-icons';
        }

        &:focus {
            body:not(.mouse-user):not(.is-oru) & {
                @extend .coned-focus-outline;
            }

            body:not(.mouse-user) & {
                @extend .oru-focus-outline;
            }
        }
    }

    &--input {
        position: absolute;
        top: 40px;
        right: 20px;
    }

    &--input-single {
        position: absolute;
        top: 40px;
        right: 5px;
    }

    &__paragraph {
        display: inline;
    }

    &__list {
        display: inline-block;
        margin-left: 10px;
        font-weight: $open-sans-regular;

        .coned-tooltip--open {
            position: relative;
            background: $transparent;
            width: 22px;
            height: 22px;
        }
    }

    &__text {
        @extend %inline-flex;
        position: relative;
        margin-left: 3px;
        background: $transparent;
    }

    &--date-picker {
        position: absolute;
        top: 40px;
        right: 20px;
    }

    &__wrapper {
        @include body-xs;
        position: relative;
        width: 100%;
        overflow: hidden;
        color: $dark-gray;
        font-family: $open-sans-family;
        font-weight: $open-sans-regular;

        @include bp-medium {
            max-width: 400px;
        }

        &:focus {
            outline: none;
        }
    }

    &__content {
        padding: 38px;

        a {
            white-space: nowrap;
        }
    }

    &--edit-profile {
        @extend %inline-flex;
        @include transform(rotate(0));

        &::after {
            @extend .icon-question::before;
        }
    }

    &--full-width {
        width: 100%;
    }

    &--white-blue {
        .coned-tooltip--open,
        .coned-tooltip--close {
            color: $white;
        }
    }

    &--white-red {
        .coned-tooltip--open,
        .coned-tooltip--close {
            color: $white;
        }
    }

    &--red {
        color: $red;
    }

    .is-oru & {
        &--open,
        &--close {
            color: $teal;
        }

        &--white-red {
            .coned-tooltip--open,
            .coned-tooltip--close {
                color: $teal;
            }
        }
    }
}

.tooltip__templates {
    display: none;
}
