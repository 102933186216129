// ============== CONED TABS DROPDOWN COMPONENT =================

.coned-tabs-dropdown {
    @include box-sizing(border-box);
    position: relative;
    top: 0;
    left: 0;
    z-index: 5;
    border: solid 1px $medium-gray;
    background-color: $white;
    width: 100%;
    height: 47px;
    overflow: visible;

    &__parent {
        width: 100%;

        .coned-tabs__tab-item {
            body:not(.mouse-user):not(.is-oru) &:focus {
                @extend .coned-focus-outline;
            }

            body:not(.mouse-user) &:focus {
                @extend .oru-focus-outline;
            }

            &:focus::before {
                content: none !important;
            }
        }
    }

    &__selected-container {
        @extend %flexbox;
        @include align-items(center);
        @include justify-content(space-between);
        position: relative;
        background-color: $white;
        cursor: pointer;
        width: 100%;
        height: 100%;

        body:not(.mouse-user):not(.is-oru) &:focus {
            @extend .coned-focus-outline;
        }

        body:not(.mouse-user) &:focus {
            @extend .oru-focus-outline;
        }
    }

    &__selected {
        @extend %flexbox;
        @include align-items(center);
        margin-left: 25px;
        text-transform: uppercase;
        color: $dark-gray;
        font: $open-sans-bold calculate-rem(14) / calculate-rem(18) $open-sans-family;
    }

    &__selected-arrow {
        margin-right: 13px;
        font-size: 24px;
    }

    &__options-container {
        position: absolute;
        top: -1px;
        left: -1px;
        width: 100%;
    }

    &__options {
        border: solid 1px $medium-gray;
        background: $white;
        padding-top: 47px;
        padding-bottom: 10px;
        width: 100%;
    }

    &__options-arrow {
        @include align-self(center);
        @include transform(rotate(180deg));
        position: absolute;
        top: 20px;
        right: 13px;
        cursor: pointer;
        font-size: 24px;
    }

    &__option {
        @extend %flexbox;
        @include align-items(center);
        cursor: pointer;
        padding-left: 25px;
        height: 38px;
        text-transform: uppercase;
        color: $dark-gray;
        font: $open-sans-bold calculate-rem(14) / calculate-rem(18) $open-sans-family;

        &.active {
            cursor: default;
        }

        &.active,
        &:hover {
            color: $blue;
        }

        body:not(.mouse-user):not(.is-oru) &:focus {
            @extend .coned-focus-outline;
        }

        body:not(.mouse-user) &:focus {
            @extend .oru-focus-outline;
        }
    }

    &__selected,
    &__option {
        .coned-tabs__tab-icon {
            margin-right: 10px;
            font-size: calculate-rem(25);
        }
    }
}

.coned-tabs-dropdown--oru {
    .coned-tabs-dropdown__option {
        &.active,
        &:hover {
            color: $teal;
        }
    }
}
