// ============== SHARE COMPONENT =================

.share {
    @extend %flexbox;
    @include flex-direction(row);
    @include justify-content(center);
    @include align-items(center);

    @include bp-medium {
        @include justify-content(flex-end);
    }

    &__list {
        @extend %flexbox;
        @include flex-direction(row);
        margin: 0;
        padding: 0;
    }

    &__text {
        margin-right: 25px;
        text-transform: uppercase;
        font: calculate-rem(18) $open-sans-family;

        &--white {
            color: $white;
        }
    }

    &__item {
        margin-right: 30px;
        background-color: $black;
        width: 50px;
        height: 50px;

        @include bp-medium {
            width: 35px;
            height: 35px;
        }

        &:last-child {
            margin-right: 0;
        }
    }
}

.share-anchor {
    cursor: pointer;
    text-decoration: none;
    color: $white;
    font-size: calculate-rem(50);

    @include bp-medium {
        font-size: calculate-rem(35);
    }

    &__facebook:hover {
        @include bp-medium {
            background-color: $blue;
        }
    }

    &__twitter:hover {
        @include bp-medium {
            background-color: $twitter-blue;
        }
    }

    &__mail:hover {
        @include bp-medium {
            background-color: $black;
        }
    }
}
