// ============== TRANSACTIONAL-REVIEW COMPONENT =================

.transactional-review {
    padding-top: 30px;
    width: 100%;

    &__edit-link {
        @extend .button-link, .button-link--extra-low-emphasis;
        position: absolute !important;
        top: 20px;
        right: 0;

        @include bp-smaller {
            display: inline-flex;
            position: relative;
            top: 0;
            margin: 10px 0 0 30px;
        }

        @include bp-medium {
            top: 30px;
        }

        body:not(.mouse-user):not(.is-oru) &:focus {
            outline: none;

            &::before {
                @extend .coned-button-focus;
            }
        }

        body:not(.mouse-user) &:focus {
            outline: none;

            &::before {
                @extend .oru-button-focus;
            }
        }

        &--title {
            top: 5px;
            margin: 0 4px 0 0;

            @include bp-medium {
                margin: 10px 4px 0 0;
            }
        }

        &--help {
            @include transform(translateY(-50%));
            top: 50%;

            @include bp-smaller {
                @include transform(translateY(-50%));
                top: 0;
                margin: 20px 0 0 50px;
            }
        }

        &--icon {
            display: inline-block;
            position: relative;
            margin-left: 10px;
            width: 14px;
            height: 14px;

            &::before {
                position: absolute;
                top: -5px;
                left: -10px;
                font-size: calculate-rem(30);
            }
        }

        &--icon-rotate {
            transform: rotate(180deg);
            margin-right: 10px;
        }

        &--gray {
            width: 20px;
            color: $medium-gray;
        }

        &--post-payment {
            display: block;
            padding: 0;
        }
    }
}
