// ============== ENERGY USAGE COMPONENT =================

.energy-usage {
    background: $white;
    padding: 45px 0 20px;
    min-height: 600px;

    @include bp-medium {
        padding: 95px 0;
    }

    @include bp-billing {
        padding: 95px 0;
    }

    &-wrapper {
        margin-top: 40px;

        &--top-spacing {
            margin-top: 0;
        }
    }

    &__title {
        @include heading-l;
        margin: 0 30px;
        color: $dark-gray;
        font-family: $lexend-family;
        font-weight: $lexend-extra-bold;

        @include bp-medium {
            margin: 0 15%;
        }

        @include bp-billing {
            margin: 0 20%;
        }

        &--30-margin-bottom-mobile {
            @include bp-small {
                margin-bottom: 30px;
            }
        }

        &--50-margin-bottom-desktop {
            @include bp-medium {
                margin-bottom: 50px;
            }
        }
    }

    &__description {
        @include body-s;
        display: block;
        color: $dark-gray;
        font-family: $open-sans-family;
    }

    &__tabs {
        @extend %flexbox;
        @include flex-direction(column-reverse);
        position: relative;
        margin-bottom: 25px;
        padding: 25px 0 0;

        @include bp-medium {
            margin: 0 0 25px;
        }

        @include bp-billing-medium {
            @include flex-direction(row);
            @include justify-content(space-between);
            @include align-items(center);
            padding-bottom: 0;
        }

        &--no-padding-top {
            padding-top: 0;
        }

        &-links {
            @include bp-small {
                margin-bottom: 20px;
            }

            @include bp-billing-medium {
                margin: 0 0 0 20px;
            }
        }

        &-links--noflex {
            margin: 0 30px;
            padding-top: 25px;

            @include bp-medium {
                margin: 0 15%;
            }

            @include bp-billing {
                margin: 0 20%;
            }

            .green-data-column {
                padding: 0;
            }

            .energy-usage__tabs-link {
                display: inline-block;
                margin: 20px 0;
            }

            .energy-usage__tabs-link--estimate {
                margin-right: 20px;

                @include bp-medium {
                    margin-right: 40px;
                }
            }
        }

        &-links--row {
            @extend %flexbox;
            @include flex-direction(row);
            @include flex-shrink(0);
            margin: 0 30px;
            padding-top: 25px;

            @include bp-medium {
                margin: 0 15%;
            }

            @include bp-billing {
                margin: 0 20%;
            }

            &-no-margin-sides {
                margin-right: 0;
                margin-left: 0;
            }

            .green-data-column {
                padding: 0;
            }

            .energy-usage__tabs-link--estimate {
                margin-right: 20px;

                @include bp-medium {
                    margin-right: 40px;
                }
            }
        }

        &-links--column {
            @extend %flexbox;
            @include flex-direction(column);
            @include flex-shrink(0);
        }

        &-link {
            @extend .button-link, .button-link--extra-low-emphasis;
            display: block;

            &-icon {
                @extend .button-icon;
                margin-right: 25px;
                font-size: calculate-rem(30);
            }

            &-icon::before {
                position: absolute;
                top: 6px;
                left: -5px;
            }
        }
    }

    .coned-tabs {
        @include flex-shrink(0);

        &__tab {
            padding: 0 20px 0 0;
        }

        &__widget--zero-z-index {
            position: relative;
            z-index: 0;
        }
    }

    .coned-tabs__section {
        @include bp-primary-nav-small {
            margin: 0 20px;
        }

        @include bp-medium {
            margin: 0 15%;
        }

        @include bp-opower-widget {
            margin: 0 15%;
        }

        @include bp-billing {
            margin: 0 20%;
        }

        &--margin-top {
            margin-top: 20px;

            @include bp-medium {
                margin-top: 35px;
            }
        }

        &--no-margin {
            margin: 0;
        }

        &-widget {
            z-index: 0;
            margin: 0;
            min-height: 145px;

            .service-unavailable-error {
                padding-top: 60px;
                text-align: center;
                line-height: 145px;
                font: calculate-rem(14) / calculate-rem(18) $open-sans-family;
            }
        }
    }
}
