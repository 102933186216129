// ============== UTILS =================

// TRANSITIONS
@mixin transition($args...) {
    -webkit-transition: $args;
    -moz-transition: $args;
    -ms-transition: $args;
    -o-transition: $args;
    transition: $args;
}

@mixin transition-delay($delay) {
    -webkit-transition-delay: $delay;
    -moz-transition-delay: $delay;
    -ms-transition-delay: $delay;
    -o-transition-delay: $delay;
    transition-delay: $delay;
}

/*
 Usage:

a {
  color: gray;
  @include transition(color .3s ease);
  &:hover {
    color: black;
  }
}

*/

// TRANSFORMS
@mixin transform($transforms) {
    -moz-transform: $transforms;
    -o-transform: $transforms;
    -ms-transform: $transforms;
    -webkit-transform: $transforms;
    transform: $transforms;
}

@mixin transform-origin($transforms) {
    -moz-transform-origin: $transforms;
    -o-transform-origin: $transforms;
    -ms-transform-origin: $transforms;
    -webkit-transform-origin: $transforms;
    transform-origin: $transforms;
}

// TRANSFORMS
@mixin transform-important($transforms) {
    -moz-transform: $transforms !important;
    -o-transform: $transforms !important;
    -ms-transform: $transforms !important;
    -webkit-transform: $transforms !important;
    transform: $transforms !important;
}

/*
 Usage

.rotate135 {
    @include transform(rotate(135deg));
}
*/

// PLACEHOLDER
@mixin placeholder {
    &::placeholder {
        @content;
    }

    &::-ms-input-placeholder {
        @content;
    }

    &::-webkit-input-placeholder {
        @content;
    }

    &::-moz-input-placeholder {
        @content;
    }
}

// PLACEHOLDER
@mixin selection {
    &::selection {
        @content;
    }

    &::-ms-selection {
        @content;
    }

    &::-webkit-selection {
        @content;
    }

    &::-moz-selection {
        @content;
    }
}

/*
 Usage

.class {
    @include placeholder {
        color: $blue;
    }
}
*/

// CROSS BROWSER OPACITY
@mixin opacity($opacity) {
    $opacity-ie: $opacity * 100;
    filter: alpha(opacity = $opacity-ie); // IE8
    -khtml-opacity: $opacity; // Safari 1.x
    opacity: $opacity; // Good browsers
}

/*
 Usage

.faded-text {
  @include opacity(0.8);
}

*/

// BOX SIZING
@mixin box-sizing($box-model) {
    -webkit-box-sizing: $box-model; // Safari <= 5
    -moz-box-sizing: $box-model; // Firefox <= 19
    box-sizing: $box-model;
}

/*
 Usage

    *,
    *:after,
    *:before {
        @include box-sizing(border-box);
    }
*/

// BORDER RADIUS
@mixin border-radius($radius) {
    -webkit-border-radius: $radius;
    border-radius: $radius;
    background-clip: padding-box; /* stops bg color from leaking outside the border: */
}

// Single side border-radius
@mixin border-top-radius($radius) {
    -webkit-border-top-right-radius: $radius;
    border-top-right-radius: $radius;
    -webkit-border-top-left-radius: $radius;
    border-top-left-radius: $radius;
    background-clip: padding-box;
}

@mixin border-right-radius($radius) {
    -webkit-border-bottom-right-radius: $radius;
    border-bottom-right-radius: $radius;
    -webkit-border-top-right-radius: $radius;
    border-top-right-radius: $radius;
    background-clip: padding-box;
}

@mixin border-bottom-radius($radius) {
    -webkit-border-bottom-right-radius: $radius;
    border-bottom-right-radius: $radius;
    -webkit-border-bottom-left-radius: $radius;
    border-bottom-left-radius: $radius;
    background-clip: padding-box;
}

@mixin border-left-radius($radius) {
    -webkit-border-bottom-left-radius: $radius;
    border-bottom-left-radius: $radius;
    -webkit-border-top-left-radius: $radius;
    border-top-left-radius: $radius;
    background-clip: padding-box;
}

/*
 Usage

.button {
    @include border-radius(5px);
}

.submit-button {
    @include border-top-radius(10px);
    @include border-right-radius(8px);
    @include border-bottom-radius(10px);
    @include border-left-radius (6px);
}
*/

// LINEAR GRADIENT
@mixin linear-gradient($start-vpoint, $start-hpoint, $end-vpoint, $start-color, $start-opacity, $end-color, $end-opacity) {
    background: -moz-linear-gradient($start-vpoint, $start-color $start-opacity, $end-color $end-opacity);
    background: -webkit-gradient(
        $start-hpoint $start-vpoint,
        $start-hpoint $end-vpoint,
        color-stop($start-opacity, $start-color),
        color-stop($end-opacity, $end-color)
    );
    background: -webkit-linear-gradient($start-vpoint, $start-color $start-opacity, $end-color $end-opacity);
    background: -o-linear-gradient($start-vpoint, $start-color $start-opacity, $end-color $end-opacity);
    background: -ms-linear-gradient($start-vpoint, $start-color $start-opacity, $end-color $end-opacity);
    background: linear-gradient(to $end-vpoint, $start-color $start-opacity, $end-color $end-opacity);
}

/*
 Usage

.abs {
    @include linear-gradient(top, left, bottom, $transparent, 0%, $dark-gray-transparent, 70%);
}

*/

@mixin linear-gradient-two-breaks(
    $start-vpoint,
    $start-hpoint,
    $end-vpoint,
    $start-color,
    $start-opacity,
    $break-one-color,
    $break-one-opacity,
    $break-two-color,
    $break-two-opacity,
    $end-color,
    $end-opacity
) {
    background: -moz-linear-gradient($start-vpoint, $start-color $start-opacity, $end-color $end-opacity);
    background: -webkit-gradient(
        $start-hpoint $start-vpoint,
        $start-hpoint $end-vpoint,
        color-stop($start-opacity, $start-color),
        color-stop($break-one-color $break-one-opacity),
        color-stop($break-two-color $break-two-opacity),
        color-stop($end-opacity, $end-color)
    );
    background: -webkit-linear-gradient(
        $start-vpoint,
        $start-color $start-opacity,
        $break-one-color $break-one-opacity,
        $break-two-color $break-two-opacity,
        $end-color $end-opacity
    );
    background: -o-linear-gradient(
        $start-vpoint,
        $start-color $start-opacity,
        $break-one-color $break-one-opacity,
        $break-two-color $break-two-opacity,
        $end-color $end-opacity
    );
    background: -ms-linear-gradient(
        $start-vpoint,
        $start-color $start-opacity,
        $break-one-color $break-one-opacity,
        $break-two-color $break-two-opacity,
        $end-color $end-opacity
    );
    background: linear-gradient(
        to $end-vpoint,
        $start-color $start-opacity,
        $break-one-color $break-one-opacity,
        $break-two-color $break-two-opacity,
        $end-color $end-opacity
    );
}

/*
 Usage

.abs {
    @include linear-gradient-two-breaks(top, left, bottom, $transparent, 0%, $transparent, 20%, $dark-gray-transparent, 70%, $dark-gray-transparent, 100%);
}

*/

@mixin linear-gradient-degrees($degrees, $start-color, $start-position, $end-color, $end-position) {
    background: -moz-linear-gradient($degrees, $start-color $start-position, $end-color $end-position);
    background: -webkit-gradient($degrees, color-stop($start-position, $start-color), color-stop($end-position, $end-color));
    background: -webkit-linear-gradient($degrees, $start-color $start-position, $end-color $end-position);
    background: -o-linear-gradient($degrees, $start-color $start-position, $end-color $end-position);
    background: -ms-linear-gradient($degrees, $start-color $start-position, $end-color $end-position);
    background: linear-gradient($degrees, $start-color $start-position, $end-color $end-position);
}

/*
 Usage

.abs {
    @include linear-gradient-degrees(91deg, $green, 50%, $light-gray, 50%);
}

*/

// ABSOLUTE POSITIONING
@mixin abs-pos($top: auto, $right: auto, $bottom: auto, $left: auto) {
    top: $top;
    right: $right;
    bottom: $bottom;
    left: $left;
    position: absolute;
}

/*
 Usage

.abs {
    @include abs-pos(10px, 10px, 5px, 15px);
}

*/

// MAINTAIN ASPECT RATIO
@mixin aspect-ratio($width, $height, $content-class) {
    position: relative;

    &::before {
        display: block;
        padding-top: ($height / $width) * 100%;
        width: 100%;
        content: '';
    }

    .#{$content-class} {
        position: absolute;
        top: 0;
        left: 0;
    }
}

/*
 Usage

.sixteen-nine {
    @include aspect-ratio(16, 9, 'content');
}

*/

// FONT-FACE
// Used for the different site font's addition
@mixin font-face($font-family) {
    @font-face {
        font-family: '#{$font-family}';
        src: /* Pretty Modern Browsers */ url('../fonts/#{$font-family}.woff2') format('woff2'),
            /* Safari, Android, iOS */ /* IE9 Compat Modes */ url('../fonts/#{$font-family}.woff') format('woff');
    }
}

/*
 Usage

@include font-face(Antenna-Bold);

*/

// CALCULATE REM
// This function returns the font size in rem.
// Reasons to use rem: https://css-tricks.com/rems-ems/
@function calculate-rem($size-value: $default-font-size) {
    $rem-size: $size-value / $default-font-size;
    @return #{$rem-size}rem;
}

/*
 Usage

body {
    font-size: calculate-rem(12);
}

*/

// CENTER BLOCK
%center-block {
    display: block;
    margin-right: auto;
    margin-left: auto;
}

/*
 Usage

.footer-wrap {
    width: 450px;
    @extend %center-block;
}
*/

// TEXT OVERFLOW
%text-truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

%text-truncate-wrap {
    overflow: hidden;
    text-overflow: ellipsis;
}

@mixin text-truncate-wrap {
    overflow: hidden;
    text-overflow: ellipsis;
}

/*
 Usage

.text-truncate {
    @extend %text-truncate;
}
*/

// GUTTER
@mixin desktop-gutter {
    margin: 0 auto;
    padding: 0 20px;
    max-width: $desktop-container-width;
}

@mixin mobile-gutter {
    padding: 0 20px;
}

// BOX SHADOW
@mixin box-shadow($top, $left, $blur, $size, $color, $inset: false) {
    @if $inset {
        -moz-box-shadow: inset $top $left $blur $size $color;
        -webkit-box-shadow: inset $top $left $blur $size $color;
        box-shadow: inset $top $left $blur $size $color;
    } @else {
        -moz-box-shadow: $top $left $blur $size $color;
        -webkit-box-shadow: $top $left $blur $size $color;
        box-shadow: $top $left $blur $size $color;
    }
}

/*
 Usage

.box-shadow {
    @include box-shadow(1px,1px,1px,0, #fff, true);
}
*/

// USER SELECT
@mixin user-select($select) {
    -webkit-touch-callout: $select;
    -webkit-user-select: $select;
    -khtml-user-select: $select;
    -moz-user-select: $select;
    -ms-user-select: $select;
    user-select: $select;
}

/*
 Usage

.user-select {
    @include user-select(none);
}
*/

// WEB KIT APPARENCE
@mixin webkit-apparence($value) {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

/*
 Usage

.webkit-apparence {
    @include webkit-apparence(none);
}
*/
