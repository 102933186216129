// ============== PREFERENCE CENTER COMPONENT =================
.preference-center {
    &__title,
    &__subtitle {
        text-align: left;
    }

    &__title {
        margin-bottom: 40px;

        @include bp-medium {
            margin-bottom: 50px;
        }
    }

    &__subtitle {
        line-height: calculate-rem(26);
        font-size: calculate-rem(24);
    }

    &__description {
        margin-top: 15px;
        border-top: 3px solid $medium-gray;
        padding: 15px 0;
    }

    &__form {
        margin-bottom: 35px;
    }

    &__notifications-header {
        @extend %flexbox;
        @include align-items(flex-end);
        margin-top: 15px;
        border-top: 3px solid $medium-gray;
        padding: 15px 0;

        @include bp-medium {
            margin-top: 30px;
        }
    }

    &__header {
        &-email,
        &-phone {
            @include flexbox;
            @include justify-content(space-between);
            @include align-items(center);
            border-bottom: 1px solid $medium-gray;
        }
    }
}

.notifications {
    &__header-title {
        width: 100%;

        @include bp-medium {
            margin-right: 20px;
        }
    }

    &__headers-container {
        display: block;

        @include bp-medium {
            @include flexbox;
        }
    }

    &__header {
        &-email,
        &-phone {
            @include flexbox;
            @include align-items(baseline);

            @include bp-medium {
                width: 50%;
            }
        }

        &-email {
            padding-bottom: 10px;
        }
    }

    &__title {
        display: inline-block;
        margin-right: 10px;
        font: $open-sans-bold calculate-rem(30) / calculate-rem(32) $open-sans-family;

        @include bp-smaller {
            word-break: normal;
        }

        &-type {
            text-transform: uppercase;
            font-size: calculate-rem(18);
        }

        &-check-type {
            text-transform: uppercase;
            font-size: calculate-rem(14);
            font-weight: bold;
        }
    }

    &__notification-type {
        font: $lexend-extra-bold calculate-rem(20) $lexend-family;
    }

    &__description {
        @extend %text-truncate-wrap;
        margin-right: 5px;
        color: $medium-gray;
        font: $open-sans-semi-bold calculate-rem(13) / 1.5em $open-sans-family;

        @include bp-smaller {
            word-break: normal;
        }

        &--option {
            color: $dark-gray;
        }

        &--multiple-option {
            padding-top: 20px;
        }
    }

    &__description--email {
        @include bp-smaller {
            width: 80%;
            word-break: break-all;
        }
    }

    &__description--disclaimer {
        font: $open-sans-light calculate-rem(13) / calculate-rem(16) $open-sans-family;

        @include bp-medium {
            width: 90%;
        }

        @include bp-smaller {
            width: 80%;
            word-break: normal;
        }
    }
}

.notification {
    &__title-container {
        @extend %flexbox;
        @include align-items(center);

        @include bp-medium {
            @include justify-content(flex-end);
        }
    }

    &__item {
        margin-top: 20px;
        border-top: 3px solid $medium-gray;
        padding: 20px 0;
    }

    &__item--last {
        border-bottom: 0;
    }

    &__description-container {
        @extend %flexbox;
        @include align-items(center);
        border-bottom: 1px solid $medium-gray;
        padding-bottom: 20px;
    }

    &__checkbox-title {
        margin-bottom: 5px;
        border-bottom: 1px solid $alto2;
        padding-bottom: 5px;
        font: $open-sans-semi-bold calculate-rem(13) / calculate-rem(16) $open-sans-family;
    }
}
