// ============== ENROLL UNITS CONFIRMATION =================

.enroll-units-confirmation {
    margin: 10px 10px 33px;
    background: $white;
    padding: 45px 30px 43px;

    @include bp-medium {
        margin: 30px 0 20px;
        padding: 95px 15% 40px;
    }

    &__header {
        border-bottom: 2px solid $alto2;
        padding-bottom: 20px;

        @include bp-medium {
            padding-bottom: 27px;
        }

        &-title {
            @include heading-m;
            letter-spacing: 0.5px;
            color: $dark-gray;
            font-family: $lexend-family;
            font-weight: $lexend-extra-bold;

            @include bp-medium {
                letter-spacing: 0.8px;
            }
        }
    }

    &__info {
        border-bottom: 1px solid $light-gray;
        padding: 23px 0 29px;

        @include bp-medium {
            padding: 36px 0 30px;
        }

        &-title,
        &-text {
            @include body-l;
            display: inline;
            color: $dark-gray;
            font-family: $open-sans-family;
        }

        &-title {
            font-weight: $open-sans-bold;
        }

        &-section {
            display: block;
            padding-bottom: 20px;

            @include bp-medium {
                padding-bottom: 5px;
            }

            &:last-child {
                padding: 0;
            }
        }
    }

    &__list {
        border-bottom: 1px solid $light-gray;
        padding: 23px 0 29px;

        @include bp-medium {
            padding: 36px 0 41px;
        }

        &-text,
        &-title,
        &-show {
            @include body-l;
            display: block;
            color: $dark-gray;
            font-family: $open-sans-family;
        }

        &-text {
            @extend %text-truncate;
            width: 100%;
            max-width: 700px;

            &--visible {
                white-space: normal;
            }
        }

        &-title {
            font-weight: $open-sans-bold;
        }

        &-show {
            color: $blue;
            font-weight: $open-sans-bold;

            @include bp-medium {
                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }

    &__bottom-container {
        @include flexbox();
        @include justify-content(center);
        @include flex-direction(column);
        padding: 28px 0 0;

        @include bp-medium {
            @include flex-direction(row);
            @include align-items(center);
        }
    }

    &__cancel {
        @extend .button-link, .button-link--primary-color;
        display: block;
        margin-bottom: 25px;
        width: 100%;
        text-align: center;

        @include bp-medium {
            margin-bottom: 0;
            width: 130px;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    &__button {
        @extend .button;
    }

    &__terms {
        @include body-l;
        display: inline;
        color: $dark-gray;
        font-family: $open-sans-family;

        &-container {
            display: block;
            padding-top: 28px;

            @include bp-medium {
                text-align: center;
            }
        }

        &-link {
            @include body-l;
            display: inline;
            font-family: $open-sans-family;
            font-weight: $open-sans-bold;
        }
    }
}
