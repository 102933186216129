// ============== RECAPTCHA COMPONENT =================

.recaptcha {
    &-wrapper {
        position: relative;
        margin: 20px 0;
        max-width: 260px;
    }

    &-container {
        position: relative;
        height: 78px;
    }

    &-container--center {
        margin-top: 60px;
        max-width: 100%;

        .g-recaptcha > div {
            margin: 10px auto;
            width: auto;
            height: auto;
            text-align: center;
        }

        .recaptcha__error-wrapper {
            text-align: center;
        }
    }

    &__error-wrapper {
        display: none;
        margin-top: 10px;
    }

    &__message--error {
        @extend .icon-more-info;
        @include align-items(center);
        position: relative;
        margin: 10px 0;
        padding-left: 30px;
        color: $red;
        font: calculate-rem(13) $open-sans-family;
        font-weight: $open-sans-semi-bold;

        &::before {
            @extend %flexbox;
            @include border-radius(50%);
            @include justify-content(center);
            @include align-items(center);
            position: absolute;
            left: 0;
            background-color: $red;
            width: 23px;
            height: 23px;
            color: $white;
            font-family: 'coned-icons';
        }
    }

    &-error-wrapper {
        padding: 10px 0;
    }

    &__loading--error {
        max-width: 100%;
        text-transform: none;
        color: $red;
        font: $open-sans-semi-bold calculate-rem(16) $open-sans-family;
    }
}
