// ============ ENERGY SHARE ===============

.energy-share {
    margin: 0 auto;
    padding-top: 45px;
    width: 100%;

    &__title {
        @include heading-m;
        font-family: $lexend-family;
        font-weight: $lexend-extra-bold;
    }

    &__paragraph {
        padding: 30px 0;

        > p {
            @include body-m;
            padding: 10px 0 0;
            color: $medium-gray;
            font-family: $open-sans-family;
        }
    }

    &__image {
        width: 100%;
        max-width: 397px;
    }

    &--no-top-padding {
        padding-top: 0;
    }
}
