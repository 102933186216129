// ============== REPORT TABLE =================
.report-table {
    background-color: $white;

    &__header-wrapper {
        position: relative;
        padding: 45px 20px 20px;

        @include bp-medium {
            padding: 60px 5% 0;
        }
    }

    &__title {
        @include heading-l;
        margin-bottom: 20px;
        border-bottom: 2px solid $alto;
        padding-bottom: 60px;
        color: $dark-gray;
        font-family: $lexend-family;
        font-weight: $lexend-extra-bold;

        @include bp-medium {
            padding-bottom: 20px;
        }
    }

    &__navigation-by {
        @extend %flexbox;
        @include button-s;
        @include align-items(center);
        position: absolute;
        bottom: 50px;
        color: $dark-gray;
        font-family: $open-sans-family;

        @include bp-medium {
            right: 5%;
            bottom: 10px;
        }
    }

    &__navigation-by-item {
        @include flexbox;
        @include button-s;
        position: relative;
        background-color: transparent;
        padding: 5px;
        color: $silver-chalice;
        font-weight: $open-sans-extra-bold;

        &:hover {
            text-decoration: underline;
        }

        &::after {
            display: inline-block;
            background-color: $dark-gray;
            width: 2px;
            height: 18px;
            content: '';
        }

        &:last-child::after {
            display: none;
        }

        &--active {
            color: $dark-gray;

            &:hover {
                text-decoration: none;
            }
        }
    }

    &__navigation-by-text {
        padding-right: 8px;
    }

    &__expand-all {
        @extend .button-link, .button-link--extra-low-emphasis;
        position: absolute;
        top: -84px;
        right: 0;

        @include bp-medium {
            top: 0;
        }
    }

    &__table {
        @include bp-medium {
            padding: 0 5% 95px;
        }

        &-list {
            margin-top: -30px;

            @include bp-medium {
                margin-top: 0;
            }
        }

        &-titles {
            @extend %flexbox;
            @include flex-flow(row wrap);
            @include align-items(center);
            position: relative;
            margin: 0 20px;
            padding: 10px 0;

            @include bp-medium {
                margin: 0;
                border-bottom: 2px solid $orange;
            }

            &--mobile {
                display: none;
                margin-top: 20px;
                border-top: 1px solid $medium-gray;
                width: 100%;
            }
        }

        &-title {
            @include body-xs;
            @include flex-basis(auto);
            @include box-sizing(border-box);
            display: none;
            padding-right: 5px;
            width: calc(100% / 5);
            text-transform: uppercase;
            font-family: $open-sans-family;
            font-weight: $open-sans-bold;

            @include bp-report-expand {
                width: calc(100% / 6);
            }

            @include bp-medium {
                display: inline-block;
            }

            span {
                display: none;

                @include bp-medium {
                    display: inline-block;
                }
            }
        }

        &-titles--hourly {
            text-align: center;
        }

        &-title--hourly {
            width: calc(100% / 2);

            @include bp-medium {
                width: calc(100% / 5);
            }

            @include bp-report-expand {
                width: calc(100% / 5);
            }
        }

        &-item {
            @extend %flexbox;
            @include body-xs;
            @include transition(all, 0.2s, ease);
            @include flex-flow(column wrap);
            @include align-items(center);
            position: relative;
            margin-bottom: 30px;
            border-bottom: 2px solid $light-gray;
            padding: 10px 20px;
            font-family: $open-sans-family;

            @include bp-medium {
                @include flex-direction(row);
                margin-bottom: 0;
                padding: 30px 0;
            }

            &:last-child {
                border: 0;
            }
        }

        &-item-description {
            @extend %flexbox;
            @include align-items(center);
            display: none;
            margin: 5px 0;
            width: 100%;
        }

        &-item-description--first {
            @include flexbox;
        }

        &-item-description--mobile {
            @include flexbox;
            width: 100%;

            @include bp-medium {
                display: none;
            }
        }

        &-text {
            @include opacity(0);
            display: none;
            padding-right: 10px;
            width: calc(100% / 5);
            text-align: left;

            @include bp-report-expand {
                width: calc(100% / 6);
            }

            @include bp-medium {
                display: block;
            }
        }

        &-text--price {
            display: none;
            font-weight: $open-sans-extra-bold;
        }

        &-text--date,
        &-text--total,
        &-text--mobile {
            @include body-l;
            font-family: $lexend-family;
            font-weight: $lexend-extra-bold;

            @include bp-medium {
                @include opacity(1);
                display: block;
                width: calc(100% / 5);
            }

            @include bp-report-expand {
                width: calc(100% / 6);
            }
        }

        &-text--mobile {
            @include body-l;
            width: calc(100% / 2);
            font-family: $lexend-family;
            font-weight: $lexend-extra-bold;
        }

        &-text--hourly {
            @include justify-content(center);
            width: calc(100% / 2);

            @include bp-medium {
                @include justify-content(space-between);
                width: calc(100% / 5);
            }
        }

        &-links-wrapper {
            display: none;
            margin-top: 20px;
            width: 100%;

            @include bp-medium {
                @include flex-direction(column);
                position: absolute;
                top: 80px;
                width: calc(100% / 5);
            }

            @include bp-report-expand {
                width: calc(100% / 6);
            }
        }

        &-link-item {
            @include button-s;
            margin-right: 5px;
            width: calc(100% / 2);
            color: $orange;
            font-family: $open-sans-family;
            font-weight: $open-sans-extra-bold;

            @include bp-medium {
                margin: 0;
                width: 100%;
            }

            @include bp-report-expand {
                margin-bottom: 5px;
                width: calc(100% / 6);
            }

            &:hover {
                text-decoration: underline;
            }
        }
    }

    &__arrow {
        @include transition(all, 0.2s, ease);
        @include order(2);
        position: absolute;
        top: 6px;
        right: 10px;
        cursor: pointer;
        color: $silver-chalice;
        font-size: calculate-rem(40);

        @include bp-medium {
            top: 31px;
            right: 0;
        }

        &:hover {
            color: $orange;
        }
    }
}

.table-item--open {
    .report-table__arrow {
        @include transform(rotate(180deg));
    }

    .report-table__table-item-description,
    .report-table__table-text,
    .report-table__table-links-wrapper,
    .report-table__table-titles--mobile {
        @include opacity(1);
        @include flexbox;
    }

    .report-table__table-title,
    .report-table__table-text--total {
        display: inline-block;
    }

    .report-table__table-text-first-item {
        display: none;
    }

    .report-table__table-titles--mobile {
        @include bp-medium {
            display: none;
        }
    }

    .report-table__table-text--hourly.report-table__table-text--date {
        display: none;

        @include bp-medium {
            @include flexbox;
        }
    }
}
