// ============ CHECKBOX ===============

.coned-checkbox {
    @include transition(all, 0.2s, ease);
    display: inline-block;
    position: relative;
    border: 2px solid $medium-gray;
    background-color: $white;
    cursor: pointer;
    padding: 20px 25px;
    color: $medium-gray;
    font: calculate-rem(16) / calculate-rem(30) $open-sans-family;
    font-weight: $open-sans-regular;

    @include bp-medium {
        display: inline-block;
        padding: 10px 15px;
    }

    &--gray {
        background-color: $light-gray;
    }

    &--checked {
        border-color: $green;
        background-color: $green;

        .coned-checkbox__indicator::after {
            @include opacity(1);
            color: $white;
        }
    }

    &--focus {
        &::before {
            position: absolute;
            top: -6px;
            left: -6px;
            z-index: 1;
            padding: 4px;
            width: 100%;
            height: 100%;
            content: '';

            body:not(.mouse-user):not(.is-oru) & {
                @extend .coned-focus-outline;
            }

            body:not(.mouse-user) & {
                @extend .oru-focus-outline;
            }
        }
    }

    &--auto {
        .coned-checkbox__input:checked ~ .coned-checkbox__indicator::after {
            @include opacity(1);
            color: $white;
        }
    }

    &__container {
        &--flex {
            @include flexbox;
            @include align-items(center);

            &-centered {
                @include justify-content(center);
            }
        }
    }

    &__text {
        @include body-m;
        padding-left: 10px;
        color: $medium-gray;
        font-family: $open-sans-family;
        font-weight: $open-sans-semi-bold;

        &--black {
            color: $black;
        }

        a {
            body:not(.mouse-user):not(.is-oru) &:focus {
                @extend .coned-focus-outline;
            }

            body:not(.mouse-user) &:focus {
                @extend .oru-focus-outline;
            }  

            &:focus::before {
                content: none !important;
            }
        }
    }

    &__input {
        @include opacity(0);
        position: absolute;
        z-index: 100;

        &--equal-hierarchy {
            width: 70px;
            height: 71px;

            @include bp-medium {
                width: 53px;
                height: 53px;
            }
        }

        &--on-focus {
            &:focus + label {
                outline: 2px solid $green;
                border: 1px solid $green;
            }
        }
    }

    &__indicator {
        display: inline-block;
        position: relative;
        width: 20px;
        height: 30px;
        vertical-align: middle;
        font-size: calculate-rem(1);

        &::after {
            @extend .icon-check-status::before;
            @include transform(translate(-70%, -65%));
            @include transition(all 0.2s ease);
            @include opacity(0);
            display: block;
            position: relative;
            top: 30%;
            left: 0;
            z-index: 1;
            width: 20px;
            height: 30px;
            color: $medium-gray;
            font: calculate-rem(50) 'coned-icons';

            @include bp-medium {
                @include transform(translate(-50%, -55%));
                font-size: calculate-rem(40);
            }
        }
    }

    &--small {
        padding: 3px 8px;

        .coned-checkbox__indicator::after {
            @include transform(translate(-25%, -35%));
            font-size: calculate-rem(30);
        }

        ~ .coned-checkbox__text {
            padding-left: 30px;
        }
    }

    /* Disabled state */
    &__input:disabled ~ .coned-checkbox__indicator {
        pointer-events: none;
    }

    /* Show check mark */
    &__input:checked ~ .coned-checkbox__indicator::after {
        display: block;
        color: $white;
    }
}
