// ============== COLLAPSIBLE CONTAINER =================

.collapsible-container {
    &-wrapper {
        margin: 0 10px;
        background-color: $white;

        @include bp-medium {
            margin: 0 auto;
        }
    }

    &__item {
        border-bottom: 1px solid $medium-gray;

        &-button {
            position: relative;
            background-color: $white;
            padding: 55px 20px;
            width: 100%;
            text-align: left;
            color: $dark-gray;
            font: $lexend-extra-bold calculate-rem(20) / calculate-rem(30) $lexend-family;

            @include bp-medium {
                padding: 60px 70px 60px 21.5%;
            }
        }

        &-button:hover {
            background-color: $alabaster;
        }

        &-button-text {
            @include heading-m;
            display: inline-block;
            width: 95%;
            height: auto;
            text-align: left;

            @include bp-medium {
                max-width: 690px;
            }
        }

        &-icon {
            position: absolute;
            top: 40%;
            right: 10px;
            color: $medium-gray;
            font-size: calculate-rem(40);

            @include bp-medium {
                right: 60px;
            }
        }

        &-link {
            display: inline-block;
            padding-top: 40px;

            @include bp-medium {
                padding-top: 20px;
            }
        }
    }

    &__item-button {
        body:not(.mouse-user):not(.is-oru) &:focus {
            outline: none;

            &::before {
                @extend .coned-button-focus;
            }
        }

        body:not(.mouse-user) &:focus {
            outline: none;

            &::before {
                @extend .oru-button-focus;
            }
        }
    }

    &__item--open {
        .collapsible-container__item-button {
            @include bp-medium {
                padding-bottom: 35px;
            }
        }

        .collapsible-container__item-button:hover {
            background-color: $white;
        }

        .collapsible-container__item-icon {
            @include transform(rotate(180deg));
        }

        .collapsible-container__item-content {
            @include opacity(1);
            visibility: visible;
            padding-top: 5px;
            padding-bottom: 55px;
            height: auto;
        }
    }
}

.collapsible-container__item-content {
    @include transition(all 0.2s ease);
    @include opacity(0);
    @include body-l;
    visibility: hidden;
    padding: 5px 20px;
    height: 0;
    overflow: hidden;
    color: $dark-gray;
    font-family: $open-sans-family;

    @include bp-medium {
        padding: 0 0 0 21.5%;
        width: 50%;
    }

    &--wider {
        @include bp-medium {
            width: 60%;
        }
    }

    p:not(:last-child) {
        margin-bottom: 15px;
    }

    ul:not(.collapsible-container__list--no-ul-styling),
    ol:not(.collapsible-container__list--no-ul-styling) {
        @include body-m;
        padding: 20px 0 0 20px;
        list-style: none;
        font-family: $open-sans-family;
        font-weight: $open-sans-semi-bold;

        @include bp-medium {
            padding-left: 14%;
        }
    }

    ul:not(:last-child):not(.collapsible-container__list--no-ul-styling),
    ol:not(:last-child):not(.collapsible-container__list--no-ul-styling) {
        margin-bottom: 15px;
    }

    ul:not(.collapsible-container__list--no-ul-styling) li {
        text-indent: -1.1em;
    }

    ol:not(.collapsible-container__list--no-ul-styling) {
        counter-reset: elementcounter;

        li {
            text-indent: -1.6em;

            &::before {
                content: counter(elementcounter) '.';
                counter-increment: elementcounter;
            }
        }
    }

    ul:not(.collapsible-container__list--no-ul-styling) li,
    ol:not(.collapsible-container__list--no-ul-styling) li {
        margin-bottom: 20px;

        &::before {
            padding-right: 10px;
            color: $blue;
            content: '•';
        }
    }

    sup {
        vertical-align: super;
        font-size: 0.75em;
    }

    a:focus {
        body:not(.mouse-user):not(.is-oru) & {
            @extend .coned-focus-outline;
        }

        body:not(.mouse-user) & {
            @extend .oru-focus-outline;
        }

        &::before {
            content: none !important;
        }
    }
}

.collapsible-container--oru {
    .collapsible-container__item-content li::before {
        color: $teal;
    }
}
