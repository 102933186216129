// ============== INFOGRAPHIC COMPONENT =================

.infographic {
    padding: 0 20px 50px;
    text-align: center;

    @include bp-medium {
        padding: 0 0 95px;
    }

    &-wrapper {
        background-color: $white;
    }

    &__title {
        @include heading-m;
        text-align: left;
        color: $dark-gray;
        font-family: $lexend-family;
        font-weight: $lexend-extra-bold;

        @include bp-medium {
            padding-left: 21.5%;
            width: 50%;
        }
    }

    &__description {
        padding-top: 28px;
        text-align: left;
        font: calculate-rem(18) / calculate-rem(26) $open-sans-family;
        word-break: break-word;

        @include bp-medium {
            display: none;
        }
    }

    &__picture-image {
        margin-bottom: 63px;
        border-bottom: 1px solid $medium-gray;
        padding: 28px 0 38px;
        width: 100%;

        @include bp-medium {
            margin-bottom: 0;
            border-bottom: 0;
            padding: 86px 0;
        }
    }

    &__hotspots {
        @include bp-medium {
            @include flexbox;
            @include flex-flow(row wrap);
            padding: 0 0 0 21.5%;
            width: 60%;
        }
    }

    &__hotspot {
        @include bp-medium {
            display: inline-block;
            margin-right: 4%;
            width: 46%;
        }

        &:not(:last-child) {
            margin-bottom: 63px;

            @include bp-medium {
                margin-bottom: 36px;
            }
        }

        &:nth-child(2n) {
            margin-right: 0;
        }

        &-picture-image {
            width: 100%;

            @include bp-medium {
                display: none;
            }
        }

        &-title {
            @include body-s;
            margin-top: 28px;
            text-align: left;
            text-transform: uppercase;
            color: $dark-gray;
            font-family: $open-sans-family;
            font-weight: $open-sans-extra-bold;

            @include bp-medium {
                margin-top: 0;
            }
        }

        &-text {
            @include body-s;
            text-align: left;
            color: $dark-gray;
            font-family: $open-sans-family;
        }
    }
}
