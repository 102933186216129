// ============== IMAGE MODAL COMPONENT =================

.image-modal {
    @extend %flexbox;
    @include justify-content(center);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999999;
    background-color: $black-almost-transparent;
    padding: 30px;
    width: calc(100vw - 60px);
    height: calc(100vh - 60px);

    @include bp-medium {
        padding: 60px;
        width: calc(100vw - 120px);
        height: calc(100vh - 120px);
    }
    
    &-image-container {
        margin: auto;
        width: 100%;
        height: 100%;
        overflow: auto;

        img {
            display: block;
            margin: auto;
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }

    &-close {
        position: fixed;
        top: 0;
        right: 0;
        z-index: 999;
        background-color: transparent;
        width: auto;
        height: auto;
        color: $white;
        font-size: calculate-rem(40);

        @include bp-medium {
            font-size: calculate-rem(80);
        }

        &:hover {
            transform: scale(1.3);
        }
    }

    &-expand {
        display: inline-flex;
        position: absolute;
        top: 16px;
        left: 16px;
        z-index: 2;
        border-radius: 3px;
        background: $white-semi-transparent;
        padding: 6px;
        color: $dark-gray;
        align-items: center;
        justify-content: center;
        
        &:hover {
            transform: scale(1.3);
        }
    }

    &--icon-white {
        color: $white;
    }
}
