.button-link {
    @extend .button-link--primary-color;
    @include button-m;
    position: relative;
    border: 0;
    background: transparent;
    padding: 0;
    text-transform: uppercase;
    text-decoration: underline;
    font-family: $open-sans-family;
    font-weight: $open-sans-extra-bold;

    [class^='icon-'],
    [class*=' icon-'] {
        &,
        &::before {
            text-decoration: none;
        }
    }

    &:hover:not([disabled], &--hover-underline) {
        text-decoration: none;
    }

    &--primary-color {
        color: $blue;

        .is-oru & {
            color: $teal;
        }
    }

    &--secondary-color {
        &,
        .is-oru & {
            color: $white;
        }
    }

    &--gray {
        color: $medium-gray;
    }

    &:disabled {
        opacity: 0.5;
        color: $medium-gray;
    }

    &--extra-low-emphasis {
        @include button-s;
        font-family: $open-sans-family;
        font-weight: $open-sans-extra-bold;
    }

    &--hover-underline {
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }
}
