// ============ ANIMATED CTA ===============

.cta {
    &-animated {
        @extend .button;
        overflow: hidden;

        &__link--no-background {
            @extend .button-link;
        }

        &__text {
            z-index: 1;
        }

        &__description {
            @extend %flexbox;
            @include align-items(center);
            height: 50px;
            text-transform: uppercase;
            color: $white;
            font: calculate-rem(13) / calculate-rem(24) $open-sans-family;
            font-weight: $open-sans-extra-bold;
        }

        &__icon {
            display: inline-block;
            z-index: 1;
            margin-right: 5px;
            font-size: calculate-rem(20);
        }

        &--alert {
            background-color: $red !important;
        }
    }

    &--before-focus {
        @include btn-focus-outline;
    }
}
