// ============== CONED HERO COMPONENT =================

.coned-hero {
    @include flexbox;
    @include align-items(center);
    @include flex-flow(row wrap);
    height: 650px;
    overflow: hidden;

    @include bp-medium {
        height: 550px;
    }

    &__picture {
        width: 100%;
        height: 100%;

        &-image {
            width: 100%;
            height: 100%;
            object-fit: none;
            object-position: center top;
        }
    }

    &__content {
        position: absolute;
        padding: 0 30px;
        overflow: hidden;
        color: $white;

        @include bp-medium {
            padding: 0 50px;
        }

        @include bp-in-page-banner {
            padding: 0 100px;
        }
    }

    &__title {
        @extend %text-truncate-wrap;
        margin-bottom: 30px;
        max-width: 1000px;
        font: $lexend-extra-bold calculate-rem(40) / calculate-rem(60) $lexend-family;

        @include bp-coned-hero {
            font: $lexend-extra-bold calculate-rem(75) / calculate-rem(92) $lexend-family;
        }

        @include bp-medium {
            font: $lexend-extra-bold calculate-rem(95) / calculate-rem(105) $lexend-family;
        }
    }

    &__sub-title {
        margin-bottom: 45px;
        max-width: 510px;
        font: calculate-rem(24) / calculate-rem(34) $open-sans-family;
    }

    &__item {
        margin-bottom: 35px;

        @include bp-coned-hero {
            display: inline-block;

            &:not(:last-child) {
                margin-right: 45px;
            }
        }
    }

    &__link {
        @extend .button-link, .button-link--secondary-color;
        margin-left: 32px;

        &-icon {
            position: absolute;
            top: -5px;
            left: -40px;
            font-size: calculate-rem(35);

            @include bp-medium {
                top: -8px;
            }
        }
    }
}
