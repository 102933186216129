// ============== ARTICLE TEXT COMPONENT =================

.article-page {
    margin: 0 10px;
    background-color: $white;
    padding-top: 50px;
    max-width: 1400px;

    @include bp-medium {
        margin: 0 auto;
        padding-top: 95px;
    }
}

.article-section {
    margin: 0 10px 35px;
    background-color: $white;
    padding-top: 50px;
    max-width: 1400px;

    @include bp-medium {
        margin: 0 auto 35px;
        padding-top: 95px;
    }
}

.article-content {
    @include body-l;
    padding: 50px 20px;
    color: $dark-gray;
    font-family: $open-sans-family;

    @include bp-medium {
        padding: 95px 0 95px 21.5%;
        width: 50%;
    }

    &--with-container {
        padding: 0 10px 50px;

        @include bp-medium {
            padding: 0 0 95px 21.5%;
        }

        a {
            word-wrap: break-word;
        }
    }

    &-wrapper {
        background-color: $white;

        @include bp-small {
            margin: 0 10px;
        }

        a:focus:not(.cta--before-focus) {
            position: static !important;
            height: 30px;

            body:not(.mouse-user):not(.is-oru) & {
                @extend .coned-focus-outline;
            }

            body:not(.mouse-user) & {
                @extend .oru-focus-outline;
            }

            &::before {
                content: none !important;
            }
        }
    }

    &--medium-size {
        padding: 42px 0;
        width: 75%;
    }

    &--centered {
        width: auto;
        text-align: center;

        @include bp-medium {
            padding: 95px 0;
            width: 100%;
        }
    }

    &--margin-top {
        margin-top: 10px;

        @include bp-medium {
            margin-top: 50px;
        }
    }

    &--margin-bottom {
        margin-bottom: 10px;

        @include bp-medium {
            margin-bottom: 35px;
        }
    }

    &--margin-bottom-mobile-20 { 
        margin-bottom: 20px;
    }

    &--margin-bottom-desktop-30 {
        @include bp-medium {
            margin-bottom: 30px;
        }
    }

    &--body-m {
        @include body-m;
    }

    a {
        @include bp-medium() {
            white-space: nowrap;
        }

        .article-content--wrap-links & {
            white-space: normal;
        }
    }

    h2 {
        @include heading-m;
        margin-bottom: 20px;
        font-family: $lexend-family;
        font-weight: $lexend-extra-bold;
    }

    p:not(:last-child) {
        margin-bottom: 35px;
    }

    p:last-of-type {
        margin-bottom: 0;
    }

    ul,
    ol {
        @include body-m;
        padding: 0 0 35px 20px;
        list-style: none;
        font-family: $open-sans-family;

        @include bp-medium {
            padding-left: 14%;
        }

        &:last-child {
            padding-bottom: 0;
        }
    }

    li {
        margin-bottom: 20px;

        &:last-child {
            margin-bottom: 0;
        }

        &::before {
            padding-right: 10px;
            color: $blue;
            content: '•';
        }

        ul,
        ol {
            margin-top: 20px;
            padding-bottom: 1px;
        }
    }

    ul li {
        text-indent: -1.1em;
    }

    ol {
        counter-reset: elementcounter;

        li {
            text-indent: -1.6em;

            &::before {
                content: counter(elementcounter) '.';
                counter-increment: elementcounter;
            }
        }
        // For supporting lists depth on rich text. please don't remove
        // scss-lint:disable NestingDepth, SelectorDepth
        ol {
            counter-reset: elementAlphaCounter;

            li {
                text-indent: -1.6em;

                &::before {
                    content: counter(elementAlphaCounter, lower-alpha) '.';
                    counter-increment: elementAlphaCounter;
                }

                ol {
                    counter-reset: elementRomanCounter;

                    li {
                        text-indent: -1.6em;

                        &::before {
                            content: counter(elementRomanCounter, lower-roman) '.';
                            counter-increment: elementRomanCounter;
                        }
                    }
                }
            }
        }
        // scss-lint:enable NestingDepth, SelectorDepth
    }

    sup {
        vertical-align: super;
        font-size: 0.75em;
    }

    blockquote {
        @include heading-l;
        margin-bottom: 35px;
        overflow: hidden;
        font-family: $lexend-family;
        font-weight: $lexend-extra-bold;
        word-break: break-word;

        @include bp-medium {
            padding-left: 14%;
        }
    }

    &__cta {
        margin: 35px 0;

        &:not(:last-child) {
            margin-right: 25px;
        }

        &-link {
            @extend .button;
        }

        &--before-focus {
            @include btn-focus-outline;
        }
    }
}

.article-content--oru {
    li {
        &::before {
            color: $orange;
        }
    }
}
