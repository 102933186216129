// ============== GOOGLE TRANSLATE COMPONENT =================

.google-translate {
    &__element {
        display: none;
        padding-top: 5px;

        &--open {
            display: block;
            background-color: $white;
        }
    }
}

.translated-ltr,
.translated-rtl {
    .search-box__logo,
    .login {
        top: 39px;
    }

    .search-box__close-button {
        top: 89px;

        @include bp-medium {
            top: 95px;
        }
    }
}
