// ============== PROGRAM COMPONENT =================
.program {
    width: 100%;

    @include bp-medium {
        width: calc(50% - 10px);
    }

    &__tile {
        @include heading-s;
        @include flexbox;
        @include flex-direction(row);
        @include justify-content(space-between); 
        @include border-radius(8px);
        margin-bottom: 16px;
        border: 2px solid $medium-gray-93;
        background: $white;
        padding: 24px 0 24px 24px;
        width: 100%;
        text-align: left;
        text-decoration: none;
        color: $dark-gray;
        font-family: $lexend;

        @include bp-medium {
            width: calc(50% - 12px);
        }

        &-arrow {
            margin-right: 24px;
            font-size: 28px;
        }

        &:last-child {
            margin-bottom: 8px;
        }

        &:hover {
            outline: solid 2px $white;
            border: solid 2px $blue;
            border-radius: 8px;
        }

        &:focus {
            outline: solid 2px $white;
            border: solid 2px $blue;
            border-radius: 8px;
        }
    }

    &__details {
        @include flexbox;
        @include flex-direction(column);
        @include border-radius(8px);
        position: relative;
        margin-bottom: 16px;
        border: 2px solid $blue;
        padding: 24px 24px 40px;

        @include bp-medium {
            margin-top: 16px;
            width: 100%;

            &:after {
                @include transform(rotate(-135deg));
                display: block;
                position: absolute;
                left: 23%;
                margin-top: -35px;
                border-right: 2px solid $blue;
                border-bottom: 2px solid $blue;
                background: $white; 
                width: 17px;
                height: 17px;
                content: '';
            }

            &--right:after {
                left: 72%;
            }

            &--left:after {
                left: 23%;
            }
        }

        @include bp-small {
            &:after {
                @include transform(rotate(-135deg));
                display: block;
                position: absolute;
                left: 47%;
                margin-top: -35px;
                border-right: 2px solid $blue;
                border-bottom: 2px solid $blue;
                background: $white;
                width: 17px;
                height: 17px;
                content: '';
            }
        }

        &-title {
            @include heading-s;
            padding-right: 77px;
            font-family: $lexend;
            font-weight: $lexend-regular;

            @include bp-medium {
                padding-right: 92px;
            }
        }

        &-simple-text {
            @include body-m;
            padding-top: 16px;
            color: $dark-gray;
            font-family: $open-sans;

            &--padding-bottom {
                padding-bottom: 40px;
            }
        }

        &-icon {
            outline: 0;
            background-color: transparent;
            width: 30px;
            height: auto;
            color: $blue;
            font-size: 3.5rem;
        }

        &-content {

            &__title {
                @include heading-s;
                border-bottom: 1px solid $light-gray;
                padding-bottom: 16px;
                color: $dark-gray;
                font-family: $lexend;
                font-weight: $lexend-light;
            }

            &__information {
                @include body-m;
                padding: 16px 0;
                font-family: $open-sans;
                font-weight: $open-sans-regular;
            }

            &__subtitle {
                @include body-m;
                padding-bottom: 16px;
                font-family: $lexend;
                font-weight: $lexend-regular;
            }

            &__subtitle--large {
                @include body-l;
                padding-top: 25px;
                padding-bottom: 16px;
                font-family: $lexend;
                font-weight: $lexend-regular;
            }

            &-amount {
                @include body-l;
                color: $dark-gray;
                font-family: $lexend;
                font-weight: $lexend-regular;
            }

            &-heading-text {
                @include heading-s;
                color: $dark-gray;
                font-family: $lexend;
                font-weight: $lexend-regular;
            }

            &--divider {
                border-bottom: 1px solid $light-gray;
            }

            &--display {
                @include flexbox;
                @include flex-direction(column);
                @include gap(8px);

                @include bp-medium {
                    @include flex-direction(row);
                    @include justify-content(space-between);
                    @include gap(0);
                }
            }

            &--display-row {
                @include flex-direction(row);
                @include justify-content(space-between);
            }

            &--font-weight-normal {
                font-weight: $lexend-light;
            }
        }

        &__list {
            @include body-m;
            padding-top: 32px;
            font-family: $open-sans;
            font-weight: $open-sans-regular;

            li {
                margin: 0 24px;
                list-style-type: disc;
            }

            li::marker {
                font-size: 12px;
            }

            &--padding-top-16 {
                 padding-top: 16px;
            }
        }
    }

    &__close {
        @include flexbox;
        @include flex-direction(row);
        position: absolute;
        right: 24px;
        background: $white;
        text-decoration: none;
        align-items: center;

        &:focus {
            outline: solid 2px $white;
            border: solid 2px $blue;
            border-radius: 0;
        }

        &:active {
            border: 0;
        }

        &-text {
            @include body-s;
            text-decoration: underline;
            color: $blue;
            font-weight: $open-sans-extra-bold;

            &:hover {
                text-decoration: none;
            }
        }
    }

    &__actions-wrapper {
        @include button-m;
        @include flexbox;
        @include flex-direction(column);
        @include justify-content(space-between);
        @include align-items(center);
        padding-top: 24px;

        @include bp-medium {
            @include flex-direction(row);
            @include justify-content(start);
            padding-top: 32px;
        }

        &__button {
            margin-top: 20px;
            padding: 0 12px;
            width: 100%;
            height: auto;
            color: $white;

            @include bp-medium {
                padding: 0 27px;
                width: auto;
            }
    
            &-text {
                @include body-m;
                text-transform: uppercase;
                font-family: $open-sans;
                font-weight: $open-sans-extra-bold;
            }
        }
        
        &-text {
            text-align: center;
            text-transform: uppercase;
            font-family: $open-sans;
            font-weight: $open-sans-extra-bold;
        }

        a + a {
            margin-top: 16px;

            @include bp-medium {
                margin: 0 0 0 16px;
            }
        }
    }

    &--padding-top-16 {
        padding-top: 16px;
    }

    &--padding-bottom-16 {
        padding-bottom:16px;
    }

    &--padding-bottom-32 {
        padding-bottom: 32px;
    }

    &--width-100 {
        width: 100%;
    }


    .is-oru & {

        &__details {
            border: 2px solid $teal;

            @include bp-small {
                &:after {
                    border-right: 2px solid $teal;
                    border-bottom: 2px solid $teal;
                }
            }

            @include bp-medium {
                &:after {
                    border-right: 2px solid $teal;
                    border-bottom: 2px solid $teal;
                }
            }

            &-icon {
                color: $teal;
            }
        }

        &__close {
            &-text {
                color: $teal;
            }

            &:focus {
                border: solid 2px $teal;
            }
        }

        &__tile {
            color: $dark-gray;

            &:hover {
                outline: solid 2px $white;
                border: solid 2px $teal;
                border-radius: 8px;
            }

            &:focus {
                outline: solid 2px $white;
                border: solid 2px $teal;
                border-radius: 8px;
            }
        }
    }
}
