// ============== COLORS =================

// PRIMARY COLORS
$blue: #0078cf;
$orange: #ff8e2c;
$teal: #004557;

// NEUTRAL & SECONDARY COLORS
$black: #000;
$blue-brand: #069bd7;
$dark-blue: #005aa3;
$dark-gray: #2a2a2a;
$dark-purple: #4f2c6c;
$dark-red: #ad0407;
$green: #00882b;
$red: #d93927;
$light-gray: #e6e6e6;
$pre-medium-gray: #5e5e5e;
$medium-gray: #636363;
$medium-gray-93: #939393;
$suva-gray: #8a8a8a;
$orange-brand: #e77613;
$purple: #76489d;
$white: #fff;
$mine-shaft: #333;
$gray-porcelain: #f7f7f7;
$gainsboro-gray: #dcdcdc;
$light-purple: #cbb1d8;
$lighter-purple: #e8d5f1;
$lighter-blue: #d9eaf6;

// LEGACY SECONDARY COLORS
$alabaster: #f8f8f8;
$alto: #cfcfcf;
$alto2: #dcdcdc;
$alto3: #d1d1d1;
$alto7: #e7e7e7;
$aqua-forest: #59ad72;
$coned-light-gray: #dbdbdb;
$cornflower: #8ac0df;
$gallery: #ebebeb;
$ghost-white: #f0f0f0;
$gray-light-3: #ddd;
$humming-bird: #cdebf7;
$lighter-gray: #f5f5f5;
$lighter-gray-blue: #f1f3f7;
$malibu: #4bcbff;
$noble: #b7b7b7;
$nobel: #b5b5b5;
$seashell: #f1f1f1;
$silver: #cbcbcb;
$silver2: #c7c7c7;
$silver3: #c1c1c1;
$silver-chalice: #adadad;
$tundora: #424242;
$twitter-blue: #78cdf0;
$wild-sand: #f4f4f4;

// ALERT COLORS
$yellow: #fff3a5;
$dark-yellow: #fdee8a;
$darker-yellow: #e3d67c;

//CHART COLORS
$light-green: #27aa5e;
$shadow-light-green: #8bccab;
$dark-chart-green: #19522c;
$sepia-black: #300202;
$gray: #7a7a7a;
$shadow-dark-green: #6ba284;
$default-selection-blue: #005aa3;
$disabled-light-gray: #777;
$disabled-gray: #656565;
$darker-gray: #3a3a3a;
$pastel-light-blue: #f0f5f9;
$light-blue: #4cbfe6;
$pastel-light-green: #a9ddbe;
$pastel-dark-green: #a3baab;
$fire-brick: #b72e24;
$red-dark-red: #94020f;
$overage-red: #94000f;

// UTILS
// For example: degradation
$blue-transparent: rgba(6, 155, 215, 0);
$orange-transparent: rgba(231, 118, 19, 0);
$alto-transparent: rgba(219, 219, 219, 0);
$white-transparent: rgba(255, 255, 255, 0);
$white-barely-transparent: rgba(255, 255, 255, 0.15);
$white-semi-transparent: rgba(255, 255, 255, 0.7);
$white-semi-transparent-2: rgba(255, 255, 255, 0.9);
$white-full-opacity: rgba(255, 255, 255, 1);
$white-transparent-85: rgba(255, 255, 255, 0.85);
$transparent: rgba(0, 0, 0, 0);
$black-barely-transparent: rgba(0, 0, 0, 0.15);
$black-quarter-transparent: rgba(0, 0, 0, 0.25);
$black-semi-transparent: rgba(0, 0, 0, 0.5);
$black-semi-transparent-2: rgba(0, 0, 0, 0.6);
$black-almost-transparent: rgba(0, 0, 0, 0.77);
$black-non-transparent: rgba(0, 0, 0, 1);
$blue-semi-transparent: rgba(13, 157, 213, 0.5);
$dark-gray-transparent: rgba(42, 42, 42, 0.75);
$dark-gray-transparent-2: rgba(42, 42, 42, 0.4);
$silver-semi-transparent: rgba(203, 203, 203, 0.2);
$silver-chalice-transparent: rgba(163, 163, 163, 0.5);
$light-gray-transparent: rgba(230, 230, 230, 0.5);
$light-gray-rbga: rgba(230, 230, 230, 1);
$black-transparent-15-percent: rgba(0, 0, 0, 0.15);
$light-purple: #cbb1d8;
