.notification-onboarding {
    margin-bottom: 35px;
    background-color: $white;
    color: $dark-gray;

    &__header {
        @include flexbox;
        @include flex-direction(column);
        @include align-items(center);
        margin-bottom: 20px;
    }

    &__image {
        margin-bottom: 24px;
    }

    &__title {
        text-align: center;
    }

    &__paragraphs-container {
        p {
            max-width: 609px;
            text-align: center;
        }
    }

    &__legend {
        @include body-m;
        font-family: $open-sans-family;
        font-weight: $open-sans-semi-bold;
    }

    &__notifications-container {
        margin-bottom: 40px;
    }

    &__notification-item {
        border-top: 3px solid $medium-gray;
    }

    &__notification-preferences {
        &--mobile {
            border-bottom: 1px solid $medium-gray;
            padding-top: 12px;

            @include bp-medium {
                display: none;
            }
        }
    }

    &__email,
    &__phone {
        @include flexbox;
        @include align-items(center);
        border-bottom: 1px solid $medium-gray;

        &-header {
            margin-right: 20px;
            text-transform: uppercase;

            &--mobile {
                @include bp-small {
                    margin-bottom: 8px;
                }
            }
        }

        &-body {
            &--desktop {
                @include bp-small {
                    display: none;
                }
            }

            &--mobile {
                @include bp-small {
                    margin-bottom: 12px;
                }
            }
        }
    }
    
    &__checkbox-switch {
        margin-left: auto;
    }

    &__preference {
        @include body-s;
        font-family: $open-sans-family;
                
        &-title {
            @include body-s;
            font-family: $open-sans-family;
            font-weight: $open-sans-bold;
        }
    }
    

    &__actions {
        @include flexbox;
        @include flex-direction(column);
        @include align-items(center);
    }

    &__button-container {
        padding-top: 25px;
        padding-bottom: 25px;
        width: 100%;
    }

    &__button {
        @extend .button, .button--high-emphasis-large;
    }
}
