// ============== POWER YOUR WAY FORM COMPONENT =================
.power-your-way-form {
    &__escos-list {
        display: none;
    }

    &__description {
        @include body-l;
        color: $dark-gray;
        font-family: $open-sans-family;

        p:not(:last-child) {
            margin-bottom: 35px;
        }

        span {
            font-weight: $open-sans-bold;
        }
    }

    &__phone-number,
    &__list-title {
        @include body-l;
        font-family: $open-sans-family;
    }

    &__list-title--phone {
        @include bp-medium {
            width: 25%;
        }
    }

    &__phone-number {
        @extend %flexbox;
        margin-top: 20px;

        @include bp-medium {
            margin-top: 0;
            width: 25%;
        }
    }

    &__phone-icon {
        margin: -5px 25px 0 15px;
        color: $blue;
        font-size: calculate-rem(40);
    }

    &__list-title {
        color: $blue;
        font-weight: $open-sans-bold;
    }

    &__esco-list-header {
        margin-top: 20px;
    }

    &__checkbox {
        @extend %flexbox;
        word-break: break-all;

        @include bp-medium {
            width: 70%;
        }

        &__label {
            @extend %flexbox;
            @include flex-shrink(1);
            @include align-items(center);
            width: 100%;
            height: 50px;
        }

        .coned-checkbox__text {
            @extend %flexbox;
            @include flex-shrink(1);
            @include align-items(center);
            @include body-m;
            width: 100%;
            height: 60px;
            font-family: $open-sans-family;
        }
    }

    &__esco-name {
        @include body-m;
        padding-left: 82px;
        color: $dark-gray;
        font-family: $open-sans-family;
        font-weight: $open-sans-regular;

        @include bp-medium {
            padding-left: 63px;
            width: 70%;
        }
    }

    &__esco-link {
        word-break: break-word;
    }
}

.coned-form__field-container--power-your-way {
    @extend %flexbox;
    @include flex-direction(column);
    @include justify-content(space-between);
    border-bottom: 1px solid $medium-gray;
    padding-bottom: 10px;

    @include bp-medium {
        @include flex-direction(row);
        @include align-items(center);
    }

    &:last-child {
        border: 0;
    }
}

.coned-form--oru {
    .power-your-way-form__phone-icon,
    .power-your-way-form__list-title {
        color: $orange;
    }
}
