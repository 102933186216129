.data-table-simple {
    &__wrapper {
        padding: 35px 0;
    }

    &__row-group {
        width: 100%;
    }

    &__row {
        border-bottom: 1px solid $medium-gray;
    }

    &__column {
        padding: 10px;
        text-align: left;

        &--half {
            width: 50%;
        }

        &--zones {
            width: 30%;

            @include bp-medium {
                width: 20%;
            }
        }

        &--zip-codes {
            width: 70%;

            @include bp-medium {
                width: 80%;
            }
        }
    }
}
