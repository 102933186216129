// ============== LEAD GEN COMPONENT =================
.lead-gen-form {
    &__top {
        padding: 50px 20px 65px;
    }

    &__bottom {
        padding: 70px 0 0;
    }

    &__required {
        margin: 50px 0;
    }

    &__phone-icon {
        margin: -5px 35px 0 15px;
        color: $dark-gray;
        font-size: calculate-rem(40);
    }

    &__description {
        @include body-m;
        color: $dark-gray;
        font-family: $open-sans-family;

        p:not(:last-child) {
            margin-bottom: 35px;
        }
    }

    // Table styles
    &__table-container {
        margin-bottom: 80px;
        width: 100%;
        text-align: left;

        &--border-mobile {
            border-top: 2px solid $medium-gray;
            padding-top: 15px;
        }
    }

    &__table-body {
        &--border {
            box-shadow: 0 -2px 0 0 $medium-gray;
        }
    }

    &__table-row {
        @extend %flexbox;
        @include flex-direction(column);
        @include justify-content(space-between);
        border-bottom: 1px solid $medium-gray;
        padding-bottom: 10px;

        &-wrapper {
            word-break: break-all;
        }
    }

    &__table-header,
    &__table-item {
        @include body-l;
        color: $dark-gray;
        font-family: $open-sans-family;

        a {
            color: $dark-gray;

            body:not(.mouse-user):not(.is-oru) &:focus {
                @extend .coned-focus-outline;
            }

            &:focus::before {
                content: none !important;    
            }
        }
    }

    &__table-header {
        font-weight: $open-sans-extra-bold;
    }

    &__table-item {
        @include transition(opacity 0.3s ease);
        @include body-m;
        font-weight: $open-sans-semi-bold;

        &--checkbox {
            margin-top: 15px;
            width: 30%;
        }

        &--phone {
            @extend %flexbox;
            margin-top: 20px;

            &--hidden-mobile {
                display: none;
            }
        }

        &--company {
            margin: 15px 0 0;
            width: 70%;
        }
    }

    &__title--success {
        color: $dark-gray;
    }

    .coned-checkbox {
        padding: 12px 18px;
    }
}

@include bp-medium {
    .lead-gen-form {
        &__top {
            padding: 100px 0 125px 21.5%;
        }

        &__bottom {
            padding: 100px 0 0;
        }

        &__table-container {
            display: table;
        }

        &__table-row {
            display: table-row;
            padding: 30px 0;
            height: auto;

            &-wrapper {
                display: table-cell;
                vertical-align: middle;
                word-break: break-word;

                > .lead-gen-form__table-item,
                > .lead-gen-form__table-header {
                    display: inline-block;
                    margin-left: -3px;
                    box-sizing: border-box;
                }
            }

            &--border {
                border: 0;

                > div {
                    border-bottom: 1px solid $medium-gray;
                }
            }
        }

        &__table-header,
        &__table-item {
            display: table-cell;
        }

        &__table-header {
            padding: 20px 2.5px;
            vertical-align: middle;
        }

        &__table-item {
            float: none;
            padding: 15px 2.5px;
            vertical-align: middle;

            &--checkbox {
                width: 30%;
                height: auto;
            }

            &--company {
                width: 65%;
            }

            &--phone {
                width: 25%;
            }
        }

        &__table-body {
            display: table-row-group;
        }

        .coned-checkbox {
            padding: 10px 15px;
        }
    }
}

@include bp-large {
    .lead-gen-form {
        &__table-item {
            &--checkbox {
                width: 20%;
            }

            &--company {
                width: 80%;
            }
        }
    }
}

