// ============== BORDER BAR =================

.border-bar {
    display: block;
    position: relative;
    border-bottom: 2px solid $medium-gray;
    width: 100%;

    &::after {
        @include transition(all, 0.4s, ease);
        position: absolute;
        left: 50%;
        border-bottom: 2px solid $green;
        width: 0;
        content: '';
    }

    &--animate::after {
        left: 0;
        width: 100%;
    }
}
