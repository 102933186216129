// ============== AVAILABLE CONNECTIONS COMPONENT =================

.available-connection-item {
    @extend %flexbox;
    @include align-items(center);
    border: 2px solid $light-gray;
    background-color: $wild-sand;
    padding: 30px 15px 30px 30px;
    width: 321px;
    max-width: 321px;

    @include bp-small {
        padding: 25px 15px 25px 25px;
        width: 300px;
    }

    @include bp-smaller {
        margin: 0 auto;
        padding: 20px 10px 20px 15px;
    }

    &__logo {
        @include inline-flex;
        width: 60px;
        height: 60px;

        @include bp-smaller {
            width: 50px;
            height: 50px;
        }
    }

    &__labels {
        @extend %flexbox;
        @include flex-direction(column);
        padding-left: 25px;
        max-width: 200px;

        @include bp-smaller {
            padding-left: 15px;
        }

        &-title {
            @extend %text-truncate-wrap;
            @include flexbox;
            @include body-s;
            max-height: 50px;
            font-family: $lexend-family;
            font-weight: $lexend-extra-bold;

            @include bp-small {
                max-height: 46px;
            }
        }

        &-subtitle {
            @extend %text-truncate-wrap;
            @include flexbox;
            @include body-s;
            max-height: 40px;
            font-family: $lexend-family;
            font-weight: $lexend-extra-bold;

            &--small {
                @include body-s;
                font-family: $open-sans-family;
                font-weight: $open-sans-semi-bold;
            }
        }
    }

    &__icon {
        @include transform(rotate(270deg));
        position: relative;
        width: 24px;
        height: 24px;
        color: $noble;

        &::before {
            position: absolute;
            top: -14px;
            left: -13px;
            font-size: calculate-rem(50);
        }

        &-container {
            @include inline-flex;
            @include flex-grow(1);
            @include justify-content(flex-end);
        }
    }

    &--full-width {
        width: auto;
        max-width: none;
    }
}

.available-connections {
    background-color: $white;
    width: 100%;

    a {
        &:hover {
            .available-connection-item {
                background-color: $alto2;
            }
        }
    }

    &__container {
        margin: 0 auto;
        padding: 100px 0 0;
        width: 778px;

        @include bp-small {
            margin: 0 30px;
            padding: 60px 0 0;
            width: auto;
        }
    }

    &__header {
        border-bottom: 3px solid $light-gray;
        padding-bottom: 15px;

        &-title {
            @include heading-l;
            margin-bottom: 20px;
            font-family: $lexend-family;
            font-weight: $lexend-extra-bold;
        }

        &-subtitle {
            @include body-m;
            color: $medium-gray;
            font-family: $open-sans-family;
            font-weight: $open-sans-semi-bold;
        }
    }

    &__paragraphs-container {
        @include body-m;
        font-family: $open-sans-family;

        p:not(:last-child) {
            margin-bottom: 25px;
        }

        a {
            @extend %focus-outline-on-element;
        }
    }

    &__list {
        @include flexbox;
        @include justify-content(space-between);
        @include flex-wrap(wrap);
        background-color: $white;
        padding-bottom: 60px;
        width: auto;

        @include bp-small {
            @include justify-content(center);
        }
    }

    &__item {
        @include inline-flex;
        padding-top: 30px;

        @include bp-smaller {
            width: 100%;
        }

        &--hidden {
            display: none;
        }

        a {
            color: $black;
        }
    }
}
