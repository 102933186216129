// ============== METER READ COMPONENT =================

.meter-read {
    padding: 80px 20px;

    @include bp-medium {
        padding: 100px 0 100px 21.5%;
        width: 60%;
    }

    &-wrapper {
        background-color: $white;

        @include bp-small {
            margin: 0 10px;
        }
    }

    &__title {
        padding-bottom: 60px;
        color: $dark-gray;
        font: $lexend-extra-bold calculate-rem(34) / calculate-rem(54) $lexend-family;

        @include bp-medium {
            padding-bottom: 90px;
        }
    }

    &__content {
        text-align: center;
    }

    &__picture {
        &-image {
            max-width: 100%;
        }
    }

    &__description {
        padding-top: 25px;
        text-align: left;
        color: $dark-gray;
        font: calculate-rem(24) / calculate-rem(40) $open-sans-family;

        @include bp-medium {
            text-align: inherit;
            line-height: calculate-rem(20);
            font-size: calculate-rem(14);
        }
    }
}
