// ============== SEARCH BOX COMPONENT =================

.search-box {
    @include transition(all 0.2s ease);
    position: fixed;
    top: 0;
    bottom: 0;
    visibility: hidden;
    z-index: 10;
    background-color: $white;
    width: 100%;
    height: 0;
    overflow: auto;

    &--active {
        visibility: visible;
    }

    &__logo {
        position: absolute;
        top: 10px;

        @include bp-medium {
            top: 20px;
        }
    }

    &__wrapper {
        position: relative;
        background-color: $white;
    }

    &__form-wrapper {
        @include box-shadow(0, 0, 10px, 15px, $white);
        @include opacity(0);
        top: 50px;
        z-index: 10;
        background-color: $white;
        width: 100%;

        @include bp-medium {
            @include box-shadow(0, 19px, 10px, 10px, $white);
            top: 70px;
        }
    }

    &__suggested-wrapper {
        @include opacity(0);
        position: relative;
        padding: 285px 20px 20px;
        overflow: auto;

        @include bp-medium {
            padding: 370px 10px 20px;
        }
    }

    &__form {
        @extend %flexbox;
        @include flex-direction(row);
        @include box-sizing(border-box);
        position: relative;
        margin-top: 100px;
        padding: 0 30px;

        @include bp-medium {
            margin-top: 135px;
        }

        &--results {
            width: 100%;

            @include bp-medium {
                margin: 80px 0 20px;
            }
        }

        &--desktop-search {
            @include bp-small {
                margin-top: 40px;
                margin-bottom: 60px;
            }
        }
    }

    &__animation {
        display: block;
        position: absolute;
        bottom: 0;
        left: 30px;
        border-bottom: 2px solid $medium-gray;
        width: calc(100% - 60px);
        height: 0;

        &::after {
            @include transition(all, 0.4s, ease);
            position: absolute;
            left: 50%;
            background-color: $blue;
            width: 0;
            height: 2px;
            content: ' ';
        }

        &--start::after {
            left: 0;
            width: 100%;
        }
    }

    &__close-button {
        @include opacity(0);
        position: absolute;
        top: 38px;
        right: 7px;
        z-index: 20;
        background-color: transparent;
        width: auto;
        height: auto;
        color: $blue;
        font-size: calculate-rem(60);

        @include bp-medium {
            top: 65px;
            right: 0;
        }

        @include bp-page-hero-large {
            right: calc((100% - 1380px) / 2);
        }

        &:focus {
            body:not(.mouse-user):not(.is-oru) & {
                @extend .coned-focus-outline;
            }

            body:not(.mouse-user) & {
                @extend .oru-focus-outline;
            }
        }
    }

    &__search-button {
        position: absolute;
        top: -5px;
        right: 15px;
        background-color: $white;
        width: auto;
        height: auto;
        color: $blue;
        font-size: calculate-rem(60);

        @include bp-medium {
            right: 22px;
            width: 90px;
            height: 86px;
            font-size: calculate-rem(80);
        }

        body:not(.mouse-user):not(.is-oru) &:focus {
            @extend .coned-focus-outline;
        }

        body:not(.mouse-user) &:focus {
            @extend .oru-focus-outline;
        }
    }

    &__input {
        @include heading-l;
        padding: 10px;
        width: 100%;
        height: 40px;
        color: $dark-gray;
        font-family: $lexend-family;
        font-weight: $lexend-extra-bold;

        @include bp-medium {
            padding: 10px 25px;
            width: 88%;
            height: 68px;
        }

        &:focus {
            outline: none;
        }

        &::placeholder {
            color: $medium-gray;
        }
    }

    &__title {
        @include body-s;
        margin: 20px 30px;
        text-transform: uppercase;
        color: $medium-gray;
        font-family: $open-sans-family;
        font-weight: $open-sans-semi-bold;

        @include bp-medium {
            margin: 25px 0 15px 47px;
        }
    }

    &__suggested-list {
        @extend %flexbox;
        @include flex-direction(column);
        margin-left: 10px;

        @include bp-medium {
            margin-left: 35px;
            padding-top: 30px;
        }
    }

    &__suggested-list-item {
        margin-bottom: 26px;
    }
}

.suggested-list__item {
    &-link {
        @extend %flexbox;
        @include flex-wrap(wrap);
        @include align-items(center);

        @include bp-medium {
            @include flex-direction(row);
        }
    }

    &-icon {
        @extend %flexbox;
        @include align-items(center);
        position: absolute;
        margin-left: -7px;
        font-size: calculate-rem(30);

        @include bp-medium {
            position: relative;
            margin-right: 13px;
            width: 25px;
            height: 25px;

            &::before {
                position: absolute;
            }
        }
    }

    &-title {
        @include transition(color 0.2s ease);
        @include button-m;
        padding-left: 25px;
        width: 100%;
        text-transform: uppercase;
        font-family: $lexend-family;
        font-weight: $lexend-extra-bold;

        @include bp-medium {
            margin-right: 10px;
            padding-left: 0;
            width: auto;
        }
    }

    &-category {
        @include body-s;
        padding-left: 25px;
        color: $medium-gray;
        font-family: $open-sans-family;
        font-weight: $open-sans-semi-bold;

        @include bp-medium {
            padding-left: 0;
        }
    }
}

.search-results-bar--oru {
    .search-box__animation::after {
        background-color: $teal;
    }
}
