.modal {
    @include transition(all 0.2s ease-in-out);
    @include transition-delay(0.7s);
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    visibility: hidden;
    opacity: 0;
    z-index: -1;
    background: transparent;
    overflow: hidden;

    &__container {
        @include transition(all 0.4s ease-in-out);
        @include transition-delay(0.2s);
        position: relative;
        background: $white;
        width: 100%;
        height: 0;
        overflow-x: hidden;
        overflow-y: hidden;
    }

    &__wrapper {
        @include transition(all 0.2s ease-in-out);
        @include transition-delay(0);
        position: relative;
        top: 40px;
        opacity: 0;
        margin: 0 auto;
        padding: 40px 20px 100px;
        width: 100%;
        max-width: 1400px;
        box-sizing: border-box;

        @include bp-medium {
            padding: 50px 20px 100px;
        }

        &-items {
            @include flexbox;
            @include flex-wrap(wrap);
            @include justify-content(flex-start);

            @include bp-medium {
                @include justify-content(space-between);
                @include gap(24px);
                padding: 0 24px;
            }
        }

        &-item {
            margin-top: calculate-rem(40);
            width: 100%;
            max-width: 330px;

            @include bp-medium {
                width: 40%;
                max-width: 40%;
            }
        }

        &-header {
            position: relative;
            margin: 0 auto;
            padding: 0 0 0 20px;
            max-width: 1400px;
        }
    }

    &__content {
        width: 100%;
        box-sizing: border-box;
    }

    &__button {
        &-close {
            @include btn-icon-focus;
            position: absolute;
            top: -20px;
            right: 0;
            border: 2px solid transparent;
            background-color: transparent;
            cursor: pointer;
            width: auto;
            height: auto;
            color: $blue;
            font-size: calculate-rem(60);

            @include bp-medium {
                top: -20px;
            }

            .is-oru & {
                color: $teal;
            }
        }

        &--after-separator {
            margin-left: 40px;
        }
    }

    &__header {
        position: fixed;
        top: 0;
        right: 20px;
        left: 0;
        z-index: 999; // needed if want sticky header title and X close
        background: $white;
        padding: 20px 0 25px;
        height: auto;

        @include bp-medium {
            padding: 30px 0;
        }
    }

    &__heading {
        @include body-l;
        margin: 0;
        padding: 0;
        text-align: left;
    }

    &__footer {
        &-filter-group {
            @include flexbox;
            @include justify-content(center);
            @include align-items(center);
            @include transform(translateY(100%));
            @include transition(transform 0.2s ease-in-out);
            position: fixed;
            right: 0;
            bottom: 0;
            left: 0;
            visibility: hidden;
            border-top: 2px solid $medium-gray;
            background: $white;
            padding: 20px 0;

            &.modal-footer--show {
                @include transform(translateY(0));
            }
        }
    }

    &[aria-hidden="false"] {
        @include transition(all 0.01s ease-in-out);
        @include transition-delay(0);
        visibility: visible;
        opacity: 1;
        z-index: 99999;

        .modal {
            &__container {
                @include transition(all 0.6s ease-in-out);
                @include transition-delay(0);
                height: 100%;
                overflow-y: auto;
            }

            &__wrapper {
                @include transition(all 0.4s ease-in-out);
                @include transition-delay(0.3s);
                top: 0;
                opacity: 1;
            }

            &__footer-filter-group {
                visibility: visible;
            }
        }
    }
}
