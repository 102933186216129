// ============ LOADING ANIMATION STANDALONE ===============

.loading-animation-component {
    @include transition(all, 0.5s, ease);
    @include border-radius(32px);
    position: relative;
    margin: 0 auto;
    width: 100%;
    height: 64px;

    @include bp-medium {
        max-width: 440px;
    }

    &--bg-container {
        background-color: $ghost-white;
        width: 64px;
    }

    &--bg-container-reversed {
        background-color: $green;
    }

    &__animation-block {
        @include transition(all, 0.4s, ease);
        @include border-radius(50px);
        position: absolute;
        top: 50%;
        left: 50%;
        background-color: $aqua-forest;
        width: 0;
        height: 0;
    }

    &__animation-block--blue {
        background-color: $blue;
    }

    &__clip-right {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 64px;
        clip: rect(0, 64px, 64px, 32px);
        pointer-events: none;
    }

    &__slice-right {
        @include transition(all, 0, ease);
        @include border-radius(32px);
        @include transform(rotate(0));
        position: absolute;
        background-color: $green;
        width: 100%;
        height: 64px;
        clip: rect(0, 32px, 64px, 0);
    }

    &__slice-right--white {
        background-color: $ghost-white;
    }

    &__clip-left {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 64px;
        clip: rect(0, 32px, 64px, 0);
        pointer-events: none;
    }

    &__slice-left {
        @include transition(all, 0, ease);
        @include border-radius(32px);
        @include transform(rotate(0));
        position: absolute;
        background-color: $green;
        width: 100%;
        height: 64px;
        clip: rect(0, 64px, 64px, 32px);
    }

    &__slice-left--white {
        background-color: $ghost-white;
    }

    &__top {
        @include border-radius(32px);
        position: absolute;
        top: 5px;
        left: 5px;
        background-color: $white;
        width: calc(100% - 10px);
        height: 54px;
    }
}

.loading-animation-component--small {
    @include border-radius(50%);
    width: 40px;
    height: 40px;

    .loading-animation-component__top {
        @include border-radius(50%);
        height: 30px;
    }

    .loading-animation-component__slice-right,
    .loading-animation-component__clip-left {
        clip: rect(0, 20px, 40px, 0);
    }

    .loading-animation-component__clip-right,
    .loading-animation-component__slice-left {
        clip: rect(0, 40px, 40px, 20px);
    }

    .loading-animation-component__slice-right,
    .loading-animation-component__slice-left,
    .loading-animation-component__clip-right,
    .loading-animation-component__clip-left {
        @include border-radius(50%);
        height: 40px;
    }
}

.loading-animation-component--blue {
    .loading-animation-component__slice-right,
    .loading-animation-component__slice-left {
        background-color: $blue;
    }

    .loading-animation-component__slice-right--white,
    .loading-animation-component__slice-left--white {
        background-color: $ghost-white;
    }
}

.loading-animation-component--oru {
    .loading-animation-component__slice-right,
    .loading-animation-component__slice-left {
        background-color: $teal;
    }

    .loading-animation-component__slice-right--white,
    .loading-animation-component__slice-left--white {
        background-color: $ghost-white;
    }

    .loading-animation-component__animation-block--blue {
        background-color: $orange;
    }
}
