// ============== CARDS-CLOSED COMPONENT =================

.manage-cards {
    @extend %flexbox;
    @include flex-direction(column);
    @include flex-wrap(wrap);
    @include justify-content(space-between);

    @include bp-medium {
        @include flex-direction(row);
        margin-bottom: 50px;
    }

    &--no-margin-bottom {
        margin-bottom: 0;
    }
    
    &--header {
        margin-top: 30px;
    }

    &__card {
        @include box-sizing(border-box);
        @include flex-grow(1);
        @include flex-basis(0);
        position: relative;
        margin-bottom: 20px;
        background-color: $white;
        padding: 30px;

        @include bp-medium {
            margin: 0 10px;
        }

        @include bp-medium {
            margin: 0 10px;
        }

        &--no-margin-bottom-mobile {
            @include bp-small {
                margin-bottom: 0;
            }
        }

        &:first-child {
            @include bp-medium {
                margin-left: 0;
            }
        }

        &:last-child {
            @include bp-medium {
                margin-right: 0;
            }
        }

        &::before {
            @include transition(all 0.2s ease);
            position: absolute;
            top: -3px;
            left: 50%;
            border-top: 3px solid $blue-brand;
            width: 0;
            content: '';
        }

        &:hover {
            background-color: $alabaster;

            &::before {
                left: 0;
                width: 100%;
            }
        }
    }

    &__box {
        margin: 10px 0;

        &-title {
            margin-top: 18px;
            color: $medium-gray;
            font: $lexend-extra-bold calculate-rem(14) / calculate-rem(18) $lexend-family;
        }

        &-copy {
            margin-bottom: 18px;
            color: $medium-gray;
            font: calculate-rem(14) / calculate-rem(20) $open-sans-family;
            font-weight: $open-sans-semi-bold;
        }
    }

    &__wrapper {
        @extend %flexbox;
        @include flex-direction(column);
        @include justify-content(space-between);
        height: 100%;
    }

    &__title {
        @include body-l;
        font-family: $lexend-family;
        font-weight: $lexend-extra-bold;

        &-container {
            @extend %flexbox;
            @include align-items(center);
            width: 95%;
        }

        .manage-cards__card--disabled & {
            color: $medium-gray;
        }
    }

    &__tile-subheading {
        margin-bottom: 0;
        color: $medium-gray;
        font: calculate-rem(12) / calculate-rem(12) $open-sans-family;
        font-weight: $open-sans-extra-bold;
    }

    &__paragraph {
        @include body-s;
        margin-bottom: 10px;
        padding-top: 5px;
        color: $medium-gray;
        font-family: $open-sans-family;
        font-weight: $open-sans-semi-bold;

        a {
            body:not(.mouse-user):not(.is-oru) &:focus {
                @extend .coned-focus-outline;
            }

            body:not(.mouse-user) &:focus {
                @extend .oru-focus-outline;
            }

            &:focus::before {
                content: none !important;
            }
        }

        .manage-cards__card--disabled & {
            color: $medium-gray;
        }
    }

    &__tile-container {
        margin: 20px 0;
    }

    &__tile-paragraph {
        margin-bottom: 10px;
        color: $medium-gray;
        font: calculate-rem(14) / calculate-rem(20) $open-sans-family;
        font-weight: $open-sans-semi-bold;
    }

    &__form-container {
        margin-top: 30px;

        @include bp-medium {
            @include flexbox;
            @include justify-content(space-between);
        }
    }

    &__form-wrapper {
        @include bp-medium {
            @include flexbox;
            @include justify-content(space-between);
            flex-wrap: wrap;
            flex-basis: 100%;
            justify-content: space-between;
        }
    }

    &__link {
        @extend .button-link, .button-link--extra-low-emphasis;
        @include inline-flex;
        @include align-items(center);

        &-wrapper {
            display: flex;
        }

        &-icon {
            position: relative;
            margin-left: 10px;
            width: 25px;
            height: 14px;

            &::before {
                position: absolute;
                top: -6px;
                left: -6px;
                font-size: calculate-rem(30);
            }
        }

        &--pay-now {
            margin-bottom: 10px;
        }
    }

    &__content-link {
        @extend .button-link, .button-link--extra-low-emphasis;
        display: block;
        margin-bottom: 20px;

        &--bottom {
            margin: 60px 0;
        }

        &--padded {
            margin: 25px 0;
        }

        span[class*='icon-'] {
            position: relative;
            padding-right: 30px;

            &::before {
                position: absolute;
                top: -7px;
                left: 3px;
                font-size: calculate-rem(30);
            }
        }
    }

    &__overlay {
        @include transition(all, 0.2s, ease);
        @include opacity(0.5);
        position: absolute;
        top: 50%;
        left: 50%;
        width: 0;
        height: 0;
    }

    &__contain {
        position: relative;
        border-bottom: 3px solid $blue;

        &-wrapper {
            position: relative;
            background-color: $white;

            @include bp-medium {
                margin: 0;
                width: 100%;
            }

            &-active {
                margin-bottom: 25px;

                @include bp-medium {
                    margin-top: 50px;
                    margin-bottom: 0;
                }
            }
        }

        &-wrapper--margin-bottom {
            .manage-cards__contain {
                padding-bottom: 50px;
            }
        }

        &-title {
            margin-bottom: 30px;
            border-bottom: 3px solid $medium-gray;
            padding-bottom: 10px;
            text-align: left;
        }

        &-paragraph > p {
            margin-top: 15px;
            font: calculate-rem(14) / calculate-rem(22) $open-sans-family;
        }

        &-paragraph--half {
            margin: 15px 0;

            > p {
                @include bp-medium {
                    display: inline-block;
                    width: 50%;
                }
            }

            > p:not(:first-of-type) {
                @include bp-medium {
                    margin-left: -3px;
                }
            }
        }

        &-bold-paragraph > p {
            @include body-l;
            font-family: $lexend-family;
            font-weight: $lexend-semi-bold;
        }

        &-arrow {
            position: absolute;
            top: -10px;
            left: 49%;
            border-right: 10px solid $transparent;
            border-bottom: 10px solid $white;
            border-left: 10px solid $transparent;
            width: 0;
            height: 0;

            @include bp-medium {
                left: calc(12% - 10px);
            }

            &--second {
                @include bp-medium {
                    left: calc(37% - 10px);
                }
            }

            &--third {
                @include bp-medium {
                    left: calc(62.5% - 10px);
                }
            }

            &--fourth {
                @include bp-medium {
                    left: calc(88% - 10px);
                }
            }
        }
    }

    &__contain-bold-paragraph > p {
        a {
            body:not(.mouse-user):not(.is-oru) &:focus {
                @extend .coned-focus-outline;
            }

            body:not(.mouse-user) &:focus {
                @extend .oru-focus-outline;
            }

            &:focus::before {
                content: none !important;
            }
        }
    }

    &__gutter {
        background-color: $white;
        padding: 65px 30px 0;

        @include bp-medium {
            padding: 60px 0 0 14.5%;
            width: 71%;
        }
    }

    &__card--disabled {
        display: none;
        border-top: 3px solid $medium-gray;

        @include bp-medium {
            display: block;
        }

        .manage-cards__overlay {
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }

    &__input-title {
        color: $dark-gray;
        font: $lexend-extra-bold calculate-rem(18) / calculate-rem(32) $lexend-family;

        @include bp-medium {
            padding-left: 30px;
        }
    }

    &__card--active {
        border-top: 5px solid $blue;

        &::before {
            top: -5px;
            border-width: 5px;
        }
    }

    &__grid {
        &-half {
            @extend %flexbox;
            @include box-sizing(border-box);
            @include flex-direction(column);
            width: 100%;

            @include bp-medium {
                width: 47%;
            }
        }

        &-one-third {
            display: block;
            width: 100%;

            @include bp-mid-small {
                width: 30%;
            }

            @include bp-medium {
                min-width: 110px;
            }
        }

        &-two-third {
            display: block;
            width: 100%;

            @include bp-mid-small {
                width: 68%;
            }
        }
    }

    &__tabs {
        margin-bottom: 30px;

        &-paragraph {
            @include body-s;
            display: inline-block;
            padding-right: 15px;
            font-family: $open-sans-family;

            @include bp-hero {
                display: block;
            }

            @include bp-extra-large-header {
                display: inline-block;
            }
        }

        &-link {
            @extend .button-link--extra-low-emphasis;
            background-color: $white;
            text-transform: uppercase;
            font-family: $lexend-family;
            font-weight: $lexend-extra-bold;

            &,
            .is-oru & {
                text-decoration: none;
                color: $medium-gray;
            }

            &:hover {
                border-bottom: 3px solid $medium-gray;
            }

            &-wrapper {
                display: flex;
            }
        }

        &-link--active {
            border-bottom: 3px solid $blue;
            color: $dark-gray;

            .is-oru & {
                border-color: $teal;
                color: $dark-gray;
            }

            &:hover {
                border-color: $medium-gray;
            }
        }

        &-separator {
            margin: 0 8px;
            background-color: $dark-gray;
            width: 3px;

            @include bp-medium {
                width: 2px;
            }
        }
    }

    &__no-padding {
        padding: 0;
    }

    &--static {
        .manage-cards__card {
            @include bp-small {
                padding-right: 20px;
                padding-left: 20px;
            }
        }

        .manage-cards__title {
            @include heading-m;
            color: $dark-gray;

            @include bp-medium {
                margin-top: 15px;
            }
        }

        .manage-cards__paragraph {
            @include body-l;
            margin-bottom: 20px;
            padding-top: 10px;
            color: $dark-gray;
            font-weight: $open-sans-regular;
        }
    }
}

.manage-cards__contain-wrapper {
    .coned-input__icon {
        color: $blue;
    }
}

.manage-cards--oru {
    .manage-cards {
        &__card::before,
        &__card--active,
        &__contain {
            border-color: $orange-brand;
        }
    }

    .coned-input__icon {
        color: $orange;
    }
}

.manage-cards__tabs-link {
    body:not(.mouse-user):not(.is-oru) &:focus {
        position: relative;
        outline: 0 !important;

        &::before {
            @extend .coned-button-focus;
        }
    }

    body:not(.mouse-user) &:focus {
        position: relative;
        outline: 0 !important;

        &::before {
            @extend .oru-button-focus;
        }
    }
}
