// ============== BILLING & PAYMENT HISTORY COMPONENT =================

.billing-payment {
    &__header {
        margin-top: 25px;

        &-wrapper {
            background: $white;
            padding: 45px 20px 0;

            @include bp-medium {
                padding: 95px 15%;
            }

            @include bp-billing {
                padding: 95px 20%;
            }

            &--no-padding {
                padding: 0;
            }
        }

        &--white-bg {
            background-color: $white;
        }

        &--20-margin-top-mobile {
            margin-top: 20px;
        }
    }

    &__checkbox-instructions {
        @include body-m;
        margin-top: 30px;
        text-align: center;
        color: $dark-gray;
        font-family: $open-sans-family;
        font-weight: $open-sans-semi-bold;

        @include bp-medium {
            margin-top: 0;
            text-align: left;
        }
    }

    &__title {
        border-bottom: 3px solid $medium-gray;
        padding-bottom: 20px;
        color: $dark-gray;
        font: $lexend-extra-bold calculate-rem(30) / calculate-rem(40) $lexend-family;

        @include bp-small {
            text-align: center;
        }
    }

    &__btns {
        @extend %flexbox;
        @include flex-direction(column);
        @include align-items(center);

        padding: 30px 0;

        @include bp-coned-images {
            @include flex-direction(row);
            @include justify-content(space-between);
            padding: 40px 0;
        }

        &--no-padding-bottom {
            padding-bottom: 0;
        }

        &-checks {
            margin-bottom: 40px;

            @include bp-large {
                margin-bottom: 0;
            }
        }

        &-checks-list {
            display: inline-block;
            padding-top: 20px;
            white-space: nowrap;

            @include bp-medium {
                @include inline-flex();
            }
        }

        &-checkbox {
            margin: 10px;

            @include bp-medium {
                margin: 0 50px 0 0;
            }
        }

        &-links {
            @include align-self(center);

            @include bp-coned-images {
                @include align-self(center);
                padding: 25px 30px 0 0;
            }

            @include bp-cards {
                padding-right: 35px;
            }
        }

        &-link {
            @extend .button-link, .button-link--extra-low-emphasis;

            display: block;
            margin-bottom: 15px;
        }

        &--links-only {
            @include justify-content(flex-end);
        }
    }
}

.billing-payment-body {
    margin-bottom: 30px;
    background-color: $white;
    padding: 15px 0;
    text-align: center;

    &--no-padding-top-bottom {
        padding-top: 0;
        padding-bottom: 0;
    }

    &--20-margin-bottom {
        margin-bottom: 20px;
    }

    &__title {
        background-color: $light-gray;
        width: 100%;
        text-align: left;

        &s {
            @include heading-s;
            padding: 20px 0;
            text-transform: uppercase;
            color: $medium-gray;
            font-family: $lexend-family;
            font-weight: $lexend-extra-bold;

            @include bp-medium {
                padding: 50px 0 20px;
            }

            &--cycle {
                display: inherit;
                text-align: center;

                @include bp-medium {
                    @include inline-flex();
                    padding-left: 40px;
                    text-align: inherit;
                }

                @include bp-coned-title {
                    margin-left: 30px;
                }
            }

            &--total {
                display: none;
                float: right;

                @include bp-medium {
                    @include inline-flex();
                    margin-right: 5%;
                }

                @include bp-coned-title {
                    @include inline-flex();
                    margin-right: 10%;
                }
            }
        }
    }

    &__show-more {
        margin: 30px auto;

        &--hide {
            display: none;
        }

        body:not(.mouse-user):not(.is-oru) &:focus {
            outline: none;

            &::before {
                @extend .coned-button-focus;
            }
        }

        body:not(.mouse-user) &:focus {
            outline: none;

            &::before {
                @extend .oru-button-focus;
            }
        }
    }
}

.billing-payment-item {
    @include transition(all, 0.2s, ease);
    display: block;
    border-bottom: 1px solid $medium-gray;
    padding: 10px 20px;
    text-align: left;

    @include bp-medium {
        padding: 50px 40px;
    }

    @include bp-coned-title {
        padding: 50px 65px;
    }

    &--hidden {
        display: none;
    }

    &s {
        background-color: $white;
    }

    &__focus {
        display: inline-block;
        
        body:not(.mouse-user):not(.is-oru) &:focus {
            @extend .coned-focus-outline;
        }

        body:not(.mouse-user) &:focus {
            @extend .oru-focus-outline;
        }

        &:focus::before {
            content: none !important;
        }
    }

    &__container {
        text-align: center;

        @include bp-medium {
            @include flexbox;
            @include align-items(center);
            text-align: left;
        }

        &-block {
            display: block;

            @include bp-medium {
                @include flexbox;
                @include flex(5);
                @include align-items(center);
            }

            &--right {
                @include bp-medium {
                    @include flex(0);
                    @include justify-content(center);
                }

                @include bp-large {
                    @include flex(1);
                }
            }

            &--right-payment {
                @include flex(1);
                @include justify-content(flex-end);


                @include bp-large {
                    @include justify-content(center);
                }
            }
        }

        &--column {
            @include flex-direction(column);
        }
    }

    &__date {
        @include flex(1);
        @include body-m;
        display: block;
        color: $medium-gray;
        font-family: $open-sans-family;
        font-weight: $open-sans-semi-bold;

        @include bp-medium {
            display: inline;
            text-align: left;
        }
    }

    &__summary-link-container {
        @include body-m;
        padding: 24px 15px;
        color: $dark-gray;
        font-family: $open-sans-family;
    }

    &__status {
        @include body-xs;
        color: $red;
        font-family: $open-sans-family;
        font-weight: $open-sans-extra-bold;

        @include bp-medium {
            padding-bottom: 5px;
        }
    }

    &__months {
        @include heading-m;
        display: block;
        padding: 5px;
        white-space: nowrap;
        color: $dark-gray;
        font-family: $lexend-family;
        font-weight: $lexend-extra-bold;

        @include bp-medium {
            display: inline;
            padding: 0;
        }
    }

    &__months-container {
        @include flex(2);
        position: relative;
        // prevent library overwrite
        box-sizing: content-box;

        @include bp-medium {
            padding: 0 20px;
            text-align: left;
        }
    }

    &__view {
        @include flex(2);

        @include bp-medium {
            text-align: left;
        }

        &-link {
            @extend .button-link, .button-link--extra-low-emphasis;
            display: inline-block;
            margin: 10px 25px;
            text-align: start;
            white-space: nowrap;

            @include bp-medium {
                margin: 0;
            }

            &-icon {
                display: inline-block;
                margin-right: 5px;
            }
        }
    }

    &__link-icon {
        position: relative;
        padding-right: 14px;

        &::before {
            position: absolute;
            top: -4px;
            left: -10px;
            font-size: calculate-rem(25);
        }
    }

    &__total-amount {
        @include body-l;
        display: inline-block;
        color: $dark-gray;
        font-family: $lexend-family;
        font-weight: $lexend-extra-bold;

        @include bp-medium {
            display: inline;
            text-align: left;
        }
    }

    &__arrow {
        cursor: pointer;
        color: $medium-gray;
        font-size: calculate-rem(40);

        &:hover {
            color: $blue;
        }
    }

    &__received {
        @include body-m;
        padding: 5px;
        width: 100%;
        color: $medium-gray;
        font-family: $open-sans-family;
        font-weight: $open-sans-semi-bold;

        @include bp-medium {
            padding-left: 5vw;
            text-align: left;
        }
    }

    &__total-received {
        @include body-m;
        padding: 5px;
        color: $medium-gray;
        font-family: $open-sans-family;
        font-weight: $open-sans-semi-bold;

        @include bp-medium {
            text-align: left;
        }
    }

    &__date-range {
        @include box-sizing(border-box);
        margin: 0 auto;
        width: 50%;
        text-transform: uppercase;
        color: $medium-gray;
        font: calculate-rem(12) / calculate-rem(20) $open-sans-family;
        font-weight: $open-sans-extra-bold;

        @include bp-medium {
            margin: 0;
            padding: 0 0 40px 165px;
            width: 100%;
            text-align: left;
            line-height: calculate-rem(26);
            font-size: calculate-rem(14);
        }

        @include bp-coned-title {
            padding-left: 190px;
        }
    }

    &--received {
        cursor: default;
        padding: 10px 20px;

        @include bp-medium {
            padding: 15px 40px;
        }

        @include bp-coned-title {
            padding: 15px 65px;
        }
    }

    &--open {
        .billing-payment-item__arrow {
            @include transform(rotate(180deg));
        }

        .billing-payment-item__table {
            height: auto;

            &-container {
                border-bottom: 1px solid $light-gray;
                padding: 20px 0;
                height: auto;

                @include bp-medium {
                    padding: 0;
                }
            }
        }

        .billing-payment-item--bill {
            border-bottom: 0;
        }
    }

    &--post-payment {
        @include flexbox;
        @include flex-direction(column);
        padding: 10px 0 10px 10px;
        text-align: left;

        @include bp-medium {
            @include flex-direction(row);
            padding: 20px 0 20px 20px;
            width: 100%;
        }

        p:first-child {
            @include bp-medium {
                width: 50%;
            }
        }
    }

    &__header-tab {
        display: none;

        @include bp-medium {
            display: block;
            font-weight: bold;
        }
    }

    &__title {
        font-weight: bold;

        @include bp-medium {
            display: none;
            padding-left: 10px;
        }
    }

    &__container-right {
        @include flexbox;
        @include justify-content(space-between);
    }

    &__table {
        @extend %flexbox;
        @include flex-wrap(wrap);

        margin-top: 10px;
        padding: 0;
        width: 100%;
        height: 0;

        @include bp-medium {
            width: 40%;
        }

        @include bp-coned-title {
            width: 30%;
        }

        &-container {
            @include transition(all 0.2s ease);
            height: 0;
            overflow: hidden;

            @include bp-medium {
                width: 100%;
            }
        }

        &-wrapper {
            @extend %flexbox;
            @include flex-direction(column);
            @include box-sizing(border-box);

            margin: 0 auto;
            width: 50%;

            @include bp-medium {
                @include flex-direction(row);
                @include align-items(flex-start);

                margin: 0;
                padding: 0 0 55px 160px;
                width: 100%;
            }

            @include bp-coned-title {
                padding-left: 190px;
            }
        }

        &-row {
            @include flex-grow(1);

            width: 50%;
            overflow: hidden;
            list-style: none;

            &--full {
                width: 100%;
            }
        }

        &-row-text {
            padding: 3px;
            text-align: left;
            color: $dark-gray;
            font: calculate-rem(12) / calculate-rem(16) $open-sans-family;
            font-weight: $open-sans-regular;

            @include bp-medium {
                padding: 1px;
                line-height: calculate-rem(22);
                font-size: calculate-rem(14);
            }

            &--bold {
                padding-top: 5px;
                font-weight: $open-sans-extra-bold;
            }

            &--title {
                font: calculate-rem(18) / calculate-rem(24) $open-sans-family;
                font-weight: $open-sans-extra-bold;

                @include bp-medium {
                    padding: 0 15px 5px 0;
                    line-height: calculate-rem(28);
                    font-size: calculate-rem(20);
                }
            }
        }
    }

    &--cancelled {
        position: relative;

        .billing-payment-item__total-amount {
            opacity: 0.6;
        }

        &::before {
            position: absolute;
            left: 0;
            background-color: $red;
            width: 10px;
            height: 100%;
            content: '';
        }
    }
}

.billing-payment-table {
    @include transition(all, 0.2s, ease);
    border-bottom: 1px solid $medium-gray;
    padding: 10px 20px;
    text-align: left;

    @include bp-medium {
        padding: 50px 40px;
    }


    &__container {
        display: table;
        padding-top: 15px;
        width: 100%;

        @include bp-medium {
            padding-top: 0;
        }

        table {
            width: 100%;
        }
    }

    &--post-payment {
        width: 100%;

        th, 
        td {
            @include body-l;
            width: 50%;
            font-family: $open-sans-family;

            @include bp-medium {
                padding: 20px 0 20px 20px;
            }
        }

        th {
            text-align: left;
            font-weight: $open-sans-bold;
        }

        td {
            font-weight: $open-sans-semi-bold;
        }
    }

    &__data {
        &--mobile {
            padding: 10px 0 10px 10px;

            @include bp-medium {
                display: none;
            }

            &:before {
                display: table-row;
                border-top: 1px solid $medium-gray;
                width: 100%;
                height: 10px;
                content: '';

            }

            &:after {
                display: table-row;
                border-bottom: 1px solid $medium-gray;
                width: 100%;
                height: 10px;
                content: '';
            }
        }

        &--desktop {
            display: none;
        
            @include bp-medium {
                display: table-row-group;
            }
        }

        &--right {
            text-align: right;
        }
    }
}

.billing-payment--oru {
    .billing-payment-item__arrow:hover {
        color: $orange;
    }
}
