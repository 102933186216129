// ============ SELECT BOX ===============

.coned-field-select {
    position: relative;
    overflow: hidden;
}

.coned-select {
    @include webkit-apparence(none);
    @include body-l;
    display: inline-block;
    border-bottom: 2px solid $medium-gray;
    background-color: $white;
    cursor: pointer;
    padding: 29px 0 0;
    width: 100%;
    height: 72px;
    color: $medium-gray;
    font-family: $open-sans-family;
    font-weight: $open-sans-semi-bold;

    @include bp-medium {
        padding-left: 20px;
    }

    &:-moz-focusring {
        text-shadow: 0 0 0 $medium-gray;
        color: transparent;
    }

    &--active {
        border-bottom: 2px solid $green;
        text-shadow: 0 0 0 $dark-gray;
        color: $dark-gray;
        font-weight: $open-sans-semi-bold;

        &:-moz-focusring {
            text-shadow: 0 0 0 $dark-gray;
        }
    }

    &--no-margin {
        margin: 0 !important;
    }

    &--body-m {
        @include body-m;
    }

    &--dark-gray {
        color: $dark-gray;
    }

    &:focus {
        outline-offset: 0;

        body:not(.mouse-user):not(.is-oru) & {
            @extend .coned-focus-outline;
        }

        body:not(.mouse-user) & {
            @extend .oru-focus-outline;
        }
    }

    &--on-focus {
        &:focus {
            outline: 3px solid $green;
        }
    }

    &:disabled {
        @include opacity(0.5);
        pointer-events: none;
    }

    &::-ms-expand {
        display: none;
    }

    &--left {
        @include bp-medium {
            border-right: 0;
        }
    }

    &__label {
        @include opacity(0);
        @include body-s;
        position: absolute;
        top: 20px;
        left: 0;
        color: $green;
        font-family: $open-sans-family;
        font-weight: $open-sans-semi-bold;

        @include bp-medium {
            left: 20px;
        }

        &--active {
            @include opacity(1);
            display: block;
            top: 16px;
        }
    }

    &__arrow {
        position: absolute;
        top: 47%;
        right: 2px;
        background-color: $white;
        width: 57px;
        height: 30px;
        text-align: center;
        color: $medium-gray;
        font-size: calculate-rem(40);
        pointer-events: none;

        &--small-right-aligned {
            top: 50%;
            width: 22px;
            font-size: calculate-rem(30);
        }
    }

    &__help-text {
        padding: 5px 0;
        font: calculate-rem(13) / calculate-rem(20) $open-sans-family;

        @include bp-medium {
            padding: 5px 0 5px 20px;
        }
    }

    option {
        color: $dark-gray;

        &:disabled {
            opacity: 0.5;
            color: $medium-gray;
        }
    }

    &.coned-input-message--error,
    &.coned-input-top-message--error {
        color: $dark-gray;
    }
}
