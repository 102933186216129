.manage-third-party {
    &__wrapper {
        margin-bottom: 20px;
        background-color: $white;

        @include bp-medium {
            margin-bottom: 50px;
        }
    }

    &__container {
        padding: 40px 10px 40px 30px;

        @include bp-medium {
            padding: 60px 0 60px 14.5%;
            width: 71%;
        }
    }

    &__title {
        @include heading-l;
        padding-bottom: 20px;
        font-family: $lexend-family;
        font-weight: $lexend-extra-bold;
    }

    &__description {
        @include body-m;
        color: $medium-gray;
        font-family: $open-sans-family;
    }

    &__table-container {
        margin-top: 40px;
        border-top: 2px solid $medium-gray;
    }

    &__table-item {
        @include flexbox;
        @include flex-direction(column);

        border-bottom: 1px solid $medium-gray;
        padding: 20px 0;

        @include bp-medium {
            @include flex-direction(row);
            @include align-items(center);
        }
    }

    &__item-title,
    &__item-date,
    &__item-cta-container {
        @include bp-small {
            padding-bottom: 5px;

            &:last-child {
                padding: 0;
            }
        }
    }

    &__item-title {
        @include body-m;
        color: $medium-gray;
        font-family: $open-sans-family;

        @include bp-medium {
            @include flex-basis(40%);
        }
    }

    &__item-date {
        @include body-s;
        color: $medium-gray;
        font-family: $open-sans-family;

        @include bp-medium {
            @include flex-basis(24%);
        }
    }

    &__item-cta-container {
        @include bp-medium {
            @include flex-basis(36%);

            text-align: right;
        }
    }

    &__cta {
        @extend .button-link;

        &:not(:first-child) {
            padding-left: 5px;
        }

        &:not(:last-child) {
            border-right: 1px solid $dark-gray;
            border-radius: unset;
            padding-right: 8px;
        }
    }
}
