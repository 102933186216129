// ============== STREET LIGHTS COMPONENT =================
.street-lights {
    margin: 20px 10px;
    background-color: $white;

    @include bp-medium {
        margin: 40px 0;
    }

    &__title {
        @include heading-l;
        border-bottom: 2px solid $medium-gray;
        padding-bottom: 20px;
        color: $dark-gray;
        font-family: $lexend-family;
        font-weight: $lexend-extra-bold;

        @include bp-medium {
            margin-bottom: 40px;
        }

        &--table {
            text-align: left;
        }

        &-wrapper {
            padding: 45px 20px 20px;

            @include bp-medium {
                padding: 95px calc(15% - 10px) 0;
            }
        }
    }

    &__table {
        @include bp-medium {
            padding: 0 calc(15% - 10px) 95px;
        }

        &-titles {
            @extend %flexbox;
            @include flex-flow(row wrap);
            @include align-items(center);
            margin: 0 20px;
            padding: 10px 0;

            @include bp-medium {
                margin: 0;
                border-bottom: 2px solid $orange;
            }
        }

        &-title {
            @include flex-basis(auto);
            @include box-sizing(border-box);
            @include body-xs;
            display: none;
            padding-right: 5px;
            text-transform: uppercase;
            font-family: $open-sans-family;

            @include bp-medium {
                display: inline-block;
                width: calc(100% / 8);
                font-weight: $open-sans-bold;
            }

            &--credit-details {
                width: calc(100% / 11);
            }
        }

        &-item {
            @extend %flexbox;
            @include transition(all, 0.2s, ease);
            @include flex-flow(column wrap);
            @include align-items(center);
            border-bottom: 1px solid $medium-gray;
            padding: 10px 20px;

            @include bp-medium {
                @include flex-direction(row);
                padding: 30px 0;
            }
        }

        &-item-description {
            @extend %flexbox;
            @include flex-basis(auto);
            @include box-sizing(border-box);
            @include order(2);
            display: none;
            margin: 5px 0;

            @include bp-medium {
                @include flexbox;
                margin: 0;
                padding-right: 10px;
                width: calc(100% / 8);
                text-align: left;
            }

            &--credit-details {
                @include bp-medium {
                    width: calc(100% / 11);
                }
            }
        }

        &-item-title {
            @include body-s;
            display: none;
            margin-right: 5px;
            text-transform: uppercase;
            font-family: $open-sans-family;
            font-weight: $open-sans-extra-bold;
        }

        &-item-title--pole {
            @include body-s;
            display: block;
            margin-top: 30px;
            margin-bottom: 5px;
            text-align: center;
            text-transform: none;
            font-family: $open-sans-family;
            font-weight: $open-sans-regular;

            @include bp-medium {
                display: none;
            }
        }

        &-item--open {
            @include bp-small {
                .street-lights__table-item-description {
                    @include flexbox;
                    @include justify-content(flex-start);
                    width: 250px;
                }

                .street-lights__table-item-pole {
                    display: none;
                }

                .street-lights__table-item-title {
                    @include flexbox;
                }

                .street-lights__arrow {
                    @include transform(rotate(180deg));
                    margin-top: 10px;
                }
            }
        }

        &-text {
            @include body-s;
            color: $dark-gray;
            font-family: $open-sans-family;
            font-weight: $open-sans-semi-bold;

            @include bp-medium {
                @include body-xs;
            }
        }

        &-text--pole {
            @include body-l;
            font-family: $lexend-family;
            font-weight: $lexend-extra-bold;

            @include bp-medium {
                @include body-xs;
                font-family: $open-sans-family;
                font-weight: $open-sans-semi-bold;
            }
        }
    }

    &__show-more {
        @extend .button;
        display: block;
        margin: 30px auto;
    }

    &__arrow {
        @include transition(all, 0.2s, ease);
        @include order(2);
        position: relative;
        cursor: pointer;
        width: 20px;
        height: 20px;
        color: $silver-chalice;

        &::before {
            position: absolute;
            top: -10px;
            left: -10px;
            font-size: calculate-rem(40);
        }

        body:not(.mouse-user):not(.is-oru) &:focus {
            @extend .coned-focus-outline;
        }

        body:not(.mouse-user) &:focus {
            @extend .oru-focus-outline;
        }

        &__button {
            border-radius: 10px;
            background-color: $white;
            padding: 10px;
            width: 20px;
            height: 20px;
        }
    }

    &__description {
        padding: 0 20px 45px;
        font: $open-sans-regular calculate-rem(14) / calculate-rem(20) $open-sans-family;

        @include bp-medium {
            padding: 0 calc(15% - 10px) 95px;
            width: 70%;
            font-size: calculate-rem(16) / calculate-rem(22);
        }
    }
}
