// ============ TEXTAREA ===============

.coned-textarea {
    @include box-sizing(border-box);
    @include body-l;
    outline: 0;
    border: 0;
    padding: 45px 0;
    width: 100%;
    color: $dark-gray;
    font-family: $open-sans-family;
    font-weight: $open-sans-semi-bold;
    touch-action: manipulation;
    resize: none;

    @include bp-smaller {
        padding: 30px 0;
    }

    @include bp-contact-us {
        padding: 33px 0;
    }

    @include bp-medium {
        padding: 33px 20px;
    }

    &:focus {
        margin-top: 38px;
        border-color: $blue;
        padding-top: 0;

        + label {
            @include body-s;
            padding-top: 10px;
            padding-bottom: 0;
            color: $green;
        }
    }

    &:disabled {
        background-color: $white;
        color: $medium-gray;

        &,
        & + .coned-label,
        & + .coned-label + .border-bar {
            opacity: 0.5;
        }
    }

    &--on-focus {
        &:focus {
            outline: 3px solid $green;
        }
    }

    &--filled ~ label {
        @include body-s;
        padding-top: 10px;
        color: $green;
    }

    &--opt-out {
        margin: 33px 0;
        outline: none;
        border: 1px solid $light-gray;
        padding: 10px;
        color: $dark-gray;
        font-family: $open-sans-family;
        font-size: calculate-rem(14);
        font-weight: $open-sans-semi-bold;
        resize: none;

        @include bp-medium {
            margin: 33px 30px;
        }
    }
}
