// ============== WAS THIS HELPFUL COMPONENT =================

.was-this-helpful {
    @extend %flexbox;
    @include align-items(center);

    &__text {
        @include body-s;
        display: inline-block;
        padding-right: 15px;
        text-transform: uppercase;
        color: $dark-gray;
        font-family: $open-sans-family;
        font-weight: $open-sans-extra-bold;
    }

    &__button {
        @extend .additional-button, .additional-button--secondary-color;
        @include border-radius(50% 0 0 50%);
        border-right: 1px solid;
        border-bottom: 2px solid;
        padding: 12px;
        width: 48px;
        height: 48px;

        &::before {
            position: absolute;
            top: 0;
            left: 2px;
            font-size: calculate-rem(45);
        }

        &--active {
            @extend .additional-button:active:not([disabled]);
        }

        body:not(.mouse-user):not(.is-oru) &:focus {
            outline: none;

            &::after {
                @extend .coned-button-focus;
            }
        }

        body:not(.mouse-user) &:focus {
            outline: none;

            &::after {
                @extend .oru-button-focus;
            }
        }
    }

    &__button--thumb-down {
        @include transform(rotate(180deg));
    }
}
