.back-to-top-link {
    &:focus {
        display: inline-block;
        position: static;
        margin: 0 0 50px 20px;
        width: auto;
        height: auto;

        @include bp-medium {
            margin: 0 0 80px 21.5%;
        }

        body:not(.mouse-user):not(.is-oru) & {
            @extend .coned-focus-outline;
        }

        body:not(.mouse-user) & {
            @extend .oru-focus-outline;
        }

        &::before {
            content: none !important;
        }
    }
}
